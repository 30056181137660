<template>
  <v-autocomplete
    v-show="canReadCohorts || isAdminOrRoot"
    :items="cohorts"
    :model-value="modelValue"
    :loading="loading"
    v-model:search="search"
    @update:model-value="(v: Cohort) => $emit('update:modelValue', v)"
    @click:clear="resetDropdown"
    :clearable="true"
    return-object
    rounded="xl"
    prepend-icon="fa fa-graduation-cap"
    item-title="title"
    hide-details
    variant="outlined"
    density="compact"
    :label="$t('common.select_option')"
    :no-data-text="loading ? $t('common.loading') : $t('components.CohortSearchDropdown.instruction')"
  >
    <template v-slot:append-item>
      <div v-intersect="endIntersect"/>
      <div class="text-center" v-if="loading && cohorts.length > 0">{{ $t("common.loading") }}</div>
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts">
import {PropType, watch} from "vue";
import {
  Cohort,
  Institution,
  Tenant,
  YapiError,
  isYapiError,
  CohortPaginationParams,
  Role,
  Entity,
  Action
} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {ref} from "vue";
import {userPaginatedAutocomplete} from "@/lib/paginatedAutocompletes";

const store = useGlobalStore();
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const canReadCohorts = store.hasAbility(Entity.Cohort, Action.read);
const loading = ref(false);

const props = defineProps({
  institution: {
    type: Object as PropType<Institution | undefined>,
    required: false,
  },
  tenant: {
    type: Object as PropType<Tenant | undefined>,
    required: false,
  },
  modelValue: {
    type: Object as PropType<Cohort | undefined>,
    required: false,
  },
});

const cohorts = ref<Cohort[]>([]);

const {
  search,
  page,
  hasNextPage,
  endIntersect,
  resetDropdown
} = userPaginatedAutocomplete<Cohort>(fetchCohorts, cohorts);

const emit = defineEmits<{
  (e: "update:modelValue", cohort: Cohort | undefined): void;
}>();

/**
 * Clear the cohort value and fetch the new cohort list
 */
watch(
  () => props.institution,
  () => {
    emit("update:modelValue", undefined);
    cohorts.value = [];
    page.value = 1;
    fetchCohorts();
  }
);

watch(
  () => props.tenant,
  () => {
    emit("update:modelValue", undefined);
    cohorts.value = [];
    page.value = 1;

    fetchCohorts();
  }
);

async function fetchCohorts() {
  const paginationParams: CohortPaginationParams = {
    title: search.value,
    size: 10,
    page: page.value
  };

  store.networkBusy = true;
  if (props.institution && props.institution._id)
    paginationParams.filterInstitution = props.institution._id;

  if (props.tenant && props.tenant._id)
    paginationParams.filterTenant = props.tenant._id;

  try {
    store.networkBusy = true;
    loading.value = true;
    const nextPage = (await yapi.admin.cohort.paginate(paginationParams)).data;
    hasNextPage.value = nextPage.hasNextPage;
    cohorts.value = cohorts.value.concat(nextPage.docs);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    loading.value = false;
  }
}

</script>
