<template>
  <report-filter-options
    :show-tenant-filter="true"
    :show-institution-filter="true"
    :show-cohort-filter="true"
    :show-cohort-breakdown="true"
    :show-institution-breakdown="true"
    :disable-run-report-button="false"
    :allow-export="reportData.length > 0"
    :is-report-active="isReportActive"
    @change-tenant="onTenantChange"
    @change-institution="onInstitutionSelectionChange"
    @change-cohort="onCohortChange"
    @change-cohort-breakdown="onCohortBreakdownChange"
    @change-institution-breakdown="onInstitutionBreakdownChange"
    @click-export-report="exportReportData"
    @click-run-report="runReport"
    @click-reset="resetReport"
  >
    <template #filterList>
      <v-list-item v-if="breakdownByCohort || breakdownByInstitution">
        <span class="font-weight-bold">{{ $t('common.breakdown_by') }}: </span>
        <span v-if="breakdownByInstitution">{{ $t('common.institution') }}</span>
        <span v-if="breakdownByCohort && breakdownByInstitution">, </span>
        <span v-if="breakdownByCohort">{{ $t('common.cohort') }}</span>
      </v-list-item>
    </template>
  </report-filter-options>
  <v-data-table
    v-if="filteredReportData.length > 0 && !loadingData"
    :items="filteredReportData"
    :headers="headers"
    :loading="loadingData"
    :items-per-page="50"
    class="mt-4"
  >
    <template v-slot:item.percentageUsed="{ item }">
      <v-chip :color="getColor(item.percentageUsed as number)">
        {{ item.percentageUsed }}%
      </v-chip>
    </template>
  </v-data-table>
  <ShowNoResults
    v-else-if="filteredReportData.length === 0 && showReport && !loadingData"
  />
</template>

<script setup lang="ts">
import {useI18n} from "vue-i18n";
import {computed, ref} from "vue";

import {useInsights} from "@/pages/ReportsDetailPage/reportsDetailPage";
import ReportFilterOptions from "@/components/Reporting/ReportFilterOptions.vue";
import ShowNoResults from "@/pages/ReportsDetailPage/components/NoResultsFoundCard.vue";
const {t} = useI18n();
const dataTableCohortSearch = ref<string>("");
const showReport = ref(false);

const {
  timeFilter,
  tenantId,
  institutionIds,
  cohortId,
  reportData,
  isReportActive,
  breakdownByCohort,
  breakdownByInstitution,
  loadingData,
  onCohortChange,
  onTenantChange,
  onInstitutionSelectionChange,
  onCohortBreakdownChange,
  onInstitutionBreakdownChange,
  getReportData,
  exportData,
  getColor
} = useInsights();

/**
 * Filters the data table by the cohort search
 */
const filteredReportData = computed(() => {
  if (!dataTableCohortSearch.value) {
    return reportData.value;
  } else {
    return reportData.value
      .filter((item) => "cohort" in item && typeof item.cohort === "object" && item.cohort !== null)
      .filter((item) =>
        (item.cohort as { title: string }).title.toLowerCase().includes(dataTableCohortSearch.value.toLowerCase()));
  }
});

/**
 * Sets the headers for the report
 */
const headers = computed(
  () => {
    const headers = [
      {
        title: t("components.AccessCodeUsageReport.tenant"),
        key: "tenant.title"
      }
    ];

    if (breakdownByInstitution.value)
      headers.push({
        title: t("components.AccessCodeUsageReport.institution"),
        key: "institution.title"
      });

    if (breakdownByCohort.value)
      headers.push({
        title: t("components.AccessCodeUsageReport.cohort"),
        key: "cohort.title"
      });

    headers.push({
      title: t("components.AccessCodeUsageReport.percentage_used"),
      key: "percentageUsed"
    },
    {
      title: t("components.AccessCodeUsageReport.used"),
      key: "used"
    },
    {
      title: t("components.AccessCodeUsageReport.not_used"),
      key: "notUsed"
    },
    {
      title: t("components.AccessCodeUsageReport.total"),
      key: "total"
    });

    return headers;
  }
);

/**
 * Fetches the Access Code usage report
 */
function runReport() {
  showReport.value = true;

  getReportData("access-code-usage", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    cohortId: cohortId.value,
    breakdownByCohort: breakdownByCohort.value,
    breakdownByInstitution: breakdownByInstitution.value,
    time: timeFilter.value
  });
}

function resetReport() {
  reportData.value = [];
  showReport.value = false;
  isReportActive.value = false;
  breakdownByCohort.value = false;
  breakdownByInstitution.value = false;
}

/**
 * Exports the Access Code usage report data
 */
function exportReportData(format: string) {
  exportData("access-code-usage", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    cohortId: cohortId.value,
    breakdownByCohort: breakdownByCohort.value,
    breakdownByInstitution: breakdownByInstitution.value,
    time: timeFilter.value,
    format
  });
}
</script>
