<template>
  <v-row v-if="editing">
    <v-col>
      <image-picker
        v-model="logoFile"
        :disabled="uploading"
      />
      <v-progress-linear
        color="primary"
        v-if="uploading"
        indeterminate
      />
      <div class="text-end">
        <v-btn
          variant="text"
          color="secondary"
          :disabled="uploading"
          @click="editing = false"
        >
          {{ $t("buttons.cancel") }}
        </v-btn>
        <v-btn
          variant="text"
          color="primary"
          :disabled="uploading"
          @click="confirmVisible = true"
        >
          {{ $t("buttons.upload") }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col align-self="center"
           class="ma-1 pa-1">
      <div v-if="store.appConfiguration.images[configurationKey]">
        <v-img
          max-height="50px"
          width="auto"
          max-width="200px"
          :src="store.appConfiguration.images[configurationKey] as string"
        />
      </div>
      <div v-else>
        No Image
      </div>

    </v-col>
    <v-spacer/>
    <v-col align-self="center">
      <div class="text-end">
        <v-btn
          color="primary"
          @click="editing = true"
        >
          {{ $t("buttons.edit") }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
  <confirm-dialog
    title="Confirm"
    text="Are you sure?"
    v-model="confirmVisible"
    @confirm="uploadImage"
    @cancel="confirmVisible = false"
  />
</template>

<script setup lang="ts">
import ImagePicker from "@/components/ImagePicker.vue";
import yapi from "@/lib/yapi";
import {PropType, ref} from "vue";
import {useGlobalStore} from "@/store";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {YapiError} from "@YenzaCT/sdk";

const store = useGlobalStore();
const props = defineProps({
  configurationKey: {
    type: String as PropType<("favicon" | "logo" | "logoLight" | "appIcon" | "appIconLight")>,
    required: true
  }
});
const editing = ref(false);
const logoFile = ref();
const uploading = ref(false);
const confirmVisible = ref(false);

async function uploadImage() {
  confirmVisible.value = false;
  uploading.value = true;
  if (!logoFile.value) {
    return;
  }
  try {
    const res = await yapi.appConfiguration.setFile("images", props.configurationKey, logoFile.value);
    store.showSnackbar("Image uploaded");
    store.appConfiguration.images[props.configurationKey] = res.data.url;
  } catch (e) {
    await store.handleYapiError(e as YapiError);
  } finally {
    editing.value = false;
    uploading.value = false;
  }
}
</script>
