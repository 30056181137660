import {defineStore} from "pinia";
import router from "@/router";
import yapi from "@/lib/yapi";
import {
  Action,
  AppConfiguration,
  Entity,
  EntityAbility,
  FrontendUser,
  Permission,
  Role,
  YapiError,
  YapiMessage
} from "@YenzaCT/sdk";
import {
  fetchConfig,
  Json,
  setAppConfiguration, setCachedConfig
} from "@/lib/appConfiguration";

export const useGlobalStore = defineStore("global", {
  state: () => ({
    user: null as null | FrontendUser,
    networkBusy: false,
    yapiMessage: null as null | YapiMessage,
    snackbarVisible: false,
    confirmDialogVisible: false,
    snackbarMessage: null as string | null,
    mainMenuRail: false,
    fatalError: "",
    appLoading: true,
    appConfiguration: {} as AppConfiguration
  }),

  actions: {
    hasAbility(entity: Entity, action: Action) {
      const user = this.user as FrontendUser;
      return user.app.permissions.some(
        (permission: Permission) => permission.abilities.some(
          (ability: EntityAbility) => ability.entity === entity && ability.action === action
        )
      );
    },
    canReadEntity(entity: Entity, subEntity: Entity) {
      const user = this.user as FrontendUser;
      const userEntityPermissions = user.app.permissions;
      const accessResults: boolean[] = [];
      for (const permission of userEntityPermissions) {
        const userEntityValue = permission.entityId;
        accessResults.push(
          this.canAccessSubEntityViaEntity(
            entity,
            subEntity,
            Action.read,
            userEntityValue as string
          ),
        );
      }

      // Return true if any permission grants read access, otherwise return false
      return accessResults.some((accessResult) => accessResult);
    },

    canAccessSubEntityViaEntity(entity: Entity, subEntity: Entity, action: Action, entityId: string) {
      const user = this.user as FrontendUser;

      const permission = user.app.permissions.find((p) => p.entity === entity && p.entityId === entityId);
      if (!permission) return false;

      return permission.abilities.some((a) => a.entity === subEntity && a.action === action);
    },
    hasSomeAbilities(entity: Entity) {
      const user = this.user as FrontendUser;
      const permissions = user.app.permissions;
      return permissions.some(
        (permission: Permission) => permission.abilities.some(
          (ability: EntityAbility) => ability.entity === entity
        )
      );
    },
    async fetchUser() {
      try {
        this.networkBusy = true;
        const res = await yapi.user.getUser();
        if (res.status === 204) {
          await router.push({name: "login"});
        } else if (res.status === 200) {
          if (res.data.app.role === Role.User) {
            this.showSnackbar("You do not have permission to access this application.");
            return await router.push({name: "login"});
          }
          this.user = res.data;
        } else {
          this.fatalError = "Unexpected server error";
        }
      } catch (e) {
        const err = e as Error;
        // eslint-disable-next-line no-console
        console.error(err);
        this.fatalError = err.message;
      } finally {
        this.appLoading = false;
        this.networkBusy = false;
      }
    },

    async login(username: string, password: string) {
      try {
        this.networkBusy = true;
        const res = await yapi.auth.login(username, password);
        if (res.status === 200) {
          this.user = res.data;
          if (this.user.app.role === Role.User) {
            this.showSnackbar("You do not have permission to access this application.");
            await router.push({name: "login"});
          } else {
            await router.push({name: "dashboard"});
          }
        }
      } finally {
        this.networkBusy = false;
      }
    },

    async logout() {
      await yapi.auth.logout();
      this.user = null;
      await router.push({name: "login"});
    },

    showSnackbar(message: string) {
      this.snackbarMessage = message;
      this.snackbarVisible = true;
    },

    handleNetworkLoadingAndSnackbar(message: string) {
      this.networkBusy = false;
      this.snackbarMessage = message;
      this.snackbarVisible = true;
    },

    async handleYapiError(e: YapiError) {
      let message = {message: e.message};
      if (e.response) {
        if (e.response.status === 401) {
          await this.logout();
          await router.push({name: "login"});
          return;
        } else {
          message = e.response.data;
        }
      }
      this.showSnackbar(message.message);
    },

    async saveAppConfiguration(key: keyof AppConfiguration, value: Json) {
      await setAppConfiguration(key, value as Record<string, unknown>);
      // Refresh the app config, busting the local cache
      const config = await fetchConfig();
      this.appConfiguration = config;
      setCachedConfig(config);
    }
  },
});

