<template>
  <v-row>
    <v-col>
      <v-card height="100%">
        <v-card-text>
          <v-table>
            <tbody>
              <detail-row
                :label="$t('accessCode.code')"
                icon="far fa-envelope"
                :value="accessCode.code"
                data-pw="voucherDetailCode"
              />
              <detail-row
                :label="$t('accessCode.account_type')"
                icon="far fa-user"
                :value="$t(`accountTypes.${accessCode.accountType}`)"
                data-pw="voucherDetailAccountType"
              />
              <detail-row
                :label="$t('accessCode.user')"
                icon="far fa-signal"
                :value="accessCode.user as string"
                data-pw="voucherDetailUser"
              />
              <detail-row
                :label="$t('accessCode.used_at')"
                icon="far fa-circle-check"
                :value="
                  !!accessCode.usedAt
                    ? toLocaleDateTime(accessCode.usedAt.toString())
                    : '-'
                "
                data-pw="voucherDetailUsedAt"
              />
              <detail-row
                :label="$t('accessCode.research')"
                icon="far fa-circle-check"
                :value="accessCode.researchAssessments.length > 0"
                data-pw="voucherDetailResearchAssessments"
              />
              <detail-row
                :label="$t('accessCode.research_assessments')"
                icon="far fa-circle-check"
                :value="formattedResearchAssessments"
                data-pw="voucherDetailResearchAssessments"
              />
              <detail-row
                v-if="canReadCohort"
                :label="$t('accessCode.cohort')"
                icon="far fa-star"
                data-pw="voucherDetailCohort"
                :value="cohort?.title"
                @click="router.push({name: 'cohort', params: {institutionId: institution?._id, id: cohort?._id}})"
              />
              <detail-row
                v-if="canReadTenant"
                :label="$t('accessCode.tenant')"
                icon="far fa-star"
                data-pw="voucherDetailTenant"
                :value="tenant?.title"
                @click="router.push({name: 'tenant', params: {id: tenant?._id}})"
              />
              <detail-row
                v-if="canReadInstitution"
                :label="$t('accessCode.institution')"
                icon="far fa-star"
                data-pw="voucherDetailInstitution"
                :value="institution?.title"
                @click="router.push({name: 'institutions', params: {id: institution?._id}})"
              />
            </tbody>
          </v-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import {AccessCode, Action, Cohort, Entity, Institution, isYapiError, Role, Tenant, YapiError} from "@YenzaCT/sdk";
import DetailRow from "@/components/DetailRow.vue";
import {toLocaleDateTime} from "@/lib/time";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {useRouter} from "vue-router";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const router = useRouter();

const store = useGlobalStore();
const props = defineProps<{
  accessCode: AccessCode;

}>();

const cohort = ref<Cohort>();
const tenant = ref<Tenant>();
const institution = ref<Institution>();

const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);

const canReadEntity = (entity: Entity, subEntity: Entity) => {
  if (!props.accessCode[entity.toLowerCase() as keyof AccessCode]) return false;

  return isAdminOrRoot.value || store.canAccessSubEntityViaEntity(
    entity,
    subEntity,
    Action.read,
    props.accessCode[entity.toLowerCase() as keyof AccessCode] as string
  ) || store.canAccessSubEntityViaEntity(
    subEntity,
    subEntity,
    Action.read,
    props.accessCode[subEntity.toLowerCase() as keyof AccessCode] as string
  );
};

const hasResearchAssessments = computed(() =>
  Object.keys(props.accessCode.researchAssessments || {}).length > 0);

const formattedResearchAssessments = computed(() => {
  if (!hasResearchAssessments.value) return "-";

  return props.accessCode.researchAssessments
    .map((assessmentKey) => t(`assessments.${assessmentKey}.title`))
    .join(", ");
});

const canReadTenant = computed(() => canReadEntity(Entity.Tenant, Entity.Tenant));
const canReadInstitution = computed(() => canReadEntity(Entity.Tenant, Entity.Institution));
const canReadCohort = computed(() =>
  canReadEntity(Entity.Tenant, Entity.Cohort) || canReadEntity(Entity.Institution, Entity.Cohort));

async function fetchDetails() {
  try {
    store.networkBusy = true;

    if (props.accessCode.cohort && canReadCohort.value) {
      cohort.value = (
        await yapi.admin.cohort.get(props.accessCode.cohort.toString())
      ).data;
    }

    if (props.accessCode.tenant && canReadTenant.value) {
      tenant.value = (
        await yapi.admin.tenant.get(props.accessCode.tenant.toString())
      ).data;
    }

    if (props.accessCode.institution && canReadInstitution.value) {
      institution.value = (
        await yapi.admin.institution.get(
          props.accessCode.institution.toString()
        )
      ).data;
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

onMounted(async () => {
  await fetchDetails();
});
</script>
