<template>
  <v-autocomplete
    v-show="canReadTenants || isAdminOrRoot"
    :model-value="modelValue"
    @update:model-value="(v: Tenant) => $emit('update:modelValue', v)"
    @click:clear="resetDropdown"
    v-model:search="search"
    :disabled="!!disabled"
    :clearable="true"
    hide-details
    :loading="loading"
    :items="tenants"
    item-title="title"
    return-object
    rounded="xl"
    prepend-icon="fa fa-buildings"
    variant="outlined" density="compact"
    :label="$t('common.select_option')"
    :no-data-text="loading ? $t('common.loading') : $t('components.TenantSearchDropdown.instruction')"
  >
    <template v-slot:append-item>
      <div v-intersect="endIntersect"/>
      <div class="text-center" v-if="loading && tenants.length > 0">{{ $t("common.loading") }}</div>
    </template>
  </v-autocomplete>

</template>

<script setup lang="ts">

import {ref, PropType, watch} from "vue";
import {Action, Entity, isYapiError, Role, Tenant, YapiError} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {userPaginatedAutocomplete} from "@/lib/paginatedAutocompletes";

const store = useGlobalStore();
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const canReadTenants = store.hasAbility(Entity.Tenant, Action.read);

const tenants = ref<Tenant[]>([]);
const loading = ref(false);

const props = defineProps({
  modelValue: {
    type: Object as PropType<Tenant | undefined>,
    required: false
  },
  disabled: {
    type: Boolean as PropType<boolean | undefined>,
    required: false,
  },
});

const {
  search,
  page,
  hasNextPage,
  endIntersect,
  resetDropdown
} = userPaginatedAutocomplete<Tenant>(fetchTenants, tenants);

watch(() => props.modelValue, () => {
  if (props.modelValue === undefined) {
    tenants.value = [];
    page.value = 1;
    search.value = "";
    fetchTenants();
  }
});

/**
 * Fetches the tenants matching the given search value
 */
async function fetchTenants() {
  const paginationParams = {
    title: search.value,
    size: 10,
    page: page.value
  };

  try {
    loading.value = true;
    store.networkBusy = true;
    const nextPage = (await yapi.admin.tenant.paginate(paginationParams)).data;
    hasNextPage.value = nextPage.hasNextPage;
    tenants.value = tenants.value.concat(nextPage.docs);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    loading.value = false;
  }
}

</script>
