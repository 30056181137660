<template>
  <v-menu
    :close-on-content-click="false"
    location="end"
  >
    <template v-slot:activator="{ props }">
      <div class="selector_row mr-1">
        <v-label>
          {{ label }}
        </v-label>
        <div
          class="colour_block elevation-1 mb-1"
          :style="`background-color: ${backgroundColor};`"
          v-bind="props"
        />
      </div>
    </template>
    <v-color-picker
      :model-value="modelValue"
      @update:model-value="v => $emit('update:modelValue', v)"
      :modes="[mode]"
      style="overflow: hidden"
    />
  </v-menu>
</template>

<script setup lang="ts">

import {computed, PropType} from "vue";
import {convertRGBAObjectToString} from "@/lib/colors";

const props = defineProps({
  label: {
    type: String as PropType<string>,
  },
  modelValue: {
    type: [String, Object] as PropType<string | { r: number, g: number, b: number, a: number }>,
    required: true
  },
  mode: {
    type: String as PropType<("rgb" | "rgba" | "hsl" | "hsla" | "hex" | "hexa")>,
    required: true
  }
});

const backgroundColor = computed(() =>
  (typeof props.modelValue === "object" && (props.mode === "rgb" || props.mode === "rgba"))
    ? convertRGBAObjectToString(props.modelValue) : props.modelValue);

defineEmits<{
  (e: "update:modelValue", colour: string): void;
}>();
</script>

<style>
.selector_row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.colour_block {
  width: 60px;
  height: 32px;
  cursor: pointer;
}
</style>
