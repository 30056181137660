<template>
  <v-card :title="$t('components.MethodsCard.title')" height="100%" width="100%" data-pw="methodsCard">
    <v-card-text>
      <v-table>
        <tbody>
          <detail-row
            :label="$t('components.MethodsCard.email')"
            icon="far fa-calendar-alt"
            :value="user.email"
          />
          <detail-row
            :label="$t('components.MethodsCard.phone')"
            icon="far fa-clock"
            :value="user.phone"
          />
          <template v-if="noProvider">
            <detail-row
              :label="$t('components.MethodsCard.oAuth_provider')"
              icon="far fa-lock"
              :value="$t('components.MethodsCard.no_oAuth_provider')" />
          </template>
          <template v-if="hasProvider('snapplify')">
            <detail-row
              :label="$t('components.MethodsCard.snapplify_user')"
              icon="far fa-lock"
              :value="user.auth.lockedUntil ? $t('common.yes') : $t('common.no')"
            />
          </template>
          <template v-if="hasProvider('stdbank')">
            <detail-row
              :label="$t('components.MethodsCard.stdbank_user')"
              icon="far fa-lock"
              :value="user.auth.lockedUntil ? $t('common.yes') : $t('common.no')"
            />
          </template>
          <template v-if="hasProvider('azuread')">
            <detail-row
              :label="$t('components.MethodsCard.azuread_user')"
              icon="far fa-lock"
              :value="user.auth.lockedUntil ? $t('common.yes') : $t('common.no')"
            />
          </template>
          <template v-if="hasProvider('oidc')">
            <detail-row
              :label="$t('components.MethodsCard.oidc_user')"
              icon="far fa-lock"
              :value="user.auth.lockedUntil ? $t('common.yes') : $t('common.no')"
            />
          </template>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import {computed, PropType, Ref, ref} from "vue";

import DetailRow from "@/components/DetailRow.vue";
import {User} from "@YenzaCT/sdk";

const props = defineProps({
  user: {
    type: Object as PropType<User>,
    required: true,
  },
});

const user: Ref<User> = ref({
  ...props.user,
});

const hasProvider = (provider: string) => user.value?.auth?.oAuthProviders?.some(
  (oAuthProvider) => oAuthProvider.driver === provider
) ?? false;

// Computed property to check if no providers exist
const noProvider = computed(() => {
  const providers = ["snapplify", "stdbank", "azuread", "oidc"];
  return !providers.some((provider) => hasProvider(provider));
});

</script>

