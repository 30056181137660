<template>
  <v-card :title="$t('components.D6Details.title')"  height="100%" >
    <v-card-text v-if="message">
      {{ message }}
    </v-card-text>

    <v-card-text v-if="school">
      <v-table>
        <tbody>
          <detail-row
            :label="$t('components.D6Details.school_name')"
            icon="far fa-title"
            :value="school.school_name"/>
          <detail-row
            :label="$t('components.D6Details.admin_email_address')"
            icon="far fa-title"
            :value="school.admin_email_address"/>

          <detail-row
            :label="$t('components.D6Details.telephone_calling_code')"
            icon="far fa-title"
            :value="school.telephone_calling_code"/>
          <detail-row
            :label="$t('components.D6Details.telephone_number')"
            icon="far fa-title"
            :value="school.telephone_number"/>
          <detail-row
            :label="$t('components.D6Details.website')"
            icon="far fa-title"
            :value="school.website"/>
          <detail-row
            :label="$t('components.D6Details.physical_address')"
            icon="far fa-title"
            :value="school.physical_address"/>
          <detail-row
            :label="$t('components.D6Details.country')"
            icon="far fa-title"
            :value="school.country"/>
          <detail-row
            :label="$t('components.D6Details.province')"
            icon="far fa-title"
            :value="school.province"/>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import {useI18n} from "vue-i18n";
const { t } = useI18n();
import DetailRow from "@/components/DetailRow.vue";
import yapi from "@/lib/yapi";
import {onMounted, ref} from "vue";

const props = defineProps(["institution"]);
const school = ref();
const message = ref();
onMounted(async () => {
  try {
    await fetchSchool();
  } catch (e) {
    message.value = t("components.D6Details.error_message");
  }
});
async function fetchSchool() {
  const res = await yapi.admin.institution.d6.getSchool(props.institution._id);
  school.value = res.data;
}
</script>
