<template>
  <MetricTile
    :loading="loading"
    :no-data="!metric || !metric.count"
    :options="{showAdditionalData: true}"
    icon="fa-solid fa-user-graduate"
  >
    <template #title>{{ $t("components.UsersHasSelectedSchoolSubjectsMetricTile.title")}}</template>
    <template #metric-title>{{ $t("components.UsersHasSelectedSchoolSubjectsMetricTile.metricTitle") }}</template>
    <template #metric>{{ metric.count }} / {{ metric.total }}
    </template>
    <template #additionalMetric> {{ metric.ratio }}%</template>
    <template #info>{{ $t("components.UsersHasSelectedSchoolSubjectsMetricTile.info") }}</template>
  </MetricTile>
</template>

<script setup lang="ts">
import MetricTile from "@/components/Metrics/MetricTile.vue";
import {useMetrics} from "@/lib/metrics";

const {
  loading,
  metric
} = useMetrics("users-selected-subjects-ratio");

</script>

<style scoped>

</style>
