<template>
  <v-card min-height="300px" height="100%" variant="outlined" class="metric-title" :disabled="noData">
    <div class="font-weight-bold px-4 pt-2">
      <v-icon color="primary" size="x-small" class="mr-3">{{ icon }}</v-icon>
      <span class="caption"><slot name="title"/></span>
    </div>
    <v-card-subtitle class="pt-0 pb-1">
      <span class="grey-lighten-3" v-if="loading">{{ $t("components.MetricTile.time_range")}}</span>
      <span v-if="!loading">{{ $t("common.total") }}, {{ durationLabel }}</span>
    </v-card-subtitle>
    <v-divider/>
    <v-card-text>
      <slot name="info"/>
      <v-row v-if="loading" align-content="center" align="center" justify="center">
        <v-col cols="12" align-self="center" class="text-center py-12">
          <v-progress-circular class="ma-auto" indeterminate/>
        </v-col>
      </v-row>
      <v-row v-if="!loading" dense align-content="center">
        <v-col cols="12" align-self="center" class="text-center py-12">

          <v-row v-if="noData" dense>
            <v-col class="text-center">
              <span class="font-weight-black  text-h3">0</span>
            </v-col>
          </v-row>
          <v-row v-if="noData" dense>
            <v-col class="text-center">
              <span class="caption font-weight-bold">
                <slot name="metric-title"/>
              </span>
            </v-col>
          </v-row>
          <v-row v-if="!noData" dense>
            <v-col cols="12">
              <span class="font-weight-black  text-h3"><slot name="metric"/></span>
            </v-col>
            <v-col v-if="options?.showAdditionalData" cols="12">
              <span class="text-h6">
                <slot name="additionalMetric"/>
              </span>
            </v-col>
            <v-col v-if="chipText" cols="12">
              <v-chip size="small" class="ml-1" :color="chipColour">
                <v-icon>fa fa-arrow-up</v-icon>
                {{ chipText }}
              </v-chip>
            </v-col>
            <v-col class="text-center">
              <span class="caption font-weight-bold">
                <slot name="metric-title"/>
              </span>
            </v-col>
            <v-col v-if="options.showNotes" cols="12">
              <span class="text-caption text-grey"><slot name="notes"/></span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import {computed, PropType} from "vue";
import {useMetricsData} from "@/lib/metrics";

const {
  currentFilter
} = useMetricsData();

const props = defineProps({
  chipText: {
    type: String as PropType<string>,
  },
  loading: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  noData: {
    type: Boolean as PropType<boolean>,
    default: false
  },
  icon: {
    type: String as PropType<string>,
  },
  options: {
    type: Object as PropType<Record<string, unknown>>,
    default: () => ({
      showAdditionalData: false,
      showNotes: false
    })
  },
  chipType: {
    type: String as PropType<string>,
    validator(value: string) {
      // The value must match one of these strings
      return ["increase", "decrease", "nochange"].includes(value);
    }
  },
});

const chipColour = computed(() => {
  if (props.chipType === "increase")
    return "green";

  if (props.chipType === "decrease")
    return "red";

  return "grey";
});

const durationLabel = computed(() => {
  const currentFilterValue = currentFilter.value;
  const unit = currentFilterValue.unit;
  const type = currentFilterValue.dateFilterType;
  const value = currentFilterValue.value;

  if (type === "in the last") {
    return "last " + value + " " + unit;
  }

  if (type === "between") {
    if (value && value[0] === "start_of_previous_day" && value[1] === "end_of_previous_day")
      return "yesterday";
    else {
      return value[0] + " - " + value[1];
    }
  }

  if (type === "since") {
    if (value === "start_of_current_day")
      return "today";
    else {
      return "since " + value;
    }
  }

  return "";
});

</script>

<style scoped>
.metric-title {
  border: 1px solid rgba(0, 0, 0, 0.10);
  background-color: #FFFFFF;
}
</style>
