<template>
  <MetricTile
    :loading="loading"
    :no-data="!metric || !metric.count"
    icon="fa-solid fa-sign-in-alt"
  >
    <template #title>{{ $t("components.UserLoginsMetricTile.title")}}</template>
    <template #metric-title>{{ $t("components.UserLoginsMetricTile.metricTitle") }}</template>
    <template #metric>
      {{ metric.count }}
    </template>
    <template #info>{{ $t("components.UserLoginsMetricTile.info") }}</template>
  </MetricTile>
</template>

<script setup lang="ts">
import MetricTile from "@/components/Metrics/MetricTile.vue";
import {useMetrics} from "@/lib/metrics";

const {
  loading,
  metric
} = useMetrics("user-logins-over-time");
</script>
