<template>
  <div>
    <v-select
      v-model="type"
      :items="couponTypes"
      variant="outlined"
      :label="$t('coupon.type')"
      data-pw="couponType"
      @update:modelValue="updateTypes"
    />
    <v-text-field
      v-if="type === 'fixed'"
      prefix="R"
      :modalValue="amount"
      variant="outlined"
      @update:modelValue="$emit('update:amount', $event)"
      :label="$t('coupon.amount')"
    />
    <v-slider
      v-else-if="type === 'percent'"
      :label="$t('coupon.percent_amount_label')"
      :modalValue="amount"
      variant="outlined"
      @update:modelValue="$emit('update:amount', $event)"
      class="align-center"
      :max="1"
      :min="0.01"
      :step="0.01"
      hide-details
    >
      <template v-slot:append>
        <v-text-field
          :modelValue="amount"
          :rules="amountRules"
          variant="outlined"
          @update:modelValue="$emit('update:amount', $event)"
          hide-details
          single-line
          density="compact"
          type="number"
          style="width: 90px"
        ></v-text-field>
      </template>
    </v-slider>
  </div>
</template>

<script setup lang="ts">
import { VSelectOption } from "@/lib/vuetify";
import {useI18n} from "vue-i18n";
import {ref} from "vue";
const { t } = useI18n();

defineProps({
  amount: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["update:type", "update:amount"]);
const type = ref("");
const amountRules = [(v: string | undefined) => !!v || t("couponTypes.amount_required")];

const couponTypes: VSelectOption[] = [
  { title: t("couponTypes.full"), value: "full"},
  { title: t("couponTypes.percent"), value: "percent"},
  { title: t("couponTypes.fixed"), value: "fixed"}
];

function updateTypes(newValue: string) {
  type.value = newValue;
  emit("update:type", type.value);
}
</script>
