<template>
  <v-card-actions>
    <v-spacer></v-spacer>
    <v-btn @click="$emit('close')">
      {{ $t('buttons.cancel') }}
    </v-btn>
    <v-btn color="primary" :disabled="!props.valid || store.networkBusy" data-pw="createCouponButton" @click="$emit('submit')">
      {{ $t('buttons.create') }}
    </v-btn>
  </v-card-actions>
</template>

<script setup lang="ts">
import {useGlobalStore} from "@/store";

const store = useGlobalStore();
defineEmits(["close", "submit"]);
const props = defineProps({
  valid: Boolean,
});

</script>
