<template>
  <v-autocomplete
    v-show="canReadInstitutions || isAdminOrRoot"
    :items="institutions"
    :model-value="modelValue"
    :loading="loading"
    v-model:search="search"
    @update:model-value="(v: Institution | Institution[] | null) => $emit('update:modelValue', v)"
    @click:clear="resetDropdown"
    :multiple="multiple"
    :clearable="true"
    rounded="xl"
    prepend-icon="fa fa-building"
    hide-details
    return-object
    item-title="title"
    variant="outlined"
    density="compact"
    :label="$t('common.select_option')"
    :no-data-text="loading
      ? $t('common.loading')
      : $t('components.InstitutionSearchDropdown.instruction')"
  >
    <template v-slot:append-item>
      <div v-intersect="endIntersect"/>
      <div class="text-center" v-if="loading && institutions.length > 0">
        {{ $t("common.loading") }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts">

import {ref, watch, PropType} from "vue";
import {
  Institution,
  isYapiError,
  Tenant,
  YapiError,
  InstitutionPaginationParams,
  Role,
  Entity,
  Action
} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {userPaginatedAutocomplete} from "@/lib/paginatedAutocompletes";

const store = useGlobalStore();
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const canReadInstitutions = store.hasAbility(Entity.Institution, Action.read);
const loading = ref(false);

const props = defineProps({
  tenant: {
    type: Object as PropType<Tenant | undefined>,
    required: false
  },
  modelValue: {
    type: Object as PropType<Institution | Institution[] | null>,
    default: null
  },
  multiple: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const institutions = ref<Institution[]>([]);
const {
  search,
  page,
  hasNextPage,
  endIntersect,
  resetDropdown
} = userPaginatedAutocomplete<Institution>(fetchInstitutions, institutions);

const emit = defineEmits<{
  (e: "update:modelValue", institution: Institution | Institution[] | null): void
}>();

watch(() => props.tenant, () => {
  emit("update:modelValue", null);
  institutions.value = [];
  page.value = 1;
  fetchInstitutions();
});

/**
 * Fetches the tenant with the given id and sets the institutions and cohorts
 * @param id
 */
async function fetchInstitutions() {
  const paginationParams: InstitutionPaginationParams = {
    title: search.value,
    size: 10,
    page: page.value
  };

  if (props.tenant && props.tenant._id) {
    paginationParams.filterTenant = props.tenant._id;
  }
  try {
    store.networkBusy = true;
    loading.value = true;
    const nextPage = (await yapi.admin.institution.paginate(paginationParams)).data;
    hasNextPage.value = nextPage.hasNextPage;
    institutions.value = institutions.value.concat(nextPage.docs);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    loading.value = false;
  }
}

</script>

