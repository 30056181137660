<template>
  <v-select
    :model-value="modelValue"
    :disabled="store.networkBusy"
    data-pw="roleSelect"
    class="pa-1 mt-1"
    variant="outlined"
    density="compact"
    hide-details
    :items="roleOptions"
    @update:model-value="(v: Role) => $emit('update:modelValue', v)"
  />
</template>

<script setup lang="ts">
import {computed, PropType} from "vue";
import { Role } from "@YenzaCT/sdk";
import { useI18n } from "vue-i18n";
import { useGlobalStore } from "@/store";

const { t } = useI18n();
const store = useGlobalStore();

const props = defineProps({
  modelValue: {
    type: [String, Array] as PropType<Role>,
    required: true
  },
  type: {
    type: String,
    required: true,
  }
});

defineEmits<{
    (e: "update:modelValue", role: Role): void;
  }>();

const currentUserRole = computed(() => store.user?.app.role);

const availableRoles = computed(() => {
  const roles = Object.values(Role);
  const includeDeleted = props.type === "filter";

  switch (currentUserRole.value) {
  case Role.Root:
    return includeDeleted
      // Type: filter
      ? roles.filter((role) => role)
      // Type: edit
      : roles.filter((role) => role !== Role.Deleted);

  case Role.Admin:
    return includeDeleted
      // Type: filter
      ? roles.filter((role) => role !== Role.Root)
      // Type: edit
      : roles.filter((role) => role !== Role.Root && role !== Role.Deleted);

  case Role.Manager:
    return includeDeleted
      // Type: filter
      ? roles.filter((role) => role !== Role.Root && role !== Role.Admin)
      // Type: edit
      : roles.filter((role) => role !== Role.Root && role !== Role.Admin && role !== Role.Deleted);

  default:
    return [];
  }
});

const roleOptions = computed(() =>
  availableRoles.value.map((role) => ({
    title: t(`roles.${role}`),
    value: role
  })));
</script>
