<template>
  <v-progress-linear
    :active="globalStore.networkBusy"
    :indeterminate="true"
    absolute
    color="yellow"
  ></v-progress-linear>
</template>
<script setup lang="ts">
import {useGlobalStore} from "@/store";

const globalStore = useGlobalStore();
</script>
