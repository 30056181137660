<template>
  <div>
    <v-row class="pl-2 pr-2">
      <!-- Show x of y -->
      <v-col class="text-left text-caption" cols="2" align-self="center">
        {{ paginationFooterData.pageText }}
      </v-col>

      <!-- Page numbers -->
      <v-col class="text-center hidden-sm-and-down" cols="8"  align-self="center">
        <v-btn
          variant="outlined"
          :disabled="!paginationFooterData.prevPage"
          @click="$emit('onPrev')"
          color="primary"
          class="mr-2"
        >
          <v-icon>far fa-chevron-left</v-icon>
          <span>{{ $t("buttons.previous") }}</span>
        </v-btn>
        <template
          v-for="item in paginationFooterData.pageSpread"
          :key="item.value"
        >
          <v-btn
            v-if="item.type === 'button'"
            :color="isCurrentPage(item.value) ? 'primary' : 'secondary'"
            elevation="0"
            size="small"
            @click="$emit('onPageChange', item.value)"
          >
            {{ item.value }}
          </v-btn>
          <span v-if="item.type === 'text'" class="pa-1">
            <strong>{{ item.value }}</strong>
          </span>
        </template>
        <v-btn
          variant="text"
          :disabled="!paginationFooterData.nextPage"
          @click="$emit('onNext')"
          color="primary"
        >
          <span>{{ $t("buttons.next") }}</span>
          <v-icon>far fa-chevron-right</v-icon>
        </v-btn>
      </v-col>

      <!-- # per page -->
      <v-col class="text-right" cols="2" align-self="center">
        <items-per-page-selector
          class="ml-auto"
          :current="paginationFooterData.limit"
          @update="(selection : number) => $emit('update:perPage', selection)"
        />
      </v-col>

    </v-row>

  </div>
</template>

<script setup lang="ts">
import {PropType} from "vue";
import {FooterData} from "@/lib/pagination";
import ItemsPerPageSelector from "@/components/ItemsPerPageSelector.vue";

const props = defineProps({
  paginationFooterData: {
    type: Object as PropType<FooterData>,
    required: true
  }
});

defineEmits<{
  (e: "update:perPage", perPage: number): void;
  (e: "onNext"): void;
  (e: "onPrev"): void
  (e: "onPageChange", pageNumber: number | string): void
}>();

function isCurrentPage(currentItem: number | string) {
  if (typeof currentItem === "string") {
    return false;
  }
  return currentItem === props.paginationFooterData.currentPage;
}
</script>

<style scoped>
.alignBottom {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
</style>
