<template>
  <v-card-account-settings
    :title="$t('components.ChangeEmailAddressDialog.title')"
    data-pw="changeEmailDialogCard"
  >
    <v-card-text>
      <p class="pb-4">
        {{ $t('components.ChangeEmailAddressDialog.warning') }}
      </p>
      <v-text-field
        v-model="user.email"
        variant="outlined"
        :rules="emailRules"
        :label="$t('components.ChangeEmailAddressDialog.new_email')"
        required
        data-pw="newEmailTextField"
      />
      <v-text-field
        v-model="confirmEmail"
        variant="outlined"
        :rules="emailRules"
        :label="$t('components.ChangeEmailAddressDialog.confirm_email')"
        :disabled="!user.email"
        required
        data-pw="confirmEmailTextField"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        data-pw="cancelChangeEmailButton"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-primary
        @click="changeEmailAddress"
        :disabled="!emailsMatch || !isValidEmail(user.email!)"
        data-pw="confirmChangeEmailButton"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-primary>
    </v-card-actions>
  </v-card-account-settings>
</template>

<script setup lang="ts">
import { computed, PropType, ref, Ref } from "vue";
import { isYapiError, DashUser, YapiError, User } from "@YenzaCT/sdk";
import { useGlobalStore } from "@/store";
import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

import yapi from "@/lib/yapi";
import { isValidEmail } from "@/lib/validation";

const store = useGlobalStore();
const { t } = useI18n();
const route = useRoute();

const confirmEmail = ref("");

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});

const user: Ref<DashUser> = ref({...props.user, email: ""} as DashUser);

const userId: Ref<string> = ref("");
userId.value = route.params.id as string;

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}>();

const emailRules = [
  (v: string) => isValidEmail(v) || t("components.ChangeEmailAddressDialog.email_invalid")
];

const changeEmailAddress = async () => {
  try {
    await yapi.admin.user.edit(userId.value, { email: user.value.email } as User);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    emit("close");
  }
};

const emailsMatch = computed(() => user.value.email === confirmEmail.value);
</script>
