<template>
  <v-card :title="$t('components.ManageUser.organisationDetails')" data-pw="organisationDetailCard" height="100%">
    <v-card-text>
      <v-table>
        <tbody>
          <detail-row
            v-if="canReadTenant"
            :label="$t('components.ProfileCard.tenant')"
            icon="far fa-globe"
            :value="tenantData?.title ?? $t('common.none')"
            @click="tenantData?._id ?
              router.push({ name: 'tenantDetail', params: { id: tenantData?._id } }) : null
            "
            data-pw="userTenantRow"
          />
          <detail-row
            v-if="canReadInstitution"
            :label="$t('components.ProfileCard.institution')"
            icon="far fa-building"
            :value="institutionData?.title ?? $t('common.none')"
            @click="institutionData?._id ?
              router.push({
                name: 'institutionDetail',
                params: { id: institutionData?._id },
              }) : null
            "
            data-pw="userInstitutionRow"
          />
          <detail-row
            v-if="canReadCohort"
            :label="$t('components.ProfileCard.cohort')"
            icon="far fa-graduation-cap"
            :value="cohortData?.title ?? $t('common.none')"
            @click="cohortData?._id ?
              router.push({
                name: 'cohortDetail',
                params: {
                  institutionId: institutionData?._id,
                  id: cohortData?._id,
                },
              }) : null
            "
            data-pw="userCohortRow"
          />
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import {onMounted, PropType, ref} from "vue";

import DetailRow from "@/components/DetailRow.vue";
import {
  Cohort,
  DashUser,
  Institution,
  isYapiError,
  Tenant,
  YapiError
} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {useRouter} from "vue-router";

const store = useGlobalStore();
const router = useRouter();
const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true,
  },
  canReadTenant: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
  canReadInstitution: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
  canReadCohort: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
});

const cohortData = ref<Cohort>();
const tenantData = ref<Tenant>();
const institutionData = ref<Institution>();

async function fetchDetails() {
  try {
    store.networkBusy = true;

    if (props.user?.app.cohort && props.canReadCohort) {
      cohortData.value = (
        await yapi.admin.cohort.get(props.user?.app?.cohort)
      ).data;
    }

    if (props.user?.app.tenant && props.canReadTenant) {
      tenantData.value = (
        await yapi.admin.tenant.get(props.user?.app.tenant.toString())
      ).data;
    }

    if (props.user?.app.institution && props.canReadInstitution) {
      institutionData.value = (
        await yapi.admin.institution.get(props.user?.app.institution.toString())
      ).data;
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

onMounted(async () => {
  await fetchDetails();
});
</script>
