<template>
  <v-radio-group
    v-model="inputType"
    color="primary"
    inline
    density="compact"
    @change="handleInputTypeChange"
    :hide-details="true"
  >
    <v-radio
      :label="$t('components.ForgotPasswordForm.contact_email')"
      value="email"
      class="mr-4"
      data-pw="emailRadio"
    />
    <v-radio
      :label="$t('components.ForgotPasswordForm.contact_phone')"
      value="phone"
      class="mr-4"
      data-pw="phoneRadio"
    />
  </v-radio-group>
</template>

<script setup lang="ts">
import { ref } from "vue";

const inputType = ref("email");

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
}>();

function handleInputTypeChange() {
  emit("update:modelValue", inputType.value);
}
</script>
