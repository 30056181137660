<script setup lang="ts">

</script>

<template>
  <v-row>
    <v-col cols="12">
      <v-skeleton-loader
        v-for="n in 5"
        :key="n"
        :loading="true"
        type="list-item-two-line"
      >
        <v-list-item
          lines="two"
          subtitle="Subtitle"
          title="Title"
          rounded
        ></v-list-item>
      </v-skeleton-loader>
    </v-col>
  </v-row>
</template>

<style scoped>

</style>
