<template>
  <authenticated-layout>
    <template #headerIcon>
      far fa-chart-mixed
    </template>
    <template #header>{{ $t(`components.ReportsDetailPage.reports.${report}`) }}</template>

    <template #breadcrumbs>
      <v-breadcrumbs
        density="compact"
        class="pl-0 text-caption"
      >
        <v-breadcrumbs-item :to="{ name: 'reports' }" class="font-weight-bold" color="secondary">
          {{ $t("components.ReportsDetailPage.insights") }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider/>
        <v-breadcrumbs-item color="secondary">
          {{ $t(`components.ReportsDetailPage.reports.${report}`) }}
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </template>

    <!-- Usage Reports-->
    <access-code-usage-report v-if="report === 'voucher-usage'"/>
    <worker-type-completion-insights v-if="report === 'worker-type-completion'"/>
    <big5-completion-insights v-if="report === 'personality-type-completion'"/>
    <subject-selection-completion v-if="report === 'subject-selection-completion'"/>

    <!-- Assessment Reports-->
    <worker-type-distribution-report v-if="report === 'worker-type-distribution'"/>

    <!-- User Choices Reports-->
    <users-subject-choices-report v-if="report === 'user-subject-choices'"/>
    <users-holland-codes-report v-if="report === 'user-worker-types'"/>
    <users-big5-types-report v-if="report === 'user-personality-types'"/>
    <subject-choice-distribution-report v-if="report === 'subject-choice-distribution'"/>
    <personality-traits-distribution-report v-if="report === 'personality-traits-distribution'" />
    <group-dashboard-report v-if="report === 'group-dashboard'" />
  </authenticated-layout>

</template>

<script setup lang="ts">
import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import {useRoute} from "vue-router";

const route = useRoute();
import AccessCodeUsageReport from "@/pages/ReportsDetailPage/Reports/AccessCodeUsageReport.vue";
import WorkerTypeCompletionInsights from "@/pages/ReportsDetailPage/Reports/HollandCodeCompletionReport.vue";
import Big5CompletionInsights from "@/pages/ReportsDetailPage/Reports/Big5CompletionReport.vue";
import SubjectSelectionCompletion from "@/pages/ReportsDetailPage/Reports/SubjectSelectionCompletionReport.vue";
import WorkerTypeDistributionReport from "@/pages/ReportsDetailPage/Reports/WorkerTypeDistributionReport.vue";
import UsersSubjectChoicesReport from "@/pages/ReportsDetailPage/Reports/UsersSubjectChoicesReport.vue";
import UsersHollandCodesReport from "@/pages/ReportsDetailPage/Reports/UsersHollandCodesReport.vue";
import UsersBig5TypesReport from "@/pages/ReportsDetailPage/Reports/UsersBig5TypesReport.vue";
import SubjectChoiceDistributionReport from "@/pages/ReportsDetailPage/Reports/SubjectChoiceDistributionReport.vue";
import PersonalityTraitsDistributionReport from "@/pages/ReportsDetailPage/Reports/PersonalityTraitsDistributionReport.vue";
import GroupDashboardReport from "@/pages/ReportsDetailPage/GroupDashboardReport/GroupDashboardReport.vue";

const report = route.params.report as string;

</script>
