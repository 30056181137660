import * as Sentry from "@sentry/vue";
import {Route} from "@sentry/vue/types/router";
import {Vue} from "@sentry/vue/types/types";

const dsn = import.meta.env.VITE_SENTRY_DSN;
const environment = import.meta.env.VITE_SENTRY_ENVIRONMENT;

export default function(app: Vue, router: VueRouter) {
  if (!dsn) {
    return;
  }

  Sentry.init({
    app,
    dsn,
    integrations: [
      new Sentry.BrowserTracing({
        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: environment === "production" ? 0.2 : 1.0,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment,
  });

  // Use beforeSend option to filter out 401 errors
  Sentry.addGlobalEventProcessor((event) => {
    if (
      event.exception?.values?.[0]?.value === "Request failed with status code 401" ||
      event.exception?.values?.[0]?.value === "Request failed with status code 403") {
      // Ignore 401 errors by returning null
      return null;
    }
    return event;
  });
}

interface VueRouter {
  onError: (fn: (err: Error) => void) => void;
  beforeEach: (fn: (to: Route, from: Route, next: () => void) => void) => void;
}
