<template>
  <UnauthenticatedLayout>
    <v-container class="d-flex justify-center">
      <v-card v-if="fatalError" style="width: 450px">
        <v-card-title>
          <YenzaLogo/>
          <br/>
          {{ $t("components.LoadingPage.title") }}

        </v-card-title>
        <v-card-text>
          <p>{{  $t("components.LoadingPage.subtitle") }}</p>
          <div class="mt-4">
            <v-spacer/>
            <blockquote class="bg-grey pa-4 rounded">
              {{fatalError}}
            </blockquote>
            <i18n-t keypath="if_issue_persists" tag="p" for="contact_support">
              <a href="support@yenzacareers.com">{{ $t("components.LoadingPage.contact_support")}}</a>.
            </i18n-t>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn-primary
            @click="refresh"
            prepend-icon="fa fa-refresh">
            {{ $t("components.LoadingPage.reload_button") }}
          </v-btn-primary>
        </v-card-actions>
      </v-card>
    </v-container>
  </UnauthenticatedLayout>
</template>

<script setup lang="ts">
import UnauthenticatedLayout from "@/layout/UnauthenticatedLayout.vue";
import {PropType} from "vue";
import YenzaLogo from "@/components/YenzaLogo.vue";

defineProps({
  fatalError: {
    type: String as PropType<string>,
    required: true
  },
});

function refresh() {
  window.location.reload();
}
</script>
