<template>
  <v-card :title="$t('common.entities.Tenant')"  height="100%" >
    <v-card-text>
      <v-table>
        <tbody>
          <detail-row
            :label="$t('common.title')"
            :value="tenant.title"
          />
          <detail-row
            :label="$t('common.slug')"
            :value="tenant.slug"
          />
          <detail-row
            :label="$t('common.country')"
            :value="country"
          />
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import {computed, PropType} from "vue";
import DetailRow from "@/components/DetailRow.vue";
import {Tenant} from "@YenzaCT/sdk";
import {countryOptions} from "@/lib/appConfiguration";

const props = defineProps({
  tenant: {
    type: Object as PropType<Tenant>,
    required: true
  }
});

const country = computed(() => countryOptions.find((c) => c.value === props.tenant.country)?.title);
</script>
