<template>
  <v-card v-show="showFilters || !isReportActive" class="data-table-toolbar px-4" elevation="0">
    <report-period-filter-bar :show-filters="false"/>

    <v-card variant="outlined" color="grey-lighten-2 my-4" width="100%">
      <v-card-text class="text-black">
        <span class="text-caption font-weight-bold">{{ $t("components.ReportFilterOptions.filter_by") }}</span>
        <v-row align-content="center" :dense="true">
          <v-col v-if="showTenantFilter" cols="3"
                 align-self="center">
            <tenant-search-dropdown v-model="selectedTenant" :label="$t('common.entities.Tenant')"/>
          </v-col>
          <v-col v-if="props.showInstitutionFilter" cols="3" align-self="center">
            <institution-search-dropdown
              :multiple="true"
              v-model="selectedInstitutions"
              :tenant="selectedTenant"
              :label="$t('common.entities.Institution')"
            />
          </v-col>
          <v-col v-if="props.showCohortFilter" cols="3" align-self="center">
            <cohort-search-dropdown
              :disabled="isCohortSearchDisabled"
              v-model="selectedCohort"
              :institution="cohortInstitution"
              :tenant="selectedTenant"
            />
          </v-col>
          <v-col>
            <slot name="filters"/>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-row v-if="showCohortBreakdown || showInstitutionBreakdown" align-content="center" :dense="true">
      <v-col>
        <v-card variant="outlined" color="grey-lighten-2" width="100%">
          <v-card-text class="text-black">
            <span class="text-black text-caption font-weight-bold">{{
              $t("components.ReportFilterOptions.breakdown_by")
            }}</span>
            <br/>
            <v-btn-group>
              <v-checkbox
                density="compact"
                v-if="showCohortBreakdown"
                :label="$t('common.entities.Cohort')"
                v-model="cohortBreakdown"
                hide-details @change="handleCohortBreakdownClick"
                class="mr-2"
              />
              <v-checkbox
                density="compact"
                v-if="showInstitutionBreakdown"
                :label="$t('common.entities.Institution')"
                v-model="institutionBreakdown"
                hide-details
                @change="handleInstitutionBreakdownClick"/>
            </v-btn-group>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row align-content="center" class="mb-4">
      <v-col align-self="center">
        <v-btn-primary
          :disabled="disableRunReportButton || store.networkBusy"
          @click="handleRunReportClick"
        >
          {{ $t("components.ReportFilterOptions.run_report") }}
        </v-btn-primary>
        <v-btn-secondary
          :disabled="store.networkBusy"
          @click="handleResetClick"
        >
          {{ $t("buttons.reset") }}
        </v-btn-secondary>
      </v-col>
      <slot name="actions"/>
    </v-row>
    <slot/>
  </v-card>
  <v-card v-show="isReportActive && !showFilters" class="data-table-toolbar px-4" elevation="0">
    <v-container fluid>
      <v-row class="noPrint">
        <v-col>
          <v-btn color="primary" @click="handleOpenFiltersClick" class="mr-4">{{ $t("buttons.open_filters") }}</v-btn>

          <data-export-download-menu v-if="allowExport" :formats="['csv', 'excel']" @click="handleExportReportClick"
                                     class="mr-2"/>

          <v-btn
            :disabled="store.networkBusy"
            @click="handleResetClick"
          >{{ $t("buttons.reset") }}
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>

          <v-list density="compact">
            <v-list-item><span class="font-weight-bold">{{ $t("common.period") }}: </span>
              <duration-label/>
            </v-list-item>
            <v-list-item v-if="selectedTenant"><span class="font-weight-bold">{{
              $t("common.tenant")
            }}: </span>{{ selectedTenant?.title }}
            </v-list-item>
            <v-list-item v-if="selectedInstitutions && selectedInstitutions.length > 0">
              <span class="font-weight-bold">{{ $t("common.institution") }}: </span>
              {{ selectedInstitutions.map((item) => item.title).join(", ") }}
            </v-list-item>
            <v-list-item v-if="selectedCohort"><span class="font-weight-bold">{{
              $t("common.cohort")
            }}: </span>{{ selectedCohort?.title }}
            </v-list-item>
            <slot name="filterList"></slot>
          </v-list>
        </v-col>

      </v-row>
    </v-container>
  </v-card>
</template>

<script setup lang="ts">
import ReportPeriodFilterBar from "@/components/Reporting/ReportPeriodFilterBar.vue";
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";
import {Action, Cohort, Entity, Institution, Role, Tenant} from "@YenzaCT/sdk";
import {computed, ref, watch} from "vue";
import {useGlobalStore} from "@/store";
import InstitutionSearchDropdown
  from "@/components/FormFields/InstitutionSearchDropdown.vue";
import CohortSearchDropdown from "@/components/FormFields/CohortSearchDropdown.vue";
import DurationLabel from "@/components/Reporting/DurationLabel.vue";
import DataExportDownloadMenu from "@/components/DataExportDownloadMenu.vue";

const store = useGlobalStore();
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const canReadTenants = store.hasAbility(Entity.Tenant, Action.read);

const props = defineProps(
  {
    showTenantFilter: {
      type: Boolean,
      default: false
    },
    showInstitutionFilter: {
      type: Boolean,
      default: false
    },
    showCohortFilter: {
      type: Boolean,
      default: false
    },
    showInstitutionBreakdown: {
      type: Boolean,
      default: false
    },
    showCohortBreakdown: {
      type: Boolean,
      default: false
    },
    disableRunReportButton: {
      type: Boolean,
      default: true
    },
    allowExport: {
      type: Boolean,
      default: false
    },
    isReportActive: {
      type: Boolean,
      default: false
    },
    reportTitle: {
      type: String,
      default: ""
    }
  }
);

const cohortBreakdown = ref<boolean>(false);
const institutionBreakdown = ref<boolean>(false);
const selectedTenant = ref<Tenant | undefined>();
const selectedInstitutions = ref<Institution[]>([]);
const selectedCohort = ref<Cohort | undefined>();
const showFilters = ref<boolean>(true);
const showTenantFilter = ((props.showTenantFilter || props.showInstitutionFilter) && canReadTenants) || isAdminOrRoot;
const cohortInstitution = computed(() => {
  if (!selectedInstitutions.value)
    return undefined;

  return selectedInstitutions.value.length === 1 ? selectedInstitutions.value[0] : undefined;
});

const isCohortSearchDisabled = computed(() => {
  if (!selectedInstitutions.value || selectedInstitutions.value.length === 0 || selectedInstitutions.value.length > 1)
    return true;

  return false;
});

const emit = defineEmits<{
  (e: "change-tenant", id: Tenant | undefined): void,
  (e: "change-cohort", id: Cohort | undefined): void,
  (e: "change-institution", ids: string[], titles: string[]): void,
  (e: "change-cohort-breakdown", value: boolean): void,
  (e: "click-export-report", format: string): void,
  (e: "change-institution-breakdown", value: boolean): void,
  (e: "click-run-report"): void,
  (e: "click-reset"): void,
  (e: "click-filters"): void,
}>();

watch(selectedTenant, (tenant: Tenant | undefined) => {
  emit("change-tenant", tenant);
});

watch(selectedCohort, (cohort: Cohort | undefined) => {
  emit("change-cohort", cohort);
});

watch(() => selectedInstitutions.value, (newInstitutions) => {
  if (!newInstitutions || newInstitutions.length === 0)
    return emit("change-institution", [], []);

  const ids = Object.values(newInstitutions)
    .map((item: Institution) => item._id ? item._id : "");
  const titles = Object.values(newInstitutions)
    .map((item: Institution) => item.title ? item.title : "");
  emit("change-institution", ids, titles);
});

function handleRunReportClick() {
  showFilters.value = false;
  emit("click-run-report");
}

function handleExportReportClick(format: string) {
  emit("click-export-report", format);
}

function handleResetClick() {
  selectedInstitutions.value = [];
  selectedCohort.value = undefined;
  selectedTenant.value = undefined;
  cohortBreakdown.value = false;
  institutionBreakdown.value = false;
  emit("click-reset");
}

function handleCohortBreakdownClick() {
  emit("change-cohort-breakdown", cohortBreakdown.value);
}

function handleInstitutionBreakdownClick() {
  emit("change-institution-breakdown", institutionBreakdown.value);
}

function handleOpenFiltersClick() {
  showFilters.value = true;
  emit("click-filters");
}

</script>

<style scoped>

</style>
