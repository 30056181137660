<template>
  <v-text-field
    :label="label"
    v-model="value"
    :append-inner-icon="icon"
    color="primary"
    :base-color="changed ? 'primary' : 'grey'"
    :clearable="true"
    @click:append-inner="save"
  />
</template>

<script setup lang="ts">
import {useGlobalStore} from "@/store";
import {computed, PropType, ref} from "vue";
import {AppConfiguration} from "@YenzaCT/sdk";

const store = useGlobalStore();

const props = defineProps({
  label: {
    type: String,
    required: true
  },
  configKey: {
    type: String as PropType<keyof AppConfiguration>,
    required: true
  },
  saveMessage: {
    type: String,
    default: "Configuration updated"
  }
});

const value = ref<string>(store.appConfiguration[props.configKey as keyof typeof store.appConfiguration] as string);

const changed = computed(() => value.value !== store.appConfiguration[props.configKey as keyof typeof store.appConfiguration]);

const icon = computed(() => changed.value && valid.value ? "far fa-save" : "");

const valid = computed(() => value.value && value.value.length > 0);

async function save() {
  if (!valid.value) {
    return;
  }
  await store.saveAppConfiguration(props.configKey, value.value);
  store.showSnackbar(props.saveMessage);
}
</script>
