{
  "common": {
    "edit": "Edit",
    "yes": "Yes",
    "no": "No",
    "all": "All",
    "coming_soon_label": "Full breakdown coming soon.",
    "title": "Title",
    "_id": "ID",
    "low": "Low",
    "medium": "Medium",
    "high": "High",
    "curriculum": "Curriculum",
    "insights": "Insights",
    "none": "None",
    "loading": "Loading...",
    "assessments": "Assessments",
    "researchAssessments": "Research Assessments",
    "results": "Results",
    "progress": "Progress",
    "user_permissions": "User Permissions",
    "per_page": "Per Page",
    "vs": "vs",
    "overview": "Overview",
    "search": "Search",
    "filters": "Filters",
    "columns": "Columns",
    "segment": "Segment",
    "period": "Period",
    "search_user_details": "Search Email, Phone, Name, Surname",
    "no_data_message": "No data available",
    "fetching_message": "Fetching data...",
    "download_status": "Your export is downloading.",
    "details": "Details",
    "confirm_title": "Confirm",
    "confirm_text": "Are you sure?",
    "type_confirm_message": "Please type 'CONFIRM' to continue.",
    "confirm": "CONFIRM",
    "no_data": "No data",
    "breakdown_by": "Breakdown by",
    "tenant": "Tenant",
    "institution": "Institution",
    "cohort": "Cohort",
    "total": "Total",
    "preview": "Preview",
    "excel": "Excel",
    "csv": "CSV",
    "select_all": "Select All",
    "entities": {
      "User": "User",
      "AccessCode": "Voucher",
      "Coupon": "Coupon",
      "Cohort": "Cohort",
      "Institution": "Institution",
      "Tenant": "Tenant"
    },
    "permissions": {
      "read": "Read",
      "create": "Create",
      "edit": "Edit",
      "delete": "Delete"
    },
    "search_title": "Search Title",
    "title_required": "Title is required",
    "created_at": "Created At",
    "country": "Country",
    "slug": "Slug",
    "card_title": "Title",
    "select_option": "Select",
    "countries": {
      "ZA": "South Africa",
      "KE": "Kenya",
      "ET": "Ethiopia",
      "AE": "UAE"
    },
    "no_permission": "You do not have permission to create a tenant."
  },
  "buttons": {
    "edit": "Edit",
    "open_filters": "Open Filters",
    "close_filters": "Close Filters",
    "delete": "Delete",
    "create": "Create",
    "close": "Close",
    "cancel": "Cancel",
    "confirm": "Confirm",
    "submit": "Submit",
    "disable": "Disable",
    "export": "Export",
    "export_raw_data": "Export Raw Data",
    "export_to_csv": "Export to CSV",
    "export_to_excel": "Export to Excel",
    "enable": "Enable",
    "previous": "Previous",
    "apply": "Apply",
    "menu": "Menu",
    "reset": "Reset",
    "reset_filters": "Reset filters",
    "filter": "Filter",
    "columns": "Columns",
    "manage": "Manage",
    "view": "View",
    "clear_filters": "Clear Filters",
    "upload": "Upload",
    "custom": "Custom",
    "accept": "Accept",
    "deny": "Deny",
    "next": "Next",
    "account_settings": "Account Settings",
    "download_reports": "Download Reports",
    "save": "Save",
    "manage": "Manage",
    "profile": "Profile"
  },
  "curriculums": {
    "kenyan_certificate_of_secondary_education": {
      "title": "Kenyan Certificate of Secondary Education",
      "subjects": {
        "mathematics": "Mathematics",
        "english": "English",
        "kiswahili": "Kiswahili",
        "physics": "Physics",
        "biology": "Biology",
        "chemistry": "Chemistry",
        "geography": "Geography",
        "history_and_government": "History And Government",
        "christian_religious_studies": "Christian Religious Studies",
        "hindu_religious_studies": "Hindu Religious Studies",
        "islamic_religious_studies": "Islamic Religious Studies",
        "home_science": "Home Science",
        "art_and_design": "Art And Design",
        "agriculture": "Agriculture",
        "woodwork": "Woodwork",
        "metal_work": "Metal Work",
        "building_construction": "Building Construction",
        "power_mechanics": "Power Mechanics",
        "electricity": "Electricity",
        "drawing_and_design": "Drawing And Design",
        "aviation_technology": "Aviation Technology",
        "computer_studies": "Computer Studies",
        "french": "French",
        "german": "German",
        "arabic": "Arabic",
        "kenyan_sign_language": "Kenyan Sign Language",
        "music": "Music",
        "business_studies": "Business Studies"
      },
      "subject_groups": {
        "mathematics": "Mathematics",
        "english": "English",
        "kiswahili": "Kiswahili",
        "science_1": "Science 1",
        "science_2": "Science 2",
        "humanities": "Humanities",
        "elective_1": "Elective 1",
        "elective_2": "Elective 2"
      }
    },
    "national_senior_certificate": {
      "title": "National Senior Certificate",
      "subjects": {
        "english_first_language": "English",
        "afrikaans_first_language": "Afrikaans",
        "ndebele_first_language": "isiNdebele",
        "sesotho_first_language": "Sesotho",
        "isixhosa_first_language": "isiXhosa",
        "isizulu_first_language": "isiZulu",
        "sepedi_first_language": "Sepedi",
        "setswana_first_language": "Setswana",
        "siswati_first_language": "siSwati",
        "tshivenda_first_language": "Tshivenda",
        "xitsonga_first_language": "Xitsonga",
        "english_second_language": "English",
        "afrikaans_second_language": "Afrikaans",
        "ndebele_second_language": "isiNdebele",
        "sesotho_second_language": "Sesotho",
        "isixhosa_second_language": "isiXhosa",
        "isizulu_second_language": "isiZulu",
        "sepedi_second_language": "Sepedi",
        "setswana_second_language": "Setswana",
        "siswati_second_language": "siSwati",
        "tshivenda_second_language": "Tshivenda",
        "xitsonga_second_language": "Xitsonga",
        "life_orientation": "Life Orientation",
        "mathematics": "Mathematics",
        "mathematical_literacy": "Mathematical Literacy",
        "ap_afrikaans": "AP Afrikaans",
        "ap_physics": "AP Physics",
        "ap_english": "AP English",
        "ap_maths": "AP Maths",
        "urdu": "Urdu",
        "telugu": "Telugu",
        "tamil": "Tamil",
        "spanish": "Spanish",
        "portuguese": "Portuguese",
        "latin": "Latin",
        "italian": "Italian",
        "hindi": "Hindi",
        "hebrew": "Hebrew",
        "gujarati": "Gujarati",
        "german": "German",
        "french": "French",
        "arabic": "Arab",
        "agricultural_technology": "Agricultural Technology",
        "engineering_graphics_and_design": "Engineering Graphics & Design",
        "music": "Music",
        "dramatic_arts": "Dramatic Arts",
        "dance_studies": "Dance Studies",
        "religion_studies": "Religion Studies",
        "consumer_studies": "Consumer Studies",
        "economics": "Economics",
        "information_technology": "Information Technology",
        "computer_applications_technology": "Computer Applications Technology",
        "electrical_technology": "Electrical Technology",
        "visual_arts": "Visual Arts",
        "physical_sciences": "Physical Sciences",
        "design": "Design",
        "mechanical_technology": "Mechanical Technology",
        "civil_technology": "Civil Technology",
        "business_studies": "Business Studies",
        "life_sciences": "Life Sciences",
        "agricultural_sciences": "Agricultural Sciences",
        "hospitality_studies": "Hospitality Studies",
        "geography": "Geography",
        "tourism": "Tourism",
        "history": "History",
        "agricultural_management_practices": "Agricultural Management Practices",
        "accounting": "Accounting"
      },
      "subject_groups": {
        "first_language": "First Language",
        "second_language": "Second Language",
        "life_orientation": "Life Orientation",
        "mathematics": "Mathematics",
        "elective_1": "Elective 1",
        "elective_2": "Elective 2",
        "elective_3": "Elective 3",
        "elective_4": "Elective 4",
        "elective_5": "Elective 5"
      }
    },
    "cambridge": {
      "title": "Cambridge",
      "subject_groups": {
        "group_1": "Group 1",
        "group_2": "Group 2",
        "group_3": "Group 3",
        "group_4": "Group 4",
        "group_5": "Group 5",
        "ungrouped": "Ungrouped"
      },
      "subjects": {
        "english_language_and_literature_a_as": "English Language and Literature A/AS",
        "english_language_a_as": "English Language A/AS",
        "english_first_language_igcse": "English First Language IGCSE",
        "afrikaans_second_language": "Afrikaans",
        "urdu_second_language": "Urdu",
        "turkish_second_language": "Turkish",
        "thai_second_language": "Thai",
        "telugu_second_language": "Telugu",
        "tamil_second_language": "Tamil",
        "spanish_second_language": "Spanish",
        "swahili_second_language": "Swahili",
        "russian_second_language": "Russian",
        "nepali_second_language": "Nepali",
        "marati_second_language": "Marati",
        "malay_second_language": "Malay",
        "korean_second_language": "Korean",
        "japanese_second_language": "Japanese",
        "italian_second_language": "Italian",
        "isizulu_second_language": "isiZulu",
        "hindi_second_language": "Hindi",
        "greek_second_language": "Greek",
        "german_second_language": "German",
        "french_second_language": "French",
        "dutch_second_language": "Dutch",
        "chinese_second_language": "Chinese",
        "arabic_second_language": "Arabic",
        "latin_second_language_igcse": "Latin IGCSE",
        "accounting_a_as": "Accounting A/ASS",
        "biology_a_as": "Biology A/AS",
        "physics_a_as": "Physics A/AS",
        "physical_science_igcse": "Physical Science IGCSE",
        "physics_igcse": "Physics IGCSE",
        "mathematics_igcse": "Mathematics IGCSE",
        "mathematics_a_as": "Mathematics A/AS",
        "co_ordinated_science_igcse": "Co-ordinated Science IGCSE",
        "combined_science_igcse": "Combined Science IGCSE",
        "biology_igcse": "Biology IGCSE",
        "chemistry_igcse": "Chemistry IGCSE",
        "art_and_design_a_as": "Art and Design A/AS",
        "chemistry_a_as": "Chemistry A/AS",
        "music_a_as": "Music A/AS",
        "history_a_as": "History A/AS",
        "geography_a_as": "Geography A/AS",
        "music_igcse": "Music IGCSE",
        "history_igcse": "History IGCSE",
        "environmental_management_igcse": "Environmental Management IGCSE",
        "economics_igcse": "Economics IGCSE",
        "geography_igcse": "Geography IGCSE",
        "economics_a_as": "Economics A/AS",
        "art_and_design_igcse": "Art and Design IGCSE",
        "business_a_as": "Business A/AS",
        "computer_science_a_as": "Computer Science A/AS",
        "information_technology_a_as": "Information Technology A/AS",
        "further_mathematics_a_as": "Further Mathematics A/AS",
        "religious_studies_igcse": "Religious Studies IGCSE",
        "divinity_a_as": "Divinity A/AS",
        "design_and_technology_igcse": "Design & Technology IGCSE",
        "computer_science_igcse": "Computer Science IGCSE",
        "business_studies_igcse": "Business Studies IGCSE",
        "accounting_igcse": "Accounting IGCSE",
        "drama_cambri_a_as": "Drama A/AS",
        "literature_in_english_igcse": "Literature in English IGCSE",
        "travel_and_tourism_a_as": "Travel & Tourism A/AS",
        "travel_and_tourism_igcse": "Travel & Tourism IGCSE",
        "drama_igcse": "Drama IGCSE",
        "information_and_communication_technology_igcse": "Information & Communication Technology IGCSE"
      }
    },
    "pre_international_baccalaureate": {
      "title": "Pre International Baccalaureate",
      "subject_groups": {
        "group_1": "Group 1",
        "group_2": "Group 2",
        "group_3": "Group 3",
        "group_4": "Group 4",
        "group_5": "Group 5",
        "group_6": "Group 6"
      },
      "subjects": {
        "english": "English Language And Literature Hl",
        "english_language_and_literature_hl": "English Language And Literature Hl",
        "french": "French",
        "business_management": "Business Management",
        "geography": "Geography",
        "history": "History",
        "philosophy": "Philosophy",
        "biology": "Biology",
        "chemistry": "Chemistry",
        "physics": "Physics",
        "computer_science": "Computer Science",
        "design_and_technology": "Design And Technology",
        "mathematics_core": "Mathematics Core",
        "further_studies_mathematics": "Further Studies Mathematics",
        "music": "Music",
        "visual_arts": "Visual Arts",
        "theatre_studies": "Theatre Studies"
      }
    },
    "international_baccalaureate": {
      "title": "International Baccalaureate",
      "subject_groups": {
        "group_1": "Group 1",
        "group_2": "Group 2",
        "group_3": "Group 3",
        "group_4": "Group 4",
        "group_5": "Group 5",
        "group_6": "Group 6"
      },
      "subjects": {
        "english_a_language_and_literature_sl": "English A Language And Literature SL",
        "english_a_language_and_literature_hl": "English A Language And Literature HL",
        "french_a_language_and_literature_hl": "French A Language And Literature HL",
        "french_a_language_and_literature_sl": "French A Language And Literature SL",
        "french_ab_initio": "French Ab Initio",
        "french_b": "French B",
        "business_management_hl": "Business Management HL",
        "business_management_sl": "Business Management SL",
        "economics_hl": "Economics HL",
        "economics_sl": "Economics SL",
        "geography_hl": "Geography HL",
        "geography_sl": "Geography SL",
        "history_hl": "History HL",
        "history_sl": "History SL",
        "psychology_hl": "Psychology HL",
        "psychology_sl": "Psychology SL",
        "social_and_cultural_anthropology_hl": "Social And Cultural Anthropology HL",
        "social_and_cultural_sl": "Social And Cultural Anthropology SL",
        "digital_society_hl": "Digital Society HL",
        "digital_society_sl": "Digital Society SL",
        "global_politics_hl": "Global Politics HL",
        "global_politics_sl": "Global Politics SL",
        "philosophy_sl": "Philosophy SL",
        "philosophy_hl": "Philosophy HL",
        "world_religions_hl": "World Religions HL",
        "world_religions_sl": "World Religions SL",
        "environmental_systems_and_societies_sl": "Environmental Systems And Societies SL",
        "environmental_systems_and_societies_hl": "Environmental Systems And Societies HL",
        "biology_hl": "Biology HL",
        "biology_sl": "Biology SL",
        "chemistry_hl": "Chemistry HL",
        "chemistry_sl": "Chemistry SL",
        "computer_science_hl": "Computer Science HL",
        "computer_science_sl": "Computer Science SL",
        "design_technology_hl": "Design Technology HL",
        "design_technology_sl": "Design Technology SL",
        "physics_hl": "Physics HL",
        "physics_sl": "Physics SL",
        "sports_exercise_and_health_science_hl": "Sports Exercise And Health Science HL",
        "sports_exercise_and_health_science_sl": "Sports Exercise And Health Science SL",
        "mathematics_analysis_and_approaches_hl": "Mathematics Analysis And Approaches HL",
        "mathematics_analysis_and_approaches_sl": "Mathematics Analysis And Approaches SL",
        "mathematics_applications_and_interpretation_hl": "Mathematics Applications And Interpretation HL",
        "mathematics_applications_and_interpretation_sl": "Mathematics Applications And Interpretation SL",
        "film_hl": "Film HL",
        "film_sl": "Film SL",
        "music_hl": "Music HL",
        "music_sl": "Music SL",
        "theatre_hl": "Theatre HL",
        "theatre_sl": "Theatre SL",
        "visual_arts_hl": "Visual Arts HL",
        "visual_arts_sl": "Visual Arts SL"
      }
    }
  },
  "products": {
    "FREE_TO_STARTER": "Free to Start",
    "FREE_TO_POWER": "Free to Commit",
    "STARTER_TO_POWER": "Start to Commit",
    "ASSESSMENT_ONLY_TO_POWER": "Assessment Only to Commit",
    "ASSESSMENT_ONLY_TO_STARTER": "Assessment Only to Start",
    "ASSESSMENT_ONLY_BEHAVIOUR_TO_POWER": "Assessment Only Behaviour to Commit",
    "ASSESSMENT_ONLY_BEHAVIOUR_TO_STARTER": "Assessment Only Behaviour to Start",
    "ASSESSMENT_ONLY_BEHAVIOUR_PLUS_TO_POWER": "Assessment Only Behaviour Plus to Commit",
    "ASSESSMENT_ONLY_BEHAVIOUR_PLUS_TO_STARTER": "Assessment Only Behaviour Plus to Start"
  },
  "roles": {
    "tenant_manager": "Tenant Manager",
    "admin": "Admin",
    "user": "User",
    "manager": "Manager",
    "deleted": "Deleted",
    "root": "Root"
  },
  "accountTypes": {
    "free": "Free",
    "assessmentOnly": "Assessment Only: Whole Person",
    "assessmentOnlyBehaviour": "Assessment Only: Behavioural",
    "assessmentOnlyBehaviourPlus": "Assessment Only: Behavioural Plus",
    "starter": "Start",
    "power": "Commit",
    "powerPlus": "Commit+"
  },
  "couponTypes": {
    "percent": "Percent",
    "full": "Full",
    "fixed": "Fixed"
  },
  "coupon": {
    "id": "ID",
    "code": "Code",
    "title": "Title",
    "type": "Type",
    "enabled": "Enabled",
    "research": "Research",
    "cohort": "Cohort",
    "max_uses": "Max Uses",
    "usage_count": "Usage Count",
    "amount": "Amount",
    "discount": "Discount (%)",
    "expires_at": "Expires",
    "products": "Products",
    "types": {
      "full": "Full",
      "percent": "Percent",
      "fixed": "Fixed"
    },
    "used": "Used",
    "percent_amount_label": "Discount (%)",
    "create_coupon": "Create Coupon",
    "title_required": "Title is required",
    "code_required": "Code is required",
    "delete_coupon": "Delete Coupon",
    "delete_message": "Are you sure you want to delete this coupon?"
  },
  "accessCode": {
    "id": "ID",
    "code": "Voucher Code",
    "user": "User",
    "select_all": "Select All",
    "account_type": "Account Type",
    "research": "Research",
    "research_assessments": "Research Assessments",
    "used": "Used",
    "used_at": "Used At",
    "tenant": "Tenant",
    "institution": "Institution",
    "cohort": "Cohort",
    "actions": "Actions"
  },
  "assessments": {
    "big_5": {
      "title": "Personality Traits"
    },
    "holland_code": {
      "title": "Worker Type",
      "R": "Builder",
      "I": "Thinker",
      "A": "Creator",
      "S": "Helper",
      "E": "Influencer",
      "C": "Organiser"
    },
    "ipip": {
      "title": "Ipip"
    },
    "interests": {
      "title": "Interests"
    },
    "entrepreneurial_intent": {
      "title": "Entrepreneurial Intent"
    },
    "learning_styles": {
      "title": "Learning Styles",
      "high": "High",
      "moderate": "Moderate",
      "low": "Low",
      "balanced": "Balanced"
    },
    "managing_emotion": {
      "title": "Managing Emotion",
      "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    "understanding_emotion": {
      "title": "Understanding Emotion",
      "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    "perceiving_emotion": {
      "title": "Perceiving Emotion",
      "info": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
    },
    "emotional_intelligence": {
      "title": "Emotional Intelligence"
    },
    "rock_paper_scissors": {
      "title": "Rock Paper Scissors Assessment",
      "rating": {
        "veryHigh": "Excellent",
        "high": "Good",
        "medium": "Average",
        "low": "Poor",
        "veryLow": "Very Poor"
      },
      "body": "You have demonstrated a not to shabby score on the Rock Paper Scissors Assessment. This assessment indicates someone's ability to reason through visual information and/or solve problems.",
      "description": "You have completed this assessment in 2 minutes",
      "info": "Need to add info"
    },
    "maze": {
      "title": "Maze Assessment",
      "rating": {
        "veryHigh": "Excellent",
        "high": "Good",
        "medium": "Average",
        "low": "Poor",
        "veryLow": "Very Poor"
      },
      "body": "You have demonstrated an excellent score on the Maze Assessment. This assessment indicates someone's ability to reason through visual information and/or solve problems.",
      "speedHeading": "Speed",
      "description": "You have completed this assessment in 2 minutes. This is a good time to complete this assessment.",
      "totalSwapsTitle": "Total Number of Swaps",
      "totalSwapsDescription": "Number of times you moved the blocks. This measures how many actions you took to get the right answer.",
      "averageSwapsTitle": "Average Velocity (Swaps / minute)",
      "averageSwapsDescription": "Number of swaps per minute which indicates your problem solving style.",
      "info": "Need to add info"
    },
    "cognitive_ability": {
      "title": "Cognitive Ability",
      "info": "TO FIGURE OUT"
    },
    "entrepreneurial": {
      "title": "Entrepreneurial"
    },
    "value_scales": {
      "title": "Value Scales"
    },
    "assessment_not_done": "Assessment not completed"
  },
  "components": {
    "GlobalSearchBar": {
      "no_results_for": "No results for {term}"
    },
    "SearchResultsCards": {
      "show_search_more": "Show More"
    },
    "RevokeManagerPermissionsDialog": {
      "title": "Revoke Manager Permissions"
    },
    "DownloadAssessmentDialog": {
      "title": "Download Assessment Report",
      "download_instruction": "Click confirm to download the assessment.",
      "report_downloading": "The report is being generated."
    },
    "DownloadCvDialog": {
      "title": "Download CV",
      "download_instruction": "Click confirm to download the CV.",
      "report_downloading": "The CV is being generated."
    },
    "AccessCodeTablePage": {
      "title": "Voucher Codes",
      "create_voucher": "Create Voucher",
      "search": "Search codes",
      "no_data_message": "No data available"
    },
    "ProfileCard": {
      "email": "Email",
      "phone": "Phone",
      "account_type": "Account Type",
      "role": "Role",
      "tenant": "Tenant",
      "institution": "Institution",
      "cohort": "Cohort"
    },
    "EditInstitution": {
      "update_message": "Institution updated successfully"
    },
    "DeleteInstitution": {
      "title": "Delete Institution",
      "instruction": "Please type in: ",
      "warning_message": "You are about to delete: ",
      "success_message": "Institution deleted successfully"
    },
    "DeleteCohort": {
      "title": "Delete Cohort",
      "delete_message": "Cohort deleted successfully"
    },
    "ReportPage": {
      "title": "Data Insights",
      "card_titles": {
        "usage": "Usage",
        "dashboards": "Dashboards",
        "distribution": "Distribution",
        "user_results": "User Results"
      },
      "card_subtitles": {
        "usage": "Select a report from the list below to explore users usage of the app."
      },
      "labels": {
        "coming_soon": "Coming Soon"
      },
      "reports": {
        "dashboards": {
          "group_analysis": "Group Analysis"
        },
        "usage": {
          "voucher_usage": "Voucher Usage",
          "worker_type_completion": "Worker Type Completion",
          "personality_type_completion": "Personality Type Completion",
          "subject_selection_completion": "Subject Selection Completion"
        },
        "users": {
          "user_subject_choices": "User Subject Choices",
          "user_worker_types": "User Worker Types",
          "user_personality_types": "User Personality Types"
        },
        "distributions": {
          "subject_choice_distribution": "Subject Choice Distribution",
          "worker_type_distribution": "Worker Type Distribution",
          "personality_type_distribution": "Personality Type Distribution"
        }
      }
    },
    "ManageUser": {
      "title": "Manage User",
      "current_permissions": "Current Permissions",
      "save_permissions": "Save Permissions",
      "organisationDetails": "Organisation Details"
    },
    "OrganisationDetailsCard": {
      "update_permissions": "Update permissions to see details"
    },
    "SubjectChoiceDistributionReport": {
      "title": "Subject Choice Distribution",
      "math_vs_math_literacy": "Math vs Math Literacy",
      "first_language": "First Language",
      "second_language": "Second Language",
      "no_data": "No data is available for the filters you have selected",
      "elective_choices": "Elective Choices",
      "mathematics": "Mathematics"
    },
    "ReportsDetailPage": {
      "insights": "Insights",
      "data_insights": "Data Insights",
      "no_result": "No Result",
      "no_subject_selected": "No Subject Selected",
      "reports": {
        "cohort-dashboard": "Cohort Dashboard",
        "group-dashboard": "Group Dashboard",
        "voucher-usage": "Voucher Usage",
        "worker-type-completion": "Worker Type Completion",
        "worker-type-distribution": "Worker Type Distribution",
        "personality-type-completion": "Personality Type Completion",
        "subject-selection-completion": "Subject Selection Completion",
        "user-subject-choices": "User Subject Choices",
        "user-worker-types": "User Worker Types",
        "user-personality-types": "User Personality Types",
        "personality-traits-distribution": "Personality Traits Distribution",
        "learning-styles-distribution": "Learning Styles Distribution",
        "subject-choice-distribution": "Subject Choice Distribution",
        "labels": {
          "select_at_least": "Please select at least a",
          "select_a": "Please select a",
          "run_report": "to run the report.",
          "select_an_option": "Please select an",
          "voucher_usage_for": "Voucher Usage for"
        },
        "access_codes": {
          "usage_report": {
            "headers": {
              "tenant": "Tenant",
              "institution": "Institution",
              "cohort": "Cohort",
              "percentage_used": "%",
              "used": "Used",
              "not_used": "Not Used",
              "total": "Total"
            },
            "completion_report": {
              "headers": {
                "institution": "Institution",
                "cohort": "Cohort",
                "percentage": "%",
                "complete": "Complete",
                "incomplete": "Incomplete",
                "total": "Total"
              }
            },
            "user_report": {
              "filter": {
                "cohort": "Cohort",
                "primary_type": "Primary Type",
                "secondary_type": "Secondary Type",
                "tertiary_type": "Tertiary Type"
              },
              "no_results": "No result"
            }
          }
        },
        "big_5_completion_report": {
          "select_report": "Please select at least a Tenant to run the report."
        },
        "holland_code_completion_report": {
          "select_report": "Please select at least a Tenant to run the report."
        },
        "access_code_completion_report": {
          "select_report": "Please select at least a Tenant to run the report."
        },
        "subject_choice_distribution_report": {
          "select_report": "Please select all of the above filters to run the report."
        },
        "subject_choice_selection_completion_report": {
          "select_report": "Please select at least a Tenant and Curriculum to run the report.",
          "select_report_curriculum": "Please select at least a Curriculum to run the report."
        },
        "users_big_5_types_report": {
          "select_report": "Please select at least a Tenant and an Institution to run the report.",
          "select_an_institution": "Please select an Institution."
        },
        "users_holland_codes_report": {
          "select_report": "Please select at least a Tenant and an Institution to run the report.",
          "select_an_institution": "Please select an Institution.",
          "no_results": "No Results",
          "filter": {
            "cohort": "Cohort",
            "primary_type": "Primary Type",
            "secondary_type": "Secondary Type",
            "tertiary_type": "Tertiary Type"
          }
        },
        "worker_type_distribution_report": {
          "select_report": "Please select at least a Tenant and an Institution to run the report.",
          "select_an_institution": "Please select at least an Institution to run the report."
        },
        "users_subject_choice_report": {
          "select_report": "Please select at least a Tenant and an Institution to run the report.",
          "select_an_institution": "Please select at least an Institution to run the report.",
          "filter": {
            "cohort": "Cohort"
          }
        },
        "completion_report": {
          "headers": {
            "institution": "Institution",
            "cohort": "Cohort",
            "percentage": "Percentage",
            "complete": "Complete",
            "incomplete": "Incomplete",
            "total": "Total"
          }
        }
      },
      "settings": {
        "report_period": "Report Period",
        "breakdown_by": "Breakdown By",
        "filter_by": "Filter By"
      },
      "filters": {
        "tenant": "Tenant",
        "tenant_instruction": "Please search for a tenant.",
        "institution": "Institution",
        "cohort": "Cohort",
        "cohort_instruction": "Please select a tenant."
      },
      "actions": {
        "run_report": "Run Report",
        "export": "Export"
      },
      "messages": {
        "run_report": "Please click Run Report to generate the report.",
        "no_data": "No results found for the selected filters."
      },
      "holland_code_headers": {
        "tenant": "Tenant",
        "institution": "Institution",
        "cohort": "Cohort",
        "voucher_code": "Voucher Code",
        "email": "Email",
        "phone": "Phone",
        "full_name": "Full Name",
        "primary": "Primary",
        "secondary": "Secondary",
        "tertiary": "Tertiary"
      },
      "big_5_headers": {
        "tenant": "Tenant",
        "institution": "Institution",
        "cohort": "Cohort",
        "voucher_code": "Voucher Code",
        "email": "Email",
        "phone": "Phone",
        "full_name": "Full Name",
        "open_mindedness": "Open Mindedness",
        "conscientiousness": "Conscientiousness",
        "extraversion": "Extraversion",
        "agreeableness": "Agreeableness",
        "negative_emotionality": "Neuroticism"
      },
      "subject_choice_distribution": {
        "alert": "No data found for the selected filters.",
        "card_titles": {
          "math_vs_math_literacy": "Math vs Math Literacy",
          "first_language": "First Language",
          "second_language": "Second Language",
          "group_1": "Group 1",
          "group_2": "Group 2",
          "elective_distribution": "Elective Distribution"
        }
      },
      "worker_type_distribution": {
        "card_titles": {
          "primary_worker_type": "Primary Worker Type",
          "secondary_worker_type": "Secondary Worker Type",
          "tertiary_worker_type": "Tertiary Worker Type"
        },
        "alert": "No Available data."
      },
      "nsCertificateHeaders": {
        "subjects": {
          "national_senior_certificate": {
            "first_language": "First Language",
            "second_language": "Second Language",
            "mathematics": "Mathematics",
            "elective_1": "Elective 1",
            "elective_2": "Elective 2",
            "elective_3": "Elective 3"
          }
        }
      },
      "cambridgeHeaders": {
        "subjects": {
          "cambridge": {
            "group_1": "Group 1",
            "group_2": "Group 2",
            "group_3": "Group 3",
            "group_4": "Group 4",
            "group_5": "Group 5",
            "undefined": "Undefined"
          }
        }
      }
    },
    "SubjectSelectionCompletionReport": {
      "title": "Subject Selection Completion",
      "headers": {
        "tenant": "Tenant",
        "institution": "Institution",
        "cohort": "Cohort",
        "percentage_complete": "Percentage Complete",
        "complete": "Complete",
        "incomplete": "Incomplete",
        "total": "Total"
      }
    },
    "HollandCodeCompletionReport": {
      "title": "Worker Type Completion",
      "headers": {
        "tenant": "Tenant",
        "institution": "Institution",
        "cohort": "Cohort",
        "percentage_complete": "Percentage Complete",
        "complete": "Complete",
        "incomplete": "Incomplete",
        "total": "Total",
        "cohort_filter_label": "Filter Cohort"
      }
    },
    "AccessCodeUsageReport": {
      "title": "Voucher Usage",
      "voucher_usage": "Voucher Usage",
      "tenant": "Tenant",
      "institution": "Institution",
      "cohort": "Cohort",
      "percentage_used": "Percentage Used",
      "used": "Used",
      "not_used": "Not Used",
      "total": "Total"
    },
    "Big5CompletionReport": {
      "title": "Personality Traits Completion",
      "headers": {
        "tenant": "Tenant",
        "institution": "Institution",
        "cohort": "Cohort",
        "percentage_complete": "Percentage Complete",
        "complete": "Complete",
        "incomplete": "Incomplete",
        "total": "Total"
      }
    },
    "UsersSubjectChoicesReport": {
      "no_subject_selected": "No Subject Selected"
    },
    "BasicAuthCard": {
      "title": "Authentication",
      "account_created": "Account Created",
      "last_login": "Last Login",
      "last_reset_request": "Last Reset Request",
      "account_locked": "Account Locked",
      "locked_until": "Locked Until",
      "failed_login_attempts": "Failed Login Attempts",
      "account_enabled": "Account Enabled",
      "download_reports": "Download Reports"
    },
    "AssessmentsDetail": {
      "instructions": "Use the dropdowns below to explore the users assessment results."
    },
    "UserDetailPage": {
      "view_report": "View Report",
      "hide_report": "Hide Report",
      "back_breadcrumb": "Users",
      "account_disabled": "This account is disabled",
      "tabs": {
        "assessments": "Assessments",
        "choices": "Choices"
      },
      "menu": {
        "enable": "Enable Account",
        "disable": "Disable Account",
        "change_email": "Change Email Address",
        "change_phone_number": "Change Phone Number",
        "change_account_type": "Change Account Type",
        "change_role": "Change Role",
        "reset_password": "Reset Password",
        "download_cv": "Download CV",
        "download_subject_report": "Download Subject Report",
        "download_assessment_report": "Download Assessment Report",
        "reset_assessments": "Reset Assessments"
      },
      "coming_soon_label": "Coming Soon",
      "user_detail_choices": {
        "curriculum": "Curriculum",
        "core_subjects": "Core Subjects",
        "electives": "Electives",
        "none_selected": "None Selected"
      },
      "user_detail_section_heading": {
        "profile": "Profile",
        "assessment_reports": "Assessment Reports",
        "choices": "Choices",
        "permissions": "Permissions",
        "authentication": "Authentication"
      },
      "user_detail_profile": {
        "title": "User Profile"
      },
      "user_detail_section": {
        "email": "Email",
        "phone": "Phone",
        "account_type": "Account Type",
        "role": "Role",
        "details": "Details",
        "edit": "Edit",
        "reset_progress": "Reset",
        "download_reports": "Download Reports",
        "download": "Download"
      },
      "user_detail_manager_permissions": {
        "title": "Manager Permissions",
        "warning": "Only Managers can have permissions. To add permissions to this user, change their role to Manager.",
        "update_role_button": "Change Role"
      },
      "manager_instructions": "Select a Tenant, Institution or Cohort to add permissions to this manager",
      "user_authentication": "User Authentication"
    },
    "ChangeEmailAddressDialog": {
      "title": "Change Email",
      "new_email": "New Email",
      "confirm_email": "Confirm Email",
      "email_invalid": "Email is invalid",
      "warning": "Changing a user's email can have unintended consequences. Please be sure you know what you are doing."
    },
    "ChangePhoneDialog": {
      "title": "Change Phone Number",
      "new_phone_number": "New phone number",
      "confirm_phone_number": "Confirm phone number",
      "phone_number_required": "Phone number is required",
      "phone_number_invalid": "Phone number is invalid",
      "phone_number_mismatch": "Phone numbers do not match",
      "international_phone_format": "Please enter a valid international phone number."
    },
    "ChangeUserRole": {
      "title": "Change User Role",
      "subtitle": "Please select a role:"
    },
    "DisableEnableDialog": {
      "disable_user": "Disable User",
      "enable_user": "Enable User",
      "user_disabled": "User disabled",
      "user_enabled": "User enabled",
      "title": "Disable / Enable User",
      "warning": "You are about to {action} the user."
    },
    "DownloadSubjectChoiceDialog": {
      "title": "Download Subject Choice Report",
      "download_instruction": "Click confirm to download the users report.",
      "no_curriculum_selected": "The user has not selected their subjects."
    },
    "MethodsCard": {
      "title": "User details",
      "snapplify_user": "Snapplify User",
      "stdbank_user": "Standard Bank User",
      "azuread_user": "Azure AD User",
      "email": "Email",
      "phone": "Phone",
      "oidc_user": "OIDC User",
      "oAuth_provider": "OAuth Providers",
      "no_oAuth_provider": "No oAuth providers Used"
    },
    "PermissionsList": {
      "alert_message": "This user does not have any assigned permissions."
    },
    "ResetAssessmentDialog": {
      "title": "Select the assessment you would like to reset for the user.",
      "label": "Current Account Type:",
      "warning_label": "WARNING!",
      "danger_zone": "Danger Zone",
      "action_label": "This action cannot be undone",
      "snackbar_message": "The requested assessments have been reset.",
      "assessments": {
        "holland_code": "Worker Type",
        "big_5": "Personality Traits",
        "learning_styles": "Learning Styles",
        "managing_emotion": "Managing Emotions",
        "understanding_emotion": "Understanding Emotions",
        "perceiving_emotion": "Perceiving Emotions",
        "rock_paper_scissors": "Rock Paper Scissors",
        "maze": "Maze",
        "steu_b": "Steu B",
        "stem_b": "Stem B",
        "entrepreneur-holland-code": "Entrepreneur Holland Code",
        "ipip": "IPIP",
        "interests": "Interests",
        "entrepreneurial_intent": "Entrepreneurial Intent",
        "value_scales": "Value Scales"
      }
    },
    "ResetPasswordDialog": {
      "title": "Reset Password",
      "info": "Passwords must contain between 8 and 256 characters and use a combination of at least three of the following: UPPERCASE LETTERS, lowercase letters, numbers, and symbols.",
      "new_password_label": "New password",
      "confirm_password_label": "Confirm password",
      "invalid_password": "Does not meet the requirements",
      "passwords_do_not_match": "Passwords do not match",
      "password_required": "Password is required",
      "successfully_reset": "Password successfully reset",
      "warning": "User not loaded"
    },
    "CVCompletion": {
      "title": "CV Completion",
      "name": "Name",
      "birthday": "Birthday",
      "phone": "Phone",
      "about": "About",
      "experience": "Experience",
      "education": "Education",
      "achievements": "Achievements",
      "skills": "Skills",
      "interests": "Interests"
    },
    "SubjectChoicesPanel": {
      "title": "Subject Choices",
      "curriculum": "Curriculum Choice",
      "compulsory": "Compulsory Subject Choices",
      "elective": "Elective Subject Choices",
      "not_selected": "Not Selected"
    },
    "FavouritedCareersPanel": {
      "expansion_panel_title": "Favourite Careers",
      "no_careers_available": "No favourite careers available"
    },
    "ChangeAccountTypeDialog": {
      "title": "Change User Account Type",
      "label": "Current Account Type:"
    },
    "CognitiveAbilityReport": {
      "very_low": "Very Low",
      "low": "Low",
      "medium": "Medium",
      "high": "High",
      "very high": "Very High"
    },
    "CohortDetailPage": {
      "title": "Cohort Detail",
      "details": "Details",
      "cohorts": "Cohorts",
      "cohort": "Cohort",
      "menu": {
        "reset_assessments": "Reset Assessments"
      },
      "proceed_instruction": "Please type the cohort title to proceed: ",
      "delete_message": "You are about to delete: "
    },
    "ReportFilterOptions": {
      "run_report": "Run Report",
      "filter_by": "Filter By",
      "breakdown_by": "Breakdown By"
    },
    "ChoicesCompletion": {
      "favourited_careers": "Favourited Careers",
      "subject_choices": "Subject Choices"
    },
    "ResetCohortAssessments": {
      "title": "Reset Assessment",
      "instruction": "Select the assessments you would like to reset for cohort: ",
      "label": {
        "select_all": "Select All"
      }
    },
    "InstitutionDetailPage": {
      "title": "Institution",
      "manage_curriculum": "Manage Curriculum",
      "subtitle": "Institution Details",
      "create_cohort": "Create Cohort",
      "d6": "D6",
      "d6_details": "D6 Details",
      "join_requests": "Join Requests"
    },
    "D6Connection": {
      "title": "Connection",
      "school_id": "School ID",
      "connect": "Connect",
      "disconnect": "Disconnect"
    },
    "JoinRequests": {
      "title": "Join Requests",
      "disabled": "Join requests are disabled",
      "requestTitle": "Someone wants to join",
      "noRequests": "No pending applications",
      "email": "Email",
      "firstName": "First Name",
      "lastName": "Last Name",
      "phone": "Phone Number",
      "accept_dialog_title": "Accept Join Request",
      "deny_dialog_title": "Deny Join Request",
      "deny_dialog_message": "Are you sure you want to deny this request?"
    },
    "D6Details": {
      "title": "Details",
      "school_name": "School Name",
      "admin_email_address": "Admin Email",
      "telephone_number": "Telephone Number",
      "telephone_calling_code": "Telephone Code",
      "website": "Website",
      "physical_address": "Physical Address",
      "country": "Country",
      "province": "Province",
      "error_message": "Error fetching school from D6"
    },
    "EditInstitutionCurriculum": {
      "card_title": "Manage Curriculums",
      "curriculum": "curriculum",
      "save_alert": "Unsaved Changes",
      "title_select_available_curriculums": "1. Select Available Curriculums",
      "instruction_select_curriculums_enabled": "Select the curriculums you would like to enable.",
      "title_select_curriculum": "2. Select Curriculum",
      "instruction_select_curriculum_below": "Select a curriculum below.",
      "instruction_select_curriculum_above": "Select an available curriculum above to get started.",
      "title_subject_groups": "Subject Groups",
      "instruction_click_subject_group": "Click on a group to select subjects.",
      "buttons": {
        "copy": "Copy",
        "select_none": "Select None",
        "select_all": "Select All"
      },
      "title_subject": "Subject",
      "instruction_select_subject": "Select subjects for: ",
      "subtitle": "Institution Detail",
      "update_message": "Curriculum updated successfully"
    },
    "InstitutionCurricula": {
      "title": "Curriculum Settings",
      "info": "No custom curriculum has been set. The default curriculum will be available.",
      "alert_no_subjects": "No subjects customised, the defaults will be available."
    },
    "InstitutionTablePage": {
      "title": "Institutions",
      "create_institution": "Create Institution"
    },
    "LoginPage": {
      "title": "Login",
      "welcome_back_message": "Welcome Back! Please enter your details."
    },
    "OTPDialog": {
      "title": "OTP Required",
      "instruction": "Please enter your OTP.",
      "invalid_otp": "Invalid code"
    },
    "LoginForm": {
      "contact_email": "Email",
      "contact_phone": "Phone",
      "password": "Password",
      "login": "Login",
      "contact_required": "Email or phone is required",
      "phone_required": "Phone Number is required",
      "password_required": "Password is required",
      "password_capital_letter_required": "Password must contain at least one capital letter",
      "password_lowercase_letter_required": "Password must contain at least one lowercase letter",
      "password_required_least_6": "Password must contain at least 6 characters",
      "password_one_number_required": "Password must contain at least one number",
      "forgotten_password": "Forgotten password?",
      "click_here_to_reset": "Click here to reset",
      "valid_email_warning": "E-mail must be valid",
      "continue_with": "Continue with ",
      "continue_with_snapplify": "Continue with Snapplify",
      "continue_with_fundi": "Continue with Fundi"
    },
    "PasswordRecoveryForm": {
      "your_code_has_been_sent_to": "Your OTP has been sent to ",
      "phone_recovery_code_title": "An OTP has been sent to your phone: ",
      "password_reset_successfully": "Your password has been updated successfully",
      "please_check_your_spam": "Please check your Junk or Spam email folder if you do not receive your OTP",
      "invalid_recovery_code": "Reset Failed - Invalid code provided. Please try again.",
      "otp_required": "OTP is required",
      "back_to_login": "Back to Login",
      "save_new_password": "Save New Password",
      "continue_to_login": "Continue to Login",
      "phone_number": {
        "label": "Phone Number"
      },
      "recovery_code": {
        "label": "Recovery Code"
      },
      "new_password": {
        "label": "New Password"
      },
      "submit": "Submit",
      "required": "This field is required"
    },
    "ForgotPasswordForm": {
      "reset_your_password": "Reset your password",
      "contact_email": "Email",
      "contact_phone": "Phone",
      "request_reset": "Request Reset",
      "send_OTP": "Send OTP",
      "back_to_login": "Back to Login",
      "email_warning": "E-mail must be Valid",
      "phone_warning": "Cell must be Valid",
      "enter_your_phone_number": "Please enter your phone number",
      "account_not_found": "Account not found: ",
      "too_recent": "You have requested a reset too recently.",
      "you_will_receive_otp": "You will receive a One Time Pin (OTP) to reset your password."
    },
    "RecoveryEmailSent": {
      "title": "Recovery Link Sent",
      "password_reset": "Please check your email for a link to reset your password."
    },
    "TenantTablePage": {
      "title": "Tenants",
      "table_headers": {
        "_id": "ID",
        "title": "Title",
        "disabled": "Disabled",
        "created_at": "Created At"
      }
    },
    "TenantDetailPage": {
      "breadcrumb": "Tenants",
      "menu": {
        "create_institution": "Create Institution",
        "enable": "Enable",
        "disable": "Disable",
        "delete": "Delete Tenant"
      },
      "delete_title": "Delete Tenant",
      "warning": "You are about to delete: ",
      "instruction": "Please type in: ",
      "content": {
        "this_tenant_disabled": "This tenant is disabled.",
        "tenant_detail": "Tenant Detail",
        "create_edit_title": "Create or Edit Tenant Manager for Yenza"
      },
      "dialog": {
        "create_institution": "Create Institution"
      }
    },
    "DisableEnableTenantDialog": {
      "disable_tenant": "Disable Tenant",
      "enable_tenant": "Enable Tenant",
      "tenant_disabled": "Tenant disabled",
      "tenant_enabled": "Tenant enabled",
      "title": "Disable / Enable Tenant",
      "warning": "You are about to {action} the tenant.",
      "info": "Please type CONFIRM below to continue."
    },
    "UserTablePage": {
      "title": "Users",
      "firstName": "First Name",
      "lastName": "Last Name",
      "table_headers": {
        "id": "ID",
        "tenant": "Tenant",
        "institution": "Institution",
        "cohort": "Cohort",
        "access_code": "Access Code",
        "first_name": "First Name",
        "last_name": "Last Name",
        "email": "Email",
        "phone": "Phone",
        "account_type": "Account Type",
        "role": "Role",
        "terms_consent": "Terms Consent",
        "terms_consented_at": "Terms Consented At",
        "created_at": "Created At"
      }
    },
    "ExportUserDialog": {
      "title": "Export Users Data",
      "description": "The filters applied to the table will apply to the export. Select the data fields you would like to export",
      "presets": "Presets",
      "presets_options": {
        "consents": "Consents",
        "assessment_tools": "Assessment Totals",
        "research": "Research",
        "none": "None"
      },
      "sort": "Sort",
      "dialog_items": {
        "tenant_data": {
          "title": "Tenant Data",
          "tenant": "Tenant ",
          "institution": "Institution",
          "cohort": "Cohort"
        },
        "personal_information": {
          "title": "Personal Information",
          "first_name": "First Name",
          "last_name": "Last Name",
          "email": "Email",
          "cell_phone_authentication": "Phone (Authentication)",
          "cell_phone_profile": "Phone (Profile)",
          "date_of_birth": "Date of Birth",
          "id_number": "ID Number",
          "nationality": "Nationality",
          "account_type": "Account Type",
          "voucher_code": "Voucher Code",
          "role": "Role",
          "time_stamps": "Time Stamps",
          "last_login": "Last Login"
        },
        "choices": {
          "title": "Choices",
          "subject_choices": "Subject Choices",
          "favorite_careers": "Favorite Careers",
          "interests": "Interests"
        },
        "research": {
          "title": "Research",
          "research_profile": "Research Profile",
          "research_assessment": "Research Assessment",
          "research_consent": "Research Consent"
        },
        "consents": {
          "title": "Consents",
          "terms_consent": "Terms Consent",
          "competition_consent": "Competition Consent",
          "parental_consent": "Parental Consent",
          "internal_marketing_consent": "Internal Marketing Consent",
          "marketing_consent": "Marketing Consent",
          "custom_consent": "Custom Consent"
        },
        "reports": {
          "title": "Assessment Data",
          "worker_type": "Worker Type",
          "big_5": "Big 5",
          "entrepreneur": "Entrepreneur",
          "learning_styles": "Learning Styles",
          "perceiving_emotion": "Perceiving Emotion",
          "managing_emotion": "Managing Emotion",
          "understanding_emotion": "Understanding Emotion",
          "rock_paper_scissors": "Rock Paper Scissors",
          "maze": "Maze",
          "all_data": "All Data",
          "totals": "Totals",
          "none": "None"
        },
        "researchAssessments": {
          "title": "Research Assessments",
          "interests": "Interests",
          "ipip": "IPIP"
        }
      },
      "download_csv": "Download CSV",
      "activeFilters": "Active Filters:"
    },
    "UserSettingsPage": {
      "title": "Settings",
      "theme_label": "Theme",
      "language": "Language",
      "preferences": "Preferences",
      "change_password": "Change Password",
      "change_password_info": "Click the button below to change your password.",
      "two_factor_auth": "Two Factor Authentication",
      "two_factor_auth_warning": "It is highly recommended to setup 2FA. Download the Google Authenticator or Microsoft Authenticator.",
      "dark": "Dark",
      "light": "Light"
    },
    "ChangePassword": {
      "title": "Change Password",
      "old_password": "Old Password",
      "new_password": "New Password",
      "new_password_confirm": "Confirm New Password",
      "password_required_least_6": "Password must be at least 6 characters long",
      "password_capital_letter_required": "Password must contain at least one capital letter",
      "password_lowercase_letter_required": "Password must contain at least one lowercase letter",
      "password_number_required": "Password must contain at least one number",
      "password_match_required": "Passwords must match"
    },
    "TwoFactorAuth": {
      "title": "Two Factor Authentication",
      "enable_2fa": "Enable 2FA",
      "already_enabled": "Already enabled for this account.",
      "step_1": "Scan this QR code with your authenticator app.",
      "step_2": "Enter the code shown by your authenticator app."
    },
    "ForgotPasswordPage": {
      "reset_your_password": "Reset your Password",
      "reset_password": "Reset Your Password",
      "enter_otp_to_reset": "Enter your OTP (One Time Pin) to reset your password",
      "follow_steps": "Follow the steps to regain access to your account.",
      "enter_details": "Please enter your details to reset your password"
    },
    "HelpPage": {
      "title": "Help",
      "system_info": "This system is designed to help you manage users, tenants, institutions, and cohorts in an efficient and user-friendly way. Here's a brief overview of the different components of the system:",
      "users_info": "Users: Users are individuals who use the system to manage their students or curriculum. Each user belongs to a tenant.",
      "tenants_info": " Tenants: Tenants are groups of users who belong to the same institution. Each tenant is associated with an institution.",
      "institutions_info": "Institutions: Institutions are organizations that use the system to manage their students and curriculum. Each institution may have multiple tenants.",
      "cohorts_info": "Cohorts: Cohorts are groups of students who are enrolled in the same course or program. Cohorts are created by users within a tenant.",
      "user_access_info": "As a user of the system, you'll have access to different features depending on your role and permissions. Here are some of the key tasks you can perform using the web control panel:",
      "manage_users": "Manage users: You can add, edit, or remove users within your tenant. You can also assign roles and permissions to users.",
      "manage_tenants": "Manage tenants: You can create new tenants within your institution, and you can manage the users within each tenant.",
      "manage_institutions": "Manage institutions: You can view and manage the different tenants associated with your institution, and you can manage the settings and configuration for your institution.",
      "manage_cohorts": "Manage cohorts: You can create, edit, or delete cohorts within your tenant, and you can manage the students and curriculum within each cohort.",
      "additional_info": "In addition to these core features, the web control panel also includes reporting and analytics tools that allow you to track student progress, monitor course performance, and identify areas for improvement.",
      "conclude_info": "We hope this help text has given you a basic understanding of the student and curriculum management\n              system and how to use the web control panel to manage your users, tenants, institutions, and cohorts. If you have any questions or need further assistance, please don't hesitate to contact our support team for help."
    },
    "LoadingPage": {
      "title": "Error loading app",
      "subtitle": "Please check your internet connection and try again.",
      "if_issue_persists": "If this issue persists please {0}",
      "contact_support": "contact support",
      "reload_button": "Reload"
    },
    "CohortTablePage": {
      "title": "Cohorts",
      "table_headings": {
        "_id": "ID",
        "title": "Title",
        "slug": "Slug",
        "tenant": "Tenant",
        "institution": "Institution",
        "created_at": "Created At"
      }
    },
    "CouponTablePage": {
      "title": "Coupons",
      "search": "Search"
    },
    "DashboardPage": {
      "title": "Dashboard"
    },
    "ExportAccessCodes": {
      "title": "Export Access Codes",
      "subtitle": "Select the fields you would like to export.",
      "active_filters": "Active Filters:",
      "format": "Format"
    },
    "AppConfigurationPage": {
      "title": "App Configuration",
      "overview": "Overview",
      "general_title": "General",
      "app_name": "App Name",
      "support_email": "Support Email",
      "onboarding": "Onboarding",
      "authentication": "Authentication",
      "localisation": "Localisation",
      "integrations": "Integrations",
      "features": "Features",
      "theme": "Theme"
    },
    "GeneralAppConfigurationForm": {
      "title": "General",
      "app_name": "App Name",
      "support_email": "Support Email",
      "save_message": "General settings updated",
      "app_url": "App URL"
    },
    "AzureADB2CConfigForm": {
      "title": "Azure AD B2C"
    },
    "SBOpenIDAuthConfigForm": {
      "title": "SB OpenID"
    },
    "ConsentSelector": {
      "title": "Consents",
      "terms": "Terms",
      "marketing": "Marketing",
      "parental": "Parental",
      "internalMarketing": "Internal Marketing",
      "research": "Research",
      "required": "Required",
      "optional": "Optional",
      "disabled": "Disabled",
      "subtitle": "These consents are shown to the user on sign up."
    },
    "OnboardingMethodsSelector": {
      "title": "Onboarding Methods",
      "redeem": "Redeem Voucher",
      "redeem_description": "Users can activate their paid account with a code",
      "payment": "Online Payment",
      "payment_description": "User can purchase available products",
      "subtitle": "These methods are available to the user during onboarding."
    },
    "InitialProfileSelector": {
      "title": "Initial Profile",
      "firstName": "First Name",
      "lastName": "Last Name",
      "birthDate": "Birth Date",
      "email": "Email",
      "phone": "Phone",
      "nationality": "Nationality",
      "rsaIdNumber": "South African ID Number",
      "subtitle": "These fields are made compulsory during onboarding."
    },
    "AuthMethodsSelector": {
      "title": "Auth Methods",
      "basic": "Basic (Email and Cell Phone)",
      "snapplify": "Snapplify",
      "azureAdb2c": "Azure AD B2C",
      "sbOpenid": "SB OpenID",
      "fmOauth": "FM OAuth",
      "subtitle": "These consents are shown to the user on sign up.",
      "oidc": "Open ID Connect",
      "auth_providers_updated": "Auth providers updated"
    },
    "LocalesSelector": {
      "title": "Locales",
      "application_locales": "Application locales",
      "assessment_locales": "Assessment locales",
      "default_locale": "Default locale",
      "save_message": "Locales updated",
      "default_assessment_locale": "Default assessment locale",
      "application_locales_title": "Application Locales",
      "assessment_locales_title": "Assessment Locales",
      "application_locales_info": "The following settings apply to the application as a whole.",
      "assessment_locales_info": "The following settings only apply to the assessments."
    },
    "AvailableCountriesSelector": {
      "title": "Available Countries",
      "label": "Countries"
    },
    "DefaultCountrySelector": {
      "title": "Default Country",
      "label": "Countries"
    },
    "FeatureSelector": {
      "title": "Features",
      "worker_type_report": "Worker Type Report",
      "entrepreneur_report": "Entrepreneur Report",
      "personality_traits_report": "Personality Traits Report",
      "areas_of_development_report": "Areas Of Development Report",
      "learning_styles_report": "Learning Styles Report",
      "cognitive_ability_report": "Cognitive Ability Report",
      "emotional_intelligence_report": "Emotional Intelligence Report",
      "value_scales_report": "Value Scales Report",
      "career_explorer": "Career Explorer",
      "subject_choice_explorer": "Subject Choice Explorer",
      "tertiary_institution_explorer": "Tertiary Institution Explorer",
      "bursaries_explorer": "Bursaries Explorer",
      "resources_explorer": "Resources Explorer",
      "skills_explorer": "Skills Explorer",
      "funding_explorer": "Funding Explorer",
      "reports_explorer": "Reports Explorer",
      "upliftment_provider_explorer": "Upliftment Provider Explorer",
      "course_discounts": "Course Discounts",
      "dashboard": "Dashboard",
      "assessment_dashboard": "Assessment Dashboard",
      "profile": "Profile",
      "articles": "Articles",
      "career_matches": "Career Matches",
      "alison": "Alison",
      "cv": "CV",
      "features_updated": "Features updated"
    },
    "TertiaryInstitutionFeatureSettings": {
      "title": "Tertiary Institution Settings",
      "visible_countries_title": "Visible Countries",
      "visible_countries_hint": "Show institutions in these countries",
      "save_message": "Available countries saved successfully",
      "empty_message": "Please select at least one country"
    },
    "SubjectChoiceNavigatorConfigForm": {
      "title": "Subject Choice",
      "available_curricula": "Available Curricula"
    },
    "SkillsExplorerConfigForm": {
      "title": "Skills Explorer",
      "show_paid_courses": "Show Paid Courses"
    },
    "ThemeEditor": {
      "title": "Colour Themes",
      "light": "Light",
      "dark": "Dark",
      "primary": "Primary",
      "secondary": "Secondary",
      "background": "Background",
      "surface": "Surface",
      "error": "Error",
      "success": "Success",
      "info": "Info",
      "warning": "Warning",
      "confirm_title": "Apply changes to theme?",
      "confirm_text": "This change will go live to all users",
      "gradient-start": "Gradient Start",
      "gradient-end": "Gradient End",
      "app-header-bar": "App Header Bar",
      "app-header-bar-text": "App Header Bar Text",
      "app-header-bar-link": "App Header Bar Link",
      "bar-gradient-start": "Bar Gradient Start",
      "bar-gradient-end": "Bar Gradient End"
    },
    "ImageEditor": {
      "title": "Images",
      "favicon": "Favicon",
      "app_icon": "App Icon",
      "light_app_icon": "Light App Icon",
      "logo": "Logo",
      "logo_full": "Full Logo",
      "light_logo": "Light Logo",
      "dash_login": "Dash Login"
    },
    "IntegrationSelector": {
      "title": "Integrations",
      "global": "Global",
      "client_app": "Client App",
      "data_dash": "Data Dash",
      "prep_app": "Prep App",
      "these_are_used_in_the_yenza_senior_app": "These integrations are used in the Yenza Careers Senior app." ,
      "confirm_title": "Confirm",
      "confirm_text": "Please confirm you would like to change the configuration",
      "d6": "D6",
      "dlocal": "DLocal",
      "payfast": "PayFast",
      "update_message": "Integrations updated"
    },
    "AccessCodeDetailPage": {
      "back_breadcrumb": "Vouchers",
      "change_account_type": "Change Account Type",
      "change_research_assessments": "Change Research Assessments",
      "delete_voucher": "Delete Voucher",
      "message_warning": "You are about to delete: "
    },
    "CohortSearchDropdown": {
      "instruction": "Please select an institution that contains cohorts."
    },
    "InstitutionSearchDropdown": {
      "instruction": "Please search for an Institution."
    },
    "TenantSearchDropdown": {
      "instruction": "Please search for a tenant."
    },
    "MetricTile": {
      "time_range": "Time Range"
    },
    "AccessCodesUsageRatioMetricTile": {
      "title": "Vouchers Issued",
      "metric_title": "Vouchers Used / Vouchers Issued",
      "info": "Number of vouchers issued and used."
    },
    "Big5CompletionRatioMetricTile": {
      "title": "Personality Trait Completion Ratio (%)",
      "metric_title": "Completions / Accounts Created",
      "info": "Number of users who have created an account and taken the Personality Traits in the provided time period."
    },
    "HollandCodeCompletionRatioMetricTile": {
      "title": "Worker Type Completion Ratio (%)",
      "metric_title": "Completions / Accounts Created",
      "info": "Number of users who have created an account and taken the Worker Type in the provided time period."
    },
    "ReturningUsersMetricTile": {
      "title": "Returning Users",
      "metric_title": "User Logins",
      "info": "The number of users who have logged in more than once since creating their account."
    },
    "SignupsMetricTile": {
      "title": "Signups",
      "metric_title": "Accounts Created",
      "info": "The amount of new accounts created."
    },
    "UsersHasSelectedSchoolSubjectsMetricTile": {
      "title": "Subjects Selected Completion Ratio (%)",
      "metricTitle": "Users Selected / Accounts Created",
      "info": "Number of users who have created an account and selected their subjects in the provided time period."
    },
    "UserLoginsMetricTile": {
      "title": "Logins",
      "metricTitle": "User Logins",
      "info": "The number of users who have logged in."
    },
    "ReportPeriodFilterBar": {
      "today": "Today",
      "yesterday": "Yesterday",
      "custom_date": "Custom Date",
      "7D": "7D",
      "30D": "30D",
      "3M": "3M",
      "6M": "6M",
      "12M": "12M",
      "24M": "24M",
      "time_frame": "Time Frame",
      "filter_by": "Filter By",
      "open_filters": "Open Filters"
    },
    "ChangeResearchAssessment": {
      "toggle_research_heading": "Research",
      "title": "Research Assessments",
      "instruction": "Select the assessments that you want to collect results for.",
      "assessments": {
        "holland_code": "Worker Type",
        "big_5": "Personality Traits",
        "learning_styles": "Learning Styles",
        "managing_emotion": "Managing Emotions",
        "understanding_emotion": "Understanding Emotions",
        "perceiving_emotion": "Perceiving Emotions",
        "rock_paper_scissors": "Rock Paper Scissors",
        "maze": "Maze",
        "steu_b": "Steu B",
        "stem_b": "Stem B",
        "entrepreneur-holland-code": "Entrepreneur Holland Code",
        "ipip": "IPIP",
        "interests": "Interests",
        "entrepreneurial_intent": "Entrepreneurial Intent",
        "value_scales": "Value Scales"
      }
    },
    "CreateVoucherCard": {
      "account_type": "Account Type",
      "quantity": "Quantity",
      "tenanted": "Tenanted",
      "tenant_settings": "Tenant Settings",
      "tenant_settings_text_caption": "Select the tenant, institution and cohort you would like to associate this code to.",
      "research": "Research",
      "research_assessments": "Research Assessments",
      "research_settings_text_caption": "Select the assessments that you want to collect results for.",
      "belongs_to_tenant": "Belongs to Tenant",
      "used_for_research": "Used for Research",
      "confirmation": "Confirmation",
      "confirmationMessage": "You are about to create {quantity} {accountType} voucher codes for {tenant}, {institution}, {cohort}",
      "assessments": {
        "holland_code": "Worker Type",
        "big_5": "Personality Traits",
        "learning_styles": "Learning Styles",
        "managing_emotion": "Managing Emotions",
        "understanding_emotion": "Understanding Emotions",
        "perceiving_emotion": "Perceiving Emotions",
        "rock_paper_scissors": "Rock Paper Scissors",
        "maze": "Maze",
        "value_scales": "Value Scales",
        "values_prep": "Values Prep",
        "interests_prep": "Interests Prep",
        "strengths_prep": "Strengths Prep"
      },
      "snackbarCompletedMessage": "Voucher has been created successfully. Amount of vouchers: ",
      "snackbarErrorMessage": "An error occurred while creating the voucher"
    },
    "DeleteVoucher": {
      "message_instruction": "Type the voucher code:"
    },
    "ManagersTable": {
      "title": "Managers",
      "no_manager_message": "No managers.",
      "revoke_permissions": "Revoke Permissions",
      "revoke_warning_message": "Are you sure you want to revoke this managers permissions?",
      "delete": "Delete",
      "email": "Email",
      "first_name": "First Name",
      "last_name": "Last Name",
      "actions": "Actions"
    },
    "MainNavigation": {
      "dashboard": "Dashboard",
      "users": "Users",
      "vouchers": "Vouchers",
      "tenants": "Tenants",
      "cohorts": "Cohorts",
      "institutions": "Institutions",
      "insights": "Insights",
      "coupons": "Coupons",
      "configuration": "App configuration",
      "manage": "Manage",
      "account": "Account settings",
      "help": "Help",
      "logout": "Logout"
    },
    "PersonalityTraitsDistributionGraph": {
      "agreeableness": "Agreeableness",
      "conscientiousness": "Conscientiousness",
      "open_mindedness": "Open Mindedness",
      "extraversion": "Extraversion",
      "negative_emotionality": "Negative Emotionality"
    },
    "GroupDashboardReport": {
      "overview": "Overview",
      "subject_choices": "Subject Choices",
      "worker_types": "Worker Types",
      "personality_types": "Personality Types"
    },
    "PersonalityTraitDistributionCard": {
      "title": "Personality Traits Distribution"
    },
    "SubjectChoiceDistributionCard": {
      "title": "Subject Choices Distribution"
    },
    "WorkTypeDistributionCard": {
      "title": "Worker Type Distribution"
    },
    "UsageSummaryCard": {
      "title": "Usage Summary",
      "accounts_created": "Accounts Created",
      "voucher_codes_issued": "Voucher Codes Issued",
      "voucher_codes_used": "Voucher Codes Used",
      "subject_choice_completions": "Subject Choice Completions",
      "worker_type_completions": "Worker Type Completions",
      "worker_type_rate": "Worker Type Rate",
      "subject_choice_rate": "Subject Choice Rate"
    },
    "CreateCards": {
      "CreateTenantInstitutionCohort": {
        "titleCreateTenant": "Create Tenant",
        "titleCreateInstitution": "Create Institution",
        "titleCreateCohort": "Create Cohort",
        "created_message": "Succesfully created",
        "error_message": "An error occurred"
      }
    }
  }
}
