<template>
  <v-responsive class="mx-auto" max-width="350">
    <v-form
      v-if="authProviders?.basic"
      ref="form"
      v-model="isFormValid"
      lazy-validation
      @keydown.enter="validate"
    >
      <v-alert v-if="errorMessage" class="mb-6" type="error" density="compact" data-pw="errorMessage">{{
        errorMessage
      }}
      </v-alert>

      <EmailPhoneRadioInput
        v-model="inputType"
        class="mb-4"
      />
      <EmailTextField
        v-if="inputType === 'email'"
        v-model="contact"
        @validate="handleEmailValidate"
      />
      <InternationalPhoneNumberInput
        v-else
        v-model="contact"
        class="mb-6 phone-input"
        @validate="handlePhoneValidate"
      />
      <PasswordTextField
        v-model="password"
        @validate="handlePasswordValidate"
      />

      <div class="button-wrapper font-weight-bold">
        {{ $t("components.LoginForm.forgotten_password") }}
        <router-link class="font-weight-bold" data-pw="forgotPasswordButton" to="" @click="navigateToForgotPassword">
          {{ $t("components.LoginForm.click_here_to_reset") }}</router-link>
      </div>

      <div class="button-wrapper">
        <v-btn
          data-pw="loginSubmitButton"
          :block="true"
          color="primary"
          class="ma-auto"
          :disabled="!isFormValid"
          @click="validate"
        >
          {{ $t("components.LoginForm.login") }}
        </v-btn>
      </div>
    </v-form>

    <v-divider v-if="isOAuth && authProviders?.basic" class="mt-4"/>

    <div class="button-container mt-4">
      <div class="button-wrapper" v-if="authProviders?.snapplify">
        <v-btn
          :block="true"
          class="ma-auto bg-grey-lighten-3"
          variant="outlined"
          data-pw="snapplifyLoginButton"
          @click="openOAuthRedirect('snapplify')"
        >
          <img
            height="16"
            class="mr-1"
            src="/src/assets/icon-snapplify.png"
          >
          {{ $t("components.LoginForm.continue_with_snapplify") }}
        </v-btn>
      </div>
      <div class="button-wrapper" v-if="authProviders?.fmOauth">
        <v-btn
          :block="true"
          variant="outlined"
          @click="openOAuthRedirect('fundi') "
          data-pw="fundiLoginButton"
          class="ma-auto bg-grey-lighten-3"
        >
          {{ $t("components.LoginForm.continue_with_fundi") }}
        </v-btn>
      </div>

      <div class="button-wrapper" v-if="authProviders?.oidc">
        <v-btn
          :block="true"
          variant="outlined"
          @click="openOAuthRedirect('oidc') "
          data-pw="fundiLoginButton"
          class="ma-auto bg-grey-lighten-3"
        >
          {{ $t("components.LoginForm.continue_with") }} {{ appName }}
        </v-btn>
      </div>
    </div>

    <v-dialog persistent v-model="showOtpDialog">
      <OTPDialog
        :password="password"
        :contact="contact"
        @close="showOtpDialog = false"
      />
    </v-dialog>
  </v-responsive>
</template>

<script setup lang="ts">
import {ref} from "vue";
import {useGlobalStore} from "@/store";
import {isYapiError, YapiError, YapiMessage} from "@YenzaCT/sdk";
import OTPDialog from "@/pages/LoginPage/components/OTPDialog.vue";
import router from "@/router";
import InternationalPhoneNumberInput from "@/components/FormFields/InternationalPhoneNumberInput.vue";
import EmailPhoneRadioInput from "@/components/FormFields/EmailPhoneRadioInput.vue";
import EmailTextField from "@/components/FormFields/EmailTextField.vue";
import PasswordTextField from "@/components/FormFields/PasswordTextField.vue";
import {openOAuthRedirect} from "@/lib/auth";

const store = useGlobalStore();
const form = ref();
const contact = ref("");
const isPhoneValid = ref(false);
const isEmailValid = ref(false);
const password = ref("");
const isFormValid = ref(false);
const isPasswordValid = ref(false);
const showOtpDialog = ref(false);
const errorMessage = ref("");
const inputType = ref("email");

interface OTPRequired extends YapiMessage {
  otpRequired: boolean;
}

const authProviders = store.appConfiguration?.authProviders;
const appName = store.appConfiguration?.appName;

const isOAuth = authProviders?.snapplify || authProviders?.fmOauth || authProviders?.oidc;

const login = async () => {
  try {
    contact.value = contact.value.replace(/\s/g, "");
    await store.login(contact.value, password.value);
  } catch (e) {
    if (isYapiError(e)) {
      const yapiErr = e as YapiError<OTPRequired>;
      if (yapiErr.response?.status === 401) {
        if (yapiErr.response?.data.otpRequired) {
          showOtpDialog.value = true;
        } else {
          const yapiMessage: YapiMessage = yapiErr.response.data as YapiMessage;
          errorMessage.value = `${yapiMessage.message}`;
        }
      }
      await store.handleYapiError(yapiErr);
    }
  }
};

async function validate() {
  const {valid} = await form.value.validate();
  if (valid) {
    await login();
  }
}

function handleEmailValidate(isValid: boolean) {
  isEmailValid.value = isValid;
}

function handlePasswordValidate(isValid: boolean) {
  isPasswordValid.value = isValid;
}

function handlePhoneValidate(isValid: boolean) {
  isPhoneValid.value = isValid;
}

function navigateToForgotPassword() {
  router.push({name: "forgotPassword"});
}
</script>

<style scoped>
.button-wrapper {
  margin-bottom: 16px; /* Adjust the spacing as needed */
}

.button-wrapper:last-child {
  margin-bottom: 0; /* Remove margin for the last button */
}
</style>
