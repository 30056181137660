<template>
  <v-card flat>
    <div class="user-header">
      <div class="overlay"></div>
      <div class="content">
        <h1 class="user-name">{{ userName }}</h1>
        <v-card class="button-group pa-4 ">
          <account-settings
            :user="user"
            :isAdminOrRoot="isAdminOrRoot"
            :canEditAnyUsers="canEditAnyUsers"
            @updateUser="handleUpdateUser"
          />
          <v-menu
            bottom
            transition="scale-transition"
          >
            <template v-slot:activator="{ props }">
              <v-btn-primary
                class="mt-2 mb-2"
                v-bind="props"
                @click="downloadReports"
              >
                <v-icon class="mr-2" left>far fa-download</v-icon>
                {{$t("components.BasicAuthCard.download_reports")}}
              </v-btn-primary>
            </template>
            <v-list>
              <v-list-item data-pw="downloadSubjectChoiceDialogButton" @click="emitDownloadSubjectChoice">
                {{ $t("components.UserDetailPage.menu.download_subject_report") }}
              </v-list-item>
              <v-list-item data-pw="downloadCVDialogButton" @click="emitDownloadCV">
                {{ $t("components.UserDetailPage.menu.download_cv") }}<br/>
              </v-list-item>
              <v-list-item data-pw="downloadAssessmentDialogButton" @click="emitDownloadAssessments" >
                {{ $t("components.UserDetailPage.menu.download_assessment_report") }} <br/>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-card>
      </div>
    </div>
  </v-card>

</template>

<script setup lang="ts">
import {computed, Ref, ref} from "vue";
import {Action, DashUser, Entity, Role} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import AccountSettings from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/AccountSettingsMenu.vue";

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
});

const emit = defineEmits([
  "downloadSubjectChoice",
  "downloadCV",
  "downloadAssessment",
  "updateUser"
]);

const store = useGlobalStore();
const user: Ref<DashUser> = ref({...props.user} as DashUser);

const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const canEditAnyUsers = computed(() => store.hasAbility(Entity.User, Action.edit));
const userName = computed(() => `${props.user.profile.firstName} ${props.user.profile.lastName}`);

const emitDownloadSubjectChoice = () => emit("downloadSubjectChoice");
const emitDownloadCV = () => emit("downloadCV");
const emitDownloadAssessments = () => emit("downloadAssessment");

function handleUpdateUser(success: boolean) {
  if (success) {
    const success = true;
    emit("updateUser", success);
  }
}

const downloadReports = () => {
  // Implement report download logic
};
</script>

<style scoped>
.user-header {
  position: relative;
  height: 200px;
  background-image: url('../../../../assets/profile-bg.jpeg');
  background-size: cover;
  background-position: center;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(63, 81, 181, 0.7);
}

.content {
  position: relative;

  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
}

.user-name {
  color: white;
  font-size: 2.5rem;
  margin-bottom: 16px;
}

.button-group {
  display: flex;
  gap: 16px;
}

.account-details-btn {
  background: rgba(255, 255, 255, 0.2) !important;
}

</style>
