<template>
  <v-row>
    <v-col v-for="(actions, entity) in checkboxesState" :key="entity">
      <div class="pb-2 font-weight-bold">
        {{ $t(`common.entities.${entity}`) }}
      </div>
      <v-checkbox
        v-for="(action, key) in actions.actions"
        :key="key"
        v-model="checkboxesState[entity].actions[key].value"
        :label="$t(`common.permissions.${key}`)"
        class="ml-3"
        :hide-details="true"
        density="compact"
      />
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {computed, PropType} from "vue";
import {PermissionCheckBoxState} from "@/lib/permission";

const props = defineProps({
  title: {
    type: String as PropType<string>,
    required: true,
  },
  modelValue: {
    type: Object as PropType<PermissionCheckBoxState>,
    required: true,
  }
});

const emit = defineEmits<{
  (e: "update:modelValue", checkboxesState: PermissionCheckBoxState): void
}>();

const checkboxesState = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  }
});
</script>
