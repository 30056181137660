<template>
  <v-row  align="center">
    <div class="flex-grow-1">
      <v-col cols="12" class="mx-auto" v-if="!dialogOpenDetail">
        <v-card color="#E2E8F0" flat>
          <v-card-text>
            <wide-trait-score-card
              :title="$t(`assessments.entrepreneurial_intent.title`)"
              :result="parseInt(String(score * 100))"
              color="orange"
              data-pw="entrepScoreCard"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </div>

    <v-dialog cols="12" v-model="dialogOpenDetail" transition="dialog-bottom-transition" >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn dark @click="closeDetailedDialog">
            <v-icon>fa fa-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("assessments.entrepreneurial_intent.title") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-col>
            {{  $t("common.coming_soon_label")}}
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script setup lang="ts">
import {ref, watch} from "vue";
import {Feature, isYapiError, YapiError} from "@YenzaCT/sdk";

import WideTraitScoreCard from "@/pages/UserDetailPage/components/common/WideTraitScoreCard.vue";
import {AxiosResponse} from "axios";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";

const props = defineProps({
  userId: {
    type: String,
    required: true
  },
  score: {
    type: Number,
    required: true
  },
  dialogOpen: {
    type: Boolean,
    default: false,
    required: true
  },
});

const store = useGlobalStore();

const dialogOpenDetail = ref(props.dialogOpen);

watch(() => props.dialogOpen, (newVal) => {
  dialogOpenDetail.value = newVal;
});

function closeDetailedDialog() {
  dialogOpenDetail.value = false;
}

const dataLoaded = ref(false);
const loading = ref(false);
const entrepContent = ref();

async function fetchEntrepReportContent() {
  loading.value = true;
  try {
    const res: AxiosResponse = await yapi.admin.user.getAssessmentReport(props.userId, Feature.EntrepreneurReport);
    entrepContent.value = res.data;
    dataLoaded.value = true;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
}

fetchEntrepReportContent();
</script>
