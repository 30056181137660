<template>
  <slot name="header"/>
  <v-main class="main-content">
    <v-container fluid>
      <v-row>
        <v-col>
          <slot/>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "UnauthenticatedLayout",
};
</script>

<style scoped>

</style>
