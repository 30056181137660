import { computed, inject, onMounted, ref, watch } from "vue";
import {
  DateFilterKey,
  DateFilterUnitKey,
  DateFilterValueKey,
  TenantFilterKey,
  InstitutionFilterKey,
  CohortFilterKey,
} from "@/symbols/metrics";
import yapi from "@/lib/yapi";

export function useMetricsData() {
  const dateFilter = inject(DateFilterKey, ref("in the last"));
  const dateFilterUnit = inject(DateFilterUnitKey, ref("days"));
  const dateFilterValue = inject(DateFilterValueKey, ref("7"));
  const tenantFilter = inject(TenantFilterKey, ref(undefined));
  const institutionFilter = inject(InstitutionFilterKey, ref(undefined));
  const cohortFilter = inject(CohortFilterKey, ref(undefined));

  const currentFilter = computed(() => ({
    dateFilterType: dateFilter.value,
    unit: dateFilterUnit.value,
    value: dateFilterValue.value,
    tenant: tenantFilter.value,
    institution: institutionFilter.value,
    cohort: cohortFilter.value,
  }));

  return {
    dateFilter,
    dateFilterUnit,
    dateFilterValue,
    tenantFilter,
    institutionFilter,
    cohortFilter,
    currentFilter,
  };
}

export function useMetrics(
  measurement: string,
) {
  const { dateFilter, dateFilterUnit, dateFilterValue, currentFilter, tenantFilter, cohortFilter, institutionFilter} =
    useMetricsData();

  const loading = ref(false);
  const metric = ref();

  onMounted(async () => {
    await fetchMetricData();
  });

  watch(
    currentFilter,
    async () => {
      await fetchMetricData();
    },
    { deep: true }
  );

  async function fetchMetricData() {
    loading.value = true;
    const time = [
      {
        dateFilterType: dateFilter.value,
        unit: dateFilterUnit.value,
        value: dateFilterValue.value,
      },
    ];

    const requestBody = {
      time,
      tenant: tenantFilter.value || null,
      institution: institutionFilter.value || null,
      cohort: cohortFilter.value || null,
    };

    const { data } = await yapi.admin.metric.post(measurement, requestBody);
    metric.value = data;
    loading.value = false;
    return data;
  }

  return {
    dateFilter,
    dateFilterUnit,
    dateFilterValue,
    currentFilter,
    loading,
    metric,
  };
}
