<script setup lang="ts">

import {Pie} from "vue-chartjs";
import {useI18n} from "vue-i18n";
import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from "chart.js";
import {computed, PropType} from "vue";
import {IWorkerTypeDistributionReportData, workerTypeTraits} from "@/pages/ReportsDetailPage/reportTypes";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

const {t} = useI18n();

const props = defineProps(
  {
    reportData: {
      type: Object as PropType<IWorkerTypeDistributionReportData>,
      required: true,
    },
  }
);

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
  }[];
}

const orderedReportData = computed(() => {
  const order = ["R", "I", "A", "S", "E", "C"];
  const sortedReportData: { [key: string]: workerTypeTraits } = {};

  const sortCodes = (codes: workerTypeTraits) => order.map((code) => ({[code]: codes[code]}))
    .reduce((acc, curr) => ({...acc, ...curr}), {});

  sortedReportData.primary_codes = sortCodes(props.reportData?.primary_codes);
  sortedReportData.secondary_codes = sortCodes(props.reportData?.secondary_codes);
  sortedReportData.tertiary_codes = sortCodes(props.reportData?.tertiary_codes);

  return sortedReportData;
});

const primaryCodesPieChart = computed(
  () => {
    const label = t("components.ReportsDetailPage.worker_type_distribution.card_titles.primary_worker_type");
    if (!orderedReportData.value) return {
      label,
      datasets: []
    };
    return createPieChartData(orderedReportData.value.primary_codes, label);
  }
);
const secondaryCodesPieChart = computed(
  () => {
    const label = t("components.ReportsDetailPage.worker_type_distribution.card_titles.secondary_worker_type");

    if (!orderedReportData.value) return {
      label,
      datasets: []
    };
    return createPieChartData(orderedReportData.value.secondary_codes, label);
  }
);
const tertiaryCodesPieChart = computed(
  () => {
    const label = t("components.ReportsDetailPage.worker_type_distribution.card_titles.tertiary_worker_type");

    if (!orderedReportData.value) return {
      label,
      datasets: []
    };
    return createPieChartData(orderedReportData.value.tertiary_codes, label);
  }
);

/**
 * Creates the data for the pie chart.
 * @param subjectCounts The subject counts to create the chart data from.
 * @param label The label for the chart.
 */
const createPieChartData = (subjectCounts: unknown, label: string): ChartData => {
  if (!subjectCounts) return {
    labels: [],
    datasets: []
  };

  const readableKeys = Object.keys(subjectCounts).map((key) => t(`assessments.holland_code.${key}`));

  return {
    labels: readableKeys,
    datasets: [
      {
        label,
        data: Object.values(subjectCounts),
        backgroundColor: [
          "#fc631d",
          "#0487ff",
          "#1eac30",
          "#ff5d5d",
          "#514ee4",
          "#ff3520",
        ]
      },
    ],
  };
};

const chartOptions: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom"
    }
  }
};

</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" md="4">
        <v-card variant="tonal">
          <v-card-title>
            {{ $t("components.ReportsDetailPage.worker_type_distribution.card_titles.primary_worker_type") }}
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-center">
            <Pie v-if="reportData.tertiary_codes" :data="primaryCodesPieChart" :options="chartOptions"/>
            <span v-else>{{ $t("common.no_data") }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card variant="tonal">
          <v-card-title>
            {{ $t("components.ReportsDetailPage.worker_type_distribution.card_titles.secondary_worker_type") }}
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-center">
            <Pie v-if="reportData.secondary_codes" :data="secondaryCodesPieChart" :options="chartOptions"/>
            <span v-else>{{ $t("common.no_data") }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card variant="tonal">
          <v-card-title>
            {{ $t("components.ReportsDetailPage.worker_type_distribution.card_titles.tertiary_worker_type") }}
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-center">
            <Pie v-if="reportData.tertiary_codes" :data="tertiaryCodesPieChart" :options="chartOptions"/>
            <span v-else>{{ $t("common.no_data") }}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<style scoped>

</style>
