
<template>
  <span>{{ $t("common.total")}}, {{ durationLabel }}</span>
</template>

<script setup lang="ts">
import {computed, inject, ref} from "vue";
import {DateFilterKey, DateFilterUnitKey, DateFilterValueKey} from "@/symbols/metrics";

const dateFilter = inject(DateFilterKey, ref("in the last"));
const dateFilterUnit = inject(DateFilterUnitKey, ref("days"));
const dateFilterValue = inject(DateFilterValueKey, ref("7"));

const durationLabel = computed(() => {
  const unit = dateFilterUnit.value;
  const type = dateFilter.value;
  const value = dateFilterValue.value;

  if (type === "in the last") {
    return "last " + value + " " + unit;
  }

  if (type === "between") {
    if (value && value[0] === "start_of_previous_day" && value[1] === "end_of_previous_day")
      return "yesterday";
    else {
      return value[0] + " - " + value[1];
    }
  }

  if (type === "since") {
    if (value === "start_of_current_day")
      return "today";
    else {
      return "since " + value;
    }
  }

  return "";
});
</script>

<style scoped>

</style>
