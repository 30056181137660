<template>
  <report-filter-options
    :show-tenant-filter="true"
    :show-institution-filter="true"
    :show-cohort-filter="true"
    :show-cohort-breakdown="false"
    :is-report-active="isReportActive"
    :disable-run-report-button="false"
    @change-tenant="onTenantChange"
    @change-institution="onInstitutionSelectionChange"
    @change-cohort="onCohortChange"
    @click-run-report="runReport"
    @clickReset="resetReport"
  />

  <div v-if="loadingData">
    <v-row>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
  </div>
  <personality-trait-distribution-graphs
    v-if="reportDataValue && dataIsSet && !loadingData"
    :report-data="reportDataValue"
    :loading-data="loadingData"
    class="mt-6"
  />
  <ShowNoResults
    v-else-if="!dataIsSet && showReport && !loadingData"
  />

</template>
<script setup lang="ts">
import {useInsights} from "@/pages/ReportsDetailPage/reportsDetailPage";
import {computed, ref, watch} from "vue";
import ReportFilterOptions from "@/components/Reporting/ReportFilterOptions.vue";
import PersonalityTraitDistributionGraphs from "@/pages/ReportsDetailPage/components/PersonalityTraitDistributionGraphs.vue";
import {IPersonalityTraitsDistributionReportData} from "@/pages/ReportsDetailPage/reportTypes";
import ShowNoResults from "@/pages/ReportsDetailPage/components/NoResultsFoundCard.vue";

const showReport = ref(false);
const dataIsSet = ref(false);

const reportDataValue = computed(() =>
  reportData.value && Object.keys(reportData.value).length > 0 ? reportData.value[0] : {}) as unknown as IPersonalityTraitsDistributionReportData;

const {
  timeFilter,
  tenantId,
  cohortId,
  institutionIds,
  isReportActive,
  reportData,
  loadingData,
  onTenantChange,
  onCohortChange,
  onInstitutionSelectionChange,
  getReportData,
  isReportEmpty
} = useInsights();

/**
 * Fetches the Worker Type Distribution report data from the API.
 */
function runReport() {
  showReport.value = true;
  getReportData("personality-traits-distribution", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    cohortId: cohortId.value,
    time: timeFilter.value
  });
}

function resetReport() {
  dataIsSet.value = false;
  reportData.value = [];
  showReport.value = false;
  isReportActive.value = false;
  institutionIds.value = [];
  tenantId.value = undefined;
  cohortId.value = undefined;
}

watch(reportDataValue, () => {
  dataIsSet.value = isReportEmpty(reportData.value);
});
</script>
