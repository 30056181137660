import {VSelectOption} from "@/lib/vuetify";
import {ThemeDefinition} from "vuetify";
import yapi from "@/lib/yapi";
import {AppConfiguration} from "@YenzaCT/sdk";

export const themeOptions: VSelectOption[] = [
  {
    title: "Light",
    value: "light"
  },
  {
    title: "Dark",
    value: "dark"
  },
];

export const localeOptions: VSelectOption[] = [
  {
    title: "English (South Africa)",
    value: "en_ZA"
  },
  {
    title: "Oromo",
    value: "om"
  },
  {
    title: "Amharic",
    value: "am"
  },
  {
    title: "Afrikaans",
    value: "af"
  }
];

export const countryOptions: VSelectOption[] = [
  {
    title: "South Africa",
    value: "ZA"
  },
  {
    title: "Kenya",
    value: "KE"
  },
  {
    title: "Ethiopia",
    value: "ET"
  },
  {
    title: "UAE",
    value: "AE"
  },
];

export const assessmentLocaleOptions: VSelectOption[] = [
  {
    title: "English (Ethopia)",
    value: "en_ET"
  },
  {
    title: "English (South Africa)",
    value: "en_ZA"
  },
  {
    title: "Afrikaans",
    value: "af"
  },
  {
    title: "Amharic",
    value: "am"
  },
  {
    title: "Central America Spanish",
    value: "cas"
  },
  {
    title: "French",
    value: "fr"
  },
  {
    title: "Hindi",
    value: "hi"
  },
  {
    title: "Portuguese",
    value: "po"
  },
  {
    title: "Arabic",
    value: "ar"
  },
  {
    title: "Oromo",
    value: "om"
  },
  {
    title: "Swahili",
    value: "sw"
  },
  {
    title: "isiZulu",
    value: "zu"
  },
  {
    title: "isiXhosa",
    value: "xh"
  },
];

export async function setAppConfiguration(key: keyof AppConfiguration, value: Record<string, unknown>) {
  await yapi.appConfiguration.set(key, value);
}

export async function loadAppConfiguration(): Promise<AppConfiguration> {
  const cachedConfig = getCachedConfig();

  if (cachedConfig) {
    return cachedConfig;
  } else {
    return await fetchConfig();
  }
}

export async function fetchConfig(): Promise<AppConfiguration> {
  const config = (await yapi.appConfiguration.list()).data;

  const themeLight: ThemeDefinition = config.themeLight;
  const themeDark: ThemeDefinition = config.themeDark;

  return {
    ...config,
    themeLight,
    themeDark,
  };
}

function getCachedConfig(): AppConfiguration | null {
  const json = localStorage.getItem("app_config");
  if (json) {
    return JSON.parse(json) as AppConfiguration;
  }

  return null;
}

export function setCachedConfig(config: AppConfiguration) {
  const json = JSON.stringify(config);
  localStorage.setItem("app_config", json);
}

type JsonPrimitive = string | number | boolean | null;

type JsonMap = {
  [key: string]: JsonPrimitive | JsonMap | JsonArray;
}

type JsonArray = Array<JsonPrimitive | JsonMap | JsonArray>;

export type Json = JsonPrimitive | JsonMap | JsonArray;
