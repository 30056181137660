<script setup lang="ts">

import {computed, onMounted, ref} from "vue";
import yapi from "@/lib/yapi";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {Curricula} from "@YenzaCT/sdk/src/yapi/objects/user/Curricula";
import {useI18n} from "vue-i18n";
import {useGlobalStore} from "@/store";
const {t} = useI18n();
const store = useGlobalStore();

const schoolCurricula = ref<Curricula>();

const props = defineProps({
  reportData: {
    type: Array,
    required: true
  },
  curriculum: {
    type: String,
    required: true
  },
  loadingData: {
    type: Boolean,
    required: true
  }
});

function getSubjectValue(key: string, item: unknown) {
  if (!props.curriculum)
    return "No Curriculum";

  if (!item)
    return "No Value";

  const cellItem = item as unknown as SubjectTableCellItem;
  if (!cellItem.subjects || !cellItem.subjects[key]) {
    return t("components.UsersSubjectChoicesReport.no_subject_selected");
  } else {
    return t(`curriculums.${props.curriculum}.subjects.${cellItem.subjects[key]}`);
  }
}

interface SubjectTableCellItem {
  subjects: {
      [key: string]: string;
    };
}

/**
 * Sets the headers for the report
 */
const headers = computed(() => {
  const baseHeaders = [
    {
      title: t("components.ReportsDetailPage.holland_code_headers.tenant"),
      key: "tenant.title"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.institution"),
      key: "institution.title"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.cohort"),
      key: "cohort.title"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.email"),
      key: "email"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.phone"),
      key: "phone"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.full_name"),
      key: "fullName"
    }
  ];

  if (curriculumHeaders.value)
    return baseHeaders.concat(curriculumHeaders.value);
  return baseHeaders;
});

async function getSchoolCurricula() {
  try {
    store.networkBusy = true;

    const res = await yapi.curriculum.list();
    schoolCurricula.value = res.data;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

const curriculumHeaders = computed(() => {
  if (!schoolCurricula.value)
    return [];

  if (props.curriculum)
    return Object.values(schoolCurricula.value[props.curriculum].subjectGroups).map((subjectGroup) => ({
      title: t(`curriculums.${props.curriculum}.subject_groups.${subjectGroup.slug}`),
      key: subjectGroup.slug
    }));
  return [];
});

onMounted(async () => {
  await getSchoolCurricula();
});
</script>

<template>
  <v-data-table
    :items="reportData"
    :headers="headers"
    :loading="loadingData"
    :items-per-page="50"
  >
    <template v-for="header in curriculumHeaders" v-slot:[`item.${header.key}`]="{ item }">
      {{ getSubjectValue(header.key, item) }}
    </template>
  </v-data-table>
</template>

<style scoped>

</style>
