<template>
  <authenticated-layout>
    <template #headerIcon>
      far fa-money-bill
    </template>
    <template #header>
      {{ $t("components.CouponTablePage.title") }}
    </template>

    <v-data-table-server
      class="elevation-1 rounded mb-6"
      density="compact"
      :loading="store.networkBusy"
      :headers="headers"
      :items="coupons"
      :items-per-page="perPage"
      :items-length="0"
      data-pw="couponsTable"
      @click:row="onClickRow"
      @update:sort-by="onSort"
      :sort-by="sort"
      :hover="true"
      hide-default-footer
    >
      <template v-slot:no-data>
        <h5 class="pa-2 d-flex align-center justify-center align-items-center">
          {{ $t("common.no_data_message") }}
        </h5>
      </template>

      <template v-slot:loader>
        <v-sheet
          class="d-flex align-center justify-center"
          height="100%"
        >
          <v-progress-linear
            indeterminate
            color="primary"
          ></v-progress-linear>
        </v-sheet>
      </template>

      <template v-slot:top>
        <v-toolbar class="data-table-toolbar">
          <search-text-field
            class="ml-2"
            :label="$t('components.CouponTablePage.search')"
            :disabled="store.networkBusy"
            data-pw="couponsSearchBox"
            @update:text="text => columns.title.filter = text"
          />
          <v-spacer/>
          <toolbar-menu
            :label="$t('common.filters')"
            :active-filter-count="activeFilterCount"
            icon="far fa-filter"
            @resetFilter="resetFilter"
          >
            <toolbar-menu-list-item-menu
              :label="columns.title.title"
              data-pw="couponTitleFilter"
              :is-filter-active="!!columns.title.filter"
            >
              <filter-text-field
                :label="columns.title.title"
                v-model="titleFilter"
                data-pw="filterListTitle"
              />
            </toolbar-menu-list-item-menu>
            <toolbar-menu-list-item-menu
              :label="columns.type.title"
              data-pw="couponTypeFilter"
              :is-filter-active="!!columns.type.filter"
            >
              <v-select
                v-model="columns.type.filter"
                :items="couponTypes"
                :label="$t('coupon.type')"
              />
            </toolbar-menu-list-item-menu>
            <toolbar-menu-list-item-menu
              :label="columns.enabled.title"
              data-pw="couponEnabledFilter"
              :is-filter-active="!!columns.enabled.filter"
            >
              <v-radio-group v-model="columns.enabled.filter">
                <v-radio
                  :label="$t('common.yes')"
                  value="true"
                  data-pw="filterEnabledYes"
                />
                <v-radio
                  :label="$t('common.no')"
                  value="false"
                  data-pw="filterEnabledNo"
                />
                <v-radio
                  :label="$t('common.all')"
                  value="all"
                  data-pw="filterEnabledAll"
                />
              </v-radio-group>
            </toolbar-menu-list-item-menu>
            <toolbar-menu-list-item-menu
              :label="$t('coupon.used')"
              data-pw="couponUsedFilter"
              :is-filter-active="!!columns.usageCount.filter"
            >
              <v-radio-group v-model="columns.usageCount.filter">
                <v-radio
                  :label="$t('common.yes')"
                  value="true"
                  data-pw="filterUsedYes"
                />
                <v-radio
                  :label="$t('common.no')"
                  value="false"
                  data-pw="filterUsedNo"
                />
                <v-radio
                  :label="$t('common.all')"
                  value="all"
                  data-pw="filterUsedAll"
                />
              </v-radio-group>
            </toolbar-menu-list-item-menu>
          </toolbar-menu>

          <toolbar-menu :label="$t('common.columns')" class="mr-2" icon="far fa-columns">
            <v-list-item>
              <v-checkbox-btn
                :label="$t('coupon.id')"
                v-model="columns.id.visible"
                :color="columns.id.visible ? 'primary' : ''"
                data-pw="couponIdColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('coupon.title')"
                v-model="columns.title.visible"
                :color="columns.title.visible ? 'primary' : ''"
                data-pw="couponTitleColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('coupon.type')"
                v-model="columns.type.visible"
                :color="columns.type.visible ? 'primary' : ''"
                data-pw="couponTypeColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('coupon.enabled')"
                v-model="columns.enabled.visible"
                :color="columns.enabled.visible ? 'primary' : ''"
                data-pw="couponEnabledColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('coupon.research')"
                v-model="columns.research.visible"
                :color="columns.research.visible ? 'primary' : ''"
                data-pw="couponResearchColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('coupon.cohort')"
                v-model="columns.cohort.visible"
                :color="columns.cohort.visible ? 'primary' : ''"
                data-pw="couponCohortColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('coupon.max_uses')"
                v-model="columns.maxUses.visible"
                :color="columns.maxUses.visible ? 'primary' : ''"
                data-pw="couponMaxUsesColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('coupon.usage_count')"
                v-model="columns.usageCount.visible"
                :color="columns.usageCount.visible ? 'primary' : ''"
                data-pw="couponUsageCountColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('coupon.amount')"
                v-model="columns.amount.visible"
                :color="columns.amount.visible ? 'primary' : ''"
                data-pw="couponAmountColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('coupon.expires_at')"
                v-model="columns.expiresAt.visible"
                :color="columns.expiresAt.visible ? 'primary' : ''"
                data-pw="couponExpiresAtColumn"
              />
            </v-list-item>
            <v-list-item>
              <v-checkbox-btn
                :label="$t('common.created_at')"
                v-model="columns.createdAt.visible"
                :color="columns.createdAt.visible ? 'primary' : ''"
                data-pw="couponCreatedAtColumn"
              />
            </v-list-item>
          </toolbar-menu>
          <v-btn
            class="mr-4 bg-primary"
            color="white"
            prepend-icon="far fa-plus"
            @click="showCouponDialog = true"
          >
            {{ $t('buttons.create') }}
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.type="{ item }">
        {{ $t(`couponTypes.${item["type"]}`) }}
      </template>

      <template v-slot:item.enabled="{ item }">
        {{ item.enabled ? t("yes") : t("no") }}
      </template>

      <template v-slot:item.expiresAt="{ item }">
        {{ toLocaleDateTime(item["expiresAt"]) }}
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ toLocaleDateTime(item["createdAt"]) }}
      </template>

      <template v-slot:bottom>
        <v-divider/>
        <pagination-controls
          v-if="paginatedData"
          class="text-center pt-2"
          :pagination-footer-data="paginationFooterData"
          @update:per-page="onUpdatePerPage"
          @on-next="onNext"
          @on-prev="onPrev"
          @on-page-change="onPageChange"
          data-pw="couponCodePaginationControls"
        />
      </template>
    </v-data-table-server>
    <v-dialog v-model="showCouponDialog" persistent max-width="800">
      <create-coupon-dialog
        @close="showCouponDialog = false; fetchPage()"
      />
    </v-dialog>
  </authenticated-layout>
</template>

<script setup lang="ts">
import {computed, onMounted, Ref, ref, watch} from "vue";
import {Coupon, isYapiError, Paginated, YapiError} from "@YenzaCT/sdk";
import {useRouter, useRoute} from "vue-router";
import {toLocaleDateTime} from "@/lib/time";
import {useI18n} from "vue-i18n";

import CreateCouponDialog from "@/pages/CouponTablePage/components/CreateCouponDialog.vue";
import yapi from "@/lib/yapi";
import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import PaginationControls from "@/components/PaginationControls.vue";
import {ColumnsConfig, FooterData, footerDataFactory, usePagination} from "@/lib/pagination";
import {useGlobalStore} from "@/store";
import SearchTextField from "@/components/SearchTextField.vue";
import FilterTextField from "@/components/DataTable/FilterTextField.vue";
import ToolbarMenu from "@/components/DataTable/ToolbarMenu.vue";
import ToolbarMenuListItemMenu from "@/components/DataTable/ToolbarMenuListItemMenu.vue";
import {VSelectOption} from "@/lib/vuetify";
import {CouponPaginationParams} from "@YenzaCT/sdk";
import {WritableComputedRef} from "@vue/reactivity";

const router = useRouter();
const route = useRoute();
const store = useGlobalStore();
const {t} = useI18n();

const paginatedData: Ref<Paginated<Coupon> | undefined> = ref();
let paginationFooterData: FooterData = footerDataFactory();

const coupons = ref<Coupon[]>([]);
const showCouponDialog: Ref<boolean> = ref(false);

const used = ref("all");

const cols: ColumnsConfig = {
  id: {
    title: t("coupon.id"),
    modelKey: "_id",
    visible: false,
    filter: ""
  },
  title: {
    title: t("coupon.title"),
    modelKey: "title",
    visible: true,
    filter: ""
  },
  type: {
    title: t("coupon.type"),
    modelKey: "type",
    visible: true,
    filter: ""
  },
  enabled: {
    title: t("coupon.enabled"),
    modelKey: "enabled",
    visible: true,
    filter: ""
  },
  research: {
    title: t("coupon.research"),
    modelKey: "research",
    visible: false,
    filter: ""
  },
  cohort: {
    title: t("coupon.cohort"),
    modelKey: "cohort",
    visible: false,
    filter: ""
  },
  maxUses: {
    title: t("coupon.max_uses"),
    modelKey: "maxUses",
    visible: true,
    filter: ""
  },
  usageCount: {
    title: t("coupon.usage_count"),
    modelKey: "usageCount",
    visible: true,
    filter: ""
  },
  amount: {
    title: t("coupon.amount"),
    modelKey: "amount",
    visible: false,
    filter: ""
  },
  expiresAt: {
    title: t("coupon.expires_at"),
    modelKey: "expiresAt",
    visible: true,
    filter: ""
  },
  createdAt: {
    title: t("common.created_at"),
    modelKey: "createdAt",
    visible: true,
    filter: ""
  },
};

const {
  columns,
  headers,
  page,
  perPage,
  sort,
  activeFilterCount,
  resetFilter,
  createUrlFilterParameters,
  onUpdatePerPage,
  onSort,
  onNext,
  onPrev,
  onPageChange,
  setupFooterData,
} = usePagination(fetchPage, {
  initialColumns: cols,
  initialSort: [{
    key: "createdAt",
    order: "asc"
  }]
});

onMounted(async () => {
  setupParams();
  await fetchPage();
});

const titleFilter: WritableComputedRef<string> = computed({
  get: (): string => columns.title.filter.toString(),
  set: (val: string | string[]) => {
    columns.title.filter = val.toString();
  }
});

watch(used, () => {
  fetchPage();
});

async function fetchPage() {
  const params: CouponPaginationParams = {
    page: page.value,
    size: perPage.value,
    sortBy: sort.value[0].key,
    sortOrder: sort.value[0].order,

    type: columns.type.filter as string || undefined,
    filterCode: columns.title.filter as string || undefined,
    maxUses: columns.maxUses.filter as string || undefined,
    used: used.value as string || undefined,
  };

  try {
    store.networkBusy = true;
    paginatedData.value = (await yapi.admin.coupon.paginate(params)).data;
    await router.push({query: createUrlFilterParameters(params)});
    coupons.value = paginatedData.value.docs;
    paginationFooterData = setupFooterData(paginatedData.value);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

function setupParams() {
  page.value = route.query.page ? parseInt(route.query.page as string) : page.value;
  perPage.value = route.query.size ? parseInt(route.query.size as string) : perPage.value;
  sort.value[0].key = route.query.sortBy as string || sort.value[0].key;
  sort.value[0].order = route.query.sortOrder as "asc" | "desc" || sort.value[0].order;

  columns.type.filter = route.query.type as string || columns.type.filter;
  columns.title.filter = route.query.filterCode as string || columns.title.filter,
  columns.maxUses.filter = route.query.maxUses as string || columns.maxUses.filter,
  used.value = route.query.used as string || used.value;
}

const onClickRow = async (event: Event, row: { internalItem: { raw?: Coupon } }) => {
  try {
    if (row.internalItem && row.internalItem.raw) {
      const couponId = row.internalItem.raw._id;
      if (couponId) {
        await openDetailPage(couponId);
      }
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  }
};

const openDetailPage = async (id: string) => {
  await router.push({
    name: "couponDetail",
    params: {id}
  });
};

const couponTypes: VSelectOption[] = [
  { title: t("couponTypes.full"), value: "full"},
  { title: t("couponTypes.percent"), value: "percent"},
  { title: t("couponTypes.fixed"), value: "fixed"}
];
</script>
