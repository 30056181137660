<template>
  <v-container fluid class="d-flex justify-center">
    <v-card style="min-width: 300px;">
      <v-card-title>
        {{ $t("components.OTPDialog.title") }}
      </v-card-title>
      <v-card-subtitle>
        {{ $t('components.OTPDialog.instruction') }}
      </v-card-subtitle>
      <v-card-text>
        <v-otp-input :autofocus="true" v-model="otp" />
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn color="secondary" @click="$emit('close')">
          {{ $t("buttons.cancel") }}
        </v-btn>
        <v-btn
          color="primary"
          @click="loginWithOTP"
          v-on:keyup.enter="onPressEnter"
          :disabled="otp.length !== 6"
        >
          {{ $t("buttons.confirm") }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script setup lang="ts">
import {PropType, ref} from "vue";
import {useI18n} from "vue-i18n";

import yapi from "@/lib/yapi";
import router from "@/router";
import {useGlobalStore} from "@/store";
import {isYapiError, YapiError} from "@YenzaCT/sdk";

const store = useGlobalStore();
const {t} = useI18n();
const props = defineProps({
  contact: {
    type: String as PropType<string>,
    required: true,
  },
  password: {
    type: String as PropType<string>,
    required: true,
  }
});

const emit = defineEmits(["close"]);

const otp = ref("");

async function onPressEnter() {
  if (otp.value.length === 6) {
    await loginWithOTP();
  }
}

async function loginWithOTP() {
  try {
    store.networkBusy = true;
    const res = await yapi.auth.login(props.contact, props.password, otp.value);
    store.user = res.data;
    emit("close");
    await router.push({name: "dashboard"});
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      if (yError.response?.status === 401) {
        store.snackbarMessage = t("components.LoginFormDirectory.OTPDialog.invalid_otp");
      }
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}
</script>
