<template>
  <v-select
    :model-value="modelValue"
    :disabled="store.networkBusy"
    data-pw="accountTypeSelect"
    class="pa-1 mt-1"
    variant="outlined"
    density="compact"
    hide-details
    :items="accountTypeOptions"
    @update:model-value="(v: AccountType) => $emit('update:modelValue', v)"
  />
</template>

<script setup lang="ts">
import {ref} from "vue";
import {AccountType} from "@YenzaCT/sdk";
import {useI18n} from "vue-i18n";
import {useGlobalStore} from "@/store";

const {t} = useI18n();
const store = useGlobalStore();

defineProps(["modelValue"]);

defineEmits<{
  (e: "update:modelValue", accountType: AccountType): void;
}>();

const accountTypeOptions = ref(Object.values(AccountType)
  .map((slug) => ({
    title: t(`accountTypes.${slug}`),
    value: slug
  })));
</script>

<style scoped>

</style>
