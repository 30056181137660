<template>
  <v-card>
    <v-card-title>{{ $t("components.UserDetailPage.user_detail_section_heading.choices") }}</v-card-title>
    <v-divider class="mx-4" />
    <v-card-text>
      <v-row>
        <v-col cols="12" md="4">
          <h3 class="subtitle-1 mb-2">{{ $t("components.UserDetailPage.user_detail_choices.curriculum") }}</h3>
          <v-chip color="primary" v-if="user.schoolCurriculum" variant="outlined">
            {{ $t(`curriculums.${user.schoolCurriculum}.title`) }}
          </v-chip>
          <v-chip color="grey lighten-1" v-else>{{ $t("components.UserDetailPage.user_detail_choices.none_selected") }}</v-chip>
        </v-col>

        <v-col cols="12" md="4">
          <h3 class="subtitle-1 mb-2">{{ $t("components.UserDetailPage.user_detail_choices.core_subjects") }}</h3>
          <template v-if="user.schoolCurriculum">
            <v-chip
              v-for="(subject, group) in getCoreSubjects"
              :key="group"
              color="primary"
              variant="outlined"
              class="ma-1"
            >
              <span class="font-weight-bold">
                {{ $t(`curriculums.${user.schoolCurriculum}.subject_groups.${group}`) }}: &nbsp;
              </span>
              <span>
                {{ subject ? $t(`curriculums.${user.schoolCurriculum}.subjects.${subject}`) :
                  $t("components.UserDetailPage.user_detail_choices.none_selected") }}
              </span>
            </v-chip>
          </template>
          <v-chip color="grey lighten-1" v-if="!hasCoreSubjects">
            {{ $t("components.UserDetailPage.user_detail_choices.none_selected") }}
          </v-chip>
        </v-col>

        <v-col cols="12" md="4">
          <h3 class="subtitle-1 mb-2">{{ $t("components.UserDetailPage.user_detail_choices.electives") }}</h3>
          <v-chip
            v-for="elective in getElectives"
            :key="elective"
            color="primary"
            variant="outlined"
            class="ma-1"
          >
            {{ $t(`curriculums.${user.schoolCurriculum}.subjects.${elective}`) }}
          </v-chip>
          <v-chip color="grey lighten-1" v-if="!hasElectives">
            {{ $t("components.UserDetailPage.user_detail_choices.none_selected") }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { Profile } from "@YenzaCT/sdk";

type Curriculum = "national_senior_certificate" | "cambridge" | "kenyan_certificate_of_secondary_education" | "international_baccalaureate";

const props = defineProps({
  user: {
    type: Object as PropType<Profile>,
    required: true
  },
});

const getCoreSubjects = computed(() => {
  const curriculum = props.user.schoolCurriculum as Curriculum | undefined;
  const choices = curriculum ? props.user.schoolCurriculumChoices?.[curriculum] : undefined;
  if (!choices || !curriculum) return {};

  const coreGroups: Record<Curriculum, string[]> = {
    "national_senior_certificate": ["first_language", "second_language", "life_orientation", "mathematics"],
    "cambridge": ["group_1", "group_2", "group_3", "group_4", "group_5"],
    "kenyan_certificate_of_secondary_education": ["mathematics", "english", "kiswahili", "science_1", "science_2", "humanities"],
    "international_baccalaureate": ["group_1", "group_2", "group_3", "group_4", "group_5"]
  };

  return Object.fromEntries(
    Object.entries(choices)
      .filter(([key]) => coreGroups[curriculum].includes(key))
  );
});

const getElectives = computed(() => {
  const curriculum = props.user.schoolCurriculum as Curriculum | undefined;
  const choices = curriculum ? props.user.schoolCurriculumChoices?.[curriculum] : undefined;
  if (!choices || !curriculum) return [];

  const electiveGroups: Record<Curriculum, string[]> = {
    "national_senior_certificate": ["elective_1", "elective_2", "elective_3", "elective_4", "elective_5"],
    "cambridge": ["ungrouped"],
    "kenyan_certificate_of_secondary_education": ["elective_1", "elective_2"],
    "international_baccalaureate": ["group_6"]
  };

  return Object.entries(choices)
    .filter(([key, value]) => electiveGroups[curriculum].includes(key) && value !== null)
    .map(([, value]) => value as string);
});

const hasCoreSubjects = computed(() => Object.values(getCoreSubjects.value).some((subject) => subject !== null));

const hasElectives = computed(() => getElectives.value.length > 0);
</script>

<style scoped>
</style>
<style scoped>
</style>
