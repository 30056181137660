export default {
  beta: "Beta",
  password: "Password",
  download: "Download",
  filters: "Filters",
  users: "Users",
  none: "None",
  menu: "Menu",
  high: "High",
  moderate: "Moderate",
  low: "Low",
  balanced: "Balanced",
  segment: "Segment",
  entities: {
    User: "User",
    AccessCode: "Voucher",
    Coupon: "Coupon",
    Cohort: "Cohort",
    Institution: "Institution",
    Tenant: "Tenant",
  },
  permissions: {
    read: "Read",
    create: "Create",
    edit: "Edit",
    delete: "Delete",
  },
  assessments: {
    holland_code: "Worker Type",
    big_5: "Personality Traits",
    learning_styles: "Learning Styles",
    managing_emotion: "Managing Emotion",
    perceiving_emotion: "Perceiving Emotion",
    understanding_emotion: "Understanding Emotion",
    maze: "Maze",
    rock_paper_scissors: "Rock Paper Scissors",
    ipip: "International Personality Item Pool",
    interests: "Interests",
    steu_b: "Situational Test of Emotion Understanding Brief ",
    stem_b: "STEM (B)",
    entrepreneurial_intent: "Entrepreneurial Intent",
    "entrepreneur-holland-code": "Entrepreneurial Holland Code",
  }
};
