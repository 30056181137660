<template>
  <div class="d-flex flex-row justify-space-between align-items-center">
    <v-btn
      color="primary"
      prepend-icon="fa fa-key"
      @click="dialogOpen=true"
    >
      {{ $t("components.ChangePassword.title") }}
    </v-btn>
  </div>

  <v-dialog
    v-model="dialogOpen"
  >
    <v-container class="d-flex justify-center">
      <v-card min-width="300">
        <v-card-title>
          {{ $t("components.ChangePassword.title") }}
        </v-card-title>

        <v-card-text>
          <v-text-field
            :append-inner-icon="showPasswordOld ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'"
            :label="$t('components.ChangePassword.old_password')"
            :type="showPasswordOld ? 'text' : 'password'"
            :rules="oldPasswordRules"
            v-model="oldPassword"
            @click:append-inner="showPasswordOld = !showPasswordOld"
          />
          <v-divider class="mb-4"/>
          <v-text-field
            :append-inner-icon="showPasswordNew ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'"
            :label="$t('components.ChangePassword.new_password')"
            :rules="newPasswordRules"
            :type="showPasswordNew ? 'text' : 'password'"
            v-model="newPassword"
            @click:append-inner="showPasswordNew = !showPasswordNew"
          />
          <v-text-field
            :append-inner-icon="showPasswordConfirm ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'"
            :label="$t('components.ChangePassword.new_password_confirm')"
            :rules="newPasswordRules"
            :type="showPasswordConfirm ? 'text' : 'password'"
            v-model="newPasswordConfirm"
            @click:append-inner="showPasswordConfirm = !showPasswordConfirm"
          />
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="secondary" @click="dialogOpen=false">
            {{ $t("buttons.cancel") }}
          </v-btn>
          <v-btn
            color="primary"
            @click="changePassword"
            :disabled="!newPasswordsValid"
          >
            {{ $t("buttons.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {isYapiError, YapiError} from "@YenzaCT/sdk";

import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const store = useGlobalStore();
const dialogOpen = ref(false);

const oldPassword = ref("");
const newPassword = ref("");
const newPasswordConfirm = ref("");
const showPasswordOld = ref(false);
const showPasswordNew = ref(false);
const showPasswordConfirm = ref(false);

const props = defineProps({
  userId: {
    type: String,
    required: true
  }
});

const oldPasswordRules = [
  (v: string) => (!!v && v.length >= 6) || t("components.ChangePassword.password_required_least_6"),
  (v: string) => /[A-Z]/.test(v) || t("components.ChangePassword.password_capital_letter_required"),
  (v: string) => /[a-z]/.test(v) || t("components.ChangePassword.password_lowercase_letter_required"),
  (v: string) => /\d/.test(v) || t("components.ChangePassword.password_number_required"),
];

const newPasswordRules = [
  (v: string) => (!!v && v.length >= 6) || t("components.ChangePassword.password_required_least_6"),
  (v: string) => /[A-Z]/.test(v) || t("components.ChangePassword.password_capital_letter_required"),
  (v: string) => /[a-z]/.test(v) || t("components.ChangePassword.password_lowercase_letter_required"),
  (v: string) => /\d/.test(v) || t("components.ChangePassword.password_number_required"),
  (v: string) => v === newPassword.value || t("components.ChangePassword.password_match_required")
];

const newPasswordsValid = computed(() => newPasswordRules.every((rule) => rule(newPassword.value) === true));

async function changePassword() {
  try {
    await yapi.admin.user.changePassword(props.userId, newPassword.value);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    dialogOpen.value = false;
  }
}

</script>
