<template>
  <learning-styles-report-skeleton-loader v-if="loading"/>
  <v-row v-else-if="loadReportContent" class="d-flex flex-column">
    <!--  types and results-->
    <div class="flex-grow-1">
      <v-col cols="12" v-if="!dialogOpenDetail">
        <v-row class="ma-2">
          <v-col cols="12">
            <LearningStyleTraitScoreCard
              :title="learningStylesReportContent.labels.data_driven_vs_conceptual"
              :left-label="learningStylesReportContent.labels.data"
              :right-label="learningStylesReportContent.labels.conceptual"
              :score="learningStylesReportContent.results.DC.score"
              :colour="DC_colour"
            />
          </v-col>
          <v-col cols="12">
            <LearningStyleTraitScoreCard
              :title="learningStylesReportContent.labels.optic_vs_written"
              :left-label="learningStylesReportContent.labels.optic"
              :right-label="learningStylesReportContent.labels.written"
              :score="learningStylesReportContent.results.OW.score"
              :colour="OW_colour"
            />
          </v-col>
        </v-row>
        <v-row class="ma-2">
          <v-col cols="12">
            <LearningStyleTraitScoreCard
              :title="learningStylesReportContent.labels.structured_vs_fluid"
              :left-label="learningStylesReportContent.labels.structured"
              :right-label="learningStylesReportContent.labels.fluid"
              :score="learningStylesReportContent.results.SF.score"
              :colour="SF_colour"
            />
          </v-col>
          <v-col cols="12">
            <LearningStyleTraitScoreCard
              :title="learningStylesReportContent.labels.doing_vs_thinking"
              :left-label="learningStylesReportContent.labels.doing"
              :right-label="learningStylesReportContent.labels.thinking"
              :score="learningStylesReportContent.results.DT.score"
              :colour="DT_colour"
            />
          </v-col>
        </v-row>
      </v-col>
    </div>

    <!-- Overview -->
    <v-dialog cols="12" v-model="dialogOpenDetail" transition="dialog-bottom-transition" >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn dark @click="closeDetailedDialog">
            <v-icon>fa fa-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("assessments.learning_styles.title") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          Full breakdown coming soon.
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script setup lang="ts">
import {PropType, ref, computed, watch} from "vue";
import {
  isYapiError,
  Feature,
  LearningStylesResult,
  YapiError
} from "@YenzaCT/sdk";
import LearningStyleTraitScoreCard
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/LearningStyleReport/LearningStyleTraitScoreCard.vue";
import {AxiosResponse} from "axios";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import LearningStylesReportSkeletonLoader
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/LearningStyleReport/LearningStylesReportSkeletonLoader.vue";

const props = defineProps({
  userId: {
    type: String,
    required: true
  },
  score: {
    type: Object as PropType<LearningStylesResult>,
    required: true
  },
  dialogOpen: {
    type: Boolean,
    default: false,
    required: true
  },
});

const store = useGlobalStore();
const DC_colour = "orange";
const OW_colour = "blue";
const DT_colour = "green";
const SF_colour = "purple";

const dialogOpenDetail = ref(props.dialogOpen);

watch(() => props.dialogOpen, (newVal) => {
  dialogOpenDetail.value = newVal;
});

function closeDetailedDialog() {
  dialogOpenDetail.value = false;
}

const loadReportContent = computed(() => {
  if (dataLoaded.value) {
    return learningStylesReportContent.value;
  }
  return null;
});
const dataLoaded = ref(false);
const loading = ref(false);
const learningStylesReportContent = ref();

async function fetchLearningStylesReportContent() {
  loading.value = true;
  try {
    const res: AxiosResponse = await yapi.admin.user.getAssessmentReport(props.userId, Feature.LearningStylesReport);
    learningStylesReportContent.value = res.data;
    dataLoaded.value = true;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
}

fetchLearningStylesReportContent();

</script>

<style scoped>
.btn-bg {
  background-color: #F1F5F9;
  border-color: #CBD5E1;
}
</style>
