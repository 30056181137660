<template>
  <report-filter-options
    :show-tenant-filter="true"
    :show-institution-filter="true"
    :show-cohort-filter="true"
    :show-cohort-breakdown="true"
    :show-institution-breakdown="true"
    :is-report-active="isReportActive"
    :allow-export="filteredReportData.length > 0"
    :disable-run-report-button="false"
    @change-tenant="onTenantChange"
    @change-institution="onInstitutionSelectionChange"
    @change-cohort="onCohortChange"
    @change-cohort-breakdown="onCohortBreakdownChange"
    @change-institution-breakdown="onInstitutionBreakdownChange"
    @click-run-report="runReport"
    @click-export-report="exportReportData"
    @click-reset="resetReport"
  >
    <template #filterList>
      <v-list-item v-if="breakdownByCohort || breakdownByInstitution">
        <span class="font-weight-bold">{{ $t('common.breakdown_by') }}: </span>
        <span v-if="breakdownByInstitution">{{ $t('common.institution') }}</span>
        <span v-if="breakdownByCohort && breakdownByInstitution">, </span>
        <span v-if="breakdownByCohort">{{ $t('common.cohort') }}</span>
      </v-list-item>
    </template>
  </report-filter-options>

  <v-data-table
    v-if="filteredReportData.length > 0"
    :items="filteredReportData"
    :headers="headers"
    :loading="loadingData"
    :items-per-page="50"
  >
    <template v-slot:item.percentageComplete="{ item }">
      <v-chip :color="getColor(item.percentageComplete as number)">
        {{ item.percentageComplete }}%
      </v-chip>
    </template>
  </v-data-table>
  <ShowNoResults
    v-else-if=" filteredReportData.length <= 0 && showReport && !loadingData"
  />
</template>

<script setup lang="ts">
import {computed, ref} from "vue";

import {useI18n} from "vue-i18n";
import {useInsights} from "@/pages/ReportsDetailPage/reportsDetailPage";
import ReportFilterOptions from "@/components/Reporting/ReportFilterOptions.vue";
import ShowNoResults from "@/pages/ReportsDetailPage/components/NoResultsFoundCard.vue";

const {t} = useI18n();
const showReport = ref(false);

const dataTableCohortSearch = ref<string>("");

const {
  timeFilter,
  tenantId,
  institutionIds,
  cohortId,
  isReportActive,
  breakdownByCohort,
  breakdownByInstitution,
  reportData,
  loadingData,
  getColor,
  onTenantChange,
  onCohortChange,
  onCohortBreakdownChange,
  onInstitutionBreakdownChange,
  getReportData,
  exportData,
  onInstitutionSelectionChange
} = useInsights();

/**
 * Filters the data table by the cohort search
 */
const filteredReportData = computed(() => {
  if (!dataTableCohortSearch.value) {
    return reportData.value;
  } else {
    return reportData.value
      .filter((item) => "cohort" in item && typeof item.cohort === "object" && item.cohort !== null)
      .filter((item) =>
        (item.cohort as { title: string }).title.toLowerCase().includes(dataTableCohortSearch.value.toLowerCase()));
  }
});

const headers = computed(
  () => {
    const headers = [
      {
        title: t("components.Big5CompletionReport.headers.tenant"),
        key: "tenant.title"
      }
    ];

    if (breakdownByInstitution.value)
      headers.push({
        title: t("components.Big5CompletionReport.headers.institution"),
        key: "institution.title"
      },);

    if (breakdownByCohort.value)
      headers.push({
        title: t("components.Big5CompletionReport.headers.cohort"),
        key: "cohort.title"
      });

    headers.push({
      title: t("components.Big5CompletionReport.headers.percentage_complete"),
      key: "percentageComplete"
    },
    {
      title: t("components.Big5CompletionReport.headers.complete"),
      key: "complete"
    },
    {
      title: t("components.Big5CompletionReport.headers.incomplete"),
      key: "incomplete"
    },
    {
      title: t("components.Big5CompletionReport.headers.total"),
      key: "total"
    });

    return headers;
  }
);

/**
 * Fetch the Big 5 Completion report data from the API.
 */
function runReport() {
  showReport.value = true;

  getReportData("big-5-completion", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    cohortId: cohortId.value,
    breakdownByCohort: breakdownByCohort.value,
    breakdownByInstitution: breakdownByInstitution.value,
    time: timeFilter.value
  });
}

function resetReport() {
  reportData.value = [];
  showReport.value = false;
  isReportActive.value = false;
  breakdownByCohort.value = false;
  breakdownByInstitution.value = false;
}

/**
 * Exports the Big 5 Completion report data to a CSV file.
 */
function exportReportData(format: string) {
  exportData("big-5-completion", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    cohortId: cohortId.value,
    breakdownByCohort: breakdownByCohort.value,
    breakdownByInstitution: breakdownByInstitution.value,
    time: timeFilter.value,
    format
  });
}

</script>
