import {DateTime} from "luxon";

import {useUserSettings} from "@/lib/userSettings";
import {kebabCase} from "lodash";

export function toLocaleDateTime(dateTime: string | Date | null | undefined): string {
  if (!dateTime) {
    return "";
  }

  if (dateTime instanceof Date) {
    dateTime = dateTime.toISOString();
  }

  const {currentLocale} = useUserSettings();
  return DateTime
    .fromISO(dateTime)
    .setLocale(kebabCase(currentLocale.value.toString()))
    .toFormat("f");
}
