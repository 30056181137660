<template>
  <v-card :title="$t('common.details')"  height="100%" >
    <v-card-text>
      <v-table>
        <tbody>
          <detail-row
            :label="$t('common.entities.Cohort')"
            icon="far fa-title"
            :value="cohort.title"/>
          <detail-row
            :label="$t('common.slug')"
            icon="far fa-slug"
            :value="cohort.slug"/>
          <detail-row
            :label="$t('common.entities.Institution')"
            icon="far fa-slug"
            :value="institutionTitle"/>
        </tbody>
      </v-table>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import {PropType} from "vue";

import DetailRow from "@/components/DetailRow.vue";
import {Cohort} from "@YenzaCT/sdk";

const props = defineProps({
  cohort: {
    type: Object as PropType<Cohort>,
    required: true
  }
});

const institutionTitle = typeof props.cohort.institution === "string" ? props.cohort.institution : props.cohort.institution.title;
</script>
