const creator = (size: number) => `<svg width=${size} height=${size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.62432 17.5C6.62432 18.3284 7.2959 19 8.12432 19H10.8757C11.7041 19 12.3757 18.3284 12.3757 17.5V12.8757C12.3757 12.5995 12.5995 12.3757 12.8757 12.3757H17.5C18.3284 12.3757 19 11.7041 19 10.8757V8.12432C19 7.2959 18.3284 6.62432 17.5 6.62432H12.8757C12.5995 6.62432 12.3757 6.40047 12.3757 6.12432V1.5C12.3757 0.671572 11.7041 0 10.8757 0H8.12433C7.2959 0 6.62432 0.671573 6.62432 1.5V6.12432C6.62432 6.40047 6.40047 6.62432 6.12432 6.62432H1.5C0.671572 6.62432 0 7.2959 0 8.12432V10.8757C0 11.7041 0.671573 12.3757 1.5 12.3757H6.12432C6.40047 12.3757 6.62432 12.5995 6.62432 12.8757V17.5Z" fill="#1EAC30"/>
</svg>
`;

const builder = (size: number) => `<svg width=${size} height=${size} viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.20096 1.25C6.77831 0.250002 8.22169 0.25 8.79904 1.25L14.4282 11C15.0056 12 14.2839 13.25 13.1292 13.25H1.87084C0.716136 13.25 -0.00555372 12 0.571797 11L6.20096 1.25Z" fill="#FC631D"/>
</svg>
`;

const organiser = (size: number) => `<svg width=${size} height=${size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9278 7.57216C12.9278 6.74374 12.2563 6.07217 11.4278 6.07217H7.57216C6.74374 6.07217 6.07217 6.74374 6.07217 7.57217V11.4278C6.07217 12.2563 6.74374 12.9278 7.57217 12.9278H11.4278C12.2563 12.9278 12.9278 12.2563 12.9278 11.4278V7.57216ZM1.5 0C0.671573 0 0 0.671573 0 1.5V17.5C0 18.3284 0.671573 19 1.5 19H17.5C18.3284 19 19 18.3284 19 17.5V1.5C19 0.671573 18.3284 0 17.5 0H1.5Z" fill="#FF3520"/>
</svg>
`;

const helper = (size: number) => `<svg width=${size} height=${size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M1.49747 5C0.663897 5 -0.0230633 5.68521 0.128021 6.50497C0.912954 10.7639 4.81034 14 9.50006 14C14.1898 14 18.0872 10.7639 18.8721 6.50497C19.0232 5.68521 18.3362 5 17.5026 5H15.445C14.6275 5 13.9861 5.67607 13.684 6.4357C13.0438 8.04545 11.412 9.18966 9.50006 9.18966C7.5881 9.18966 5.95629 8.04545 5.3161 6.4357C5.014 5.67607 4.37265 5 3.55515 5H1.49747Z" fill="#FF5D5D"/>
</svg>
`;

const thinker = (size: number) => `<svg width=${size} height=${size} viewBox="0 0 15 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.64535 1.58947C6.91727 0.765576 8.08273 0.765579 8.35465 1.58948L9.24337 4.28216C9.38675 4.71657 9.83318 4.97431 10.2811 4.88128L13.0574 4.30459C13.9068 4.12813 14.4896 5.13745 13.912 5.78489L12.0244 7.90089C11.7199 8.24225 11.7199 8.75775 12.0244 9.09911L13.912 11.2151C14.4896 11.8626 13.9068 12.8719 13.0574 12.6954L10.2811 12.1187C9.83318 12.0257 9.38675 12.2834 9.24337 12.7178L8.35465 15.4105C8.08273 16.2344 6.91727 16.2344 6.64535 15.4105L5.75663 12.7178C5.61325 12.2834 5.16682 12.0257 4.71893 12.1187L1.94263 12.6954C1.09316 12.8719 0.510431 11.8626 1.08798 11.2151L2.97556 9.09911C3.28008 8.75775 3.28008 8.24225 2.97556 7.90089L1.08798 5.78489C0.510429 5.13744 1.09316 4.12813 1.94264 4.30459L4.71893 4.88128C5.16682 4.97431 5.61325 4.71657 5.75663 4.28216L6.64535 1.58947Z" fill="#0487FF"/>
</svg>
`;
const influencer = (size: number) => `<svg width=${size} height=${size} viewBox="0 0 14 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M5.38039 0H2.17096C0.904567 0 0.208058 1.4724 1.01133 2.45144L5.98866 8.51791C6.44122 9.0695 6.44262 9.86354 5.992 10.4167L0.993638 16.5526C0.195365 17.5326 0.892672 19 2.15661 19H6.02169C6.4909 19 6.93308 18.7804 7.21668 18.4066L13.2775 10.4182C13.7003 9.86102 13.6818 9.08559 13.233 8.54912L6.5309 0.537538C6.2459 0.196855 5.82456 0 5.38039 0Z" fill="#514EE4"/>
</svg>
`;

export {
  creator,
  organiser,
  helper,
  thinker,
  influencer,
  builder
};
