<template>
  <v-menu
    location="end"
    :close-on-content-click="false"
  >
    <template v-slot:activator="{ props }">
      <v-list-item
        active-class="list-item"
        class="list-item"
        v-bind="props"
        data-pw="toolBarMenuListItemMenu"
        :style="isFilterActive ? primaryColour : 'black'"
      >
        {{ label }}
      </v-list-item>
    </template>
    <v-card
      min-width="300px"
      class="mr-1 elevation-2"
      variant="outlined"
    >
      <v-card-text>
        <span class="text-caption">
          {{ label }}
        </span>
        <slot/>
      </v-card-text>
    </v-card>
  </v-menu>

</template>

<script setup lang="ts">
import {PropType} from "vue";
import { useTheme } from "vuetify";
import {ref} from "vue";

const theme = useTheme();

defineProps({
  label: {
    type: String as PropType<string>,
    required: true
  },
  isFilterActive: {
    type: Boolean as PropType<boolean>,
    required: true
  }
});

const primaryColour = ref();
primaryColour.value = `color: ${theme.themes.value.light.colors.primary}`;
</script>

<style scoped>
  .list-item {
    cursor: pointer;
  }
  .list-item:hover {
    background-color: lightgrey;
    color: black;
  }
</style>
