import {Action, Entity, EntityAbility, Permission} from "@YenzaCT/sdk";
import {merge} from "lodash";

export type PermissionCheckBoxState = Record<string, {label: string; actions: Record<string, { label: string; value: boolean; }>}>
const defaultTenantPermissionCheckBoxState: PermissionCheckBoxState = {
  [Entity.Tenant]: {
    label: "Tenant",
    actions: {
      [Action.edit]: {label: "Edit", value: false},
      [Action.read]: {label: "Read", value: false},
    }
  },
  [Entity.Institution]: {
    label: "Institution",
    actions: {
      [Action.create]: {label: "Create", value: false},
      [Action.read]: {label: "Read", value: false},
      [Action.edit]: {label: "Edit", value: false},
      [Action.delete]: {label: "Delete", value: false},
    }
  },
  [Entity.Cohort]: {
    label: "Cohort",
    actions: {
      [Action.create]: {label: "Create", value: false},
      [Action.read]: {label: "Read", value: false},
      [Action.edit]: {label: "Edit", value: false},
      [Action.delete]: {label: "Delete", value: false},
    }
  },
  [Entity.User]: {
    label: "User",
    actions: {
      [Action.edit]: {label: "Edit", value: false},
      [Action.read]: {label: "Read", value: false},
    }
  },
  [Entity.AccessCode]: {
    label: "Vouchers",
    actions: {
      [Action.read]: {label: "Read", value: false},
    }
  },
};
const defaultInstitutionPermissionCheckBoxState: PermissionCheckBoxState = {
  [Entity.Institution]: {
    label: "Institution",
    actions: {
      [Action.edit]: {label: "Edit", value: false},
      [Action.read]: {label: "Read", value: false},
    }
  },
  [Entity.Cohort]: {
    label: "Cohort",
    actions: {
      [Action.create]: {label: "Create", value: false},
      [Action.read]: {label: "Read", value: false},
      [Action.edit]: {label: "Edit", value: false},
      [Action.delete]: {label: "Delete", value: false},
    }
  },
  [Entity.User]: {
    label: "User",
    actions: {
      [Action.edit]: {label: "Edit", value: false},
      [Action.read]: {label: "Read", value: false},
    }
  },
  [Entity.AccessCode]: {
    label: "Vouchers",
    actions: {
      [Action.read]: {label: "Read", value: false},
    }
  },
};
const defaultCohortPermissionCheckBoxState: PermissionCheckBoxState = {
  [Entity.Cohort]: {
    label: "Cohort",
    actions: {
      [Action.read]: {label: "Read", value: false},
    }
  },
  [Entity.User]: {
    label: "User",
    actions: {
      [Action.edit]: {label: "Edit", value: false},
      [Action.read]: {label: "Read", value: false},
    }
  },
  [Entity.AccessCode]: {
    label: "Vouchers",
    actions: {
      [Action.read]: {label: "Read", value: false},
    }
  },
};

export const defaultCheckBoxStates: Partial<Record<Entity, PermissionCheckBoxState>> = {
  [Entity.Cohort]: defaultCohortPermissionCheckBoxState,
  [Entity.Institution]: defaultInstitutionPermissionCheckBoxState,
  [Entity.Tenant]: defaultTenantPermissionCheckBoxState
};

export function permissionToCheckboxState(permission: Permission): PermissionCheckBoxState {
  const checkboxState: PermissionCheckBoxState = {};
  permission.abilities.forEach((ability: EntityAbility) => {
    const {
      entity,
      action
    } = ability;
    if (!checkboxState[entity]) {
      checkboxState[entity] = {
        label: entity,
        actions: {},
      };
    }
    checkboxState[entity].actions[action] = {
      label: action,
      value: true,
    };
  });
  return merge({}, defaultCheckBoxStates[permission.entity], checkboxState);
}

export function checkboxStateToAbilities(checkboxState: PermissionCheckBoxState): EntityAbility[] {
  const abilities: EntityAbility[] = [];
  Object.keys(checkboxState).forEach((entity: string) => {
    Object.keys(checkboxState[entity].actions).forEach((action: string) => {
      if (checkboxState[entity].actions[action].value) {
        abilities.push({
          entity: entity as Entity,
          action: action as Action,
        });
      }
    });
  });

  return abilities;
}
