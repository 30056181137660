<template>
  <v-card
    :title="title"
    v-if="data.length"
    color="transparent"
    :prepend-icon="icon"
    elevation="0"
    data-pw="searchResultCard"
  >
    <v-list class="bg-transparent">
      <v-list-item
        v-for="(item, index) in limitedData"
        :key="index"
        :title="formattedTitles[index]"
        append-icon="far fa-chevron-right"
        @click="handleClick(item._id)"
        active-class="bg-pink"
        :class="{ active: selectedIndex === index }"
        class="text-white cursor"
      >
      </v-list-item>
      <v-list-item
        v-if="showMore"
        :key="'show-more'"
        :title="$t('components.SearchResultsCards.show_search_more')"
        append-icon="far fa-chevron-right"
        @click="handleShowMoreClick"
        class="text-white"
      >
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script lang="ts" setup>
import {computed, ref, PropType} from "vue";
import {GlobalSearchResponse} from "@YenzaCT/sdk";
import {useRouter} from "vue-router";

const router = useRouter();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  routeName: {
    type: String,
    required: true,
  },
  showMoreRoute: {
    type: Object,
    required: true,
  },
  data: {
    type: Array as PropType<GlobalSearchResponse["users" | "accessCode" | "tenants" | "institutions" | "cohorts"]>,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
});

const emit = defineEmits<{
  (event: "click"): void;
}>();

const selectedIndex = ref(-1);

const formattedTitles = computed(() =>
  props.data.map((item) => {
    function getDisplayTitle(item: GlobalSearchResponse["users" | "accessCode" | "tenants" | "institutions" | "cohorts"][0]) {
      if ("email" in item && item.email) {
        return item.email;
      } else if ("firstName" in item && item.firstName) {
        return item.firstName;
      } else if ("lastName" in item && item.lastName) {
        return item.lastName;
      } else if ("phone" in item && item.phone) {
        return item.phone;
      }
    }

    if ("email" in item) {
      return getDisplayTitle(item);
    } else if ("code" in item) {
      return `${item.code || "Untitled"}`.trim();
    } else if ("title" in item) {
      return item.title;
    } else {
      return "Untitled";
    }
  }));

const limitedData = computed(() => props.data.slice(0, 5));
const showMore = computed(() => props.data.length > 3);

async function handleShowMoreClick() {
  await router.push(props.showMoreRoute);
  emit("click");
}

async function handleClick(id: string) {
  await router.push({
    name: props.routeName,
    params: {id}
  });

  emit("click");
}

</script>

<style scoped>
.v-list-item:hover {
  background-color: rgba(15, 23, 42, 0.95);
  border-radius: 12px !important;
}
</style>
