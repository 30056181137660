<template>
  <v-text-field
    v-model="contact"
    :label="$t('components.ForgotPasswordForm.contact_email')"
    required
    :rules="emailRules"
    variant="outlined"
    density="compact"
    @keydown.space.prevent
    @input="validateEmail"
    data-pw="input"
  />
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const contact = ref("");
const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "validate", value: boolean): void;
}>();

const emailRegex =
  // eslint-disable-next-line max-len
  /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const emailRules = [
  (v: string) => !!v || t("components.ForgotPasswordForm.email_warning"),
  (v: string) => emailRegex.test(v) || t("components.ForgotPasswordForm.email_warning"),
];

const isEmailValid = computed(() => emailRegex.test(contact.value));

function validateEmail() {
  emit("update:modelValue", contact.value);
  emit("validate", isEmailValid.value);
}
</script>
