<template>
  <authenticated-layout>
    <template #headerIcon>
      far fa-building
    </template>
    <template #header>
      {{ institution?.title }}
    </template>

    <template #breadcrumbs>
      <v-breadcrumbs
        density="compact"
        class="pl-0 text-caption"
      >
        <v-breadcrumbs-item :to="{ name: 'institutions' }" class="font-weight-bold" color="secondary">
          {{ $t("components.InstitutionDetailPage.title") }}
        </v-breadcrumbs-item>
        <v-breadcrumbs-divider/>
        <v-breadcrumbs-item color="secondary">
          {{ institution?.title }}
        </v-breadcrumbs-item>
      </v-breadcrumbs>
    </template>

    <template #context-menu>
      <v-menu
        bottom
        transition="scale-transition"
      >
        <template v-slot:activator="{ props }">
          <v-btn-primary
            v-bind="props"
            prepend-icon="fa fa-bars"
          >
            {{ $t("buttons.menu") }}
          </v-btn-primary>
        </template>
        <v-list min-width="250px">
          <v-list-item
            :disabled="!isAdminOrRoot && !store.hasAbility(Entity.Institution, Action.edit)"
            @click="showEditInstitution = true"
          >
            {{ $t("buttons.edit") }}
          </v-list-item>
          <v-list-item
            :disabled="!isAdminOrRoot && !store.hasAbility(Entity.Institution, Action.edit)"
            @click="showEditCurriculum = true">
            {{ $t("components.InstitutionDetailPage.manage_curriculum") }}
          </v-list-item>
          <v-list-item
            :disabled="!isAdminOrRoot && !store.hasAbility(Entity.Cohort, Action.create)"
            @click="showCreateDialog = true">
            {{ $t("components.InstitutionDetailPage.create_cohort") }}
          </v-list-item>
          <v-list-item
            :disabled="!isAdminOrRoot && !store.hasAbility(Entity.Institution, Action.delete)"
            @click="showDeleteInstitution = true">
            <span class="text-red">{{ $t("buttons.delete") }}</span>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>

    <v-container v-if="institution" fluid class="py-4">
      <v-row>
        <v-col cols="3" lg="2">
          <v-list color="transparent">
            <v-list-item
              :active="isActive(Section.Details)"
              active-class="bg-primary"
              @click="() => changeSection(Section.Details)"
              prepend-icon="far fa-building"
              :title="$t('common.details')"
            />
            <v-list-item
              v-if="store.appConfiguration.integrations.d6"
              :active="isActive(Section.D6)"
              active-class="bg-primary"
              @click="changeSection(Section.D6)"
              prepend-icon="far fa-computer"
              :title="$t('components.InstitutionDetailPage.d6')"
            />
            <v-list-item
              :active="isActive(Section.JoinRequests)"
              active-class="bg-primary"
              @click="changeSection(Section.JoinRequests)"
              prepend-icon="far fa-user-graduate"
              :title="$t('components.InstitutionDetailPage.join_requests')"
            />
          </v-list>
        </v-col>
        <v-col cols="9" lg="10">
          <v-row v-if="isActive(Section.Details)">
            <v-col cols="12">
              <h2>{{  $t("components.InstitutionDetailPage.subtitle") }}</h2>
            </v-col>
            <v-col cols="12">
              <institution-details
                :title="institution.title"
                :slug="institution.slug"
                :tenant="tenantTitle"/>
            </v-col>
            <v-col cols="12" >
              <institution-curricula :curricula="institution.curricula"/>
            </v-col>
            <v-col cols="12">
              <managers-table v-if="isAdminOrRoot" @revoked="fetchInstitution" :managers="managers" :entity-type="Entity.Institution"/>
            </v-col>
          </v-row>
          <v-row v-if="isActive(Section.D6)">
            <v-col cols="12">
              <h2>{{  $t("components.InstitutionDetailPage.d6_details") }}</h2>
            </v-col>
            <v-col cols="12">
              <D6Details v-if="institution.d6" :institution="institution"/>
            </v-col>
            <v-col cols="12">
              <D6Connection :institution="institution" @connect="(schoolId) => connectD6(schoolId)" @disconnect="disconnectD6"/>
            </v-col>
          </v-row>
          <v-row v-if="isActive(Section.JoinRequests)">
            <v-col cols="12">
              <h2>{{  $t("components.JoinRequests.title") }}</h2>
            </v-col>
            <v-col cols="12">
              <v-alert color="warning" v-if="!institution.allowsJoinRequests">
                <v-alert-title class="justify-space-between">
                  <div>{{ $t("components.JoinRequests.disabled") }}</div>
                  <v-btn
                    variant="plain"
                    @click="showEditInstitution = true"
                    icon="fa fa-gear"
                  />
                </v-alert-title>

              </v-alert>
              <v-spacer/>
              <div v-if="institution.joinRequests.length > 0">
                <join-request
                  v-for="userId in institution.joinRequests"
                  :key="userId"
                  :user-id="userId"
                  :institution-id="institution._id"
                  @removed="fetchInstitution"
                />
              </div>
              <div v-else>
                {{ $t("components.JoinRequests.noRequests")}}
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="showEditCurriculum">
      <edit-institution-curriculum
        v-if="institution"
        @close="onEditCurriculumDialog()"
        :title="institution ? institution.title : ''"
        :curricula="institution.curricula"
      />
    </v-dialog>

    <v-dialog v-model="showDeleteInstitution" max-width="500">
      <delete-institution
        v-if="institution"
        @close="showDeleteInstitution = false"
        :confirm-string="institution ? institution.title : ''"
        :message="institution ? institution.title : ''"
      />
    </v-dialog>

    <v-dialog v-model="showEditInstitution" max-width="500">
      <edit-institution
        @close="onEditDialog()"
        :institution="institution!"
      />
    </v-dialog>

    <v-dialog v-model="showCreateDialog" max-width="500">
      <create-tenant-institution-cohort
        :entity-title="$t('components.CreateCards.CreateTenantInstitutionCohort.titleCreateCohort')"
        :institution-id="id"
        :entity-type="Entity.Cohort"
        :allowed="store.hasAbility(Entity.Cohort, Action.create) || isAdminOrRoot"
        data-pw="createCohortCard"
        @close="showCreateDialog = false"
      />
    </v-dialog>
  </authenticated-layout>
</template>

<script setup lang="ts">
import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import {onMounted, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import yapi from "@/lib/yapi";
import {Action, Entity, Institution, isYapiError, Role, User, YapiError} from "@YenzaCT/sdk";
import InstitutionDetails from "@/pages/InstitutionDetailPage/components/InstitutionDetails.vue";
import InstitutionCurricula from "@/pages/InstitutionDetailPage/components/InstitutionCurricula.vue";
import DeleteInstitution from "@/pages/InstitutionDetailPage/components/DeleteInstitution.vue";
import EditInstitution from "@/pages/InstitutionDetailPage/components/EditInstitution.vue";
import {useGlobalStore} from "@/store";
import EditInstitutionCurriculum from "@/pages/InstitutionDetailPage/components/EditInstitutionCurriculum.vue";
import ManagersTable from "@/components/ManagersTable.vue";
import D6Details from "@/pages/InstitutionDetailPage/components/D6Details.vue";
import D6Connection from "@/pages/InstitutionDetailPage/components/D6Connection.vue";
import JoinRequest from "@/pages/InstitutionDetailPage/components/JoinRequest.vue";
import CreateTenantInstitutionCohort from "@/components/CreateCards/CreateTenantInstitutionCohortCard.vue";

const store = useGlobalStore();
const route = useRoute();
const router = useRouter();

const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const institution = ref<Institution>();
const managers = ref<User[]>([]);
const tenantTitle = ref<string>();
const showDeleteInstitution = ref(false);
const showEditInstitution = ref(false);
const showEditCurriculum = ref(false);
const showCreateDialog = ref(false);
const id = route.params.id as string;

enum Section {
  Details = "details",
  D6 = "d6",
  JoinRequests = "joinRequests"
}

const isActive = (section: Section) => {
  if (!route.params.section) {
    return section === Section.Details;
  }
  return route.params.section === section;
};

async function changeSection(section: Section) {
  await router.push({ name: "institutionDetail", params: { id: id, section: section } });
}

async function fetchInstitution() {
  try {
    store.networkBusy = true;
    institution.value = (await yapi.admin.institution.get(id)).data;
    managers.value = (await yapi.admin.managers.list(Entity.Institution, id)).data;
    if (institution.value && typeof institution.value.tenant !== "string") {
      tenantTitle.value = institution.value.tenant.title;
    } else if (typeof institution.value.tenant === "string") {
      tenantTitle.value = institution.value.tenant;
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

onMounted(async () => {
  await fetchInstitution();
});

watch(() => route.params.id, async (newId, oldId) => {
  if (newId !== oldId) {
    await fetchInstitution();
  }
});

async function onEditCurriculumDialog() {
  showEditCurriculum.value = false;
  await fetchInstitution();
}

async function onEditDialog() {
  showEditInstitution.value = false;
  await fetchInstitution();
}

async function connectD6(schoolId: string) {
  try {
    await yapi.admin.institution.d6.connect(id, schoolId);
    await fetchInstitution();
    store.showSnackbar("School connected");
  } catch (e: unknown) {
    const err = e as YapiError;
    if (err.response) {
      const res = err.response;
      if (res.status === 403) {
        store.showSnackbar("You do not have permission to connect to this school.");
      } else {
        store.showSnackbar("Something went wrong connecting to this school.");
      }
    }
  }
}

async function disconnectD6() {
  await yapi.admin.institution.d6.disconnect(id);
  await fetchInstitution();
}
</script>
