<script setup lang="ts">

import {Bar} from "vue-chartjs";
import {useI18n} from "vue-i18n";
import {ArcElement, BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip, ChartOptions} from "chart.js";
import {computed, PropType} from "vue";
import {
  IPersonalityTraitsDistributionReportData,
  personalityTypeDistributionValues
} from "@/pages/ReportsDetailPage/reportTypes";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

const {t} = useI18n();

const props = defineProps(
  {
    reportData: {
      type: Object as PropType<IPersonalityTraitsDistributionReportData>,
      required: true,
    },
  }
);

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
  }[];
}

const createTraitChart = (traitKey: keyof IPersonalityTraitsDistributionReportData, labelKey: string) => computed(() => {
  const label = t(labelKey);
  if (!props.reportData) return {
    label,
    datasets: []
  };
  return createChartData(props.reportData[traitKey][0], label);
});

const agreeablenessChart = createTraitChart("agreeableness", "components.PersonalityTraitsDistributionGraph.agreeableness");
const conscientiousnessChart = createTraitChart("conscientiousness", "components.PersonalityTraitsDistributionGraph.conscientiousness");
const openMindednessChart = createTraitChart("open_mindedness", "components.PersonalityTraitsDistributionGraph.open_mindedness");
const extraversionChart = createTraitChart("extraversion", "components.PersonalityTraitsDistributionGraph.extraversion");
const negativeEmotionalityChart = createTraitChart("negative_emotionality", "components.PersonalityTraitsDistributionGraph.negative_emotionality");

/**
 * Creates the data for the pie chart.
 * @param personalityTraitCounts The subject counts to create the chart data from.
 * @param label The label for the chart.
 */
const createChartData = (personalityTraitCounts: personalityTypeDistributionValues, label: string): ChartData => {
  if (!personalityTraitCounts) return {
    labels: [],
    datasets: []
  };

  const order = ["high", "medium", "low"];
  const sortedKeys = order.filter((key) => key in personalityTraitCounts);
  const readableKeys = sortedKeys.map((key) => t(`common.${key}`));

  return {
    labels: readableKeys,
    datasets: [
      {
        label,
        data: sortedKeys.map((key) => personalityTraitCounts[key]),
        backgroundColor: [
          "#003f5c",
          "#444e86",
          "#955196",
          "#dd5182",
          "#ff6e54",
          "#ffa600",
        ]
      },
    ],
  };
};

const chartOptions: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom"
    }
  }
};

</script>

<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="12" md="4">
        <v-card height="100%" variant="tonal">
          <v-card-title>
            {{ $t("components.PersonalityTraitsDistributionGraph.agreeableness") }}
          </v-card-title>
          <v-divider />
          <v-card-text class="text-center">
            <Bar v-if="reportData.agreeableness?.length > 0" :options="chartOptions" :data="agreeablenessChart"/>
            <span v-else>{{ $t("common.no_data") }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card height="100%" variant="tonal">
          <v-card-title>
            {{ $t("components.PersonalityTraitsDistributionGraph.conscientiousness") }}
          </v-card-title>
          <v-divider />
          <v-card-text class="text-center">
            <Bar v-if="reportData.conscientiousness?.length > 0" :options="chartOptions" :data="conscientiousnessChart"/>
            <span v-else>{{ $t("common.no_data") }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="4">
        <v-card height="100%" variant="tonal">
          <v-card-title>
            {{ $t("components.PersonalityTraitsDistributionGraph.open_mindedness") }}
          </v-card-title>
          <v-divider />
          <v-card-text class="text-center">
            <Bar v-if="reportData.open_mindedness?.length > 0" :options="chartOptions" :data="openMindednessChart"/>
            <span v-else>{{ $t("common.no_data") }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card height="100%" variant="tonal">
          <v-card-title>
            {{ $t("components.PersonalityTraitsDistributionGraph.extraversion") }}
          </v-card-title>
          <v-divider />
          <v-card-text class="text-center">
            <Bar v-if="reportData.extraversion?.length > 0" :options="chartOptions" :data="extraversionChart"/>
            <span v-else>{{ $t("common.no_data") }}</span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card height="100%" variant="tonal">
          <v-card-title>
            {{ $t("components.PersonalityTraitsDistributionGraph.negative_emotionality") }}
          </v-card-title>
          <v-divider />
          <v-card-text class="text-center">
            <Bar v-if="reportData.negative_emotionality?.length > 0" :options="chartOptions" :data="negativeEmotionalityChart"/>
            <span v-else>{{ $t("common.no_data") }}</span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

  </v-container>
</template>

<style scoped>

</style>
