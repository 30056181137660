<template>
  <v-text-field
    :append-inner-icon="showPassword ? 'fa-solid fa-eye' : 'fa-solid fa-eye-slash'"
    :label="$t('components.LoginForm.password')"
    :type="showPassword ? 'text' : 'password'"
    :rules="passwordRules"
    v-model="password"
    required
    data-pw="usernamePassword"
    variant="outlined"
    density="compact"
    @input="validatePassword"
    @click:append-inner="showPassword = !showPassword"
  />
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {useI18n} from "vue-i18n";

const {t} = useI18n();
const password = ref("");
const showPassword = ref(false);

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "validate", value: boolean): void;
}>();

const passwordRules = [
  (v: string) => (!!v && v.length >= 6) || t("components.LoginForm.password_required_least_6"),
  (v: string) => /[A-Z]/.test(v) || t("components.LoginForm.password_capital_letter_required"),
  (v: string) => /[a-z]/.test(v) || t("components.LoginForm.password_lowercase_letter_required"),
  (v: string) => /\d/.test(v) || t("components.LoginForm.password_one_number_required"),
];

const isPasswordValid = computed(() => passwordRules.every((rule) => rule(password.value)));

function validatePassword() {
  emit("update:modelValue", password.value);
  emit("validate", isPasswordValid.value);
}
</script>

<style scoped>
</style>
