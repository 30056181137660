<template>
  <div class="d-flex flex-row justify-space-between align-items-center">
    <v-btn
      v-if="store.user?.twoFactorEnabled"
      disabled prepend-icon="fa fa-lock-open"
      @click="dialogOpen=true"
    >
      {{ $t("buttons.disable") }}
    </v-btn>
    <v-btn
      v-else
      color="green"
      prepend-icon="fa fa-lock"
      @click="dialogOpen=true"
    >
      {{ $t("components.TwoFactorAuth.enable_2fa") }}
    </v-btn>
  </div>

  <v-dialog
    v-model="dialogOpen"
  >
    <v-container class="d-flex justify-center">
      <v-card>
        <v-card-title>
          {{ $t("components.TwoFactorAuth.title") }}
        </v-card-title>

        <v-card-text>
          <p>{{ $t("components.TwoFactorAuth.step_1") }}</p>
          <div class="d-flex justify-center ma-2 rounded">
            <canvas id="twoFactorQR" class="rounded-xl"/>
          </div>
          <v-divider/>
          <p class="mt-2">{{ $t("components.TwoFactorAuth.step_2") }}</p>
          <v-otp-input :autofocus="true" v-model="code" />
        </v-card-text>

        <v-card-actions>
          <v-spacer/>
          <v-btn color="secondary" @click="dialogOpen=false">
            {{ $t("buttons.cancel") }}
          </v-btn>
          <v-btn color="primary" @click="enable"
                 :disabled="code.length !== 6">
            {{ $t("buttons.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-container>
  </v-dialog>
</template>

<script setup lang="ts">
import QRCode from "qrcode";
import {ref, watch} from "vue";
import {FrontendUser, isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";

const store = useGlobalStore();
const dialogOpen = ref(false);
const secret = ref("");
const code = ref("");

watch(dialogOpen, (val) => {
  if (val) {
    code.value = "";
    fetchEnableCode();
  }
});

async function fetchEnableCode() {
  try {
    store.networkBusy = true;
    const res = await yapi.auth.twoFactorBeginEnable();
    secret.value = res.data.secret.otpauth_url;
    const canvas = document.getElementById("twoFactorQR");
    QRCode.toCanvas(canvas, res.data.secret.otpauth_url, {width: 256}, function(error: Error | null | undefined) {
      if (error) {
        alert(error);
      }
    });
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

async function enable() {
  try {
    store.networkBusy = true;
    await yapi.auth.twoFactorConfirmEnable(code.value);
    (store.user as FrontendUser).twoFactorEnabled = true;
    dialogOpen.value = false;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}
</script>
