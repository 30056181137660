<template>
  <authenticated-layout>
    <template #headerIcon>
      fa-solid fa-user
    </template>
    <template #header>
      <user-name-display v-if="user" :user="user" />
    </template>
    <template #breadcrumbs>
      <user-detail-breadcrumbs v-if="user" :user="user"/>
    </template>

    <v-col cols="12" class="pl-8">
      <v-row class="ga-2" v-if="manageView === 'profile'">
        <v-btn-primary
          data-pw="profilePrimaryButton"
          @click="manageView = 'profile'"
        >
          {{ $t("buttons.profile")}}
        </v-btn-primary>
        <v-btn-secondary
          data-pw="manageSecondaryButton"
          @click="manageView = 'manage'"
        >
          {{ $t("buttons.manage") }}
        </v-btn-secondary>
      </v-row>
      <v-row class="ga-2" v-else>
        <v-btn-secondary
          data-pw="profileSecondaryButton"
          @click="manageView = 'profile'"
        >
          {{ $t("buttons.profile")}}
        </v-btn-secondary>
        <v-btn-primary
          data-pw="managePrimaryButton"
          @click="manageView = 'manage'"
        >
          {{ $t("buttons.manage") }}
        </v-btn-primary>
      </v-row>
    </v-col>

    <v-container
      v-if="user"
      fluid class="py-4"
    >
      <v-row v-if="manageView === 'profile'">
        <v-col cols="12">
          <v-row>
            <v-col v-if="user.profile.firstName" cols="12">
              <user-name-card
                :user="user"
                @downloadCV="showDownloadCVDialog = true"
                @downloadSubjectChoice="showDownloadSubjectChoiceReportDialog = true"
                @downloadAssessment="showDownloadAssessmentReportDialog = true"
                @updateUser="handleUpdateUser"
              />
            </v-col>
            <v-col cols="12">
              <profile-card
                :account-type="$t(`accountTypes.${user.app.accountType}`)"
                :role="$t(`roles.${user.app.role}`)"
                :user="user"
              />
            </v-col>
            <v-col cols="12">
              <progress-card
                :assessments="user.assessments"
                :account-type="user.app.accountType"
                :country="user.country"
                :profile="user.profile"
                :research="user.app.researchAssessments ?? []"/>
            </v-col>
          </v-row>
          <v-row >
            <v-col cols="12">
              <assessment-card
                :user="user"
                :account-type="user.app.accountType"
                :research="user.app.researchAssessments" />
            </v-col>
          </v-row>
          <v-row >
            <v-col cols="12">
              <choices-detail-card :user="user.profile"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <manage-user :user="user" @updateUser="handleUpdateUser" v-else/>
      <v-dialog v-model="showDownloadSubjectChoiceReportDialog" persistent max-width="500">
        <download-subject-choice-dialog
          :user="user"
          @close="showDownloadSubjectChoiceReportDialog = false"
        />
      </v-dialog>
      <v-dialog v-model="showDownloadAssessmentReportDialog" persistent max-width="500">
        <download-assessment-dialog
          :user="user"
          @close="showDownloadAssessmentReportDialog = false"
        />
      </v-dialog>
      <v-dialog v-model="showDownloadCVDialog" persistent max-width="500">
        <download-cv-dialog
          :user="user"
          @close="showDownloadCVDialog = false"
        />
      </v-dialog>
    </v-container>

  </authenticated-layout>
</template>

<script setup lang="ts">
import {onMounted, ref, watch} from "vue";
import {useRoute} from "vue-router";
import {
  isYapiError,
  DashUser,
  YapiError
} from "@YenzaCT/sdk";
import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import ProfileCard from "@/pages/UserDetailPage/components/profile/ProfileCard.vue";
import ProgressCard from "@/pages/UserDetailPage/components/profile/ProgressCard/ProgressCard.vue";
import ChoicesDetailCard from "@/pages/UserDetailPage/components/profile/ChoicesDetailCard.vue";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";
import DownloadSubjectChoiceDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/DownloadSubjectChoiceCard.vue";
import DownloadAssessmentDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/DownloadAssessmentCard.vue";
import DownloadCvDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/DownloadCvCard.vue";
import UserNameCard from "@/pages/UserDetailPage/components/profile/UserNameCard.vue";
import AssessmentCard from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/AssessmentReportsCard.vue";
import ManageUser from "@/pages/UserDetailPage/components/manage/ManageUserSection.vue";
import UserDetailBreadcrumbs from "@/pages/UserDetailPage/components/common/UserDetailBreadcrumbs.vue";
import UserNameDisplay from "@/pages/UserDetailPage/components/common/UserNameDisplay.vue";

const store = useGlobalStore();
const route = useRoute();
const showDownloadSubjectChoiceReportDialog = ref(false);
const showDownloadAssessmentReportDialog = ref(false);
const showDownloadCVDialog = ref(false);
const user = ref<DashUser>();
const manageView = ref("profile");

async function fetchUser() {
  try {
    store.networkBusy = true;
    user.value = (await yapi.admin.user.get(route.params.id as string)).data;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

function handleUpdateUser(success: boolean) {
  if (success) {
    fetchUser();
  }
}

onMounted(async () => {
  await fetchUser();
});

watch(() => route.params.id, async (newId, oldId) => {
  if (newId !== oldId) {
    await fetchUser();
  }
});

</script>
