<template>
  <v-card
    class="data-table-toolbar px-4" elevation="0">
    <v-card-text>
      {{ $t("components.ReportsDetailPage.messages.no_data") }}
    </v-card-text>
  </v-card>
</template>
<script setup lang="ts">
</script>
