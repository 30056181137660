import common from "@/locales/en_ZA/common";
import curriculums from "@/locales/en_ZA/curriculums";
import countries from "@/locales/en_ZA/countries";
import translations from "@/locales/en.json";
import _ from "lodash";

export default _.merge(
  {
    common,
    countries,
    curriculums,
  },
  translations
);
