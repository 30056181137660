<template>
  <vue-tel-input
    :preferred-countries="['za', 'et', 'ae', 'ke']"
    validate-on-blur
    :valid-characters-only="true"
    outlined
    :dropdownOptions="{ showFlags: true, showDialCodeInList: true }"
    @on-input="handlePhoneInput"
  >
  </vue-tel-input>
</template>

<script setup lang="ts">
import {ref} from "vue";
import {VueTelInput} from "vue-tel-input";

const contact = ref("");
const isPhoneValid = ref(false);

const emit = defineEmits<{
  (e: "update:modelValue", value: string): void;
  (e: "validate", value: boolean): void;
}>();

type vueTelPhoneInput = {
  "country": string;
  "countryCode": string;
  "formatted": string;
  "valid": boolean;
  "possible": boolean;
  "nationalNumber": string;
  "countryCallingCode": string;
  "number": string;
};

function handlePhoneInput(number: string, phoneObject: vueTelPhoneInput) {
  contact.value = phoneObject.number;
  isPhoneValid.value = phoneObject.valid;
  emit("update:modelValue", contact.value);
  emit("validate", isPhoneValid.value);
}
</script>
<style scoped>
:global(.vue-tel-input input) {
  padding: 9px 0;
}
</style>
