<template>
  <v-card data-pw="downloadAssessmentDialogCard">
    <v-card-title>
      {{ $t('components.DownloadAssessmentDialog.title') }}
    </v-card-title>

    <v-card-text>
      <span v-if="!downloading">
        {{ $t("components.DownloadAssessmentDialog.download_instruction") }}
      </span>
      <div v-else>
        {{ $t("components.DownloadAssessmentDialog.report_downloading") }}
        <v-progress-linear
          indeterminate
          color="primary" />
      </div>
    </v-card-text>

    <v-card-actions>
      <v-spacer/>
      <v-btn
        data-pw="cancelDownloadAssessmentReportButton"
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="downloadReport"
        data-pw="confirmDownloadAssessmentReportButton"
        :disabled="downloading"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import yapi from "@/lib/yapi";
import download from "@/lib/documentDownload";
import {DashUser, isYapiError, YapiError} from "@YenzaCT/sdk";
import {PropType, ref, Ref} from "vue";
import {useGlobalStore} from "@/store";

const store = useGlobalStore();

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});

const user: Ref<DashUser> = ref({...props.user} as DashUser);

const downloading = ref(false);

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}
>();

async function downloadReport() {
  downloading.value = true;
  try {
    const res = await yapi.admin.user.getDocument(
      user.value?._id as string,
      "assessment-report",
    );
    download(res.data, "Assessment Report");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    downloading.value = false;
    emit("close");
  }
}
</script>
