<template>
  <v-card flat color="grey-lighten-4">
    <v-card-title>
      Vuetify Theme Colors
    </v-card-title>
    <v-divider />
    <v-card-text>
      <h4>{{ $t("components.ThemeEditor.light") }}</h4>
      <colour-selector
        v-for="key in keys"
        :label="$t('components.ThemeEditor.' + key)"
        :key="key"
        v-model="colours[key]"
        mode="hex"
      />
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        color="secondary"
        :disabled="!changed"
        @click="reset"
      >
        {{ $t("buttons.reset") }}
      </v-btn>
      <v-btn
        color="primary"
        :disabled="!changed"
        @click="confirmVisible = true"
      >
        {{ $t("buttons.apply") }}
      </v-btn>
    </v-card-actions>
  </v-card>
  <confirm-dialog
    v-model="confirmVisible"
    @confirm="save"
    :title="$t('components.ThemeEditor.confirm_title')"
    :text="$t('components.ThemeEditor.confirm_text')"
  />
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {Json} from "@/lib/appConfiguration";
import {useGlobalStore} from "@/store";
import {useTheme} from "vuetify";
import ColourSelector from "@/pages/AppConfigurationPage/ThemeEditor/ColourSelector.vue";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

const store = useGlobalStore();
const theme = useTheme();

const confirmVisible = ref(false);
const {colors} = theme.themes.value.light;

const keys = [
  "primary",
  "secondary",
  "background",
  "surface",
  "error",
  "success",
  "info",
  "warning",
  "app-header-bar",
  "app-header-bar-text",
  "app-header-bar-link",
  "gradient-start",
  "gradient-end",
  "bar-gradient-start",
  "bar-gradient-end"
];

const colours = ref({...colors});

const changed = computed(() => {
  for (const key of keys) {
    if (colours.value[key] !== colors[key]) {
      return true;
    }
  }
  return false;
});

function reset() {
  colours.value = {...colors};
}

async function save(): Promise<void> {
  confirmVisible.value = false;
  theme.themes.value.light.colors = {...colors, ...colours.value};
  await store.saveAppConfiguration("themeLight", theme.themes.value.light as unknown as Json);
  store.showSnackbar("Theme saved");
}
</script>
