<script setup lang="ts">
import {AxiosResponse} from "axios";
import yapi from "@/lib/yapi";
import {Feature, isYapiError, YapiError} from "@YenzaCT/sdk";
import {ref} from "vue";
import {useGlobalStore} from "@/store";
import {IqReport } from "@YenzaCT/web-components";
const store = useGlobalStore();

const loading = ref(false);
const dataLoaded = ref(false);
const report = ref();

const props = defineProps({
  userId: {
    type: String,
    required: true
  }
});

async function fetchReport() {
  loading.value = true;
  try {
    const res: AxiosResponse = await yapi.admin.user.getAssessmentReport(props.userId, Feature.CognitiveAbilityReport);
    report.value = res.data;
    dataLoaded.value = true;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
}

fetchReport();
</script>

<template>
  <div v-if="report">
    <iq-report .componentData="report" debug/>
  </div>
</template>

<style scoped>

</style>
