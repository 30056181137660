<template>
  <v-container fluid class="pa-0">
    <v-row>
      <v-col cols="4" v-for="(chart, index) in chartData" :key="`${index}-chart`">
        <v-card variant="tonal">
          <v-card-title>
            {{ $t(`curriculums.${curriculum}.subject_groups.${index}`) }}
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-center">
            <Pie v-if="chart.datasets?.length > 0" :options="chartOptions" :data="chart"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card width="100%" variant="tonal">
          <v-card-title>
            {{ $t("components.SubjectChoiceDistributionReport.elective_choices") }}
          </v-card-title>
          <v-divider/>
          <v-card-text class="text-center">
            <Bar v-if="mergedElectivesChartData.datasets?.length > 0" :data="mergedElectivesChartData"
                 :options="{ indexAxis: 'y' }"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script setup lang="ts">

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  Legend,
  LinearScale,
  Title,
  Tooltip
} from "chart.js";
import {Bar, Pie} from "vue-chartjs";
import {computed} from "vue";
import {useI18n} from "vue-i18n";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string[];
  }[];
}

interface ReportDataValue {
  [key: string]: Record<string, number>;
}

type CurriculumGroupData = {
  [key: string]: ChartData;
};

type ChartKeys = {
  [key: string]: string[];
};

const {t} = useI18n();

const props = defineProps(
  {
    curriculum: {
      type: String,
      required: true,
    },
    reportData: {
      type: Object,
      required: true,
    },
  }
);

const chartData = computed(() => {
  const data: CurriculumGroupData = {};

  if (!chartKeyArray[props.curriculum])
    return {};

  for (const key of chartKeyArray[props.curriculum]) {
    data[key] = createPieChartData(props.reportData[key], t(`curriculums.${props.curriculum}.subject_groups.${key}`));
  }
  return data;
});

/**
 * Creates the pie chart data for a given subject count
 * @param subjectCounts The subject counts
 * @param label The label for the chart
 */
function createPieChartData(subjectCounts: unknown, label: string): ChartData {
  if (subjectCounts)
    return {
      labels: Object.keys(subjectCounts)
        .map((subjectLabel) => t(`curriculums.${props.curriculum}.subjects.${subjectLabel}`)),
      datasets: [
        {
          label,
          data: Object.values(subjectCounts),
          backgroundColor: colors,
        },
      ],
    };

  return {
    labels: [],
    datasets: [
      {
        label,
        data: [],
        backgroundColor: colors,
      },
    ],
  };
}

const colors = [
  "#003f5c",
  "#444e86",
  "#955196",
  "#dd5182",
  "#ff6e54",
  "#ffa600",
];

const chartKeyArray: ChartKeys = {
  "national_senior_certificate": ["first_language", "second_language", "mathematics"],
  "cambridge": ["group_1", "group_2", "group_3", "group_4", "group_5", "ungrouped"],
  "international_baccalaureate": ["group_1", "group_2", "group_3", "group_4", "group_5", "group_6"],
  "pre_international_baccalaureate": ["group_1", "group_2", "group_3", "group_4", "group_5", "group_6"],
  "kenyan_certificate_of_secondary_education": ["science_1", "science_2", "humanities"],
};

const chartOptions: ChartOptions = {
  responsive: true,
  plugins: {
    legend: {
      position: "bottom"
    }
  }
};

/**
 * Merges all the electives chosen into a single data set and distribution table
 */
const mergedElectivesChartData = computed(() => {
  if (!props.reportData || !props.curriculum) return {label: "Electives", datasets: []};

  const electiveKeys: Record<string, string[]> = {
    "national_senior_certificate": ["elective_1", "elective_2", "elective_3", "elective_4", "elective_5"],
    "cambridge": ["group_3", "group_4", "group_5", "ungrouped"],
    "international_baccalaureate": ["group_3", "group_4", "group_6"],
    "pre_international_baccalaureate": ["group_3", "group_4", "group_6"],
    "kenyan_certificate_of_secondary_education": ["elective_1", "elective_2"],
  };

  // Assume reportDataValue is of type ReportDataValue.
  const reportDataObject: ReportDataValue = props.reportData as ReportDataValue;

  const mergedElectives: Record<string, number> = {};

  // 1. Merge the electives
  for (const elective in reportDataObject) {
    // Only merge the electives
    if (!electiveKeys[props.curriculum].includes(elective))
      continue;

    const electiveObject = reportDataObject[elective];
    // Merge together the electives
    for (const subject in electiveObject) {
      if (mergedElectives[subject] !== undefined) {
        mergedElectives[subject] += electiveObject[subject];
      } else {
        mergedElectives[subject] = electiveObject[subject];
      }
    }
  }

  // 2. Sort them in descending order
  const sortedEntries = Object.entries(mergedElectives).sort((a, b) => b[1] - a[1]);
  const sortedObject = Object.fromEntries(sortedEntries);

  const labels = Object.keys(sortedObject)
    .map((label) => t(`curriculums.${props.curriculum}.subjects.${label}`));

  const dataValues = Object.values(sortedObject);

  const data: ChartData = {
    labels,
    datasets: [
      {
        label: "Electives",
        backgroundColor: colors,
        data: dataValues,
      },
    ],
  };

  return data;
});

</script>
<style scoped>

</style>
