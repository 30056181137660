<!-- CouponFormFields.vue -->
<template>
  <div>
    <v-text-field
      :modelValue="title"
      :rules="titleRules"
      :label="$t('coupon.title')"
      variant="outlined"
      required
      data-pw="couponTitle"
      @update:modelValue="$emit('update:title', $event)"
    ></v-text-field>
    <v-text-field
      :modelValue="code"
      :rules="codeRules"
      variant="outlined"
      :label="$t('coupon.code')"
      data-pw="couponCode"
      @update:modelValue="$emit('update:code', $event)"
      required
    ></v-text-field>
    <v-text-field
      :modelValue="expiresAt"
      :rules="expiresAtRules"
      :label="$t('coupon.expires_at')"
      variant="outlined"
      type="date"
      data-pw="couponExpiresAt"
      @update:modelValue="$emit('update:expiresAt', $event)"
    />
    <v-text-field
      :modelValue="maxUses"
      :rules="maxUsesRules"
      :label="$t('coupon.max_uses')"
      variant="outlined"
      type="number"
      data-pw="couponMaxUses"
      @update:modelValue="$emit('update:maxUses', $event)"
    />
    <v-select
      :modalValue="products"
      variant="outlined"
      :label="$t('coupon.products')"
      :items="productOptions"
      data-pw="couponProducts"
      @update:modelValue="$emit('update:products', $event)"
      multiple
      chips
      deletable-chips
      small-chips
      required
    />
  </div>
</template>

<script setup lang="ts">
import {ProductType} from "@YenzaCT/sdk";
import {useI18n} from "vue-i18n";
import {VSelectOption} from "@/lib/vuetify";

const {t} = useI18n();

defineProps({
  title: {
    type: String,
    required: true,
    default: ""
  },
  code: {
    type: String,
    required: true,
    default: ""
  },
  expiresAt: {
    required: true,
    default: ""
  },
  maxUses: {
    type: Number,
    required: true,
    default: 0
  },
  products: {
    type: Array,
    required: true,
  },
});

const titleRules = [(v: string | undefined) => !!v || t("coupon.title_required")];
const codeRules = [(v: string | undefined) => !!v || t("coupon.code_required")];
const maxUsesRules = [(v: number) => v >= 0 || t("coupon.max_uses_invalid")];
const expiresAtRules = [(v: string | undefined) => !!v || t("coupon.expires_at_required")];

const productOptions: VSelectOption[] = Object.values(ProductType)
  .map((product) => ({
    title: t(`products.${product}`),
    value: product,
  }));
</script>
