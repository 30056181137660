<script setup lang="ts">

import {PropType, ref, Ref} from "vue";
import yapi from "@/lib/yapi";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import PersonalityTraitDistributionGraphs
  from "@/pages/ReportsDetailPage/components/PersonalityTraitDistributionGraphs.vue";
import {IPersonalityTraitsDistributionReportData} from "@/pages/ReportsDetailPage/reportTypes";

const store = useGlobalStore();
const loading = ref(false);
const graphData: Ref<IPersonalityTraitsDistributionReportData> = ref({
  agreeableness: [],
  extraversion: [],
  open_mindedness: [],
  conscientiousness: [],
  negative_emotionality: []
});

const props = defineProps({
  tenantId: {
    type: String,
    required: false,
    default: null
  },
  cohortId: {
    type: String,
    required: false,
    default: null
  },
  institutionIds: {
    type: Array as PropType<string[]>,
    required: false,
    default: null
  },
  timeFilter: {
    type: Object as PropType<{ dateFilterType: string, unit: string, value: string | string[] }[]>,
    required: true,
    default: null
  }
});

async function fetchReportData() {
  try {
    store.networkBusy = true;
    loading.value = true;
    const res = await yapi.admin.reports.post("personality-traits-distribution", {
      institutionIds: props.institutionIds,
      tenantId: props.tenantId,
      time: props.timeFilter
    });
    if (res.data)
      graphData.value = res.data[0] as unknown as IPersonalityTraitsDistributionReportData;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
    store.networkBusy = false;
  }
}

fetchReportData();
</script>

<template>
  <h1>{{ $t("components.PersonalityTraitDistributionCard.title")}}</h1>
  <v-divider class="mb-3"/>
  <div v-if="loading">
    <v-row>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
  </div>
  <personality-trait-distribution-graphs v-if="!loading" :report-data="graphData"/>
</template>

<style scoped>

</style>
