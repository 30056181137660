export function downloadCSV(data: BlobPart, filename: string): void {
  downloadFile(data, filename, "text/csv;charset=utf-8");
}
export function downloadExcel(data: string, filename: string) {
  const binaryData = base64ToBinary(data);
  downloadFile(binaryData, filename, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;");
}

function downloadFile(data: BlobPart, filename: string, blobType: string) {
  const blob = new Blob([data], { type: blobType });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;

  a.download = filename;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
}
/**
 * Function to decode base64 to binary string
 * @param base64 - The base64 string to decode
 * @returns - The decoded binary string
 */
function base64ToBinary(base64: string): Uint8Array {
  const binaryString = atob(base64);
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
}
