<script setup lang="ts">

import SubjectDistributionGraphs from "@/pages/ReportsDetailPage/components/SubjectDistributionGraphs.vue";
import {PropType, ref, Ref} from "vue";
import yapi from "@/lib/yapi";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";

const store = useGlobalStore();
const loading = ref(false);
const graphData: Ref<Record<string, unknown>> = ref({});

const props = defineProps({
  tenantId: {
    type: String,
    required: false,
    default: null
  },
  cohortId: {
    type: String,
    required: false,
    default: null
  },
  institutionIds: {
    type: Array as PropType<string[]>,
    required: false,
    default: null
  },
  curriculum: {
    type: String,
    required: true,
  },
  timeFilter: {
    type: Object as PropType<{ dateFilterType: string, unit: string, value: string | string[] }[]>,
    required: true,
    default: null
  }
});

async function fetchReportData() {
  try {
    store.networkBusy = true;
    loading.value = true;
    const res = await yapi.admin.reports.post("subject-choice-distribution", {
      institutionIds: props.institutionIds,
      tenantId: props.tenantId,
      breakdownByCohort: false,
      curriculum: props.curriculum,
      time: props.timeFilter
    });
    if (res.data)
      graphData.value = res.data[0];
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
    store.networkBusy = false;
  }
}

fetchReportData();

</script>

<template>
  <h1>{{ $t("components.SubjectChoiceDistributionCard.title")}}</h1>
  <v-divider class="mb-3"/>
  <div v-if="loading">
    <v-row>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
  </div>
  <subject-distribution-graphs class="mb-6" v-if="!loading"
                               :report-data="graphData"
                               :curriculum="curriculum"/>
</template>

<style scoped>

</style>
