import {debounce} from "lodash";
import {onMounted, ref, Ref, defineProps, watch} from "vue";

export function userPaginatedAutocomplete<T>(fetchData: () => Promise<void>, items: Ref<T[]>) {
  const search = ref("");
  const page = ref<number>(1);
  const loading = ref<boolean>(false);
  const hasNextPage = ref<boolean>(false);

  onMounted(async () => {
    await fetchData();
  });

  async function resetDropdown() {
    items.value = [];
    page.value = 1;
    search.value = "";
    await fetchData();
  }

  async function endIntersect(isIntersecting: boolean) {
    if (isIntersecting && hasNextPage.value) {
      page.value++;
      await fetchData();
    }
  }

  const debouncedSearch = debounce(async (input: string) => {
    items.value = [];
    page.value = 1;
    search.value = input;
    await fetchData();
  }, 1000);

  watch(search, (val: string) => {
    if (val !== "" && val.length > 1) {
      debouncedSearch(val);
    }
  });

  return {
    search,
    page,
    loading,
    hasNextPage,
    defineProps,
    endIntersect,
    debouncedSearch,
    resetDropdown
  };
}
