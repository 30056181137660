<template>
  <MetricTile
    :loading="loading"
    :no-data="!metric || !metric.count"
    :options="{
      showAdditionalData: true
    }"
    icon="fa fa-ticket"
    data-pw="metricTile">
    <template #title>{{ $t("components.AccessCodesUsageRatioMetricTile.title") }}</template>
    <template #metric-title>{{ $t("components.AccessCodesUsageRatioMetricTile.metric_title") }}</template>
    <template #metric>{{ metric.count }} / {{ metric.total }}
    </template>
    <template #additionalMetric> {{ metric.ratio }}%</template>
    <template #info>{{ $t("components.AccessCodesUsageRatioMetricTile.info") }}</template>
  </MetricTile>
</template>

<script setup lang="ts">
import MetricTile from "@/components/Metrics/MetricTile.vue";
import {useMetrics} from "@/lib/metrics";

const {
  loading,
  metric
} = useMetrics("access-code-usage-ratio");

</script>

<style scoped>

</style>
