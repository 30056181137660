export function isValidEmail(text: string): boolean {
  return !!text.toLowerCase()
  // eslint-disable-next-line max-len
    .match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
}

export function isValidPhoneNumber(text: string): boolean {
  // eslint-disable-next-line no-useless-escape
  return !!text.match(/^(\+|00)[1-9][0-9 \-\(\)\.]{7,32}$/);
}
