<script setup lang="ts">

import ReportFilterOptions from "@/components/Reporting/ReportFilterOptions.vue";
import CurriculumSelectDropdown from "@/components/FormFields/CurriculumSelectDropdown.vue";
import {useInsights} from "@/pages/ReportsDetailPage/reportsDetailPage";
import {ref} from "vue";
import UsageSummaryCard from "@/pages/ReportsDetailPage/GroupDashboardReport/UsageSummaryCard.vue";
import SubjectChoiceDistributionCard
  from "@/pages/ReportsDetailPage/GroupDashboardReport/SubjectChoiceDistributionCard.vue";
import WorkerTypeDistributionCard from "@/pages/ReportsDetailPage/GroupDashboardReport/WorkerTypeDistributionCard.vue";
import PersonalityTraitDistributionCard
  from "@/pages/ReportsDetailPage/GroupDashboardReport/PersonalityTraitDistributionCard.vue";
import UserSubjectChoicesDetailCard
  from "@/pages/ReportsDetailPage/GroupDashboardReport/UserSubjectChoicesDetailCard.vue";
import UserWorkerTypesDetailCard from "@/pages/ReportsDetailPage/GroupDashboardReport/UserWorkerTypesDetailCard.vue";
import UserPersonalityTraitsDetailCard
  from "@/pages/ReportsDetailPage/GroupDashboardReport/UserPersonalityTraitsDetailCard.vue";

const tab = ref(1);
const reportActive = ref(false);

const {
  timeFilter,
  tenantId,
  cohortId,
  institutionIds,
  curriculum,
  onTenantChange,
  onCurriculumChange,
  onCohortChange,
  onInstitutionSelectionChange
} = useInsights();

/**
 * Fetches the Subject Choice Distribution report data from the API.
 */
async function runReport() {
  reportActive.value = true;
}

function resetReport() {
  tenantId.value = undefined;
  cohortId.value = undefined;
  institutionIds.value = [];
  curriculum.value = undefined;
  reportActive.value = false;
  tab.value = 1;
}

</script>

<template>
  <report-filter-options
    :show-tenant-filter="true"
    :show-institution-filter="true"
    :show-cohort-filter="true"
    :show-cohort-breakdown="false"
    :is-report-active="reportActive"
    :disable-run-report-button="!tenantId && !institutionIds.length"
    @change-tenant="onTenantChange"
    @change-institution="onInstitutionSelectionChange"
    @change-cohort="onCohortChange"
    @click-run-report="runReport"
    @click-reset="resetReport"
    @click-filters="reportActive = false"
  >
    <template #filters>
      <v-col>
        <CurriculumSelectDropdown v-model="curriculum" @change="onCurriculumChange"/>
      </v-col>
    </template>

    <template #filterList>
      <v-list-item v-if="curriculum">
        <span class="font-weight-bold">{{  $t('common.curriculum') }}: </span> {{ $t(`curriculums.${curriculum}.title`) }}
      </v-list-item>
    </template>
  </report-filter-options>

  <v-container v-if="reportActive" fluid>
    <v-tabs
      v-model="tab"
      color="deep-purple-accent-4 mb-4"
      align-tabs="center"
    >
      <v-tab :value="1">{{ $t("components.GroupDashboardReport.overview")}}</v-tab>
      <v-tab v-if="curriculum" :value="2">{{ $t("components.GroupDashboardReport.subject_choices")}}</v-tab>
      <v-tab :value="3">{{ $t("components.GroupDashboardReport.worker_types")}}</v-tab>
      <v-tab :value="4">{{ $t("components.GroupDashboardReport.personality_types")}}</v-tab>
    </v-tabs>

    <v-tabs-window v-model="tab">
      <v-tabs-window-item :value="1">
        <v-row class="mt-6">
          <v-col cols="4">
            <usage-summary-card :time-filter="timeFilter" :tenant-id="tenantId" :institution-ids="institutionIds" :cohort-id="cohortId"/>
          </v-col>
          <v-col cols="8">

            <subject-choice-distribution-card
              v-if="curriculum"
              :time-filter="timeFilter"
              :tenant-id="tenantId"
              :cohort-id="cohortId"
              :institution-ids="institutionIds"
              :curriculum="curriculum"/>

            <worker-type-distribution-card
              ref="workerTypeDistributionCard"
              :time-filter="timeFilter"
              :tenant-id="tenantId"
              :cohort-id="cohortId"
              :institution-ids="institutionIds"/>

            <personality-trait-distribution-card
              :time-filter="timeFilter"
              :tenant-id="tenantId"
              :cohort-id="cohortId"
              :institution-ids="institutionIds"/>
          </v-col>
        </v-row>
      </v-tabs-window-item>
      <v-tabs-window-item v-if="curriculum" :value="2">
        <user-subject-choices-detail-card
          :time-filter="timeFilter"
          :curriculum="curriculum"
          :tenant-id="tenantId"
          :cohort-id="cohortId"
          :institution-ids="institutionIds"/>
      </v-tabs-window-item>
      <v-tabs-window-item :value="3">
        <user-worker-types-detail-card
          :time-filter="timeFilter"
          :tenant-id="tenantId"
          :cohort-id="cohortId"
          :institution-ids="institutionIds"/>
      </v-tabs-window-item>
      <v-tabs-window-item :value="4">
        <user-personality-traits-detail-card
          :time-filter="timeFilter"
          :tenant-id="tenantId"
          :cohort-id="cohortId"
          :institution-ids="institutionIds"
        />
      </v-tabs-window-item>
    </v-tabs-window>

  </v-container>

</template>

<style scoped>

</style>
