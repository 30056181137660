<template>
  <v-card-account-settings
    :title="$t('components.ChangePhoneDialog.title')"
    data-pw="changePhoneDialogCard"
  >
    <v-card-text>
      <p class="pb-4">
        {{ $t('components.ChangePhoneDialog.international_phone_format') }}
      </p>
      <InternationalPhoneNumberInput
        v-model="phoneNumber"
        class="mb-6 phone-input"
        @validate="handlePhoneValidate"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn-secondary
        data-pw="cancelChangePhoneNumberButton"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-primary
        @click="changePhoneNumber"
        :disabled="!isFormValid"
        data-pw="confirmChangePhoneNumberButton"
      >
        {{ $t("buttons.confirm") }}
      </v-btn-primary>
    </v-card-actions>
  </v-card-account-settings>
</template>

<script setup lang="ts">
import {PropType, ref, Ref} from "vue";
import {isYapiError, DashUser, YapiError, User} from "@YenzaCT/sdk";
import {useRoute} from "vue-router";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";
import InternationalPhoneNumberInput from "@/components/FormFields/InternationalPhoneNumberInput.vue";
const store = useGlobalStore();
const route = useRoute();
const phoneNumber = ref("");

defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});

const isFormValid = ref(false);

const userId: Ref<string> = ref("");

userId.value = route.params.id as string;

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
}
>();

const changePhoneNumber = async () => {
  try {
    await yapi.admin.user.edit(userId.value, { phone: phoneNumber.value } as User);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    emit("close");
  }
};

function handlePhoneValidate(isValid: boolean) {
  isFormValid.value = isValid;
}
</script>
