<template>
  <metric-skeleton-loader v-if="loading"/>
  <v-row class="d-flex" v-else>
    <div class="flex-grow-1">
      <v-col cols="12">
        <wide-trait-score-card
          v-if="valueScalesContent?.valueScales?.openness_to_change"
          :title="valueScalesContent.valueScales.openness_to_change.title"
          :result="Math.round(valueScalesContent.valueScales.openness_to_change.score)"
          color="green"
        />
        <v-divider/>

        <wide-trait-score-card
          v-if="valueScalesContent?.valueScales?.self_enhancement"
          :title="valueScalesContent.valueScales.self_enhancement.title"
          :result="Math.round(valueScalesContent.valueScales.self_enhancement.score)"
          color="orange"
        />
        <v-divider/>

        <wide-trait-score-card
          v-if="valueScalesContent?.valueScales?.self_transcendence"
          :title="valueScalesContent.valueScales.self_transcendence.title"
          :result="Math.round(valueScalesContent.valueScales.self_transcendence.score)"
          color="blue"
        />
        <v-divider/>
        <wide-trait-score-card
          v-if="valueScalesContent?.valueScales?.conservation"
          :title="valueScalesContent.valueScales.conservation.title"
          :result="Math.round(valueScalesContent.valueScales.conservation.score)"
          color="green"
        />
      </v-col>
    </div>
  </v-row>
  <!-- Overview -->
  <v-dialog cols="12" v-model="dialogOpenDetail" transition="dialog-bottom-transition" >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn dark @click="closeDetailedDialog">
          <v-icon>fa fa-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("assessments.value_scales.title") }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        Full breakdown coming soon.
      </v-card-text>
    </v-card>
  </v-dialog>

</template>

<script setup lang="ts">
import {ref, watch} from "vue";
import {Feature, isYapiError, YapiError} from "@YenzaCT/sdk";
import WideTraitScoreCard from "@/pages/UserDetailPage/components/common/WideTraitScoreCard.vue";
import {AxiosResponse} from "axios";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import MetricSkeletonLoader
  from "@/pages/UserDetailPage/components/profile/AssessmentReportsCard/MetricSkeletonLoader.vue";

// Define interface for the value scales data structure
interface ValueScale {
  title: string;
  score: number;
  dimensions: Record<string, unknown>;
  description: Array<Record<string, unknown>>;
}

interface ValueScalesContent {
  valueScales: {
    self_transcendence: ValueScale;
    self_enhancement: ValueScale;
    openness_to_change: ValueScale;
    conservation: ValueScale
  };
  labels: Record<string, string>;
}

const props = defineProps({
  userId: {
    type: String,
    required: true
  },
  dialogOpen: {
    type: Boolean,
    default: false,
    required: true
  },
});

const store = useGlobalStore();
const dialogOpenDetail = ref(props.dialogOpen);
const dataLoaded = ref(false);
const loading = ref(false);
const valueScalesContent = ref<ValueScalesContent>();

function closeDetailedDialog() {
  dialogOpenDetail.value = false;
}

watch(() => props.dialogOpen, (newVal) => {
  dialogOpenDetail.value = newVal;
});

async function fetchValueScalesReportContent() {
  loading.value = true;
  try {
    const res: AxiosResponse = await yapi.admin.user.getAssessmentReport(props.userId, Feature.ValueScalesReport);
    valueScalesContent.value = res.data;
    dataLoaded.value = true;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
}

fetchValueScalesReportContent();
</script>
