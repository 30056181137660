export function convertRGBAToObject(rgba: string): { r: number, g: number, b: number, a: number } {
  const rgbaArray = rgba.replace("rgba(", "").replace(")", "").split(",")
    .map((value) => parseInt(value));
  return {
    r: rgbaArray[0],
    g: rgbaArray[1],
    b: rgbaArray[2],
    a: rgbaArray[3]
  };
}

export function convertRGBAObjectToString(rgba: { r: number, g: number, b: number, a: number }): string {
  return `rgba(${rgba.r}, ${rgba.g}, ${rgba.b}, ${rgba.a})`;
}
