<template>
  <v-card class="mt-2 mb-2" v-if="user">
    <v-card-title>
      {{ $t("components.JoinRequests.requestTitle")}}
    </v-card-title>
    <v-card-text>
      <v-table>
        <tbody>
          <tr>
            <td>{{ $t("components.JoinRequests.email") }}</td>
            <td>{{ user.email }}</td>
          </tr>
          <tr>
            <td>{{ $t("components.JoinRequests.first_name") }}</td>
            <td>{{ user.profile.firstName }}</td>
          </tr>
          <tr>
            <td>{{ $t("components.JoinRequests.last_name") }}</td>
            <td>{{ user.profile.lastName }}</td>
          </tr>
          <tr>
            <td>{{ $t("components.JoinRequests.phone") }}</td>
            <td>{{ user.profile.phone }}</td>
          </tr>
        </tbody>
      </v-table>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        color="primary"
        variant="text"
        @click="showAcceptDialog = true"
      >
        {{ $t("buttons.accept")}}
      </v-btn>
      <v-btn
        color="error"
        variant="text"
        @click="showDenyDialog = true"
      >
        {{ $t("buttons.deny")}}
      </v-btn>
    </v-card-actions>
  </v-card>
  <v-card v-else>
    <v-card-text class="text-center">
      <v-progress-circular indeterminate/>
    </v-card-text>
  </v-card>
  <v-dialog v-model="showAcceptDialog" width="450">
    <v-card>
      <v-card-title>
        {{ $t("components.JoinRequests.accept_dialog_title")}}
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="selectedCohortId"
          :items="cohorts"
          item-text="name"
          item-value="_id"
          label="Select Cohort"/>
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="primary" @click="accept(userId)" :disabled="!selectedCohortId">
          {{ $t("buttons.accept")}}
        </v-btn>
        <v-btn @click="showAcceptDialog = false">
          {{ $t("buttons.cancel")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showDenyDialog" width="450">
    <v-card v-if="user">
      <v-card-title>
        {{ $t("components.JoinRequests.deny_dialog_title")}}
      </v-card-title>
      <v-card-text>
        {{ $t("components.JoinRequests.deny_dialog_message")}}
      </v-card-text>
      <v-card-actions class="justify-end">
        <v-btn color="primary" @click="deny(userId)">
          {{ $t("buttons.deny")}}
        </v-btn>
        <v-btn @click="showDenyDialog = false">
          {{ $t("buttons.cancel")}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import yapi from "@/lib/yapi";
import { DashUser, Cohort } from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";

const props = defineProps(["userId", "institutionId"]);
const emit = defineEmits(["removed"]);
const user = ref<DashUser|null>(null);
const cohorts = ref<Cohort[]>([]);
const showAcceptDialog = ref(false);
const showDenyDialog = ref(false);
const selectedCohortId = ref<string|null>(null);

const store = useGlobalStore();

onMounted(async () => {
  await Promise.all([fetchUser(), fetchCohorts()]);
});

async function fetchUser() {
  const res = await yapi.admin.user.get(props.userId);
  user.value = res.data;
}

async function fetchCohorts() {
  const res = await yapi.admin.cohort.paginate({
    filterInstitution: props.institutionId,
    size: 1000 // try to ensure all cohorts are fetched
  });
  cohorts.value = res.data.docs;
}

async function accept(userId: string) {
  if (selectedCohortId.value === null) {
    return;
  }

  await yapi.admin.institution.acceptJoinRequest(props.institutionId, userId, selectedCohortId.value);
  showAcceptDialog.value = false;
  store.showSnackbar("Join request approved");
  emit("removed");
}

async function deny(userId: string) {
  await yapi.admin.institution.denyJoinRequest(props.institutionId, userId);
  showDenyDialog.value = false;
  store.showSnackbar("Join request denied");
  emit("removed");
}
</script>
