<template>
  <div class="detail-item">
    <div v-if="label" class="label">{{ $t(label) }}</div>
    <div v-if="displayValue" class="value-container">
      <v-icon v-if="valueIcon" :icon="valueIcon" size="small" class="value-icon"></v-icon>
      <span class="value">{{ displayValue }}</span>
    </div>
    <v-chip v-else color="grey lighten-1" text-color="grey lighten-1">{{$t("components.UserDetailPage.user_detail_choices.none_selected")}}</v-chip>
  </div>
</template>

<script lang="ts" setup>
import {computed} from "vue";

const props = defineProps({
  label: String,
  value: String,
  valueIcon: {
    type: String,
    default: ""
  }
});

const displayValue = computed(() => props.value && props.value.trim() !== "" ? props.value : "");
</script>

<style scoped>
.detail-item {
  margin-bottom: 16px;
}

.label {
  font-size: 15px;
  font-weight: 700;
  color: #333;
  margin-bottom: 6px;
}

.value-container {
  display: inline-flex;
  align-items: center;
  background-color: #E8EAF6;
  border-radius: 20px;
  padding: 6px 12px;
}

.value-icon {
  margin-right: 6px;
  color: #3F51B5;
}

.value {
  font-size: 14px;
  color: #3F51B5;
  font-weight: 500;
}
</style>
