<script setup lang="ts">
import {PropType, ref} from "vue";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";
import {YapiError} from "@YenzaCT/sdk";
import ConfirmDialog from "@/components/ConfirmDialog.vue";

const store = useGlobalStore();
const props = defineProps({
  configurationKey: {
    type: String as PropType<("regular" | "light" | "medium" | "bold")>,
    required: true
  }
});

const editing = ref(false);
const file = ref();
const uploading = ref(false);
const confirmVisible = ref(false);

const acceptedTypes = [
  "application/x-font-ttf",
  "application/x-font-otf",
  "application/x-font-woff",
  "application/x-font-woff2",
  "application/font-woff",
  "application/font-woff2",
  "application/vnd.ms-fontobject",
  "font/ttf",
  "font/otf",
  "font/woff"
];

async function uploadFont() {
  confirmVisible.value = false;
  uploading.value = true;
  if (!file.value) {
    return;
  }
  try {
    const res = await yapi.appConfiguration.setFile("fonts", props.configurationKey, file.value);
    store.showSnackbar("Font uploaded");
    store.appConfiguration.fonts[props.configurationKey] = res.data.url;
  } catch (e) {
    await store.handleYapiError(e as YapiError);
  } finally {
    editing.value = false;
    uploading.value = false;
  }
}
</script>

<template>
  <v-row v-if="editing">
    <v-col>
      <v-file-input
        label="Font File"
        v-model="file"
        :accept="acceptedTypes"
        :disabled="uploading"
      />
      <v-progress-linear
        color="primary"
        v-if="uploading"
        indeterminate
      />
      <div class="text-end">
        <v-btn
          variant="text"
          color="secondary"
          :disabled="uploading"
          @click="editing = false"
        >
          {{ $t("buttons.cancel") }}
        </v-btn>
        <v-btn
          variant="text"
          color="primary"
          :disabled="uploading"
          @click="confirmVisible = true"
        >
          {{ $t("buttons.upload") }}
        </v-btn>
      </div>
    </v-col>
  </v-row>
  <v-row v-else>
    <v-col align-self="center"
           class="ma-1 pa-1">
      <div v-if="store.appConfiguration.fonts[configurationKey]">
        <a :href="store.appConfiguration.fonts[configurationKey]">
          {{ store.appConfiguration.fonts[configurationKey] }}
        </a>
      </div>
      <div v-else>
        No Font Uploaded
      </div>
    </v-col>
    <v-spacer/>
    <v-col align-self="center">
      <div class="text-end">
        <v-btn
          color="primary"
          @click="editing = true"
        >
          {{ $t("buttons.edit") }}
        </v-btn>
      </div>
    </v-col>
  </v-row>

  <confirm-dialog
    title="Confirm"
    text="Are you sure?"
    v-model="confirmVisible"
    @confirm="uploadFont"
    @cancel="confirmVisible = false"
  />
</template>

<style scoped>

</style>
