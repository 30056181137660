<template>
  <v-menu bottom transition="scale-transition">
    <template v-slot:activator="{ props }">
      <v-btn-secondary
        v-bind="props"
        data-pw="accountSettingsButton"
        class="my-2"
      >
        <v-icon class="mr-2" left>far fa-gear</v-icon>
        {{ $t("buttons.account_settings") }}
      </v-btn-secondary>
    </template>

    <v-list>
      <v-list-item
        v-for="(item, index) in menuItems"
        :key="index"
        :hidden="!item.show"
        @click="item.action"
        :data-pw="item.testId"
        :style="{ color: item.textColor }"
      >
        <template v-slot:prepend>
          <v-icon color="primary">{{ item.icon }}</v-icon>
        </template>
        <p>
          {{ $t(item.translationKey) }}
        </p>
      </v-list-item>
    </v-list>
  </v-menu>

  <v-dialog v-model="showDialogs.changeEmail">
    <change-email-address-dialog
      :user="user"
      @close="onCloseDialog('changeEmail')"
    />
  </v-dialog>
  <v-dialog v-model="showDialogs.changePhone">
    <change-phone-dialog
      :user="user"
      @close="onCloseDialog('changePhone')"
    />
  </v-dialog>
  <v-dialog v-model="showDialogs.changeAccountType">
    <change-account-type-dialog
      :user="user"
      @close="onCloseDialog('changeAccountType')"
    />
  </v-dialog>
  <v-dialog v-model="showDialogs.enableDisableUser">
    <enable-disable-dialog
      :user="user"
      @close="onCloseDialog('enableDisableUser')"
    />
  </v-dialog>
  <v-dialog v-model="showDialogs.resetAssessments">
    <reset-assessments
      :id="user._id!" type="user"
      @close="onCloseDialog('resetAssessments')"
    />
  </v-dialog>
  <v-dialog
    location="center"
    v-model="showDialogs.resetPasswordForm">
    <reset-password-card
      :user="user"
      @close="onCloseDialog('resetPasswordForm')"
    />
  </v-dialog>
  <v-dialog v-model="showDialogs.changeUserRole">
    <update-user-role
      @close="onCloseDialog('changeUserRole')"
      :user="user"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import {ref, computed, defineProps, defineEmits, PropType} from "vue";
import { DashUser } from "@YenzaCT/sdk";
import ChangeEmailAddressDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/ChangeEmailAddressCard.vue";
import ChangePhoneDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/ChangePhoneCard.vue";
import ChangeAccountTypeDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/ChangeAccountTypeCard.vue";
import EnableDisableDialog from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/DisableEnableCard.vue";
import ResetAssessments from "@/components/ResetAssessmentCard.vue";
import ResetPasswordCard from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/ResetPasswordCard.vue";
import UpdateUserRole from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/ChangeUserRoleCard.vue";

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  },
  isAdminOrRoot: {
    type: Boolean,
    required: true
  },
  canEditAnyUsers: {
    type: Boolean,
    required: true
  },
});

const emit = defineEmits(["updateUser"]);

const showDialogs = ref({
  changeEmail: false,
  changePhone: false,
  changeAccountType: false,
  enableDisableUser: false,
  resetAssessments: false,
  resetPasswordForm: false,
  changeUserRole: false,
});

const onCloseDialog = (dialogType: keyof typeof showDialogs.value) => {
  showDialogs.value[dialogType] = false;
  emit("updateUser", true);
};

const menuItems = computed(() => [
  {
    translationKey: "components.UserDetailPage.menu.reset_password",
    icon: "fa-light fa-key",
    action: () => (showDialogs.value.resetPasswordForm = true),
    testId: "resetPasswordDialogButton",
    show: props.isAdminOrRoot || props.canEditAnyUsers,
    textColor: "black"
  },
  {
    translationKey: "components.UserDetailPage.menu.change_email",
    icon: "fa-light fa-envelope",
    action: () => (showDialogs.value.changeEmail = true),
    testId: "changeEmailDialogButton",
    show: props.isAdminOrRoot || props.canEditAnyUsers,
    textColor: "black"
  },
  {
    translationKey: "components.UserDetailPage.menu.change_phone_number",
    icon: "fa-light fa-phone",
    action: () => (showDialogs.value.changePhone = true),
    testId: "changePhoneDialogButton",
    show: props.isAdminOrRoot || props.canEditAnyUsers,
    textColor: "black"
  },
  {
    translationKey: "components.UserDetailPage.menu.change_account_type",
    icon: "fa-light fa-user-cog",
    action: () => (showDialogs.value.changeAccountType = true),
    show: props.isAdminOrRoot || props.canEditAnyUsers,
    testId: "changeAccountTypeDialogButton",
    textColor: "black"
  },
  {
    translationKey: "components.UserDetailPage.menu.change_role",
    icon: "fa-regular fa-circle-user",
    action: () => (showDialogs.value.changeUserRole = true),
    show: props.isAdminOrRoot || props.canEditAnyUsers,
    testId: "changeUserRoleDialogButton",
    textColor: "black"
  },
  {
    translationKey: "components.UserDetailPage.menu.reset_assessments",
    icon: "fa-light fa-undo",
    action: () => (showDialogs.value.resetAssessments = true),
    show: props.isAdminOrRoot || props.canEditAnyUsers,
    testId: "resetAssessmentsDialogButton",
    textColor: "black"
  },
  {
    translationKey: props.user?.auth.accountDisabled
      ? "components.UserDetailPage.menu.enable"
      : "components.UserDetailPage.menu.disable",
    icon: "fa-light fa-user-slash",
    action: () => (showDialogs.value.enableDisableUser = true),
    testId: "disableEnableUserDialogButton",
    show: props.isAdminOrRoot || props.canEditAnyUsers,
    textColor: "red"
  },
]);

</script>
