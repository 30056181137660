<template>
  <v-card flat color="grey-lighten-4" class="mb-6">
    <v-card-title>
      Country Settings
    </v-card-title>
    <v-card-text>
      <configuration-section-row>
        <template #name>
          Available Countries
        </template>
        <template #comment>
          The user can set their account to one of these countries during signup.
        </template>
        <v-select
          variant="outlined"
          density="compact"
          v-model="selectedAvailableCountries"
          :label="$t('components.AvailableCountriesSelector.label')"
          :items="countryOptions"
          :item-props="itemProps"
          chips
          multiple/>
      </configuration-section-row>
      <configuration-section-row :show-divider="false">
        <template #name>
          Default Country
        </template>
        <template #comment>
          New signups will default to this country.
        </template>
        <v-select
          variant="outlined"
          density="compact"
          v-model="selectedDefaultCountry"
          :label="$t('components.DefaultCountrySelector.label')"
          :items="validDefaultCountries"/>
      </configuration-section-row>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn
        color="secondary"
        @click="reset"
        :disabled="!changed"
      >
        {{ $t('buttons.reset') }}
      </v-btn>
      <v-btn
        color="primary"
        @click="confirmVisible = true"
        :disabled="!valid || !changed"
      >
        {{ $t('buttons.apply') }}
      </v-btn>
    </v-card-actions>
  </v-card>

  <confirm-dialog
    :title="$t('common.confirm_title')"
    :text="$t('common.confirm_text')"
    v-model="confirmVisible"
    @confirm="save"
    @cancel="confirmVisible = false"
  />
</template>

<script setup lang="ts">
import {computed, ref} from "vue";
import {useGlobalStore} from "@/store";
import {CountryCode, isYapiError, YapiError} from "@YenzaCT/sdk";
import ConfirmDialog from "@/components/ConfirmDialog.vue";
import {isEqual} from "lodash";
import {countryOptions} from "@/lib/appConfiguration";
import {VSelectOption} from "@/lib/vuetify";
import ConfigurationSectionRow from "@/pages/AppConfigurationPage/components/ConfigurationSectionRow.vue";

const store = useGlobalStore();

const confirmVisible = ref(false);
const selectedAvailableCountries = ref<CountryCode[]>(store.appConfiguration.availableCountries as CountryCode[]);
const selectedDefaultCountry = ref<CountryCode>(store.appConfiguration.defaultCountry as CountryCode);

const changed = computed(() => !isEqual(selectedAvailableCountries.value, store.appConfiguration.availableCountries) ||
  !isEqual(selectedDefaultCountry.value, store.appConfiguration.defaultCountry));
const valid = computed(() => selectedAvailableCountries.value.length > 0 && selectedDefaultCountry.value.length > 0);

function reset() {
  selectedAvailableCountries.value = store.appConfiguration.availableCountries as CountryCode[];
  selectedDefaultCountry.value = store.appConfiguration.defaultCountry as CountryCode;
}

async function save() {
  // First save available, as they are required for default
  await saveAvailable();
  await saveDefault();
  confirmVisible.value = false;
}

async function saveAvailable() {
  try {
    await store.saveAppConfiguration("availableCountries", selectedAvailableCountries.value);
    store.showSnackbar("Available countries updated");
  } catch (e) {
    if (isYapiError(e)) {
      const error = e as YapiError;
      store.showSnackbar(error.message);
    } else {
      store.showSnackbar("An error occurred while saving the countries");
    }
  }
}

function itemProps(item: VSelectOption) {
  if (item.value === selectedDefaultCountry.value)
    return {
      disabled: true
    };
}

const validDefaultCountries = computed(
  () => countryOptions.filter((c) => selectedAvailableCountries.value.includes(c.value as CountryCode))
);

async function saveDefault() {
  try {
    await store.saveAppConfiguration("defaultCountry", selectedDefaultCountry.value);
    store.showSnackbar("Default country updated");
  } catch (e) {
    if (isYapiError(e)) {
      const error = e as YapiError;
      store.showSnackbar(error.message);
    } else {
      store.showSnackbar("An error occurred while saving the country");
      confirmVisible.value = false;
      reset();
    }
  }
}
</script>
