<template>
  <authenticated-layout class="">
    <template #headerIcon>
      fa-solid fa-gauge
    </template>
    <template #header>
      {{ $t("components.DashboardPage.title") }}
    </template>
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <ReportPeriodFilterBar :show-filters="true" />
        </v-col>
      </v-row>

      <v-row>
        <v-col v-if="canReadVouchersTenant || canReadVouchersInstitution || canReadVouchersCohort || isAdminOrRoot" cols="4">
          <AccessCodesUsageRatioMetricTile />
        </v-col>
        <v-col cols="4">
          <SignupsMetricTile/>
        </v-col>
        <v-col cols="4">
          <UserLoginsMetricTile />
        </v-col>
        <v-col cols="4">
          <ReturningUsersMetricTile />
        </v-col>
        <v-col cols="4">
          <UserHasSelectedSchoolSubjects />
        </v-col>
        <v-col cols="4">
          <HollandCodeCompletionRatioMetricTile />
        </v-col>
        <v-col cols="4">
          <Big5CompletionRatioMetricTile />
        </v-col>
      </v-row>
    </v-container>

  </authenticated-layout>
</template>

<script setup lang="ts">
import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import SignupsMetricTile from "@/components/Metrics/SignupsMetricTile.vue";
import {Ref, ref, provide} from "vue";
import {DateFilterKey, DateFilterUnitKey, DateFilterValueKey, TenantFilterKey, InstitutionFilterKey, CohortFilterKey} from "@/symbols/metrics";
import UserLoginsMetricTile from "@/components/Metrics/UserLoginsMetricTile.vue";
import ReturningUsersMetricTile from "@/components/Metrics/ReturningUsersMetricTile.vue";
import UserHasSelectedSchoolSubjects from "@/components/Metrics/UserHasSelectedSchoolSubjectsMetricTile.vue";
import HollandCodeCompletionRatioMetricTile from "@/components/Metrics/HollandCodeCompletionRatioMetricTile.vue";
import Big5CompletionRatioMetricTile from "@/components/Metrics/Big5CompletionRatioMetricTile.vue";
import AccessCodesUsageRatioMetricTile from "@/components/Metrics/AccessCodesUsageRatioMetricTile.vue";
import ReportPeriodFilterBar from "@/components/Reporting/ReportPeriodFilterBar.vue";
import { Entity, Role} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";

const store = useGlobalStore();

const dateFilter: Ref<string> = ref("in the last");
const dateFilterUnit: Ref<string> = ref("days");
const dateFilterValue: Ref<string | string[]> = ref("7");
const tenantFilter:Ref<undefined> = ref(undefined);
const institutionFilter:Ref<undefined> = ref(undefined);
const cohortFilter:Ref<undefined> = ref(undefined);
provide(TenantFilterKey, tenantFilter);
provide(InstitutionFilterKey, institutionFilter);
provide(CohortFilterKey, cohortFilter);
provide(DateFilterKey, dateFilter);
provide(DateFilterUnitKey, dateFilterUnit);
provide(DateFilterValueKey, dateFilterValue);
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);

const canReadVouchersTenant = ref(store.canReadEntity(Entity.Tenant, Entity.AccessCode));
const canReadVouchersInstitution = ref(store.canReadEntity(Entity.Institution, Entity.AccessCode));
const canReadVouchersCohort = ref(store.canReadEntity(Entity.Cohort, Entity.AccessCode));
</script>
