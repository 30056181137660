export default function download(data: Blob, name: string) {
  const url = URL.createObjectURL(data);
  const a = document.createElement("a");
  a.download = `${name}.pdf`;
  a.href = url;
  a.target = "_self";

  a.click();

  setTimeout(function() {
    a.remove();
    URL.revokeObjectURL(url);
  }, 100);
}
