<template>
  <v-menu>
    <template v-slot:activator="{ props }">
      <v-btn
        variant="text"
        color="primary"
        v-bind="props"
        data-pw="perPageButton"
      >
        {{ $t("common.per_page", [current]) }}
        <v-icon>fa far fa-chevron-down</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(item, index) in numItems"
        :key="index"
        :value="index"
        @click="() => onClickItem(item)"
        :active="item === current"
        data-pw="perPageItem"
      >
        <v-list-item-title>{{ item }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import {PropType, ref} from "vue";

defineProps({
  current: {
    type: Number as PropType<number>,
    required: true
  }
});

const emit = defineEmits<{
  (e: "update", perPage: number): void;
}>();

const numItems = ref([10, 20, 30, 50]);
function onClickItem(selection: number) {
  emit("update", selection);
}
</script>
