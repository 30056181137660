<template>
  <div class="search-container">
    <v-text-field
      v-model="searchString"
      :placeholder="$t('common.search') + '...'"
      :append-inner-icon="searchString ? 'far fa-close' : 'far fa-search'"
      variant="plain"
      :hide-details="true"
      autocomplete="off"
      density="compact"
      :class="{ 'searched pb-0': hasResults, 'pb-5': !hasResults }"
      :autofocus="true"
      @click:append-inner="clearSearch"
      @keyup.enter="globalSearch"
      data-pw="globalSearchBar"
      class="pa-4 font-weight-bold "
    />
    <v-list v-if="hasResults" class="list-element">
      <v-list-item>
        <search-results-cards
          title="Users"
          icon="far fa-users"
          :data="users"
          data-pw="searchResultsUsers"
          route-name="userDetail"
          :show-more-route="{ name: 'users', query: { search: searchString } }"
          @click="handleSearchResultClick"
        />
        <search-results-cards
          title="Access Codes"
          icon="far fa-ticket"
          route-name="accessCode"
          :show-more-route="{ name: 'vouchers', query: { search: searchString } }"
          :data="accessCodes"
          @click="handleSearchResultClick"
        />
        <search-results-cards
          title="Tenants"
          icon="far fa-globe"
          route-name="tenantDetail"
          :show-more-route="{ name: 'tenants', query: { search: searchString } }"
          :data="tenants"
          @click="handleSearchResultClick"
        />
        <search-results-cards
          title="Institutions"
          icon="far fa-school"
          :data="institutions"
          route-name="institutionDetail"
          :show-more-route="{ name: 'institutions', query: { search: searchString } }"
          @click="handleSearchResultClick"
        />
        <search-results-cards
          title="Cohorts"
          icon="far fa-graduation-cap"
          route-name="cohortDetail"
          :show-more-route="{ name: 'cohorts', query: { search: searchString } }"
          :data="cohorts"
          @click="handleSearchResultClick"
        />
      </v-list-item>
    </v-list>
    <div v-if="searchString && !hasResults && searchActive" class="pa-4">
      {{ t('components.GlobalSearchBar.no_results_for', { term: searchString }) }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from "vue";
import { useGlobalStore } from "@/store";
import {GlobalSearchResponse, isYapiError, YapiError} from "@YenzaCT/sdk";
import SearchResultsCards from "@/components/GlobalSearch/SearchResultsCards.vue";
import yapi from "@/lib/yapi";
import {debounce} from "lodash";
import {useI18n} from "vue-i18n";

const store = useGlobalStore();
const searchString = ref("");
const { t } = useI18n();

const users = ref<GlobalSearchResponse["users"]>([]);
const accessCodes = ref<GlobalSearchResponse["accessCode"]>([]);
const tenants = ref<GlobalSearchResponse["tenants"]>([]);
const institutions = ref<GlobalSearchResponse["institutions"]>([]);
const cohorts = ref<GlobalSearchResponse["cohorts"]>([]);
const globalSearchStringLength = ref(0);
const searchActive = ref(false);

const emit = defineEmits<{
  (event: "close"): void;
}>();

async function globalSearch() {
  searchActive.value = false;
  if (searchString.value.length > 2) {
    try {
      const response = await yapi.admin.globalSearch(searchString.value);
      if (response.data.users && response.data.users.length > 0) {
        users.value = response.data.users;
      }
      if (response.data.accessCode && response.data.accessCode.length > 0) {
        accessCodes.value = response.data.accessCode;
      }
      if (response.data.tenants && response.data.tenants.length > 0) {
        tenants.value = response.data.tenants;
      }
      if (response.data.institutions && response.data.institutions.length > 0) {
        institutions.value = response.data.institutions;
      }
      if (response.data.cohorts && response.data.cohorts.length > 0) {
        cohorts.value = response.data.cohorts;
      }
      globalSearchStringLength.value = searchString.value.length;
      searchActive.value = true;
    } catch (e) {
      if (isYapiError(e)) {
        const yError = e as YapiError;
        await store.handleYapiError(yError);
      } else {
        throw e;
      }
    }
  }
  if (globalSearchStringLength.value > searchString.value.length) {
    users.value = [];
    accessCodes.value = [];
    tenants.value = [];
    institutions.value = [];
    cohorts.value = [];
    globalSearchStringLength.value = 0;
    return;
  }
}

const debouncedGlobalSearch = debounce(globalSearch, 600);

watch(searchString, debouncedGlobalSearch);

function handleSearchResultClick() {
  emit("close");
}

function clearSearch() {
  searchString.value = "";
  users.value = [];
  accessCodes.value = [];
  tenants.value = [];
  institutions.value = [];
  cohorts.value = [];
}

const hasResults = computed(() => Object.values({
  users: users.value.length,
  accessCodes: accessCodes.value.length,
  tenants: tenants.value.length,
  institutions: institutions.value.length,
  cohorts: cohorts.value.length,
}).some((value) => value > 0));

</script>

<style scoped>
.search-container {
  background-color: rgba(32, 43, 61, 1.0);
  color: white;
  border-radius: 12px;
}

.list-element {
  background-color: rgba(0, 0, 0, 0);
  color: white;
  max-height: 400px;
  overflow-y: scroll;
}
</style>
