<template>
  <v-row class="d-flex flex-column">
    <div class="flex-grow-1">
      <v-col cols="12">
        <v-row>
          <v-col cols="12">
            <wide-trait-score-card
              :title="$t(`assessments.managing_emotion.title`)"
              :result="parseInt(String(managingEmotionScore.total_score * 100))"
              :color="managingEmotionColour"
            />
          </v-col>
          <v-col cols="12">
            <wide-trait-score-card
              :title="$t(`assessments.understanding_emotion.title`)"
              :result="parseInt(String(understandingEmotionScore?.total_score * 100))"
              :color="understandingEmotionColour"
            />
          </v-col>
          <v-col cols="12">
            <wide-trait-score-card
              :title="$t(`assessments.perceiving_emotion.title`)"
              :result="parseInt(String(perceivingEmotionScore.total_score * 100))"
              :color="perceivingEmotionColour"
            />
          </v-col>
        </v-row>
      </v-col>
    </div>

    <v-dialog cols="12" v-model="dialogOpenDetail" transition="dialog-bottom-transition" >
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn dark @click="closeDetailedDialog">
            <v-icon>fa fa-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("assessments.emotional_intelligence.title") }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-col>
            {{  $t("common.coming_soon_label")}}
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script setup lang="ts">
import WideTraitScoreCard from "@/pages/UserDetailPage/components/common/WideTraitScoreCard.vue";
import {ManagingEmotionResult, PerceivingEmotionResult, UnderstandingEmotionResult} from "@YenzaCT/sdk";
import {PropType, ref, watch} from "vue";

const props = defineProps({
  managingEmotionScore: {
    type: Object as PropType<ManagingEmotionResult>,
    required: true
  },
  understandingEmotionScore: {
    type: Object as PropType<UnderstandingEmotionResult>,
    required: true
  },
  perceivingEmotionScore: {
    type: Object as PropType<PerceivingEmotionResult>,
    required: true
  },
  dialogOpen: {
    type: Boolean,
    default: false,
    required: true
  }
});

const dialogOpenDetail = ref(props.dialogOpen);

watch(() => props.dialogOpen, (newVal) => {
  dialogOpenDetail.value = newVal;
});

function closeDetailedDialog() {
  dialogOpenDetail.value = false;
}

const managingEmotionColour = "green";
const understandingEmotionColour = "blue";
const perceivingEmotionColour = "red";
</script>

<style scoped>
.btn-bg {
  background-color: #F1F5F9;
  border-color: #CBD5E1;
}
</style>
