import { YapiClient } from "@YenzaCT/sdk";
import config from "@/config";

const yapi: YapiClient = new YapiClient({
  serverUrl: config.yapiUrl,
  cookie: {
    domain: config.cookieDomain,
    secure: true,
    sameSite: "lax"
  },
});

export default yapi;
