import {useTheme} from "vuetify";
import {ref, watch} from "vue";
import {useI18n} from "vue-i18n";
import {setCookie} from "typescript-cookie";
import {VSelectOption} from "@/lib/vuetify";
import {useGlobalStore} from "@/store";
import {localeOptions, themeOptions} from "@/lib/appConfiguration";

export function useUserSettings() {
  const {locale} = useI18n({useScope: "global"});

  const theme = useTheme();
  const store = useGlobalStore();

  const currentTheme = ref(theme.global.name);
  const currentLocale = ref(locale);

  const themeItems: VSelectOption[] = themeOptions;

  watch(currentTheme, (theme: string) => {
    setCookie("theme", theme, {sameSite: "lax"});
  });

  const localeItems = localeOptions.filter((localeOption) => store.appConfiguration.locales.available.includes(localeOption.value));

  watch(currentLocale, (locale) => {
    setCookie("locale", locale as string, {sameSite: "lax"});
  });

  return {
    currentTheme,
    currentLocale,
    themeItems,
    localeItems,
  };
}
