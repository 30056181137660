<template>
  <v-card-account-settings
    :title="$t('components.ChangeUserRole.title')"
    data-pw="changeUserRoleDialogCard"
  >
    <v-card-text>
      <p class="pb-4">
        {{ $t('components.ChangeUserRole.subtitle') }}
      </p>
      <select-role
        v-model="userRole"
        type="edit"
        @update:modelValue="handleRoleSelect"
      />
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn-secondary @click="$emit('close')">
        {{ $t("buttons.cancel") }}
      </v-btn-secondary>
      <v-btn-primary @click="changeRole">
        {{ $t("buttons.confirm") }}
      </v-btn-primary>
    </v-card-actions>
  </v-card-account-settings>
</template>

<script setup lang="ts">
import { PropType, ref } from "vue";
import { isYapiError, DashUser, Role, YapiError } from "@YenzaCT/sdk";
import { useGlobalStore } from "@/store";
import yapi from "@/lib/yapi";
import SelectRole from "@/components/SelectRole.vue";

const store = useGlobalStore();

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});
const userRole = ref(props.user.app.role);

function handleRoleSelect(selectedRole: Role) {
  userRole.value = selectedRole;
}

const emit = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
  (e: "roleUpdate", role: Role): void;
}>();

const changeRole = async () => {
  try {
    if (userRole.value && props.user._id) {
      await yapi.admin.user.edit(props.user._id, { app: { role: userRole.value } } as DashUser);
      emit("roleUpdate", userRole.value);
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    emit("close");
  }
};
</script>
