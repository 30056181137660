<template>
  <report-filter-options
    :show-tenant-filter="true"
    :show-institution-filter="true"
    :show-cohort-filter="true"
    :show-cohort-breakdown="false"
    :is-report-active="isReportActive"
    :disable-run-report-button="!curriculum "
    @change-tenant="onTenantChange"
    @change-institution="onInstitutionSelectionChange"
    @change-cohort="onCohortChange"
    @click-run-report="runReport"
    @click-reset="resetReport"
  >
    <template #filters>
      <v-col>
        <CurriculumSelectDropdown v-model="curriculum" @change="onCurriculumChange"/>
      </v-col>
    </template>

    <template #filterList>
      <v-list-item>
        <span class="font-weight-bold">{{  $t('common.curriculum') }}: </span> {{ $t(`curriculums.${curriculum}.title`) }}
      </v-list-item>
    </template>
  </report-filter-options>

  <div v-if="loadingData">
    <v-row>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
  </div>
  <subject-distribution-graphs
    v-if="reportDataValue && dataIsSet"
    :report-data="reportDataValue"
    :curriculum="activeCurriculum" class="mt-6"
  />
  <ShowNoResults
    v-else-if="!dataIsSet && showReport && !loadingData"
  />
</template>

<script setup lang="ts">
import {useInsights} from "@/pages/ReportsDetailPage/reportsDetailPage";
import {computed, ref, watch} from "vue";

import ReportFilterOptions from "@/components/Reporting/ReportFilterOptions.vue";
import CurriculumSelectDropdown from "@/components/FormFields/CurriculumSelectDropdown.vue";
import SubjectDistributionGraphs from "@/pages/ReportsDetailPage/components/SubjectDistributionGraphs.vue";
import ShowNoResults from "@/pages/ReportsDetailPage/components/NoResultsFoundCard.vue";

const {
  timeFilter,
  tenantId,
  institutionIds,
  cohortId,
  curriculum,
  loadingData,
  isReportActive,
  breakdownByCohort,
  reportData,
  getReportData,
  onTenantChange,
  onCurriculumChange,
  onCohortChange,
  onInstitutionSelectionChange,
  isReportEmpty
} = useInsights();

const reportDataValue = computed(() => reportData.value ? reportData.value[0] : null);
const showReport = ref(false);
const dataIsSet = ref(false);
let activeCurriculum = "";

function runReport() {
  if (curriculum.value)
    activeCurriculum = curriculum.value;
  showReport.value = true;
  getReportData("subject-choice-distribution", {
    institutionIds: institutionIds.value,
    tenantId: tenantId.value,
    cohortId: cohortId.value,
    breakdownByCohort: breakdownByCohort.value,
    curriculum: curriculum.value,
    time: timeFilter.value
  });
}

function resetReport() {
  reportData.value = [];
  dataIsSet.value = false;
  showReport.value = false;
  curriculum.value = undefined;
  isReportActive.value = false;
  institutionIds.value = [];
  tenantId.value = undefined;
  cohortId.value = undefined;
}

watch(reportDataValue, () => {
  dataIsSet.value = isReportEmpty(reportData.value);
});
</script>
