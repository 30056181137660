<template>
  <authenticated-layout>
    <template #headerIcon>
      far fa-chart-mixed
    </template>
    <template #header>{{ $t("components.ReportPage.title") }}</template>

    <v-container class="py-4" :fluid="true">
      <v-row>
        <v-col cols="6">
          <v-card>
            <v-card-title>
              {{ $t("components.ReportPage.card_titles.usage") }}
            </v-card-title>
            <v-card-subtitle>{{ $t("components.ReportPage.card_subtitles.usage") }}</v-card-subtitle>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-list>
                    <div v-bind:key="report.route" v-for="report in reports.usage">
                      <v-list-item @click="goToReport(report.route)">
                        {{ report.title }}
                      </v-list-item>
                      <v-divider/>
                    </div>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mt-6">
            <v-card-title>
              {{ $t("components.ReportPage.card_titles.distribution") }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-list>
                    <div v-bind:key="report.route" v-for="report in reports.distribution">
                      <v-list-item @click="goToReport(report.route)">
                        {{ report.title }}
                      </v-list-item>
                      <v-divider/>
                    </div>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6">
          <v-card>
            <v-card-title>
              {{ $t("components.ReportPage.card_titles.user_results") }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-list>
                    <div v-bind:key="report.route" v-for="report in reports.users">
                      <v-list-item @click="goToReport(report.route)">
                        {{ report.title }}
                      </v-list-item>
                      <v-divider/>
                    </div>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

          <v-card class="mt-6">
            <v-card-title>
              {{ $t("components.ReportPage.card_titles.dashboards") }}
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-list>
                    <div v-bind:key="report.route" v-for="report in reports.dashboard">
                      <v-list-item @click="goToReport(report.route)">
                        {{ report.title }}
                      </v-list-item>
                      <v-divider/>
                    </div>
                  </v-list>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </authenticated-layout>

</template>

<script setup lang="ts">
import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import router from "@/router";
import {useI18n} from "vue-i18n";

const {t} = useI18n();

function goToReport(report: string) {
  router.push({name: "reportsDetail", params: {report}});
}

const reports = {
  "dashboard": [
    {
      "title": t("components.ReportPage.reports.dashboards.group_analysis"),
      "route": "group-dashboard"
    },
  ],
  "usage": [
    {
      "title": t("components.ReportPage.reports.usage.voucher_usage"),
      "route": "voucher-usage"
    }, {
      "title": t("components.ReportPage.reports.usage.worker_type_completion"),
      "route": "worker-type-completion"
    }, {
      "title": t("components.ReportPage.reports.usage.personality_type_completion"),
      "route": "personality-type-completion"
    }, {
      "title": t("components.ReportPage.reports.usage.subject_selection_completion"),
      "route": "subject-selection-completion"
    }
  ],
  "users": [
    {
      "title": t("components.ReportPage.reports.users.user_subject_choices"),
      "route": "user-subject-choices"
    },
    {
      "title": t("components.ReportPage.reports.users.user_worker_types"),
      "route": "user-worker-types"
    },
    {
      "title": t("components.ReportPage.reports.users.user_personality_types"),
      "route": "user-personality-types"
    },
  ],
  "distribution": [
    {
      "title": t("components.ReportPage.reports.distributions.subject_choice_distribution"),
      "route": "subject-choice-distribution"
    },
    {
      "title": t("components.ReportPage.reports.distributions.worker_type_distribution"),
      "route": "worker-type-distribution"
    },
    {
      "title": t("components.ReportPage.reports.distributions.personality_type_distribution"),
      "route": "personality-traits-distribution"
    }
  ]
};
</script>

<style scoped>

</style>
