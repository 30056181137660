<template>
  <authenticated-layout>
    <v-dialog v-model="editDialog" max-width="500px">
      <edit-coupon-dialog
        v-model="editDialog"
        :coupon="editCoupon"
        @update="updateCoupon"
        @close="editDialog = false"
      />
    </v-dialog>

    <v-dialog v-model="deleteDialog" max-width="500px">
      <delete-coupon-dialog
        v-model="deleteDialog"
        @delete="deleteCoupon"
      />
    </v-dialog>

    <v-card v-if="coupon !== undefined">
      <v-card-title>
        <v-row class="mb-1">
          <v-col cols="9">
            <h2>{{ coupon.title }}</h2>
          </v-col>
          <v-col class="text-right ">
            <v-btn-primary @click="editDialog = true">
              {{ $t("buttons.edit") }}
            </v-btn-primary>
            <v-btn-delete @click="deleteDialog = true">
              {{  $t("buttons.delete") }}
            </v-btn-delete>
          </v-col>

        </v-row>

      </v-card-title>

      <v-card-text>
        <v-list>
          <v-list-item>
            <v-list-item-title>{{ $t('coupon.code') }}</v-list-item-title>
            <v-list-item-subtitle>{{ coupon.code }}</v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>{{ $t('coupon.type') }}</v-list-item-title>
            <v-list-item-subtitle>{{ typeLabel }}</v-list-item-subtitle>
          </v-list-item>
          <v-list-item>

            <v-list-item-title>{{ $t('coupon.amount') }}</v-list-item-title>
            <v-list-item-subtitle v-if="coupon.type === 'full'">N/A</v-list-item-subtitle>
            <v-list-item-subtitle v-else-if="coupon.type === 'percent'">{{ (coupon.amount * 100)}}%</v-list-item-subtitle>
            <v-list-item-subtitle v-else>R{{ coupon.amount }}.00</v-list-item-subtitle>
          </v-list-item>

          <v-list-item>
            <v-list-item-title>{{ $t('coupon.type') }}</v-list-item-title>
            <v-list-item-subtitle>
              <v-chip v-for="product in coupon.products" :key="product" small>
                {{ product }}
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <p v-else>{{ $t("common.loading") }}</p>
  </authenticated-layout>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from "vue";
import yapi from "@/lib/yapi";
import {useRoute} from "vue-router";
import {Coupon, isYapiError, YapiError} from "@YenzaCT/sdk";
import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";
import {useGlobalStore} from "@/store";
import EditCouponDialog from "@/pages/CouponDetailPage/components/EditCouponDialog.vue";
import DeleteCouponDialog from "@/pages/CouponDetailPage/components/DeleteCouponDialog.vue";
import {VSelectOption} from "@/lib/vuetify";
import router from "@/router";

const route = useRoute();
const store = useGlobalStore();
const coupon = ref<Coupon>();
const editDialog = ref(false);
const editCoupon = ref<Coupon>({ products: [] as string[]} as Coupon);
const deleteDialog = ref(false);

const couponTypes: VSelectOption[] = [
  { title: "Full", value: "full"},
  { title: "Percent", value: "percent"},
  { title: "Fixed", value: "fixed"},
];

const typeLabel = computed(() => couponTypes.find(
  (typeRecord) => typeRecord.value === coupon.value?.type
)?.title);

async function deleteCoupon() {
  try {
    store.networkBusy = true;
    await yapi.admin.coupon.remove(route.params.id as string);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    await router.replace({name: "coupons"});
  }
}

async function fetchCoupon() {
  try {
    store.networkBusy = true;
    const response = await yapi.admin.coupon.get(route.params.id as string);
    coupon.value = {...response.data};

    // copy data, not reference
    editCoupon.value = {...response.data };
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

async function updateCoupon(updatedCoupon: Coupon) {
  editCoupon.value = updatedCoupon;
  try {
    await yapi.admin.coupon.edit(route.params.id as string, editCoupon.value as Coupon);
    await fetchCoupon();
    editDialog.value = false;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

onMounted(async () => {
  await fetchCoupon();
});

</script>
