<template>
  <v-card height="100%" data-pw="userDetailsCard">

    <v-card-title class="d-flex justify-space-between align-center">
      <h2>{{$t("components.UserDetailPage.user_detail_section.details")}}</h2>
      <!--      TODO: IMPLEMENT EDIT USER DETAILS-->
      <!--      <v-btn-secondary-->
      <!--        @click="editDetails"-->
      <!--        v-bind="props"-->
      <!--        elevation="0"-->
      <!--      >-->
      <!--        <v-icon-->
      <!--          color="primary"-->
      <!--          size="small"-->
      <!--          class="mr-2"-->
      <!--        >-->
      <!--          far fa-edit-->
      <!--        </v-icon>-->
      <!--        <span>{{$t("components.UserDetailPage.user_detail_section.edit")}}</span>-->
      <!--      </v-btn-secondary>-->
    </v-card-title>
    <v-divider class="mx-4" />
    <v-card-text>
      <v-row>
        <v-col cols="12" sm="4" md="3">
          <detail-item
            label="components.UserDetailPage.user_detail_section.email"
            :value="props.user?.email"
            value-icon="fa-light fa-envelope"
            data-pw="userEmailRow"
          />
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <detail-item
            label="components.UserDetailPage.user_detail_section.phone"
            :value="props.user?.phone"
            value-icon="fa-light fa-phone"
            data-pw="userPhoneRow"
          />
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <detail-item
            label="components.UserDetailPage.user_detail_section.account_type"
            :value="$t(`accountTypes.${props.user?.app.accountType}`)"
            value-icon="fa-light fa-user-cog"
            data-pw="userAccountTypeRow"
          />
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <detail-item
            label="components.UserDetailPage.user_detail_section.role"
            value-icon="fa-light fa-user"
            :value="roleDisplay"
            data-pw="userRoleRow"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts" setup>
import {computed, onMounted, PropType, ref} from "vue";
import {
  Action,
  Cohort,
  Entity,
  Institution,
  isYapiError,
  Role,
  Tenant,
  DashUser,
  YapiError,
} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import DetailItem from "@/components/DetailItem.vue";

const store = useGlobalStore();
const props = defineProps({
  accountType: {
    type: String as PropType<string>,
    required: true,
  },
  role: {
    type: String as PropType<string>,
    required: true,
  },
  user: {
    type: Object as PropType<DashUser>,
    required: true,
  }
});

const cohortData = ref<Cohort>();
const tenantData = ref<Tenant>();
const institutionData = ref<Institution>();

const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);

const canReadEntity = (entity: Entity, subEntity: Entity) => {
  const userEntityValue = props.user?.app[entity.toLowerCase() as "cohort" | "institution" | "tenant"];
  if (!userEntityValue) return false;

  return isAdminOrRoot.value || store.canAccessSubEntityViaEntity(
    entity,
    subEntity,
    Action.read,
    userEntityValue
  ) || store.canAccessSubEntityViaEntity(
    subEntity,
    subEntity,
    Action.read,
    userEntityValue
  );
};

const canReadTenant = computed(() => canReadEntity(Entity.Tenant, Entity.Tenant));
const canReadInstitution = computed(() => canReadEntity(Entity.Tenant, Entity.Institution));
const canReadCohort = computed(() =>
  canReadEntity(Entity.Tenant, Entity.Cohort) || canReadEntity(Entity.Institution, Entity.Cohort));

async function fetchDetails() {
  try {
    store.networkBusy = true;

    if (props.user?.app.cohort && canReadCohort.value) {
      cohortData.value = (
        await yapi.admin.cohort.get(props.user?.app?.cohort)
      ).data;
    }

    if (props.user?.app.tenant && canReadTenant.value) {
      tenantData.value = (
        await yapi.admin.tenant.get(props.user?.app.tenant.toString())
      ).data;
    }

    if (props.user?.app.institution && canReadInstitution.value) {
      institutionData.value = (
        await yapi.admin.institution.get(props.user?.app.institution.toString())
      ).data;
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

onMounted(async () => {
  await fetchDetails();
});

const roleDisplay = computed(() => {
  const userRole = props.user?.app.role;
  const roles = [];

  if (userRole === Role.User) roles.push("User");
  if (userRole === Role.Admin) roles.push("Admin");
  if (userRole === Role.Manager) roles.push("Manager");
  if (userRole === Role.Root) roles.push("Root");

  // Note: We typically don't display 'Deleted' as an active role

  return roles.join(", ");
});
</script>
