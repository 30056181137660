<template>
  <v-card data-pw="downloadCvDialogCard">
    <v-card-title>
      {{ $t('components.DownloadCvDialog.title') }}
    </v-card-title>
    <v-card-text>
      <span v-if="allowDownload">
        {{ $t("components.DownloadCvDialog.download_instruction") }}
      </span>
      <span v-else>
        {{ $t("components.DownloadCvDialog.no_cv_available") }}
      </span>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        data-pw="cancelDownloadCvButton"
        color="secondary"
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="downloadCv"
        :disabled="!allowDownload"
        data-pw="confirmDownloadCvButton"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { computed, PropType, ref, Ref } from "vue";
import { isYapiError, DashUser, YapiError } from "@YenzaCT/sdk";
import { useGlobalStore } from "@/store";
import yapi from "@/lib/yapi";
import download from "@/lib/documentDownload";

const store = useGlobalStore();

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});

const user: Ref<DashUser> = ref({...props.user} as DashUser);

const allowDownload = computed(() => !!user.value.profile.experiences);

const emit = defineEmits<{
    (e: "confirm"): void;
    (e: "close"): void;
  }>();

async function downloadCv() {
  try {
    const res = await yapi.admin.user.getDocument(
        user.value?._id as string,
        "cv",
    );
    download(res.data, "CV");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
    emit("close");
  }
}
</script>
