const env = import.meta.env;
const yapiSchema = (env.VITE_YAPI_SSL.toLocaleLowerCase() === "true" ? "https" : "http") + "://";
const yapiHost = env.VITE_YAPI_HOST;
const appSchema = (env.VITE_HTTPS.toLocaleLowerCase() === "true" ? "https" : "http") + "://";
const appHost = env.VITE_HOST;

const config = {
  cookieDomain: env.VITE_COOKIE_DOMAIN,
  yapiUrl: `${yapiSchema}${yapiHost}:${env.VITE_YAPI_PORT}`,
  appUrl: `${appSchema}${appHost}:${env.VITE_PORT}`
};

export default config;
