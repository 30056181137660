<template>
  <unauthenticated-header />
  <div class="bg-primary authentication-wrapper">
    <v-container class="fill-height">
      <v-row>
        <v-col cols="12">
          <v-card width="384px" class="ma-auto" elevation="6">
            <v-card-title>{{ $t("components.ForgotPasswordPage.reset_your_password") }}</v-card-title>
            <v-card-subtitle v-if="!contact">
              {{ $t('components.ForgotPasswordPage.enter_details') }}
            </v-card-subtitle>
            <v-card-subtitle v-else>
              {{ $t('components.ForgotPasswordPage.enter_otp_to_reset') }}
            </v-card-subtitle>
            <v-divider class="mx-4 mt-2" />
            <v-card-text>
              <!-- Initial Forgot Password Form -->
              <ForgotPasswordForm
                v-if="!contact"
                @submit="handleForgotPasswordSubmission"
              />
              <PasswordRecoveryForm v-else :contact="contact" :recovery-type="type" />

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script setup lang="ts">
import { ref } from "vue";
import ForgotPasswordForm from "@/pages/ResetPasswordPage/ForgotPasswordForm.vue";
import PasswordRecoveryForm from "@/pages/ResetPasswordPage/PasswordRecoveryForm.vue";
import UnauthenticatedHeader from "@/layout/UnauthenticatedHeader.vue";

const contact = ref("");
const type = ref("");

const handleForgotPasswordSubmission = (submission: { contact: string, type: string }) => {
  contact.value = submission.contact;
  type.value = submission.type;
};
</script>

<style scoped>
.authentication-wrapper {
  height: 100vh;
}
</style>
