<template>
  <v-card-create-tenant-institution-cohort
    :data-pw="dataPw"
    :title="entityTitle"
  >
    <v-card-text v-if="allowed">
      <v-form @submit.prevent @submit="handleSubmit">
        <template v-if="entityType === Entity.Tenant">
          <v-text-field
            :label="$t('common.title')"
            v-model="title"
            variant="outlined" />
          <v-select
            :label="$t('common.country')"
            v-model="country"
            :items="countryOptions"
            item-text="title"
            item-value="value"
            variant="outlined"
          />
        </template>

        <template v-else-if="entityType === Entity.Institution">
          <tenant-search-dropdown
            class="mb-4"
            v-model="selectedTenant"
            :disabled="disableTenantSelect"
            data-pw="tenantSelector"
          />
          <v-text-field
            :label="$t('common.title')"
            v-model="title"
            variant="outlined"
            :rules="titleRules"
            data-pw="titleField"
          />
        </template>

        <template v-else-if="entityType === Entity.Cohort">
          <div v-if="emptyCohort">
            <tenant-search-dropdown
              class="mb-4"
              v-model="selectedTenant"
              :disabled="disableTenantSelect"
              data-pw="tenantSelector"
            />
            <institution-search-dropdown
              class="mb-4"
              v-model="selectedInstitution"
              :tenant="selectedTenant"
            />
          </div>
          <v-text-field
            :label="$t('common.title')"
            v-model="title"
            variant="outlined"
            :rules="titleRules"
          />
        </template>
      </v-form>
    </v-card-text>

    <v-card-text v-else>
      <v-alert type="error">
        {{ $t("common.no_permission") }}
      </v-alert>
    </v-card-text>

    <v-card-actions class="justify-end">
      <v-btn-secondary @click="$emit('close')">
        {{ $t('buttons.cancel') }}
      </v-btn-secondary>
      <v-btn-primary :disabled="!canCreate" @click="handleSubmit">
        {{ $t("buttons.create") }}
      </v-btn-primary>
    </v-card-actions>
  </v-card-create-tenant-institution-cohort>
</template>

<script setup lang="ts">
import {computed, defineProps, onMounted, PropType, ref, watch} from "vue";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import yapi from "@/lib/yapi";
import {CountryCode, Entity, isYapiError, Tenant, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";
import InstitutionSearchDropdown from "@/components/FormFields/InstitutionSearchDropdown.vue";

const props = defineProps({
  entityTitle: {
    type: String,
    required: true,
  },
  entityType: {
    type: String as PropType<Entity>,
    required: true,
  },
  allowed: {
    type: Boolean,
    required: true,
  },
  tenant: {
    type: Object as PropType<Tenant | undefined>,
    required: false,
  },
  dataPw: {
    type: String,
    required: true,
  },
  institutionId: {
    type: String,
    required: false,
  },
  emptyCohort: {
    type: Boolean,
    required: false,
  },
});

const { t } = useI18n();
const router = useRouter();
const store = useGlobalStore();

const title = ref("");
const country = ref<keyof typeof CountryCode | "">("");
const selectedTenant = ref<Tenant | undefined>();
const selectedInstitution = ref();

const disableTenantSelect = ref(false);
const disableInstitutionSelect = ref(true);
const titleRules = [(v: string) => !!v || t("common.title_required")];

const availableCountries = ref(store.appConfiguration.availableCountries);
const countryOptions = computed(() =>
  availableCountries.value.map((slug) => ({
    title: t(`common.countries.${slug}`),
    value: slug,
  })),);

onMounted(async () => {
  if (props.tenant !== undefined) {
    selectedTenant.value = props.tenant;
    disableTenantSelect.value = true;
  }
});

const canCreate = computed(() => {
  if (props.entityType === Entity.Tenant && title.value) {
    return !!title.value && !!country.value;
  } else if (props.entityType === Entity.Institution && title.value) {
    return !!selectedTenant.value?.title && !!selectedTenant.value?._id;
  } else if (props.emptyCohort && props.entityType === Entity.Cohort && selectedInstitution.value && title.value) {
    return !!selectedInstitution.value && !!title.value;
  } else if (props.entityType === Entity.Cohort && title.value) {
    return !!title.value && !!props.institutionId;
  }
  return false;
});

watch(() => props.tenant, (newTenant) => {
  if (newTenant) {
    selectedTenant.value = newTenant;
    disableTenantSelect.value = true;
  }
});
watch(() => selectedInstitution, (value) => {
  if (selectedInstitution.value !== undefined) {
    selectedInstitution.value = value;
    disableInstitutionSelect.value = true;
  }
});
async function handleSubmit() {
  try {
    if (props.entityType === Entity.Tenant) {
      const tenant = await yapi.admin.tenant.create(title.value, country.value as CountryCode);
      await router.push({ name: "tenantDetail", params: { id: tenant.data._id } });
    } else if (props.entityType === Entity.Institution) {
      if (selectedTenant.value && selectedTenant.value._id) {
        const institution = await yapi.admin.institution.create(title.value, selectedTenant.value._id);
        await router.push({ name: "institutionDetail", params: { id: institution.data._id } });
      }
    } else if (props.entityType === Entity.Cohort && props.emptyCohort) {
      const cohort = await yapi.admin.cohort.create(title.value, selectedInstitution.value._id);
      await router.push({ name: "cohortDetail", params: { id: cohort.data._id } });
      store.showSnackbar(t("components.CreateCards.CreateTenantInstitutionCohort.created_message"));
    } else if (props.entityType === Entity.Cohort && props.institutionId) {
      const cohort = await yapi.admin.cohort.create(title.value, props.institutionId);
      await router.push({ name: "cohortDetail", params: { id: cohort.data._id } });
    }
    store.showSnackbar(t("components.CreateCards.CreateTenantInstitutionCohort.created_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const error = e as YapiError;
      store.showSnackbar(error.message);
    } else {
      store.showSnackbar(t("components.CreateCards.CreateTenantInstitutionCohort.error_message"));
    }
  }
}
</script>
