<template>
  <v-card class="data-table-toolbar px-4" elevation="0">
    <v-row no-gutters>
      <v-col>
        <h5 class="pt-4">{{ $t("components.ReportPeriodFilterBar.time_frame") }}</h5>
      </v-col>
    </v-row>
    <v-row no-gutters class="pl-1 pr-4 my-2" align-content="center" justify="space-between">
      <v-btn-toggle density="compact" class="mr-2">
        <v-menu :close-on-content-click="false">
          <template v-slot:activator="{ props }">
            <v-btn
              size="small"
              variant="flat"
              v-bind="props"
              prepend-icon="fa fa-calendar"
            >
              {{ $t("buttons.custom")}}
            </v-btn>
          </template>
          <v-card min-width="200px" rounded variant="outlined" class="mt-1">
            <VueDatePicker
              v-model="customDateFilter"
              range
              inline
              auto-apply
              multi-calendars
              :enable-time-picker="false"
            />
          </v-card>
        </v-menu>
        <v-btn
          size="small"
          @click="setDateFilter('since', 'start_of_current_day', 'hours' )"
          :active="isDateFilterActive('since', 'start_of_current_day', 'hours')"
          :class="isDateFilterActive('since', 'start_of_current_day', 'hours') ? 'font-weight-bold text-primary' : ''"
        >
          {{ $t('components.ReportPeriodFilterBar.today') }}
        </v-btn>
        <v-btn
          size="small"
          @click="setDateFilter('between', ['start_of_previous_day', 'end_of_previous_day'], 'hours' )"
          :active="isDateFilterActive('between', ['start_of_previous_day', 'end_of_previous_day'], 'hours')"
          :class="isDateFilterActive('between', ['start_of_previous_day', 'end_of_previous_day'], 'hours') ? 'font-weight-bold text-primary' : ''"
        >
          {{ $t('components.ReportPeriodFilterBar.yesterday') }}
        </v-btn>
        <v-btn
          @click="setDateFilter('in the last', '7', 'days' )"
          :active="isDateFilterActive('in the last', '7', 'days')"
          :class="isDateFilterActive('in the last', '7', 'days') ? 'font-weight-bold text-primary' : ''"
        >
          {{ $t('components.ReportPeriodFilterBar.7D') }}
        </v-btn>
        <v-btn
          @click="setDateFilter('in the last', '30', 'days' )"
          :active="isDateFilterActive('in the last', '30', 'days')"
          :class="isDateFilterActive('in the last', '30', 'days') ? 'font-weight-bold text-primary' : ''"
        >
          {{ $t('components.ReportPeriodFilterBar.30D') }}
        </v-btn>
        <v-btn
          @click="setDateFilter('in the last', '3', 'months' )"
          :active="isDateFilterActive('in the last', '3', 'months')"
          :class="isDateFilterActive('in the last', '3', 'months') ? 'font-weight-bold text-primary' : ''"
        >
          {{ $t('components.ReportPeriodFilterBar.3M') }}
        </v-btn>
        <v-btn
          @click="setDateFilter('in the last', '6', 'months' )"
          :active="isDateFilterActive('in the last', '6', 'months')"
          :class="isDateFilterActive('in the last', '6', 'months') ? 'font-weight-bold text-primary' : ''"
        >
          {{ $t('components.ReportPeriodFilterBar.6M') }}
        </v-btn>
        <v-btn
          @click="setDateFilter('in the last', '12', 'months' )"
          :class="isDateFilterActive('in the last', '12', 'months') ? 'font-weight-bold text-primary' : ''"
        >
          {{ $t('components.ReportPeriodFilterBar.12M') }}
        </v-btn>
        <v-btn
          @click="setDateFilter('in the last', '24', 'months' )"
          :class="isDateFilterActive('in the last', '24', 'months') ? 'font-weight-bold text-primary' : ''"
        >
          {{ $t('components.ReportPeriodFilterBar.24M') }}
        </v-btn>
      </v-btn-toggle>
      <v-row class="ml-auto">
        <v-spacer/>
        <v-btn-secondary
          v-if="activeFilterCount"
          variant="outlined"
          class="my-2"
          data-pw="resetFilterButton"
          @click="handleResetFilter"
        >
          <v-icon class="mr-2">far fa-refresh</v-icon>
          {{ $t("buttons.reset_filters") }}
        </v-btn-secondary>

      </v-row>
    </v-row>

    <div v-if="showFilters">
      <v-divider class="pb-4"/>
      <v-row class="pb-4">
        <v-col>
          <h5>{{ $t("components.ReportPeriodFilterBar.filter_by") }}</h5>
        </v-col>
      </v-row>
      <v-row no-gutters class="pl-2 pb-4">
        <v-col cols="4">
          <h5>{{ $t("common.entities.Tenant") }}</h5>
          <tenant-search-dropdown
            v-model="selectedTenant"
            class="pl-1 mb-3"
          />
        </v-col>
        <v-col cols="4" class="pr-4 pl-2">
          <h5>{{ $t("common.entities.Institution") }}</h5>
          <institution-search-dropdown
            :multiple="false"
            v-model="selectedInstitution"
            :tenant="selectedTenant"
            class="column-content mb-3"
          />
        </v-col>
        <v-col cols="4" class="pr-4">
          <h5>{{ $t("common.entities.Cohort") }}</h5>
          <cohort-search-dropdown
            v-model="selectedCohort"
            :institution="selectedInstitution"
            :tenant="selectedTenant"
            class="column-content mb-3"
          />
        </v-col>
      </v-row>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import {ref, inject, watch, computed} from "vue";
import {
  DateFilterKey,
  DateFilterUnitKey,
  DateFilterValueKey,
  TenantFilterKey,
  InstitutionFilterKey,
  CohortFilterKey
} from "@/symbols/metrics";
import {isEqual, isArray} from "lodash";
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";
import InstitutionSearchDropdown from "@/components/FormFields/InstitutionSearchDropdown.vue";
import CohortSearchDropdown from "@/components/FormFields/CohortSearchDropdown.vue";
import VueDatePicker from "@vuepic/vue-datepicker";
import {
  Cohort,
  Institution,
  Tenant,
} from "@YenzaCT/sdk";

const dateFilter = inject(DateFilterKey, ref("in the last"));
const dateFilterUnit = inject(DateFilterUnitKey, ref("days"));
const dateFilterValue = inject(DateFilterValueKey, ref("7"));
const customDateFilter = ref();
const tenantFilter = inject(TenantFilterKey, ref(undefined));
const institutionFilter = inject(InstitutionFilterKey, ref(undefined));
const cohortFilter = inject(CohortFilterKey, ref(undefined));

const selectedTenant = ref<Tenant>();
const selectedInstitution = ref<Institution>();
const selectedCohort = ref<Cohort>();

defineProps({
  showFilters: {
    type: Boolean,
    default: true
  }
});

function isDateFilterActive(filter: string, value: string | string[], unit: string) {
  if (isArray(value)) {
    return dateFilter.value === filter && isEqual(dateFilterValue.value, value) && dateFilterUnit.value === unit;
  }

  return dateFilter.value === filter && dateFilterValue.value === value && dateFilterUnit.value === unit;
}

const activeFilterCount = computed(() => {
  let count = 0;
  if (selectedTenant.value) count++;
  if (selectedInstitution.value) count++;
  if (selectedCohort.value) count++;
  if (customDateFilter.value && customDateFilter.value.length > 0) count++;

  return count;
});

watch(selectedTenant, (newTenant) => {
  tenantFilter.value = newTenant?._id;
}, {deep: true});

watch(selectedInstitution, (newInstitution) => {
  institutionFilter.value = newInstitution?._id;
}, {deep: true});

watch(selectedCohort, (newCohort) => {
  cohortFilter.value = newCohort?._id;
}, {deep: true});

function handleResetFilter() {
  selectedTenant.value = undefined;
  selectedInstitution.value = undefined;
  selectedCohort.value = undefined;
  customDateFilter.value = [];
  dateFilter.value = "in the last";
  dateFilterUnit.value = "days";
  dateFilterValue.value = "7";
}

function setDateFilter(filter: string, value: string | string[], unit: string) {
  dateFilter.value = filter;
  dateFilterValue.value = value;
  dateFilterUnit.value = unit;
}
const showCustomDatePicker = ref(false);

watch(dateFilter, (newFilter) => {
  if (newFilter !== "range") {
    customDateFilter.value = [];
  }
});
watch(() => customDateFilter.value, (newDates) => {
  if (newDates.length > 0) {
    setDateFilter("range", newDates, "dates");
  }
  showCustomDatePicker.value = false;
});
</script>
<style scoped>
</style>
