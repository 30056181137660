<template>
  <v-text-field
    :placeholder="label"
    class="pa-1"
    variant="outlined"
    density="compact"
    @update:modelValue="onUpdate"
    :disabled="store.networkBusy"
    :model-value="modelValue"
    data-pw="filterTextField"
  />
</template>

<script setup lang="ts">
import {PropType} from "vue";
import {debounce} from "lodash";
import {useGlobalStore} from "@/store";

const store = useGlobalStore();
const props = defineProps({
  modelValue: {
    type: String as PropType<string>,
    required: true
  },
  label: {
    type: String as PropType<string>,
    required: true
  },
  debounceWaitMs: {
    type: Number as PropType<number>,
    default: 1000,
  },
});
const emit = defineEmits(["update:modelValue"]);

const onUpdate = debounce((text: string) => {
  emit("update:modelValue", text);
}, props.debounceWaitMs);

</script>
