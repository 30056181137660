<script setup lang="ts">

import {PropType, ref, Ref} from "vue";
import yapi from "@/lib/yapi";

const usersCreatedOverTime = ref();
const accessCodeUsage = ref();
const workerTypeCompletion = ref();
const subjectChoiceCompletion = ref();

const props = defineProps({
  tenantId: {
    type: String,
    required: false,
    default: null
  },
  cohortId: {
    type: String,
    required: false,
    default: null
  },
  institutionIds: {
    type: Array as PropType<string[]>,
    required: false,
    default: null
  },
  timeFilter: {
    type: Object as PropType<{ dateFilterType: string, unit: string, value: string | string[] }[]>,
    required: true,
    default: null
  }
});

async function fetchMetricData(measurement: string, metric: Ref<unknown>) {
  const requestBody = {
    time: props.timeFilter,
    tenant: props.tenantId || null,
    institutions: props.institutionIds || null,
    cohort: props.cohortId || null,
  };

  loadingMeasurements.value[measurement] = true;
  const {data} = await yapi.admin.metric.post(measurement, requestBody);
  loadingMeasurements.value[measurement] = false;
  metric.value = data;
}

const loadingMeasurements: Ref<Record<string, boolean>> = ref(
  {
    "users-created-over-time": false,
    "access-code-usage-ratio": false,
    "users-completed-holland-code-ratio": false,
    "users-selected-subjects-ratio": false
  }
);

async function fetchReportData() {
  await Promise.all([
    fetchMetricData("users-created-over-time", usersCreatedOverTime),
    fetchMetricData("access-code-usage-ratio", accessCodeUsage),
    fetchMetricData("users-completed-holland-code-ratio", workerTypeCompletion),
    fetchMetricData("users-selected-subjects-ratio", subjectChoiceCompletion)
  ]);
}

fetchReportData();

</script>

<template>
  <v-card variant="tonal">
    <v-card-title>
      {{ $t("components.UsageSummaryCard.title") }}
    </v-card-title>
    <v-divider/>
    <v-list>
      <v-list-item>
        <v-list-item-title>
          {{ $t("components.UsageSummaryCard.accounts_created") }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-progress-circular size="16" v-if="loadingMeasurements['users-created-over-time']"
                               :indeterminate="true"/>
          <span class="font-weight-bold" v-else-if="usersCreatedOverTime && usersCreatedOverTime.count">{{
            usersCreatedOverTime.count
          }}</span>
          <span v-else>{{ $t("common.no_data") }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-title>
          {{ $t("components.UsageSummaryCard.voucher_codes_issued") }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-progress-circular size="16" v-if="loadingMeasurements['access-code-usage-ratio']"
                               :indeterminate="true"/>
          <span class="font-weight-bold" v-else-if="accessCodeUsage && accessCodeUsage.total">{{ accessCodeUsage.total }}</span>
          <span v-else>{{ $t("common.no_data") }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-title>
          {{ $t("components.UsageSummaryCard.voucher_codes_used") }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-progress-circular size="16" v-if="loadingMeasurements['access-code-usage-ratio']"
                               :indeterminate="true"/>
          <span class="font-weight-bold" v-else-if="accessCodeUsage"><b>{{ accessCodeUsage.count }}</b> / {{ accessCodeUsage.total }}</span>
          <span v-else>{{ $t("common.no_data") }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-title>
          {{ $t("components.UsageSummaryCard.subject_choice_completions") }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-progress-circular size="16" v-if="loadingMeasurements['users-selected-subjects-ratio']"
                               :indeterminate="true"/>
          <span class="font-weight-bold" v-else-if="subjectChoiceCompletion.count"><b>{{
            subjectChoiceCompletion.count
          }}</b> / {{ subjectChoiceCompletion.total }}</span>
          <span v-else>{{ $t("common.no_data") }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-title>
          {{ $t("components.UsageSummaryCard.subject_choice_rate") }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-progress-circular size="16" v-if="loadingMeasurements['users-selected-subjects-ratio']"
                               :indeterminate="true"/>
          <span class="font-weight-bold" v-else-if="subjectChoiceCompletion.ratio">{{ subjectChoiceCompletion.ratio }}%</span>
          <span v-else>{{ $t("common.no_data") }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-title>
          {{ $t("components.UsageSummaryCard.worker_type_completions") }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-progress-circular size="16" v-if="loadingMeasurements['users-completed-holland-code-ratio']"
                               :indeterminate="true"/>
          <span class="font-weight-bold" v-else-if="workerTypeCompletion.count"><b>{{
            workerTypeCompletion.count
          }}</b> / {{ workerTypeCompletion.total }}</span>
          <span v-else>{{ $t("common.no_data") }}</span>
        </v-list-item-subtitle>
      </v-list-item>
      <v-divider />
      <v-list-item>
        <v-list-item-title>
          {{ $t("components.UsageSummaryCard.worker_type_rate") }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-progress-circular size="16" v-if="loadingMeasurements['users-completed-holland-code-ratio']"
                               :indeterminate="true"/>
          <span class="font-weight-bold" v-else-if="workerTypeCompletion.ratio">{{ workerTypeCompletion.ratio }}%</span>
          <span v-else>{{ $t("common.no_data") }}</span>
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </v-card>

</template>

<style scoped>

</style>
