<template>
  <tr @click="emits('click')">
    <td class="d-flex align-center" data-pw="labelColumn">
      <v-icon v-if="icon" class="mr-2" size="small" color="primary" :icon="icon" data-pw="displayIcon"/>
      {{ label }}
    </td>
    <td data-pw="displayValue">{{ value ?? '-' }}</td>
  </tr>
</template>

<script setup lang="ts">
import {PropType} from "vue";

const emits = defineEmits(["click"]);

defineProps({
  label: {
    type: String as PropType<string>,
    required: true
  },
  icon: {
    type: String as PropType<string>,
  },
  value: {
    type: [String, Boolean] as PropType<string | boolean>,
  },
});
</script>
