<template>
  <MetricTile
    :loading="loading"
    :no-data="!metric || !metric.count"
    icon="fa-solid fa-user-plus"
  >
    <template #title>{{ $t("components.SignupsMetricTile.title") }}</template>
    <template #metric-title>{{ $t("components.SignupsMetricTile.metric_title") }}</template>
    <template #metric>
      {{ metric.count }}
    </template>
    <template #info>{{ $t("components.SignupsMetricTile.info") }}</template>
  </MetricTile>
</template>

<script setup lang="ts">
import MetricTile from "@/components/Metrics/MetricTile.vue";
import {useMetrics} from "@/lib/metrics";

const {
  loading,
  metric
} = useMetrics("users-created-over-time");
</script>
