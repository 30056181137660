<template>
  <v-text-field
    density="compact"
    variant="outlined"
    :disabled="store.networkBusy"
    rounded
    :label="label"
    append-inner-icon="far fa-search"
    data-pw="searchTextField"
    single-line
    hide-details
    v-model="text"
    @update:model-value="onUpdate"
  />
</template>

<script setup lang="ts">
import {PropType, ref, watch} from "vue";
import {debounce} from "lodash";
import {useGlobalStore} from "@/store";

const store = useGlobalStore();
const props = defineProps({
  label: {
    type: String as PropType<string>,
    required: true,
  },
  debounceWaitMs: {
    type: Number as PropType<number>,
    default: 1000,
  },
  searchString: {
    type: String as PropType<string>,
  },
});

const text = ref(props.searchString as string);

watch(() => props.searchString, (newSearchString) => {
  if (newSearchString)
    text.value = props.searchString as string;
  else text.value = "";
});

const emit = defineEmits<{(e: "update:text", text: string): void; }>();

const onUpdate = debounce(() => {
  emit("update:text", text.value);
}, props.debounceWaitMs);
</script>
