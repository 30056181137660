<script setup lang="ts">

import WorkerTypeDistributionGraphs from "@/pages/ReportsDetailPage/components/WorkerTypeDistributionGraphs.vue";
import {PropType, ref, Ref} from "vue";
import yapi from "@/lib/yapi";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import {IWorkerTypeDistributionReportData} from "@/pages/ReportsDetailPage/reportTypes";

const store = useGlobalStore();
const loading = ref(false);
const graphData: Ref<IWorkerTypeDistributionReportData> = ref({
  primary_codes: {},
  secondary_codes: {},
  tertiary_codes: {}
});

const props = defineProps({
  tenantId: {
    type: String,
    required: false,
    default: null
  },
  cohortId: {
    type: String,
    required: false,
    default: null
  },
  institutionIds: {
    type: Array as PropType<string[]>,
    required: false,
    default: null
  },
  timeFilter: {
    type: Object as PropType<{ dateFilterType: string, unit: string, value: string | string[] }[]>,
    required: true,
    default: null
  }
});

async function fetchReportData() {
  try {
    store.networkBusy = true;
    loading.value = true;
    const res = await yapi.admin.reports.post("holland-code-distribution", {
      institutionIds: props.institutionIds,
      tenantId: props.tenantId,
      time: props.timeFilter
    });
    if (res.data && Object.keys(res.data[0]).length > 0)
      graphData.value = res.data[0] as unknown as IWorkerTypeDistributionReportData;
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
    store.networkBusy = false;
  }
}

fetchReportData();
</script>

<template>
  <h1>{{ $t("components.WorkTypeDistributionCard.title")}}</h1>
  <v-divider class="mb-3"/>
  <div v-if="loading">
    <v-row>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
      <v-col>
        <v-skeleton-loader type="card"/>
      </v-col>
    </v-row>
  </div>
  <worker-type-distribution-graphs v-if="graphData && !loading"
                                   :report-data="graphData"
                                   class="mt-3 mb-6"/>
</template>

<style scoped>

</style>
