<template>
  <v-card>
    <v-card-title>
      {{ $t('components.D6Connection.title') }}
    </v-card-title>
    <v-card-text>
      <div v-if="institution.d6?.schoolId">
        <div>
          <v-label>{{ $t('components.D6Connection.school_id') }}</v-label> {{ institution.d6.schoolId }}
        </div>
        <v-btn color="primary" @click="$emit('disconnect')">
          {{ $t('components.D6Connection.disconnect') }}
        </v-btn>
      </div>
      <div v-else>
        <v-text-field label="School ID" v-model="schoolId"/>
        <v-btn-primary @click="$emit('connect', schoolId)">
          {{ $t('components.D6Connection.connect') }}
        </v-btn-primary>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import {ref} from "vue";

const props = defineProps(["institution"]);
const schoolId = ref(props.institution.d6?.schoolId ?? "");
defineEmits(["connect", "disconnect"]);
</script>
