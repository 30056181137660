<template>
  <v-card>
    <v-card-title>Edit Coupon</v-card-title>
    <v-card-text>
      <v-text-field v-model="editCoupon.code" label="Code" />
      <v-select v-model="editCoupon.type" :items="couponTypes" label="Type"/>
      <v-text-field prefix="R" v-if="editCoupon.type === 'fixed'" v-model="editCoupon.amount" label="Amount"/>
      <v-slider
        label="Discount (%)"
        v-else-if="editCoupon.type === 'percent'"
        v-model="editCoupon.amount"
        class="align-center"
        :max="1"
        :min="0.01"
        :step="0.01"
        hide-details
      >
        <template v-slot:append>
          <v-text-field
            v-model="editCoupon.amount"
            hide-details
            single-line
            density="compact"
            type="number"
            style="width: 90px"
          ></v-text-field>
        </template>
      </v-slider>
      <v-text-field v-model="editCoupon.maxUses" :label="$t('coupon.max_uses')"/>
      <v-select
        v-model="editCoupon.products"
        :items="productTypes"
        label="Products"
        multiple
        chips
        deletable-chips
        small-chips
        dense/>
    </v-card-text>
    <EditCardActions
      @close="$emit('close')"
      @update="emitUpdate"
      :coupon="editCoupon"/>
  </v-card>
</template>

<script setup lang="ts">
import { ref, watchEffect } from "vue";
import { ProductType } from "@YenzaCT/sdk";
import EditCardActions from "@/pages/CouponDetailPage/components/EditCardActions.vue";

const props = defineProps({
  coupon: Object,
});
const editCoupon = ref({...props.coupon});

const emit = defineEmits(["close", "update"]);

watchEffect(() => {
  editCoupon.value = {...props.coupon};
});

const couponTypes = [
  { title: "Full", value: "full"},
  { title: "Percent", value: "percent"},
  { title: "Fixed", value: "fixed"},
];

const productTypes = Object.values(ProductType) as string[];

// Emit update event when the coupon is updated
const emitUpdate = () => {
  emit("update", editCoupon.value);
};

</script>

<style scoped>

</style>
