<template>
  <v-card flat color="grey-lighten-4">
    <v-card-title>
      {{ $t("components.SubjectChoiceNavigatorConfigForm.title") }}
    </v-card-title>
    <v-divider class="my-2"/>
    <v-card-text>
      <configuration-section-row :show-divider="false">
        <template #name>
          {{ $t('components.SubjectChoiceNavigatorConfigForm.available_curricula') }}
        </template>
        <template #comment>
          Selected curriculums will be available for the user in the Subject Choice tools.
        </template>
        <v-select
          v-model="selected"
          multiple
          :items="curricula"
        >
        </v-select>
      </configuration-section-row>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="save">
        {{ $t('buttons.apply') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {useI18n} from "vue-i18n";
import {useGlobalStore} from "@/store";
import {YapiError, isYapiError} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import ConfigurationSectionRow from "@/pages/AppConfigurationPage/components/ConfigurationSectionRow.vue";
import {onMounted, ref} from "vue";

const store = useGlobalStore();
const {t} = useI18n();
const curricula = ref<string[]>([]);

onMounted(async () => {
  await fetchCurricula();
});

const selected = ref([...store.appConfiguration.featureSettings.subjectChoiceNavigator.curriculums]);

/**
 * Fetches the curricula from the API.
 */
async function fetchCurricula() {
  try {
    store.networkBusy = true;
    curricula.value = Object.keys((await yapi.admin.curriculum.list()).data);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

async function save(): Promise<void> {
  if (!selected.value.length) {
    store.showSnackbar(t("empty_message"));
    return;
  }
  try {
    const featureSettings = store.appConfiguration.featureSettings;
    featureSettings.subjectChoiceNavigator.curriculums = selected.value;

    await store.saveAppConfiguration("featureSettings", featureSettings);
    store.showSnackbar(t("components.SubjectChoiceNavigatorConfigForm.save_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  }
}
</script>

<style scoped>

</style>
