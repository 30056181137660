export default {
  "national_senior_certificate": {
    "title": "National Senior Certificate",
    "subjects": {
      "english_first_language": "English",
      "afrikaans_first_language": "Afrikaans",
      "ndebele_first_language": "isiNdebele",
      "sesotho_first_language": "Sesotho",
      "isixhosa_first_language": "isiXhosa",
      "isizulu_first_language": "isiZulu",
      "sepedi_first_language": "Sepedi",
      "setswana_first_language": "Setswana",
      "siswati_first_language": "siSwati",
      "tshivenda_first_language": "Tshivenda",
      "xitsonga_first_language": "Xitsonga",
      "english_second_language": "English",
      "afrikaans_second_language": "Afrikaans",
      "ndebele_second_language": "isiNdebele",
      "sesotho_second_language": "Sesotho",
      "isixhosa_second_language": "isiXhosa",
      "isizulu_second_language": "isiZulu",
      "sepedi_second_language": "Sepedi",
      "setswana_second_language": "Setswana",
      "siswati_second_language": "siSwati",
      "tshivenda_second_language": "Tshivenda",
      "xitsonga_second_language": "Xitsonga",
      "life_orientation": "Life Orientation",
      "mathematics": "Mathematics",
      "further_studies_mathematics": "Further Studies Mathematics",
      "further_studies_english": "Further Studies English",
      "further_studies_physics": "Further Studies Physics",
      "mathematical_literacy": "Mathematical Literacy",
      "ap_afrikaans": "AP Afrikaans",
      "ap_physics": "AP Physics",
      "ap_english": "AP English",
      "ap_maths": "AP Maths",
      "urdu": "Urdu",
      "telugu": "Telugu",
      "tamil": "Tamil",
      "spanish": "Spanish",
      "portuguese": "Portuguese",
      "latin": "Latin",
      "italian": "Italian",
      "hindi": "Hindi",
      "hebrew": "Hebrew",
      "gujarati": "Gujarati",
      "german": "German",
      "french": "French",
      "arabic": "Arab",
      "agricultural_technology": "Agricultural Technology",
      "engineering_graphics_and_design": "Engineering Graphics & Design",
      "music": "Music",
      "dramatic_arts": "Dramatic Arts",
      "dance_studies": "Dance Studies",
      "religion_studies": "Religion Studies",
      "consumer_studies": "Consumer Studies",
      "economics": "Economics",
      "information_technology": "Information Technology",
      "computer_applications_technology": "Computer Applications Technology",
      "electrical_technology": "Electrical Technology",
      "visual_arts": "Visual Arts",
      "physical_sciences": "Physical Sciences",
      "design": "Design",
      "mechanical_technology": "Mechanical Technology",
      "civil_technology": "Civil Technology",
      "business_studies": "Business Studies",
      "life_sciences": "Life Sciences",
      "agricultural_sciences": "Agricultural Sciences",
      "hospitality_studies": "Hospitality Studies",
      "geography": "Geography",
      "tourism": "Tourism",
      "history": "History",
      "agricultural_management_practices": "Agricultural Management Practices",
      "accounting": "Accounting"
    },
    "subject_groups": {
      "first_language": "First Language",
      "second_language": "Second Language",
      "life_orientation": "Life Orientation",
      "mathematics": "Mathematics",
      "elective_1": "Elective 1",
      "elective_2": "Elective 2",
      "elective_3": "Elective 3",
      "elective_4": "Elective 4",
      "elective_5": "Elective 5"
    }
  },
  "kenyan_certificate_of_secondary_education": {
    "title": "Kenyan Certificate of Secondary Education",
    "subjects": {
      "mathematics": "Mathematics",
      "english": "English",
      "kiswahili": "Kiswahili",
      "physics": "Physics",
      "biology": "Biology",
      "chemistry": "Chemistry",
      "geography": "Geography",
      "history_and_government": "History And Government",
      "christian_religious_studies": "Christian Religious Studies",
      "hindu_religious_studies": "Hindu Religious Studies",
      "islamic_religious_studies": "Islamic Religious Studies",
      "home_science": "Home Science",
      "art_and_design": "Art And Design",
      "agriculture": "Agriculture",
      "woodwork": "Woodwork",
      "metal_work": "Metal Work",
      "building_construction": "Building Construction",
      "power_mechanics": "Power Mechanics",
      "electricity": "Electricity",
      "drawing_and_design": "Drawing And Design",
      "aviation_technology": "Aviation Technology",
      "computer_studies": "Computer Studies",
      "french": "French",
      "german": "German",
      "arabic": "Arabic",
      "kenyan_sign_language": "Kenyan Sign Language",
      "music": "Music",
      "business_studies": "Business Studies"
    },
    "subject_groups": {
      "mathematics": "Mathematics",
      "english": "English",
      "kiswahili": "Kiswahili",
      "science_1": "Science 1",
      "science_2": "Science 2",
      "humanities": "Humanities",
      "elective_1": "Elective 1",
      "elective_2": "Elective 2"
    }

  },
  "cambridge": {
    "title": "Cambridge",
    "subject_groups": {
      "group_1": "Group 1",
      "group_2": "Group 2",
      "group_3": "Group 3",
      "group_4": "Group 4",
      "group_5": "Group 5",
      "ungrouped": "Ungrouped"
    },
    "subjects": {
      "english_language_and_literature_a_as": "English Language and Literature A/AS",
      "english_language_a_as": "English Language A/AS",
      "english_first_language_igcse": "English First Language IGCSE",
      "afrikaans_second_language": "Afrikaans",
      "urdu_second_language": "Urdu",
      "turkish_second_language": "Turkish",
      "thai_second_language": "Thai",
      "telugu_second_language": "Telugu",
      "tamil_second_language": "Tamil",
      "spanish_second_language": "Spanish",
      "swahili_second_language": "Swahili",
      "russian_second_language": "Russian",
      "nepali_second_language": "Nepali",
      "marati_second_language": "Marati",
      "malay_second_language": "Malay",
      "korean_second_language": "Korean",
      "japanese_second_language": "Japanese",
      "italian_second_language": "Italian",
      "isizulu_second_language": "isiZulu",
      "hindi_second_language": "Hindi",
      "greek_second_language": "Greek",
      "german_second_language": "German",
      "french_second_language": "French",
      "dutch_second_language": "Dutch",
      "chinese_second_language": "Chinese",
      "arabic_second_language": "Arabic",
      "latin_second_language_igcse": "Latin IGCSE",
      "accounting_a_as": "Accounting A/ASS",
      "biology_a_as": "Biology A/AS",
      "physics_a_as": "Physics A/AS",
      "physical_science_igcse": "Physical Science IGCSE",
      "physics_igcse": "Physics IGCSE",
      "mathematics_igcse": "Mathematics IGCSE",
      "mathematics_a_as": "Mathematics A/AS",
      "co_ordinated_science_igcse": "Co-ordinated Science IGCSE",
      "combined_science_igcse": "Combined Science IGCSE",
      "biology_igcse": "Biology IGCSE",
      "chemistry_igcse": "Chemistry IGCSE",
      "art_and_design_a_as": "Art and Design A/AS",
      "chemistry_a_as": "Chemistry A/AS",
      "music_a_as": "Music A/AS",
      "history_a_as": "History A/AS",
      "geography_a_as": "Geography A/AS",
      "music_igcse": "Music IGCSE",
      "history_igcse": "History IGCSE",
      "environmental_management_igcse": "Environmental Management IGCSE",
      "economics_igcse": "Economics IGCSE",
      "geography_igcse": "Geography IGCSE",
      "economics_a_as": "Economics A/AS",
      "art_and_design_igcse": "Art and Design IGCSE",
      "business_a_as": "Business A/AS",
      "computer_science_a_as": "Computer Science A/AS",
      "information_technology_a_as": "Information Technology A/AS",
      "further_mathematics_a_as": "Further Mathematics A/AS",
      "religious_studies_igcse": "Religious Studies IGCSE",
      "divinity_a_as": "Divinity A/AS",
      "design_and_technology_igcse": "Design & Technology IGCSE",
      "computer_science_igcse": "Computer Science IGCSE",
      "business_studies_igcse": "Business Studies IGCSE",
      "accounting_igcse": "Accounting IGCSE",
      "drama_cambri_a_as": "Drama A/AS",
      "literature_in_english_igcse": "Literature in English IGCSE",
      "travel_and_tourism_a_as": "Travel & Tourism A/AS",
      "travel_and_tourism_igcse": "Travel & Tourism IGCSE",
      "drama_igcse": "Drama IGCSE",
      "information_and_communication_technology_igcse": "Information & Communication Technology IGCSE"
    }
  },
  "pre_international_baccalaureate": {
    "title": "Pre International Baccalaureate",
    "subject_groups": {
      "group_1": "Group 1",
      "group_2": "Group 2",
      "group_3": "Group 3",
      "group_4": "Group 4",
      "group_5": "Group 5",
      "group_6": "Group 6"
    },
    "subjects": {
      "english": "English Language And Literature Hl",
      "french": "French",
      "business_management": "Business Management",
      "geography": "Geography",
      "history": "History",
      "philosophy": "Philosophy",
      "biology": "Biology",
      "chemistry": "Chemistry",
      "physics": "Physics",
      "computer_science": "Computer Science",
      "design_and_technology": "Design And Technology",
      "mathematics_core": "Mathematics Core",
      "further_studies_mathematics": "Further Studies Mathematics",
      "music": "Music",
      "visual_arts": "Visual Arts",
      "theatre_studies": "Theatre Studies",
    }
  },
  "international_baccalaureate": {
    "title": "International Baccalaureate",
    "subject_groups": {
      "group_1": "Group 1",
      "group_2": "Group 2",
      "group_3": "Group 3",
      "group_4": "Group 4",
      "group_5": "Group 5",
      "group_6": "Group 6"
    },
    "subjects": {
      "english_a_language_and_literature_sl": "English A Language And Literature SL",
      "english_a_language_and_literature_hl": "English A Language And Literature HL",
      "french_a_language_and_literature_hl": "French A Language And Literature HL",
      "french_a_language_and_literature_sl": "French A Language And Literature SL",
      "french_ab_initio": "French Ab Initio",
      "french_b": "French B",
      "business_management_hl": "Business Management HL",
      "business_management_sl": "Business Management SL",
      "economics_hl": "Economics HL",
      "economics_sl": "Economics SL",
      "geography_hl": "Geography HL",
      "geography_sl": "Geography SL",
      "history_hl": "History HL",
      "history_sl": "History SL",
      "psychology_hl": "Psychology HL",
      "psychology_sl": "Psychology SL",
      "social_and_cultural_anthropology_hl": "Social And Cultural Anthropology HL",
      "social_and_cultural_anthropology_sl": "Social And Cultural Anthropology SL",
      "digital_society_hl": "Digital Society HL",
      "digital_society_sl": "Digital Society SL",
      "global_politics_hl": "Global Politics HL",
      "global_politics_sl": "Global Politics SL",
      "philosophy_sl": "Philosophy SL",
      "philosophy_hl": "Philosophy HL",
      "world_religions_hl": "World Religions HL",
      "world_religions_sl": "World Religions SL",
      "environmental_systems_and_societies_sl": "Environmental Systems And Societies SL",
      "environmental_systems_and_societies_hl": "Environmental Systems And Societies HL",
      "biology_hl": "Biology HL",
      "biology_sl": "Biology SL",
      "chemistry_hl": "Chemistry HL",
      "chemistry_sl": "Chemistry SL",
      "computer_science_hl": "Computer Science HL",
      "computer_science_sl": "Computer Science SL",
      "design_technology_hl": "Design Technology HL",
      "design_technology_sl": "Design Technology SL",
      "physics_hl": "Physics HL",
      "physics_sl": "Physics SL",
      "sports_exercise_and_health_science_hl": "Sports Exercise And Health Science HL",
      "sports_exercise_and_health_science_sl": "Sports Exercise And Health Science SL",
      "mathematics_analysis_and_approaches_hl": "Mathematics Analysis And Approaches HL",
      "mathematics_analysis_and_approaches_sl": "Mathematics Analysis And Approaches SL",
      "mathematics_applications_and_interpretation_hl": "Mathematics Applications And Interpretation HL",
      "mathematics_applications_and_interpretation_sl": "Mathematics Applications And Interpretation SL",
      "film_hl": "Film HL",
      "film_sl": "Film SL",
      "music_hl": "Music HL",
      "music_sl": "Music SL",
      "theatre_hl": "Theatre HL",
      "theatre_sl": "Theatre SL",
      "visual_arts_hl": "Visual Arts HL",
      "visual_arts_sl": "Visual Arts SL"
    }
  }
};
