<template>
  <v-card-account-settings
    :title="$t('components.ResetPasswordDialog.title')"
    data-pw="resetPasswordDialogCard"
  >
    <v-card-text>
      <p class="pb-4">
        {{ $t('components.ResetPasswordDialog.info') }}
      </p>
      <v-text-field
        v-model="password"
        :rules="passwordRules"
        :type="showPassword ? 'text' : 'password'"
        :label="$t('components.ResetPasswordDialog.new_password_label')"
        placeholder="Enter New Password"
        :append-icon="showPassword ? 'fa-regular fa-eye' : 'fa-solid fa-eye-slash'"
        @click:append="showPassword = !showPassword"
        variant="outlined"
        required
        class="mx-6 mb-2"
        data-pw="newPasswordTexField"
      />
      <v-text-field
        v-model="confirmPassword"
        :rules="confirmPasswordRules"
        :type="showConfirmPassword ? 'text' : 'password'"
        :label="$t('components.ResetPasswordDialog.confirm_password_label')"
        placeholder="Confirm New Password"
        :append-icon="showConfirmPassword ? 'fa-regular fa-eye' : 'fa-solid fa-eye-slash'"
        :persistent-hint="true"
        @click:append="showConfirmPassword = !showConfirmPassword"
        :disabled="!password"
        variant="outlined"
        required
        class="mx-6"
        data-pw="confirmNewPasswordTexField"
      />
    </v-card-text>

    <v-card-actions>
      <v-btn-secondary
        data-pw="cancelResetPasswordButton"
        @click="$emit('close')"
      >
        {{ $t('buttons.cancel') }}
      </v-btn-secondary>
      <v-btn-primary
        @click="doActionAndClose"
        :disabled="!isButtonEnabled"
        data-pw="confirmResetPasswordButton"
      >
        {{ $t('buttons.confirm') }}
      </v-btn-primary>
    </v-card-actions>
  </v-card-account-settings>
</template>

<script setup lang="ts">
import {computed, PropType, ref, Ref} from "vue";
import {useI18n} from "vue-i18n";
import {DashUser} from "@YenzaCT/sdk";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";

const { t } = useI18n();
const store = useGlobalStore();

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  },
});

const emit = defineEmits<{
  (e: "close"): void;
}>();

function isStrongPassword(password: string): boolean {
  const matches = password.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,256}$/);
  return !!matches;
}

const showPassword: Ref<boolean> = ref(false);
const showConfirmPassword: Ref<boolean> = ref(false);

const password = ref("");
const passwordRules = [
  (v: string) => isStrongPassword(v) || t("components.ResetPasswordDialog.invalid_password"),
  (v: string) => !!v || t("components.ResetPasswordDialog.password_required")
];

const confirmPassword: Ref<string> = ref("");

const confirmPasswordRules = [
  (v: string) => isStrongPassword(v) || t("components.ResetPasswordDialog.invalid_password"),
  (v: string) => password.value.length !== 0 && password.value === v || t("components.ResetPasswordDialog.passwords_do_not_match"),
];

const isButtonEnabled = computed(() => {
  const isPasswordValid = passwordRules.every((rule) => rule(password.value) === true);
  const isConfirmPasswordValid = confirmPasswordRules.every((rule) => rule(confirmPassword.value) === true);
  return isPasswordValid && isConfirmPasswordValid;
});

const resetPassword = async (newPassword: string) => {
  if (!props.user) throw new Error(t("components.ResetPasswordDialog.warning"));

  if (props.user._id)
    await yapi.admin.user.changePassword(props.user._id, newPassword);
};

const doActionAndClose = async (): Promise<void> => {
  await resetPassword(password.value);
  emit("close");
  store.showSnackbar(t("components.ResetPasswordDialog.successfully_reset"));
};
</script>
