<template>
  <v-card class="data-table-toolbar px-4" elevation="0" data-pw="manageUserSectionCard">
    <v-row no-gutters align="center" justify="space-between">
      <v-col>
        <h3 class="pl-2">{{ $t('components.ManageUser.title') }}</h3>
      </v-col>
      <account-settings-menu
        :user="user"
        :isAdminOrRoot="isAdminOrRoot"
        :canEditAnyUsers="canEditAnyUsers"
        @updateUser="handleUpdateUser"
      />
    </v-row>
  </v-card>
  <v-row>
    <v-col v-if="canViewOrganisationDetails">
      <organisation-details-card
        :user="user"
        :can-read-tenant="canReadTenant"
        :can-read-institution="canReadInstitution"
        :can-read-cohort="canReadCohort"
      />
    </v-col>
    <v-col>
      <methods-card :user="user as User"/>
    </v-col>
  </v-row>
  <v-row>
    <v-col md="6">
      <basic-auth-card :user="user"/>
    </v-col>
    <v-col md="6">
      <v-card v-if="isAdminOrRoot" class="px-4 pb-4" data-pw="PermissionsSection">
        <v-card-title class="pl-1 pb-4 pt-5">
          {{ $t('components.UserDetailPage.user_detail_section_heading.permissions') }}
        </v-card-title>
        <v-divider class="pb-4"/>
        <v-card-subtitle class="pl-8">
          <span v-if="userRole === Role.Manager || userRole === Role.Root">
            {{ $t("components.ManageUser.current_permissions") }}
          </span>
          <span v-else>
            {{
              $t("components.UserDetailPage.user_detail_manager_permissions.warning")
            }}
          </span>
        </v-card-subtitle>
        <v-card-text>
          <permissions-list :permissions="user.app.permissions"/>
          <v-row class="px-4 pt-6" v-if="userRole === Role.Manager || userRole === Role.Root">
            <v-col>
              <span class="text-grey">
                {{ $t("components.UserDetailPage.manager_instructions") }}
              </span>
            </v-col>
          </v-row>
          <v-row v-if="userRole === Role.Manager || userRole === Role.Root">
            <v-col>
              <v-card>
                <v-card-text>
                  <tenant-search-dropdown
                    v-model="selectedTenant"
                    class="pb-4"
                  />
                  <institution-search-dropdown
                    v-model="selectedInstitution"
                    :tenant="selectedTenant"
                    class="pb-4"
                  />
                  <cohort-search-dropdown
                    v-model="selectedCohort"
                    :institution="selectedInstitution"
                    :tenant="selectedTenant"
                  />
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="selectedTenant && (userRole === Role.Manager || userRole === Role.Root)">
            <v-col cols="12">
              <v-card>
                <v-card-title>{{ getSelectedEntityTitle }}</v-card-title>
                <v-card-text>
                  <permission-checkboxes
                    :title="getSelectedEntityTitle"
                    v-model="checkboxesState"
                  />
                </v-card-text>
                <v-card-actions>
                  <v-btn-secondary
                    class="my-2"
                    @click="confirmPermission">
                    {{$t("components.ManageUser.save_permissions")}}
                  </v-btn-secondary>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
        <v-spacer v-if="userRole !== Role.Manager && userRole !== Role.Root"/>
        <v-btn-secondary
          class="my-2"
          data-pw="accountSettingsButton"
          prepend-icon="fa-solid fa-triangle-exclamation"
          v-if="userRole !== Role.Manager && userRole !== Role.Root"
          @click="showChangeRoleDialog = true"
        >
          {{
            $t("components.UserDetailPage.user_detail_manager_permissions.update_role_button")
          }}
        </v-btn-secondary>
      </v-card>
    </v-col>

  </v-row>
  <v-dialog v-model="showChangeRoleDialog" persistent max-width="500">
    <update-user-role
      :user="user"
      @roleUpdate="handleRoleUpdate"
      @close="handleUpdateUser"
    />
  </v-dialog>
</template>

<script setup lang="ts">
import PermissionsList from "@/pages/UserDetailPage/components/manage/PermissionsList.vue";
import {computed, PropType, Ref, ref, watch} from "vue";
import {
  DashUser,
  Role,
  Tenant,
  Institution,
  Cohort,
  Entity,
  Action,
  Permission,
  isYapiError,
  YapiError,
  User
} from "@YenzaCT/sdk";
import InstitutionSearchDropdown from "@/components/FormFields/InstitutionSearchDropdown.vue";
import PermissionCheckboxes from "@/pages/UserDetailPage/components/manage/PermissionCheckboxes.vue";
import CohortSearchDropdown from "@/components/FormFields/CohortSearchDropdown.vue";
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";
import BasicAuthCard from "@/pages/UserDetailPage/components/manage/BasicAuthCard.vue";
import {
  checkboxStateToAbilities,
  defaultCheckBoxStates,
  PermissionCheckBoxState,
  permissionToCheckboxState
} from "@/lib/permission";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import UpdateUserRole from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/ChangeUserRoleCard.vue";
import AccountSettingsMenu from "@/pages/UserDetailPage/components/common/AccountSettingsMenu/AccountSettingsMenu.vue";
import OrganisationDetailsCard from "@/pages/UserDetailPage/components/manage/OrganisationDetailsCard.vue";
import MethodsCard from "@/pages/UserDetailPage/components/manage/MethodsCard.vue";

const store = useGlobalStore();
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);

const props = defineProps({
  user: {
    type: Object as PropType<DashUser>,
    required: true
  }
});

const emit = defineEmits(["updateUser"]);
const userRole = ref(props.user.app.role);
const user = ref<DashUser>(props.user);
const showChangeRoleDialog = ref(false);

function handleRoleUpdate(updatedRole: Role) {
  userRole.value = updatedRole;
}

function handleUpdateUser() {
  const success = true;
  showChangeRoleDialog.value = false;
  emit("updateUser", success);
}

const canEditAnyUsers = computed(() => store.hasAbility(Entity.User, Action.edit));
const selectedTenant = ref<Tenant>();
const selectedInstitution = ref<Institution>();
const selectedCohort = ref<Cohort>();
const checkboxesState: Ref<PermissionCheckBoxState> = ref({});

const getSelectedEntityTitle = computed(() => {
  let title = "";
  if (selectedCohort.value) {
    title = selectedCohort.value.title || "";
  } else if (selectedInstitution.value) {
    title = selectedInstitution.value.title || "";
  } else if (selectedTenant.value) {
    title = selectedTenant.value.title || "";
  }
  return title;
});

function getUserPermission(entityId: string): Permission | undefined {
  if (!user.value) return undefined;
  return user.value.app.permissions.find((p) => p.entityId === entityId);
}

function getEntityOfSelected(): Entity | null {
  if (selectedCohort.value) {
    return Entity.Cohort;
  } else if (selectedInstitution.value) {
    return Entity.Institution;
  } else if (selectedTenant.value) {
    return Entity.Tenant;
  } else {
    return null;
  }
}

async function confirmPermission(): Promise<void> {
  if (store.networkBusy) {
    store.showSnackbar("Please wait for the previous request to finish");
    return;
  }

  const entity = getEntityOfSelected();
  if (!entity) return;
  const entityId = selectedCohort.value?._id || selectedInstitution.value?._id || selectedTenant.value?._id;

  const permission: Permission = {
    entity: entity,
    entityId: entityId as string,
    abilities: checkboxStateToAbilities(checkboxesState.value),
  };
  store.networkBusy = true;
  await setPermission(permission);
  store.networkBusy = false;
  store.showSnackbar(`Permissions for ${getSelectedEntityTitle.value} updated`);
}

async function setPermission(permission: Permission) {
  try {
    store.networkBusy = true;
    const res = await yapi.admin.user.setPermission(
      user.value?._id as string,
      permission,
    );
    user.value = res.data.user;
    handleUpdateUser();
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      store.showSnackbar(yError.message);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

const canReadEntity = (entity: Entity, subEntity: Entity) => {
  const userEntityValue = props.user?.app[entity.toLowerCase() as "cohort" | "institution" | "tenant"];
  if (!userEntityValue) return false;

  return isAdminOrRoot.value || store.canAccessSubEntityViaEntity(
    entity,
    subEntity,
    Action.read,
    userEntityValue
  ) || store.canAccessSubEntityViaEntity(
    subEntity,
    subEntity,
    Action.read,
    userEntityValue
  );
};

const canReadTenant = computed(() => canReadEntity(Entity.Tenant, Entity.Tenant));
const canReadInstitution = computed(() => canReadEntity(Entity.Tenant, Entity.Institution));
const canReadCohort = computed(() =>
  canReadEntity(Entity.Tenant, Entity.Cohort) || canReadEntity(Entity.Institution, Entity.Cohort));
const canViewOrganisationDetails = computed(() => canReadTenant.value || canReadInstitution.value || canReadCohort.value);

watch([selectedTenant, selectedInstitution, selectedCohort], async ([tenant, institution, cohort]) => {
  const entityId = cohort?._id || institution?._id || tenant?._id;
  const entity: Entity = getEntityOfSelected() as Entity;

  if (entityId) {
    const permission = getUserPermission(entityId);
    checkboxesState.value = permission
      ? permissionToCheckboxState(permission) : defaultCheckBoxStates[entity] as PermissionCheckBoxState;
  }
});
</script>

