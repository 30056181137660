<script setup lang="ts">
import {computed, PropType} from "vue";
import {useI18n} from "vue-i18n";
import {IPersonalityTraitsReportData} from "@/pages/ReportsDetailPage/reportTypes";

const {t} = useI18n();

defineProps({
  reportData: {
    type: Array as PropType<IPersonalityTraitsReportData[]>,
    required: true
  },
  loadingData: {
    type: Boolean,
    required: true
  }
});

const headers = computed(
  () => [
    {
      title: t("components.ReportsDetailPage.big_5_headers.tenant"),
      key: "tenant.title"
    },
    {
      title: t("components.ReportsDetailPage.big_5_headers.institution"),
      key: "institution.title"
    },
    {
      title: t("components.ReportsDetailPage.big_5_headers.cohort"),
      key: "cohort.title"
    },
    {
      title: t("components.ReportsDetailPage.big_5_headers.email"),
      key: "email"
    },
    {
      title: t("components.ReportsDetailPage.big_5_headers.phone"),
      key: "phone"
    },
    {
      title: t("components.ReportsDetailPage.big_5_headers.full_name"),
      key: "fullName"
    },
    {
      title: t("components.ReportsDetailPage.big_5_headers.open_mindedness"),
      key: "open_mindedness"
    },
    {
      title: t("components.ReportsDetailPage.big_5_headers.conscientiousness"),
      key: "conscientiousness"
    },
    {
      title: t("components.ReportsDetailPage.big_5_headers.extraversion"),
      key: "extraversion"
    },
    {
      title: t("components.ReportsDetailPage.big_5_headers.agreeableness"),
      key: "agreeableness"
    },
    {
      title: t("components.ReportsDetailPage.big_5_headers.negative_emotionality"),
      key: "negative_emotionality"
    },
  ]
);

function getLevelLabel(percentage: number) {
  if (percentage > 75)
    return "High";

  if (percentage > 35)
    return "Medium";

  return "Low";
}

function getLevelColor(percentage: number, inverted = false) {
  if (inverted) {
    if (percentage > 75)
      return "red";

    if (percentage > 35)
      return "orange";

    return "green";
  }

  if (percentage > 75)
    return "green";

  if (percentage > 35)
    return "orange";

  return "red";
}
</script>

<template>
  <v-data-table
    :items="reportData"
    :headers="headers"
    :loading="loadingData"
    :items-per-page="50"
  >
    <template v-slot:[`item.open_mindedness`]="{ item }: { item: IPersonalityTraitsReportData}">
      {{
        !item["open_mindedness"]
          ? $t("components.ReportsDetailPage.no_result")
          : item["open_mindedness"]
      }} <span v-if='item["open_mindedness"]'>%</span>
      <v-chip v-if='item["open_mindedness"]' :color='getLevelColor(item["open_mindedness"] as number)' size="x-small">
        {{ getLevelLabel(item["open_mindedness"] as number) }}
      </v-chip>
    </template>

    <template v-slot:item.conscientiousness="{ item }">
      {{
        !item["conscientiousness"]
          ? $t("components.ReportsDetailPage.no_result")
          : item["conscientiousness"]
      }} <span v-if='item["conscientiousness"]'>%</span>
      <v-chip v-if='item["conscientiousness"]' :color='getLevelColor(item["conscientiousness"] as number)'
              size="x-small">
        {{ getLevelLabel(item["conscientiousness"] as number) }}
      </v-chip>
    </template>

    <template v-slot:item.extraversion="{ item }">
      {{
        !item["extraversion"]
          ? $t("components.ReportsDetailPage.no_result")
          : item["extraversion"]
      }} <span v-if='item["extraversion"]'>%</span>
      <v-chip v-if='item["extraversion"]' :color='getLevelColor(item["extraversion"] as number)' size="x-small">
        {{ getLevelLabel(item["extraversion"] as number) }}
      </v-chip>
    </template>

    <template v-slot:item.agreeableness="{ item }">
      {{
        !item["agreeableness"]
          ? $t("components.ReportsDetailPage.no_result")
          : item["agreeableness"]
      }} <span v-if='item["agreeableness"]'>%</span>
      <v-chip v-if='item["agreeableness"]' :color='getLevelColor(item["agreeableness"] as number)' size="x-small">
        {{ getLevelLabel(item["agreeableness"] as number) }}
      </v-chip>
    </template>

    <template v-slot:item.negative_emotionality="{ item }">
      {{
        !item["negative_emotionality"]
          ? $t("components.ReportsDetailPage.no_result")
          : item["negative_emotionality"]
      }} <span v-if='item["negative_emotionality"]'>%</span>
      <v-chip v-if='item["negative_emotionality"]' :color='getLevelColor(item["negative_emotionality"] as number, true)'
              size="x-small">
        {{ getLevelLabel(item["negative_emotionality"] as number) }}
      </v-chip>
    </template>

  </v-data-table>
</template>

<style scoped>

</style>
