<template>
  <v-card>
    <v-card-text>
      <v-list>
        <v-list-item>
          <v-list-item-title>{{ $t("components.RevokeManagerPermissionsDialog.title") }}</v-list-item-title>
          <v-card-text>
            <v-alert density="compact" v-if="error" type="error" class="mb-4"> {{ error }}</v-alert>
            {{ message }}
          </v-card-text>
          <v-text-field
            v-model="verifyString"
            variant="outlined"
            :label="`Please type in '${confirmString}'`"
          >
          </v-text-field>
        </v-list-item>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-spacer/>
      <v-btn
        @click="$emit('close')"
      >
        {{ $t("buttons.cancel") }}
      </v-btn>
      <v-btn
        color="primary"
        @click="revokePermissions"
        v-bind:disabled="verifyString !== confirmString"
      >
        {{ $t("buttons.confirm") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {PropType, ref} from "vue";
import {Entity, isYapiError, User, YapiError} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";
import {useRoute} from "vue-router";

const route = useRoute();

const props = defineProps({
  manager: {
    type: Object as PropType<User>,
    required: true
  },
  confirmString: {
    type: String,
    required: true
  },
  message: {
    type: String,
    required: true
  },
  entityType: {
    type: String as PropType<Entity>,
    required: true,
  },
});

const emits = defineEmits<{
  (e: "confirm"): void;
  (e: "close"): void;
  (e: "revoked"): void;
}>();

const store = useGlobalStore();
const verifyString = ref("");
const error = ref<string | undefined>("");
const id = route.params.id as string;

const revokePermissions = async () => {
  try {
    if (props.manager._id) {
      await yapi.admin.managers.remove(props.entityType, id, props.manager._id);
      store.showSnackbar("Permissions revoked successfully");
      emits("revoked");
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      error.value = yError.response?.data.message;
    } else {
      throw e;
    }
  }
};
</script>

