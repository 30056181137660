<template>
  <v-row>

    <v-col cols="12" v-if="resetPasswordSuccessful" class="text-center">
      <v-icon size="x-large" color="green">fa fa-check-circle</v-icon>
      <p class="font-weight-bold py-3">
        {{ $t("components.PasswordRecoveryForm.password_reset_successfully") }}
      </p>

      <v-btn
        :block="true"
        color="primary"
        class="ma-auto"
        data-pw="submitRecoveryButton"
        :to="{ name: 'login' }"
      >
        {{ $t("components.PasswordRecoveryForm.continue_to_login") }}
        <v-icon class="mr-2">fa fa-arrow-right</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="12" v-else>
      <p class="font-weight-bold">
        {{ $t("components.PasswordRecoveryForm.your_code_has_been_sent_to") }} <span class="text-primary">{{
          contact
        }}</span>
      </p>

      <v-form
        ref="recoveryCodeForm"
        v-model="isValid"
        data-pw="enter-recovery-code"
        @submit.prevent="submitRecoveryCodeForm"
      >
        <!-- Recovery Code -->
        <div class="rounded mb-2">
          <v-otp-input
            v-model="recoveryCode"
            :rules="otpRules"
            :label="$t('components.PasswordRecoveryForm.recovery_code.label')"
            length="6"
            class="my-2"
            required
            persistent-placeholder
            name="recoveryCode"
            outlined
            data-pw="recoveryCodeField"
            width="=100%"
          />
        </div>
        <!-- New Password -->
        <PasswordTextField
          v-model="password"
          @validate="handlePasswordValidate"
          class="mt-5"
        />

        <v-alert v-if="!validatedCode" type="error">
          {{ $t("components.PasswordRecoveryForm.invalid_recovery_code") }}
        </v-alert>

        <!-- Submit Button -->
        <v-btn
          :loading="loading"
          :disabled="!isValid"
          :block="true"
          color="primary"
          class="ma-auto"
          data-pw="submitRecoveryButton"
          @click="submitRecoveryCodeForm"
        >
          <v-icon class="mr-2">fa fa-lock</v-icon>
          {{ $t("components.PasswordRecoveryForm.save_new_password") }}
        </v-btn>

        <v-alert v-if="recoveryType === 'email'" type="warning" variant="outlined" density="compact" class="mt-3">
          <span class="text-black">
            {{ $t("components.PasswordRecoveryForm.please_check_your_spam") }}
          </span>
        </v-alert>
      </v-form>

      <v-divider class="mt-4"/>

      <v-btn
        :block="true"
        variant="outlined"
        :to="{ name: 'login'}"
        data-pw="backToLoginButton"
        color="primary"
        class="mt-4 bg-grey-lighten-3">
        <v-icon class="mr-2">fa fa-arrow-left</v-icon>
        {{ $t("buttons.cancel") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {ref} from "vue";
import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {useI18n} from "vue-i18n";
import PasswordTextField from "@/components/FormFields/PasswordTextField.vue";

const props = defineProps({
  contact: {
    type: String,
    required: true,
  },
  recoveryType: {
    type: String,
    required: true,
  },
});

const {t} = useI18n();
const store = useGlobalStore();
const recoveryCode = ref("");
const password = ref("");
const isPasswordValid = ref(false);
const isValid = ref(false);
const loading = ref(false);
const resetPasswordSuccessful = ref(false);
const validatedCode = ref(true);

const otpRules = [
  (v: string) => (!!v && v.length >= 6) || t("components.PasswordRecoveryForm.otp_required")
];

function handlePasswordValidate(isValid: boolean) {
  isPasswordValid.value = isValid;
}

const submitRecoveryCodeForm = async () => {
  loading.value = true;
  try {
    await yapi.auth.resetPassword(props.contact, recoveryCode.value, password.value);
    resetPasswordSuccessful.value = true;
  } catch (e) {
    resetPasswordSuccessful.value = false;
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
      validatedCode.value = false;
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
  }
};

</script>
