<template>
  <v-file-input
    :label="label"
    v-model="files"
    :accept="accept"
    :disabled="disabled"
  />
  <v-row>
    <v-col>
      <div v-if="previewUrl" class="preview_area">
        <v-label>{{  $t("common.preview") }}</v-label>
        <img
          alt="Preview"
          :src="previewUrl"
          width="200px"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script setup lang="ts">
import {PropType, ref, watch, computed} from "vue";

defineProps({
  modelValue: {
    type: Object as PropType<File | undefined>,
  },
  label: {
    type: String as PropType<string>,
    default: "Select image"
  },
  previewHeight: {
    type: Number as PropType<number>,
    default: 120
  },
  accept: {
    type: String as PropType<string>,
    default: "image/png, image/jpeg"
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false
  }
});

const emit = defineEmits(["update:modelValue"]);

const files = ref([]);

const previewUrl = computed(() => {
  if (files.value.length > 0)
    return URL.createObjectURL(files.value[0]);
  else return null;
});

watch (files, () => {
  emit("update:modelValue", files.value[0]);
});
</script>

<style scoped>
.preview_area {
  display: flex;
  flex-direction: column;
}
</style>
