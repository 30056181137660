
<template>
  <v-select
    v-show="canShowCurriculum"
    hide-details
    variant="outlined"
    density="compact"
    class="mx-4"
    v-model="selectedCurriculum"
    :items="curricula"
    :label="$t('common.curriculum')"
    :placeholder="$t('common.curriculum')"
    @update:model-value="$emit('change', selectedCurriculum)"
  >
    <template v-slot:item="{ props, item }">
      <v-list-item v-bind="props" :title="$t(`curriculums.${item.value}.title`)"></v-list-item>
    </template>
    <template #selection="{ item }">
      <span v-if="item.value">{{ $t(`curriculums.${item.value}.title`) }}</span>
    </template>
  </v-select>
</template>

<script setup lang="ts">
import {onMounted, ref} from "vue";
import yapi from "@/lib/yapi";
import {Action, Entity, isYapiError, YapiError, Role} from "@YenzaCT/sdk";
import {useGlobalStore} from "@/store";

const selectedCurriculum = ref<string>("");
const loadingData = ref<boolean>(false);
const curricula = ref<string[]>([]);
const store = useGlobalStore();
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const canReadTenants = ref(store.hasAbility(Entity.Tenant, Action.read));
const canReadInstitutions = ref(store.hasAbility(Entity.Institution, Action.read));
const canShowCurriculum = ref(isAdminOrRoot.value || canReadTenants.value || canReadInstitutions.value);

onMounted(async () => {
  await getCurriculaList();
});

async function getCurriculaList() {
  try {
    loadingData.value = true;
    store.networkBusy = true;
    const res = await yapi.admin.curriculum.list();
    curricula.value = Object.values(res.data).map((curriculum) => curriculum.slug);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loadingData.value = false;
    store.networkBusy = false;
  }
}

</script>
