<template>
  <v-card flat color="grey-lighten-4">
    <v-card-title>
      {{ $t("components.TertiaryInstitutionFeatureSettings.title") }}
    </v-card-title>
    <v-divider class="my-2"/>
    <v-card-text>
      <configuration-section-row :show-divider="false">
        <template #name>
          {{ $t('components.TertiaryInstitutionFeatureSettings.visible_countries_title') }}
        </template>
        <template #comment>
          Institutions of the selected countries will be shown in the tool.
        </template>
        <v-select
          v-model="selected"
          multiple
          :hint="$t('components.TertiaryInstitutionFeatureSettings.visible_countries_hint')"
          :items="availableCountries"
        />
      </configuration-section-row>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn color="primary" @click="save">
        {{ $t('buttons.apply') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import {useI18n} from "vue-i18n";
import {useGlobalStore} from "@/store";
import {ref} from "vue";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import ConfigurationSectionRow from "@/pages/AppConfigurationPage/components/ConfigurationSectionRow.vue";

const store = useGlobalStore();
const {t} = useI18n();

const availableCountries = [
  {
    value: "KE", title: "Kenya"
  },
  {
    value: "ZA", title: "South Africa"
  }
];

const selected = ref(store.appConfiguration.featureSettings.tertiaryNavigator.countries);

async function save(): Promise<void> {
  if (!selected.value.length) {
    store.showSnackbar(t("empty_message"));
    return;
  }
  try {
    const settings = {...store.appConfiguration.featureSettings};
    settings.tertiaryNavigator.countries = selected.value;

    await store.saveAppConfiguration("featureSettings", settings);
    store.showSnackbar(t("components.TertiaryInstitutionFeatureSettings.save_message"));
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  }
}
</script>

<style scoped>

</style>
