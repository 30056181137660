import {ref, provide, Ref, computed} from "vue";
import {Cohort, isYapiError, Role, Tenant, YapiError} from "@YenzaCT/sdk";
import {DateFilterKey, DateFilterUnitKey, DateFilterValueKey} from "@/symbols/metrics";
import {useRoute} from "vue-router";
import {useGlobalStore} from "@/store";
import yapi from "@/lib/yapi";
import {downloadCSV, downloadExcel} from "@/lib/fileDownload";

export function useInsights() {
  const route = useRoute();
  const store = useGlobalStore();

  // Passes down the date filters to the filter bar
  const dateFilter: Ref<string> = ref("in the last");
  const dateFilterUnit: Ref<string> = ref("days");
  const dateFilterValue: Ref<string | string[]> = ref("7");

  const timeFilter = computed(() => ([{
    dateFilterType: dateFilter.value,
    unit: dateFilterUnit.value,
    value: dateFilterValue.value
  }]));

  provide(DateFilterKey, dateFilter);
  provide(DateFilterUnitKey, dateFilterUnit);
  provide(DateFilterValueKey, dateFilterValue);

  const tenantTitle = ref<string>("");
  const cohortTitle = ref<string>("");
  const isReportActive = ref<boolean>(false);
  const report = route.params.report as string;
  const reportData: Ref<Record<string, unknown>[]> = ref([]);
  const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
  const isManager = ref(store.user?.app.role === Role.Manager);
  const loadingData = ref<boolean>(false);

  // Filters
  const tenantId = ref<string | undefined>();
  const cohortId = ref<string | undefined>();
  const institutionIds = ref<string[]>([]);
  const institutionTitles = ref<string[]>([]);
  const curriculum = ref<string | undefined>();

  // Breakdowns
  const breakdownByCohort = ref<boolean>(false);
  const breakdownByInstitution = ref<boolean>(false);

  async function getReportData(report: string, data: Record<string, unknown>) {
    try {
      loadingData.value = true;
      store.networkBusy = true;
      const res = await yapi.admin.reports.post(report, data);

      reportData.value = res.data;
      isReportActive.value = true;
    } catch (e) {
      if (isYapiError(e)) {
        const yError = e as YapiError;
        await store.handleYapiError(yError);
      } else {
        throw e;
      }
    } finally {
      loadingData.value = false;
      store.networkBusy = false;
    }
  }

  async function exportData(report: string, data: Record<string, unknown>) {
    try {
      loadingData.value = true;
      store.networkBusy = true;
      const res = await yapi.admin.reports.export(report, data);
      if (res.data.format === "excel")
        downloadExcel(res.data.content.toString(), `${report}`);
      else downloadCSV(res.data.content.toString(), `${report}`);
    } catch (e) {
      if (isYapiError(e)) {
        const yError = e as YapiError;
        await store.handleYapiError(yError);
      } else {
        throw e;
      }
    } finally {
      loadingData.value = false;
      store.networkBusy = false;
    }
  }

  /**
   * Handles the selecting of a tenant from the filter
   * @param tenant The selected tenant object
   */
  function onTenantChange(tenant: Tenant | undefined) {
    if (tenant && tenant._id)
      tenantId.value = tenant._id;

    if (tenant && tenant.title)
      tenantTitle.value = tenant.title;

    if (!tenant) {
      tenantTitle.value = "";
      tenantId.value = "";
    }
  }

  /**
   * Handles the selecting of a cohort from the filter
   * @param cohort The selected cohort object
   */
  function onCohortChange(cohort: Cohort | undefined) {
    if (cohort && cohort._id)
      cohortId.value = cohort._id;

    if (cohort && cohort.title)
      cohortTitle.value = cohort.title;

    if (!cohort) {
      cohortTitle.value = "";
      cohortId.value = "";
    }
  }

  /**
   * Returns the color for the usage percentage
   * @param usagePercentage The usage percentage
   */
  function getColor(usagePercentage: number) {
    if (usagePercentage < 30) return "red";
    else if (usagePercentage < 50) return "orange";
    else return "green";
  }

  /**
   * Handles the selecting of an institution IDs from the filter
   * @param ids
   * @param titles
   */
  function onInstitutionSelectionChange(ids: string[], titles: string[]) {
    if (ids)
      institutionIds.value = ids;

    if (titles)
      institutionTitles.value = titles;
  }

  /**
   * Handles the selecting of a cohort breakdown from the filter
   * @param value The selected value
   */
  function onCohortBreakdownChange(value: boolean) {
    breakdownByCohort.value = value;
  }

  function onInstitutionBreakdownChange(value: boolean) {
    breakdownByInstitution.value = value;
  }

  /**
   * Handles the selecting of a curriculum from the filter
   * @param curriculumSlug The selected curriculum slug
   */
  function onCurriculumChange(curriculumSlug: string) {
    curriculum.value = curriculumSlug;
  }

  /**
   * Checks if the report data has any data
   * @param report
   */
  function isReportEmpty(report: Array<object>) {
    if (report.length === 0) return false;
    return report.some((item) => {
      // Check if the object is empty
      const isEmptyObject = Object.keys(item).length === 0;

      // Check if the object has at least one key with a non-empty array
      const dataIsSet = Object.values(item).some(
        (value) => Array.isArray(value) && value.length > 0
      );

      // Check if any key in the object has a non-empty object (with values greater than 0)
      const hasNonEmptyObject = Object.values(item).some(
        (value) => typeof value === "object" && value !== null &&
          Object.values(value).some((num) => typeof num === "number" && num > 0)
      );

      return !isEmptyObject && (dataIsSet || hasNonEmptyObject);
    });
  }

  return {
    dateFilter,
    dateFilterUnit,
    dateFilterValue,
    timeFilter,
    tenantId,
    tenantTitle,
    cohortId,
    cohortTitle,
    institutionIds,
    institutionTitles,
    curriculum,
    isReportActive,
    breakdownByCohort,
    breakdownByInstitution,
    report,
    loadingData,
    reportData,
    store,
    isAdminOrRoot,
    isManager,
    getColor,
    getReportData,
    exportData,
    onTenantChange,
    onCohortChange,
    onCohortBreakdownChange,
    onInstitutionBreakdownChange,
    onCurriculumChange,
    onInstitutionSelectionChange,
    isReportEmpty
  };
}
