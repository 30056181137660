<template>
  <AuthenticatedLayout>
    <template #headerIcon>
      far fa-users
    </template>
    <template #header>
      {{ $t("components.UserTablePage.title") }}
    </template>

    <v-data-table-server
      class="mb-6"
      color="bg-transparent"
      density="compact"
      :loading="store.networkBusy"
      :headers="headers"
      :items="users"
      :items-per-page="perPage"
      :items-length="0"
      @click:row="onClickRow"
      @update:sort-by="onSort"
      :sort-by="sort"
      :hover="true"
      data-pw="usersTable"
      hide-default-footer
    >
      <template v-slot:no-data>
        <h5 class="pa-2 d-flex align-center justify-center align-items-center">{{ $t("common.no_data_message") }}</h5>
      </template>
      <template v-slot:loader>
        <v-sheet
          class="d-flex align-center justify-center"
          height="100%"
        >
          <v-progress-linear
            indeterminate
            color="primary"
          ></v-progress-linear>
        </v-sheet>
      </template>

      <template v-slot:top>
        <div class="bg-white rounded">
          <v-toolbar class="data-table-toolbar mb-0">
            <search-text-field
              class="ml-2"
              :label="$t('common.search_user_details')"
              data-pw="emailSearchBox"
              :disabled="loading"
              :search-string="searchString"
              @update:text="text => {
                handleSearch(text)
              }"
            />
            <v-spacer/>
            <toolbar-menu
              :label="$t('buttons.filter')"
              :active-filter-count="activeFilterCount"
              @resetFilter="handleReset"
              icon="far fa-filter"
            >
              <toolbar-menu-list-item-menu
                :label="columns.email.title"
                data-pw="userEmailFilter"
                :is-filter-active="!!columns.email.filter"
              >
                <filter-text-field
                  :label="columns.email.title"
                  data-pw="userEmailFilterTextBox"
                  :disabled="store.networkBusy"
                  v-model="emailFilter"
                  @change="columns.email.filterText = `${ columns.email.filter}`"
                />
              </toolbar-menu-list-item-menu>
              <toolbar-menu-list-item-menu
                :label="columns.role.title"
                :is-filter-active="!!columns.role.filter"
              >
                <select-role
                  :model-value="columns.role.filter as Role"
                  type="filter"
                  @update:model-value="updateRoleFilter"
                  data-pw="userRoleFilter"
                />
              </toolbar-menu-list-item-menu>
              <toolbar-menu-list-item-menu
                :label="columns.phone.title"
                data-pw="userPhoneFilter"
                :is-filter-active="!!columns.phone.filter"
              >
                <filter-text-field
                  data-pw="userPhoneFilterTextBox"
                  :label="columns.phone.title"
                  :disabled="store.networkBusy"
                  v-model="phoneFilter"
                  @change="columns.phone.filterText = `${ columns.phone.filter}`"
                />
              </toolbar-menu-list-item-menu>
              <toolbar-menu-list-item-menu
                :label="columns.accountType.title"
                :is-filter-active="!!columns.accountType.filter"
              >
                <select-account-type
                  :label="columns.accountType.title"
                  data-pw="accountTypeFilter"
                  v-model="columns.accountType.filter"
                  @update:modelValue="columns.accountType.filterText = $t(`accountTypes.${columns.accountType.filter}`)"
                />
              </toolbar-menu-list-item-menu>
              <toolbar-menu-list-item-menu
                :label="columns.termsConsent.title"
                data-pw="userTermsConsentFilter"
                :is-filter-active="!!columns.termsConsent.filter"
              >
                <v-radio-group
                  v-model="columns.termsConsent.filter"
                  data-pw="userTermsConsentFilter"
                  :disabled="store.networkBusy"
                >
                  <v-radio
                    :label="$t('common.yes')"
                    value="true"
                    data-pw="userTermsConsentFilterYes"
                    @change="columns.termsConsent.filterText = 'Yes'"
                  />
                  <v-radio
                    :label="$t('common.no')"
                    value="false"
                    data-pw="userTermsConsentFilterNo"
                    @change="columns.termsConsent.filterText = 'No'"
                  />
                  <v-radio
                    :label="$t('common.all')"
                    value="all"
                    data-pw="userTermsConsentFilterAll"
                    @change="columns.termsConsent.filterText = 'All'"
                  />
                </v-radio-group>
              </toolbar-menu-list-item-menu>
              <toolbar-menu-list-item-menu
                :label="$t('components.UserTablePage.firstName')"
                data-pw="userNameFilter"
                :is-filter-active="!!columns.firstName.filter"
              >
                <filter-text-field
                  :label="$t('components.UserTablePage.firstName')"
                  v-model="firstNameFilter"
                  @change="columns.firstName.filterText = `${columns.firstName.filter}`"
                />
              </toolbar-menu-list-item-menu>
              <toolbar-menu-list-item-menu
                :label="$t('components.UserTablePage.lastName')"
                data-pw="userLastNameFilter"
                :is-filter-active="!!columns.lastName.filter"
              >
                <filter-text-field
                  :label="$t('components.UserTablePage.lastName')"
                  v-model="lastNameFilter"
                  @change="columns.lastName.filterText = `${columns.lastName.filter}`"
                />
              </toolbar-menu-list-item-menu>
              <toolbar-menu-list-item-menu
                :label="t('common.segment')"
                :is-filter-active="!!columns.tenant.filter"
              >
                <tenant-search-dropdown
                  v-model="selectedTenant"
                  class="mb-4"
                />
                <tenant-institution-single-select-autocomplete
                  v-model="selectedInstitution"
                  :tenant="selectedTenant"
                  :is-filter-active="!!columns.institution.filter"
                  class="mb-4"
                />
                <cohort-search-dropdown
                  v-model="selectedCohort"
                  :institution="selectedInstitution"
                  :tenant="selectedTenant"
                />
              </toolbar-menu-list-item-menu>
              <toolbar-menu-list-item-menu
                v-model="createdAtDateFilterMenu"
                :label="columns.createdAt.title"
                :is-filter-active="columns.createdAt.filter.length > 0"
              >
                <vue-date-picker
                  v-model="createdAtDateFilter"
                  range
                  inline
                  auto-apply
                  multi-calendars
                  :enable-time-picker="false"
                />
              </toolbar-menu-list-item-menu>
              <toolbar-menu-list-item-menu
                v-model="termsConsentedAtDateFilterMenu"
                :label="columns.termsConsentedAt.title"
                :is-filter-active="columns.termsConsentedAt.filter.length > 0"
              >
                <vue-date-picker
                  v-model="termsConsentedAtDateFilter"
                  range
                  inline
                  auto-apply
                  multi-calendars
                  :enable-time-picker="false"
                />
              </toolbar-menu-list-item-menu>
            </toolbar-menu>
            <toolbar-menu
              :label="$t('buttons.columns')"
              class="mr-2"
              icon="far fa-columns"
            >
              <v-list-item :key="column.modelKey" v-for="column in columns"
              >
                <v-checkbox-btn
                  :label="column.title"
                  v-model="column.visible"
                  :color="column.visible ? 'primary' : 'grey'"
                  :data-pw="`${column.modelKey}-column`"
                />
              </v-list-item>
            </toolbar-menu>
            <data-export-download-menu v-if="isAdminOrRoot" :formats="['csv', 'excel']" @click="handleExportClick"/>
          </v-toolbar>
          <div
            v-if="activeFilterCount > 0"
            class="px-2 pb-2">
            <b>{{ $t("common.filters") }}</b>
            <template
              v-for="column in columns">
              <v-chip
                :key="`${column.modelKey}_filter`"
                class="ml-2"
                size="small"
                v-if="column.filter && column.filterText">{{ column.title }}: <b>
                  {{ column.filterText }}</b></v-chip>
            </template>
          </div>
        </div>
      </template>

      <template v-slot:item.app.accountType="{ item }">
        {{ $t(`accountTypes.${item.app.accountType}`) }}
      </template>

      <template v-slot:item.app.role="{ item }">
        {{ $t(`roles.${item.app.role}`) }}
      </template>

      <template v-slot:item.consents.terms.granted="{ item }">
        <v-icon v-if="item.consents?.terms?.granted">
          far fa-check
        </v-icon>
      </template>

      <template v-slot:item.consents.terms.updatedAt="{ item }">
        {{ toLocaleDateTime(item.consents?.terms?.updatedAt?.toString()) }}
      </template>

      <template v-slot:item.createdAt="{ item }">
        {{ toLocaleDateTime(item["createdAt"]?.toString()) }}
      </template>

      <template v-slot:bottom>
        <v-divider/>
        <pagination-controls
          v-if="paginatedData"
          class="text-center pt-2"
          :pagination-footer-data="paginationFooterData"
          @update:per-page="onUpdatePerPage"
          @on-next="onNext"
          @on-prev="onPrev"
          @on-page-change="onPageChange"
          data-pw="userPaginationControls"
        />
      </template>

    </v-data-table-server>

    <v-dialog
      v-model="showExportDialog"
      max-width="500"
    >
      <export-user-dialog
        @close="showExportDialog = false"
        @download="exportPages"
        :format="exportFormat"
        :cols="cols"
      >
      </export-user-dialog>
    </v-dialog>

    <v-snackbar
      v-model="showExportingSnackBar">
      {{ $t("common.download_status") }}
      <v-progress-linear :indeterminate="true"/>
    </v-snackbar>
  </AuthenticatedLayout>
</template>

<script lang="ts" setup>
import {computed, onMounted, ref, Ref, watch} from "vue";
import {useRouter, useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {
  AccountType,
  isYapiError,
  Paginated,
  DashUser,
  UserExportSelectors,
  UserPaginationParams,
  YapiError, Role,
} from "@YenzaCT/sdk";

import yapi from "@/lib/yapi";
import {useGlobalStore} from "@/store";

import AuthenticatedLayout from "@/layout/AuthenticatedLayout.vue";

import PaginationControls from "@/components/PaginationControls.vue";
import {ColumnsConfig, FooterData, footerDataFactory, usePagination} from "@/lib/pagination";
import SearchTextField from "@/components/SearchTextField.vue";
import ToolbarMenu from "@/components/DataTable/ToolbarMenu.vue";
import ToolbarMenuListItemMenu from "@/components/DataTable/ToolbarMenuListItemMenu.vue";
import FilterTextField from "@/components/DataTable/FilterTextField.vue";
import TenantSearchDropdown from "@/components/FormFields/TenantSearchDropdown.vue";
import CohortSearchDropdown from "@/components/FormFields/CohortSearchDropdown.vue";
import TenantInstitutionSingleSelectAutocomplete
  from "@/components/FormFields/InstitutionSearchDropdown.vue";
import SelectAccountType from "@/components/SelectAccountType.vue";
import ExportUserDialog from "@/pages/UserTablePage/components/ExportUserDialog.vue";
import {WritableComputedRef} from "@vue/reactivity";
import {toLocaleDateTime} from "@/lib/time";
import VueDatePicker from "@vuepic/vue-datepicker";
import {DateTime} from "luxon";
import {downloadCSV, downloadExcel} from "@/lib/fileDownload";
import DataExportDownloadMenu from "@/components/DataExportDownloadMenu.vue";
import SelectRole from "@/components/SelectRole.vue";

const router = useRouter();
const route = useRoute();
const store = useGlobalStore();
const {t} = useI18n();
const isAdminOrRoot = ref(store.user?.app.role === Role.Admin || store.user?.app.role === Role.Root);
const termsConsentedAtDateFilter = ref<Date[]>([]);
const showExportDialog = ref(false);
const exportFormat = ref("csv");
const loading = ref(false);
const users: Ref<DashUser[]> = ref([]);
const paginatedData: Ref<Paginated<DashUser> | null> = ref(null);
let paginationFooterData: FooterData = footerDataFactory();
const showExportingSnackBar = ref<boolean>(false);

const termsConsentedAtDateFilterMenu = ref();
const createdAtDateFilterMenu = ref();
const searchString = ref(route.query.search as string);

const updateRoleFilter = (value: Role) => {
  columns.role.filter = value;
  columns.role.filterText = value ? t(`roles.${value}`) : "";
};

const cols: ColumnsConfig = {
  id: {
    title: t("components.UserTablePage.table_headers.id"),
    modelKey: "_id",
    visible: false,
    filter: "",
    filterText: ""
  },
  tenant: {
    title: t("components.UserTablePage.table_headers.tenant"),
    modelKey: "tenant.title",
    visible: true,
    filter: "",
    filterText: ""
  },
  institution: {
    title: t("components.UserTablePage.table_headers.institution"),
    modelKey: "institution.title",
    visible: true,
    filter: "",
    filterText: ""
  },
  cohort: {
    title: t("components.UserTablePage.table_headers.cohort"),
    modelKey: "cohort.title",
    visible: true,
    filter: "",
    filterText: ""
  },
  accessCode: {
    title: t("components.UserTablePage.table_headers.access_code"),
    modelKey: "accesscode.code",
    visible: true,
    filter: "",
    filterText: ""
  },
  email: {
    title: t("components.UserTablePage.table_headers.email"),
    modelKey: "email",
    visible: true,
    filter: "",
    filterText: ""
  },
  phone: {
    title: t("components.UserTablePage.table_headers.phone"),
    modelKey: "phone",
    visible: true,
    filter: "",
    filterText: ""
  },
  firstName: {
    title: t("components.UserTablePage.table_headers.first_name"),
    modelKey: "profile.firstName",
    visible: true,
    filter: "",
    filterText: ""
  },
  lastName: {
    title: t("components.UserTablePage.table_headers.last_name"),
    modelKey: "profile.lastName",
    visible: true,
    filter: "",
    filterText: ""
  },
  accountType: {
    title: t("components.UserTablePage.table_headers.account_type"),
    modelKey: "app.accountType",
    visible: true,
    filter: "",
    filterText: ""
  },
  role: {
    title: t("components.UserTablePage.table_headers.role"),
    modelKey: "app.role",
    visible: true,
    filter: "",
    filterText: ""
  },
  termsConsent: {
    title: t("components.UserTablePage.table_headers.terms_consent"),
    modelKey: "consents.terms.granted",
    visible: false,
    filter: "",
    filterText: ""
  },
  termsConsentedAt: {
    title: t("components.UserTablePage.table_headers.terms_consented_at"),
    modelKey: "consents.terms.updatedAt",
    visible: true,
    filter: [],
    filterText: ""
  },
  createdAt: {
    title: t("components.UserTablePage.table_headers.created_at"),
    modelKey: "createdAt",
    visible: true,
    filter: [],
    filterText: ""
  },
};

const {
  columns,
  headers,
  page,
  perPage,
  sort,
  activeFilterCount,
  createdAtDateFilter,
  selectedTenant,
  selectedInstitution,
  selectedCohort,
  setupTenantFilters,
  watchDateFilter,
  resetFilter,
  createUrlFilterParameters,
  onUpdatePerPage,
  onSort,
  onNext,
  onPrev,
  onPageChange,
  setupFooterData,
  setupBasicParams
} = usePagination(fetchPage, {
  initialItemsPerPage: 20,
  initialColumns: cols,
  initialSort: [{
    key: "createdAt",
    order: "desc"
  }]
});

onMounted(async () => {
  if (searchString.value) {
    handleSearch(searchString.value);
    return;
  }
  setupPageParams();
  await setupTenantFilters(
    columns.tenant.filter as string,
    columns.institution.filter as string,
    columns.cohort.filter as string
  );
  await fetchPage();
});

const emailFilter: WritableComputedRef<string> = computed({
  get: (): string => columns.email.filter.toString(),
  set: (val: string | string[]) => {
    columns.email.filter = val.toString();
  }
});

const phoneFilter: WritableComputedRef<string> = computed({
  get: (): string => columns.phone.filter.toString(),
  set: (val: string | string[]) => {
    columns.phone.filter = val.toString();
  }
});

const firstNameFilter: WritableComputedRef<string> = computed({
  get: (): string => columns.firstName.filter.toString(),
  set: (val: string | string[]) => {
    columns.firstName.filter = val.toString();
  }
});

const lastNameFilter: WritableComputedRef<string> = computed({
  get: (): string => columns.lastName.filter.toString(),
  set: (val: string | string[]) => {
    columns.lastName.filter = val.toString();
  }
});

/**
 * Watch for changes to the search query param when using global search
 */
watch(() => route.query.search, async (newSearchValue) => {
  if (newSearchValue && newSearchValue !== searchString.value) {
    resetFilter();
    handleSearch(newSearchValue as string);
  }
});

watchDateFilter(termsConsentedAtDateFilter, columns.termsConsentedAt);
watchDateFilter(createdAtDateFilter, columns.createdAt);

function handleReset() {
  searchString.value = "";
  resetFilter();
}
/**
 * Export a CSV or Excel of documents based on the current filters
 * @param selected The selected fields to export
 */
async function exportPages(selected: UserExportSelectors) {
  // Close the dialog
  showExportDialog.value = false;
  try {
    store.networkBusy = true;
    loading.value = true;
    showExportingSnackBar.value = true;

    const params = {
      ...filterParams.value,
      format: "csv"
    };

    if (exportFormat.value === "excel")
      params.format = "excel";

    const res = await yapi.admin.user.export({selectors: selected}, params);

    const now = new Date();
    const timestamp = now.toLocaleString();
    if (exportFormat.value === "excel") {
      downloadExcel(res.data.content.toString(), `user-${timestamp}`);
    } else {
      downloadCSV(res.data.content.toString(), `user-${timestamp}`);
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    showExportingSnackBar.value = false;
    loading.value = false;
    store.networkBusy = false;
  }
}

async function fetchPage() {
  const params: UserPaginationParams = {
    page: page.value,
    size: perPage.value,
    sortBy: sort.value[0].key,
    sortOrder: sort.value[0].order,
    ...filterParams.value
  };

  try {
    store.networkBusy = true;
    paginatedData.value = (await yapi.admin.user.paginate(params)).data;
    await router.push({query: createUrlFilterParameters(params)});
    users.value = paginatedData.value.docs;
    const footerData = Object.assign(paginatedData.value);
    footerData.totalPages = paginatedData.value?.hasNextPage
      ? paginatedData.value.page + 1
      : paginatedData.value.page;
    paginationFooterData = setupFooterData(footerData);
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    store.networkBusy = false;
  }
}

const filterParams = computed(() => ({
  filterEmail: columns.email.filter as string || undefined,
  filterPhone: columns.phone.filter as string || undefined,
  filterFirstName: columns.firstName.filter as string || undefined,
  filterLastName: columns.lastName.filter as string || undefined,
  filterTermsConsent: columns.termsConsent.filter as string || undefined,
  filterAccountType: columns.accountType.filter as AccountType || undefined,
  filterTenant: columns.tenant.filter as string || undefined,
  filterInstitution: columns.institution.filter as string || undefined,
  filterCohort: columns.cohort.filter as string || undefined,
  filterRole: columns.role.filter as Role || undefined,
  filterCreatedAtStart: DateTime.fromISO(columns.createdAt.filter[0])
    .startOf("day")
    .toISO() as string || undefined,
  filterCreatedAtEnd: DateTime.fromISO(columns.createdAt.filter[1])
    .endOf("day")
    .toISO() as string || undefined,
  filterTermsConsentedAtStart: DateTime.fromISO(columns.termsConsentedAt.filter[0])
    .startOf("day")
    .toISO() as string || undefined,
  filterTermsConsentAtEnd: DateTime.fromISO(columns.termsConsentedAt.filter[0])
    .endOf("day")
    .toISO() as string || undefined
}));

function handleExportClick(format: string) {
  showExportDialog.value = true;
  exportFormat.value = format;
}

/**
 * Generate the params for pagination or export
 */
function setupPageParams() {
  setupBasicParams(page, perPage, sort, route);
  columns.email.filter = route.query.filterEmail as string || columns.email.filter;
  columns.phone.filter = route.query.filterPhone as string || columns.phone.filter;
  columns.firstName.filter = route.query.filterFirstName as string || columns.firstName.filter;
  columns.lastName.filter = route.query.filterLastName as string || columns.lastName.filter;
  columns.accountType.filter = route.query.filterAccountType as AccountType || columns.accountType.filter;
  columns.termsConsent.filter = route.query.filterTermsConsent as string || columns.termsConsent.filter;
  columns.tenant.filter = route.query.filterTenant as string || columns.tenant.filter;
  columns.institution.filter = route.query.filterInstitution as string || columns.institution.filter;
  columns.cohort.filter = route.query.filterCohort as string || columns.cohort.filter;
  columns.role.filter = route.query.filterRole as Role || columns.role.filter;
}

const onClickRow = async (event: Event, row: { internalItem: { raw?: DashUser } }) => {
  try {
    if (row.internalItem && row.internalItem.raw) {
      const userId = row.internalItem.raw._id;
      if (userId) {
        await openUserDetail(userId);
      }
    }
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  }
};

function handleSearch(searchText: string) {
  columns.email.filter = searchText;
  columns.email.filterText = searchText;
  columns.phone.filter = searchText;
  columns.phone.filterText = searchText;
  columns.firstName.filter = searchText;
  columns.firstName.filterText = searchText;
  columns.lastName.filter = searchText;
  columns.lastName.filterText = searchText;
  searchString.value = searchText;
}

const openUserDetail = async (id: string) => {
  await router.push({
    name: "userDetail",
    params: {id}
  });
};
</script>

<style>
.v-data-table tbody tr:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12) !important;
}
</style>
