<script setup lang="ts">
import {useGlobalStore} from "@/store";

const store = useGlobalStore();

const logo = store.appConfiguration?.images?.logo;
const appName = store.appConfiguration?.appName;
</script>

<template>
  <v-app-bar flat>
    <template v-slot:prepend>
      <img v-if="logo" :src="logo" height="35" class="ml-6" />
      <h1 v-else class="pl-6">{{ appName }}</h1>
    </template>
  </v-app-bar>
</template>
