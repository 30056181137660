<script setup lang="ts">
import {computed, PropType} from "vue";
import {useI18n} from "vue-i18n";
import {IUserHollandCodesReportData} from "@/pages/ReportsDetailPage/reportTypes";

const {t} = useI18n();

defineProps({
  reportData: {
    type: Array as PropType<IUserHollandCodesReportData[]>,
    required: true
  },
  loadingData: {
    type: Boolean,
    required: true
  }
});

const headers = computed(
  () => [
    {
      title: t("components.ReportsDetailPage.holland_code_headers.tenant"),
      key: "tenant.title"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.institution"),
      key: "institution.title"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.cohort"),
      key: "cohort.title"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.email"),
      key: "email"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.phone"),
      key: "phone"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.full_name"),
      key: "fullName"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.primary"),
      key: "primary_code"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.secondary"),
      key: "secondary_code"
    },
    {
      title: t("components.ReportsDetailPage.holland_code_headers.tertiary"),
      key: "tertiary_code"
    },
  ]
);

function getWorkerTypeColor(code: string) {
  if (code === "R")
    return "#fc631d";

  if (code === "I")
    return "#0487ff";

  if (code === "A")
    return "#1eac30";

  if (code === "S")
    return "#ff5d5d";

  if (code === "E")
    return "#514ee4";

  if (code === "C")
    return "#ff3520";
}

</script>

<template>
  <v-data-table
    :items="reportData"
    :headers="headers"
    :loading="loadingData"
    :items-per-page="50"
  >
    <template v-slot:item.primary_code="{ item }: { item: IUserHollandCodesReportData}">
      <v-chip :color='getWorkerTypeColor(item["primary_code"])'>{{
        !item["primary_code"]
          ? $t('components.ReportsDetailPage.reports.users_holland_codes_report.no_results')
          : $t(`assessments.holland_code.${item["primary_code"]}`)
      }}
      </v-chip>
    </template>
    <template v-slot:item.secondary_code="{ item }: { item: IUserHollandCodesReportData}">
      <v-chip :color='getWorkerTypeColor(item["secondary_code"])'>
        {{
          !item["secondary_code"]
            ? $t('components.ReportsDetailPage.reports.users_holland_codes_report.no_results')
            : $t(`assessments.holland_code.${item["secondary_code"]}`)
        }}
      </v-chip>
    </template>
    <template v-slot:item.tertiary_code="{ item }: { item: IUserHollandCodesReportData}">
      <v-chip :color='getWorkerTypeColor(item["tertiary_code"])'>
        {{
          !item["tertiary_code"]
            ? $t('components.ReportsDetailPage.reports.users_holland_codes_report.no_results')
            : $t(`assessments.holland_code.${item["tertiary_code"]}`)
        }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<style scoped>

</style>
