<script setup lang="ts">
import UserHollandCodesDataTable from "@/pages/ReportsDetailPage/components/UserHollandCodesDataTable.vue";
import yapi from "@/lib/yapi";
import {isYapiError, YapiError} from "@YenzaCT/sdk";
import {downloadCSV, downloadExcel} from "@/lib/fileDownload";
import {useGlobalStore} from "@/store";
import {PropType, ref} from "vue";
import {IUserHollandCodesReportData} from "@/pages/ReportsDetailPage/reportTypes";
import DataExportDownloadMenu from "@/components/DataExportDownloadMenu.vue";

const store = useGlobalStore();
const loading = ref(false);
const tableData = ref<IUserHollandCodesReportData[]>([]);

const props = defineProps({
  tenantId: {
    type: String,
    required: false,
    default: null
  },
  cohortId: {
    type: String,
    required: false,
    default: null
  },
  institutionIds: {
    type: Array as PropType<string[]>,
    required: false,
    default: null
  },
  timeFilter: {
    type: Object as PropType<{ dateFilterType: string, unit: string, value: string | string[] }[]>,
    required: true,
    default: null
  },
});

async function fetchReportData() {
  try {
    store.networkBusy = true;
    loading.value = true;
    const res = await yapi.admin.reports.post("users-holland-codes", {
      institutionIds: props.institutionIds,
      cohortId: props.cohortId,
      tenantId: props.tenantId,
      time: props.timeFilter
    });
    if (res.data)
      tableData.value = res.data as unknown as IUserHollandCodesReportData[];
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
    store.networkBusy = false;
  }
}

async function exportData(format: string) {
  try {
    loading.value = true;
    store.networkBusy = true;
    const res = await yapi.admin.reports.export("users-holland-codes", {
      institutionIds: props.institutionIds,
      cohortId: props.cohortId,
      tenantId: props.tenantId,
      time: props.timeFilter,
      format
    });
    if (res.data.format === "excel")
      downloadExcel(res.data.content.toString(), "users-holland-codes");
    else downloadCSV(res.data.content.toString(), "users-holland-codes");
  } catch (e) {
    if (isYapiError(e)) {
      const yError = e as YapiError;
      await store.handleYapiError(yError);
    } else {
      throw e;
    }
  } finally {
    loading.value = false;
    store.networkBusy = false;
  }
}

fetchReportData();

</script>

<template>
  <v-card variant="elevated">
    <template #append>
      <data-export-download-menu :formats="['csv', 'excel']" @click="exportData"/>
    </template>
    <v-card-text>
      <user-holland-codes-data-table :loading-data="loading" :report-data="tableData"/>
    </v-card-text>
  </v-card>
</template>

<style scoped>

</style>
